import React, { useEffect, useState } from "react";
import InvoiceTable from "../../components/tables/InvoiceTable";

import { Link } from "react-router-dom";
import { colors } from "../../config/colors";

import { useDispatch, useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../../config/config";
import axios from "axios";
import Overlay from "../../components/common/Overlay";
import { edit, deleteicon } from "../../config/images";
import WebNavbar from "../../components/common/WebNavbar";
import WebSidebar from "../../components/common/WebSidebar";
import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useRef } from "react";
import { plus, minus } from "../../config/images";
import Select from "react-select";

const WebAdminAddProduct = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  var textobj = {sequenceid: 1, data: {template: "text", data:{"subtitle": "", "title": "", "description": ""}}}
  var ratingobj = {sequenceid: 1, data: {template: "rating", data:{title: "Fastness Properties", ratingdata: [{'name': "", 'rating': ""}]}}}
  const userdata = useSelector((store) => store.userReducer.userdata);
  const [showloding, setshowloding] = useState(false);
  const [productname, setproductname] = useState(null);
  const [category, setcategory] = useState(0);
  const [categorydata, setcategorydata] = useState([]);
  const [subcategory, setsubcategory] = useState(0);
  const [subcategorydata, setsubcategorydata] = useState([]);
  const [threetiercategory, setthreetiercategory] = useState(1);
  const [threetiercategorydata, setthreetiercategorydata] = useState([]);
  const [rating, setrating] = useState(0);
  const [ratingnumber, setratingnumber] = useState(0);
  const [kevincode, setkevincode] = useState(null);
  const [tagsdata, settagsdata] = useState([]);
  const [filtertagsdata, setfiltertagsdata] = useState([]);
  const [description, setdescription] = useState(null);
  const [imagesarr, setimagesarr] = useState([]);
  const [selectedtags, setselectedtags] = useState([]);
  const [selectedfiltertags, setselectedfiltertags] = useState([]);
  const [masstone, setmasstone] = useState(null);
  const [tinttone, settinttone] = useState(null);
  const [technicalsheet, settechnicalsheet] = useState();
  const [safetysheet, setsafetysheet] = useState();
  const [cas, setcas] = useState(null);
  const [ci, setci] = useState(null);
  const [chemicalclass, setchemicalclass] = useState(null);
  const [sectionsarr, setsectionsarr] = useState([textobj]);
  const [updatestate, setupdatestate] = useState(false);
  const [applicationarr, setapplicationarr] = useState([
    { id: 1, application: '', kevincode: '', imagesarr: [], tags: [] }
  ]);

  const sectionsarrRef = useState(null);
  sectionsarrRef.current = sectionsarr;

  const imagesarrRef = useRef(null);
  imagesarrRef.current = imagesarr;

  const applicationarrRef = useRef(null);
  applicationarrRef.current = applicationarr;

  const handlesubmit = async () => {
    setshowloding(true)
    var data = {
      productname: productname,
      categoryid: category,
      subcategoryid: subcategory,
      threetiercategoryid: threetiercategory,
      rating: rating,
      ratingcount: ratingnumber,
      kevincode: kevincode,
      description: description,
      imagesarr: imagesarrRef?.current,
      tags: selectedtags,
      filtertags: selectedfiltertags,
      applicationarrarr: applicationarrRef?.current,
      masstone: masstone,
      tinttone: tinttone,
      technicalsheet: technicalsheet,
      safetysheet: safetysheet,
      cas: cas,
      ci: ci,
      chemicalclass: chemicalclass,
      sectionsarr : sectionsarrRef.current
    };
    console.log("data >>", data);
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userdata?.token,
      },
    };
    let resp = await axios
      .post(apiUrl + "webadminaddproduct", data, headers)
      .then((res) => {
        // console.log('res >>>', res.data)
        if (res.data.status == "success") {
          navigate('/webadmin/product-lists');
          setshowloding(false);
        } else {
          alert(res.data.message);
          setshowloding(false);
        }
      })
      .catch((err) => {
        setshowloding(false);
        alert(err.message);
      });
    setshowloding(false);
    
  };

  const handleinputchangeapplicationarr = (index,name, text) => {
     var prevapplicationdata = applicationarrRef.current;
     prevapplicationdata[index][name] = text;
     console.log('prevapplicationdata >>', prevapplicationdata);
     setapplicationarr(prevapplicationdata)
     setupdatestate(!updatestate);
  }

  const onplussectionfunction = async() => {
    var textobj = {sequenceid: 1, data: {template: "text", data:{"subtitle": "", "title": "", "description": ""}}}
    var prevdata = sectionsarrRef.current;
     textobj['sequenceid'] = parseInt(sectionsarrRef.current.length) + 1;
    prevdata.push(textobj);
    setsectionsarr(prevdata);
    setupdatestate(!updatestate);
    // var ratingobj = {sequenceid: 1, data: {template: "rating", data:{"title": "Fastness Properties", "ratingdata": [{'name': "", 'rating': ""}]}}}
  }

  const onminussectionfunction = async() => {
    var prevdata = sectionsarrRef.current;
    if(prevdata.length > 1) {
        var newarr = prevdata.slice(0, -1);
        setsectionsarr(newarr);
        setupdatestate(!updatestate);
    }
  }

  const handlechangetemplate = async(index, text) => {
    var textobj = {"subtitle": "", "title": "", "description": ""}
    var ratingobj = {"title": "", "ratingdata": [{'name': "", 'rating': ""}], "type": text}
    var ratingpercent = {"title": "", "ratingdata": [{'name': "", 'ratingvalue': ""}], "type": text}
    var prevdata = sectionsarrRef.current;
    prevdata[index]['data']['template'] = text;
    if(text == 'text') {
        prevdata[index]['data']['data'] = textobj;
    } else if(text == 'ratingpercentage') {
      prevdata[index]['data']['data'] = ratingpercent;
    }
    else {
        prevdata[index]['data']['data'] = ratingobj;
    }
    setsectionsarr(prevdata)
    setupdatestate(!updatestate);
  }

  const handlechangeinputsections = async(index, template, name, text) => {
    var prevdata = sectionsarrRef.current;
    if(template == 'text') {
        prevdata[index]['data']['data'][name] = text;
    } else {
        prevdata[index]['data']['data'][name] = text;
    }
    setsectionsarr(prevdata)
    setupdatestate(!updatestate);
  }

  const handlechangeinputsections1 = async(index, subindex,name, text) => {
    var prevdata = sectionsarrRef.current;
    prevdata[index]['data']['data']['ratingdata'][subindex][name] = text;
    setsectionsarr(prevdata)
    setupdatestate(!updatestate);
  }

  const onplusappendratingdiv = async(index, template) => {
    var prevdata = sectionsarrRef.current;
    var ratingdataarr = prevdata[index]['data']['data']['ratingdata'];
    var obj = {'name': "", 'rating': ""};
    var percentobj = {'name': "", 'ratingvalue': ""};
    if(template == 'ratingpercentage') {
      ratingdataarr.push(percentobj);
    } else {
      ratingdataarr.push(obj);
    }
    prevdata[index]['data']['data']['ratingdata'] = ratingdataarr;
    console.log('ratingdata >>', prevdata[index]['data']['data']['ratingdata']);
    setsectionsarr(prevdata)
    setupdatestate(!updatestate);
  }

  const onminusremoveratingdiv = async(index) => {
    var prevdata = sectionsarrRef.current;
    var ratingdataarr = prevdata[index]['data']['data']['ratingdata'];
    if(ratingdataarr.length > 1) {
        var newarr = ratingdataarr.slice(0,-1)
        prevdata[index]['data']['data']['ratingdata'] = newarr;
        setsectionsarr(prevdata)
        setupdatestate(!updatestate);
    }
  }

  const onplusfunction = () => {
    var obj = {
      id: 1,
      application: '',
      kevincode: '',
      imagesarr: [],
      tags: []
    };
    setapplicationarr((a) => [...a, obj]);
  };
  const onminusfunction = () => {
    console.log("onminusfunction ...");
    if (applicationarrRef.current.length == 1) {
    } else {
      var newarr = applicationarrRef.current.slice(0, -1);
      setapplicationarr(newarr);
    }
  };

  const fetchcategorydata = async () => {
    setshowloding(true);
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userdata?.token,
      },
    };
    let resp = await axios
      .get(apiUrl + "maincategorylist", headers)
      .then((res) => {
        // console.log('res >>>', res.data)
        if (res.data.status == "success") {
          setcategorydata(res.data.data);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
    setshowloding(false);
  };

  const fetchsubcatdatabyid = async (id) => {
    var data = {
      categoryid: id,
    };
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userdata?.token,
      },
    };
    let resp = await axios
      .post(apiUrl + "subcategorybyid", data, headers)
      .then((res) => {
        // console.log('res >>>', res.data)
        if (res.data.status == "success") {
          setsubcategorydata(res.data.data);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const fetchthreetiercatdatabyid = async (id) => {
    var data = {
      subcategory: id,
    };
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userdata?.token,
      },
    };
    let resp = await axios
      .post(apiUrl + "getthreetiercategorybyid", data, headers)
      .then((res) => {
        // console.log('res >>>', res.data)
        if (res.data.status == "success") {
          setthreetiercategorydata(res.data.data);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        // console.log("Called", reader);
        baseURL = reader.result;
        // console.log(baseURL);
        resolve(baseURL);
      };
      // console.log(fileInfo);
    });
  };

  const handleinputchange = async (name, text) => {
    var imgarr = [];
    for (var i = 0; i < text.length; i++) {
      var obj = {};
      await getBase64(text[i]).then((result) => {
        // console.log('result ...', result);
        // base64_decode($base64encodedstring)
        obj["name"] = text[i].name;
        obj["image"] = result;
      });
      imgarr.push(obj);
      // setdocumentsarr(a => [...a,obj ]);
    }
    console.log("imgarr >>", imgarr);
    setimagesarr(imgarr);
  };

  const handleapplicationimage = async (index, text) => {
    var imgarr = [];
    for (var i = 0; i < text.length; i++) {
      var obj = {};
      await getBase64(text[i]).then((result) => {
        // console.log('result ...', result);
        // base64_decode($base64encodedstring)
        obj["name"] = text[i].name;
        obj["image"] = result;
      });
      imgarr.push(obj);
      // setdocumentsarr(a => [...a,obj ]);
    }
    console.log("handleapplicationimage imgarr >>", imgarr);

    var prevapplicationdata = applicationarrRef.current;
     prevapplicationdata[index]['imagesarr'] = imgarr;
     setapplicationarr(prevapplicationdata)
     setupdatestate(!updatestate);
    // setimagesarr(imgarr);
  };

  const handleinputchange1 = async (name, file) => {
    var obj = {};
    await getBase64(file).then((result) => {
      obj["name"] = name;
      obj["image"] = result;
    });
    if (name == "technical") {
      settechnicalsheet(obj);
    } else {
      setsafetysheet(obj);
    }
  };

  const fetchtagsdata = async () => {
    setshowloding(true);
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userdata?.token,
      },
    };
    let resp = await axios
      .get(apiUrl + "gettagslist", headers)
      .then((res) => {
        // console.log('res >>>', res.data)
        if (res.data.status == "success") {
          //
          var tgdata = res.data.data;
          var tgdataarr = [];
          for (var i = 0; i < tgdata.length; i++) {
            var obj = {};
            obj["value"] = tgdata[i].name;
            obj["label"] = tgdata[i].name;
            tgdataarr.push(obj);
          }
          settagsdata(tgdataarr);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
    setshowloding(false);
  };

  const fetchfiltertagsdata = async() => {
    setshowloding(true);
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userdata?.token,
      },
    };
    let resp = await axios
      .get(apiUrl + "getfiltertagslist", headers)
      .then((res) => {
        // console.log('res >>>', res.data)
        if (res.data.status == "success") {
          //
          var tgdata = res.data.data;
          var tgdataarr = [];
          for (var i = 0; i < tgdata.length; i++) {
            var obj = {};
            obj["value"] = tgdata[i].name;
            obj["label"] = tgdata[i].name;
            tgdataarr.push(obj);
          }
          setfiltertagsdata(tgdataarr);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
    setshowloding(false);
  }

  const handleChange = (selectedOption) => {
    console.log("selectedOption >>", selectedOption);
    var selecttagarr = [];
    for (var i = 0; i < selectedOption.length; i++) {
      selecttagarr.push(selectedOption[i].value);
    }
    setselectedtags(selecttagarr);
  };

  const handleChange1 = (selectedOption) => {
    console.log("selectedOption >>", selectedOption);
    var selecttagarr = [];
    for (var i = 0; i < selectedOption.length; i++) {
      selecttagarr.push(selectedOption[i].value);
    }
    setselectedfiltertags(selecttagarr);
  };
  const handleChange2 = (selectedOption, index) => {
    console.log("selectedOption >>", selectedOption);
    var selecttagarr = [];
    for (var i = 0; i < selectedOption.length; i++) {
      selecttagarr.push(selectedOption[i].value);
    }
    var prevapplicationdata = applicationarrRef.current;
     prevapplicationdata[index]['tags'] = selecttagarr;
     setapplicationarr(prevapplicationdata)
     setupdatestate(!updatestate);
    // setselectedfiltertags(selecttagarr);
  };

  useEffect(() => {
    if (category > 0) {
      fetchsubcatdatabyid(category);
    } else {
    }
  }, [category]);

  useEffect(() => {
    if (subcategory > 0) {
      fetchthreetiercatdatabyid(subcategory);
    } else {
    }
  }, [subcategory]);

  useEffect(() => {
    if (userdata && Object.keys(userdata).length > 0) {
      fetchcategorydata();
      fetchtagsdata();
      fetchfiltertagsdata();
    } else {
      navigate("/WebAdminlogin");
    }
  }, []);
  return (
    <div className="HomeMain">
      <Overlay showloader={showloding} />
      <div className="sidebar-div">
        <WebSidebar name={"Admin"} />
      </div>
      <div className="main-layout">
        <WebNavbar name={"Admin"} />
        <div className="mainDiv">
          <p className="oneplaceHead">ADD PRODUCT</p>
          <div className="addproductform">
            <div className="row">
              {/* name */}
              <div className="col-md-3">
                <div className="addproduct-formbox">
                  <label>Product Name</label>
                  <input
                    value={productname}
                    onChange={(t) => {
                      setproductname(t.target.value);
                    }}
                    type="text"
                    className="form-control tabinput"
                    placeholder="Enter Product Name"
                  />
                </div>
              </div>
              {/* category */}
              <div className="col-md-3">
                <div className="addproduct-formbox">
                  <label>Category</label>
                  <select
                    value={category}
                    onChange={(t) => {
                      setcategory(t.target.value);
                    }}
                    className="form-control tabinput"
                    required
                  >
                    <option value="0">Select category</option>
                    {categorydata?.map((item, index) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                  </select>
                </div>
              </div>
              {/* sub category */}
              <div className="col-md-3">
                <div className="addproduct-formbox">
                  <label>Sub Category</label>
                  <select
                    value={subcategory}
                    onChange={(t) => {
                      setsubcategory(t.target.value);
                    }}
                    className="form-control tabinput"
                    required
                  >
                    <option value="0">Select subcategory</option>
                    {subcategorydata?.map((item, index) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                  </select>
                </div>
              </div>
              {/* three tier category */}
              <div className="col-md-3">
                <div className="addproduct-formbox">
                  <label>3-Tier Category</label>
                  <select
                    value={threetiercategory}
                    onChange={(t) => {
                      setthreetiercategory(t.target.value);
                    }}
                    className="form-control tabinput"
                    required
                  >
                    <option value="1">All</option>
                    {threetiercategorydata?.map((item, index) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                  </select>
                </div>
              </div>
              {/* rating star */}
              <div className="col-md-3">
                <div className="addproduct-formbox">
                  <label>Rating Star</label>
                  <input
                    value={rating}
                    onChange={(t) => {
                      setrating(t.target.value);
                    }}
                    type="number"
                    className="form-control tabinput"
                    placeholder="Enter Rating"
                  />
                </div>
              </div>
              {/* rating survey number */}
              <div className="col-md-3">
                <div className="addproduct-formbox">
                  <label>Rating Survey Number</label>
                  <input
                    value={ratingnumber}
                    onChange={(t) => {
                      setratingnumber(t.target.value);
                    }}
                    type="number"
                    className="form-control tabinput"
                    placeholder="Enter Rating"
                  />
                </div>
              </div>
              {/* Chemical Class */}
              <div className="col-md-3">
                <div className="addproduct-formbox">
                  <label>Chemical Class</label>
                  <input
                    value={chemicalclass}
                    onChange={(t) => {
                      setchemicalclass(t.target.value);
                    }}
                    type="text"
                    className="form-control tabinput"
                    placeholder="Enter Chemical Class"
                  />
                </div>
              </div>
              {/* Description */}
              <div className="col-md-3">
                <div className="addproduct-formbox">
                  <label>Description</label>
                  <textarea
                    value={description}
                    onChange={(t) => {
                      setdescription(t.target.value);
                    }}
                    type="number"
                    className="form-control tabinput"
                    placeholder="Enter product description"
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="row">
              {/* Mass Tone */}
              <div className="col-md-3">
                <div className="addproduct-formbox">
                  <label>Mass Tone</label>
                  <input
                    value={masstone}
                    onChange={(t) => {
                      setmasstone(t.target.value);
                    }}
                    type="text"
                    className="form-control tabinput"
                    placeholder="Mass Tone Hexcode without #"
                  />
                </div>
              </div>
              {/* Tint Tone */}
              <div className="col-md-3">
                <div className="addproduct-formbox">
                  <label>Tint Tone</label>
                  <input
                    value={tinttone}
                    onChange={(t) => {
                      settinttone(t.target.value);
                    }}
                    type="text"
                    className="form-control tabinput"
                    placeholder="Tint Tone Hexcode without #"
                  />
                </div>
              </div>
              {/* Technical Data sheet */}
              <div className="col-md-3">
                <div className="addproduct-formbox">
                  <label>Technical data sheet</label>
                  <input
                    onChange={(t) => {
                      handleinputchange1("technical", t.target.files[0]);
                    }}
                    accept="application/pdf"
                    type="file"
                    className="form-control tabinput"
                    placeholder="Tint Tone Hexcode without #"
                  />
                </div>
              </div>
              {/* Safety Data sheet */}
              <div className="col-md-3">
                <div className="addproduct-formbox">
                  <label>Safety Data Sheet</label>
                  <input
                    onChange={(t) => {
                      handleinputchange1("safety", t.target.files[0]);
                    }}
                    accept="application/pdf"
                    type="file"
                    className="form-control tabinput"
                  />
                </div>
              </div>
              {/* CAS No. */}
              <div className="col-md-3">
                <div className="addproduct-formbox">
                  <label>CAS number</label>
                  <input
                    value={cas}
                    onChange={(t) => {
                      setcas(t.target.value);
                    }}
                    type="text"
                    className="form-control tabinput"
                    placeholder="Enter CAS number"
                  />
                </div>
              </div>
              {/* CI No. */}
              <div className="col-md-3">
                <div className="addproduct-formbox">
                  <label>CI number</label>
                  <input
                    value={ci}
                    onChange={(t) => {
                      setci(t.target.value);
                    }}
                    type="text"
                    className="form-control tabinput"
                    placeholder="Enter CI number"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="addproduct-formbox">
                  <label>Tags</label>
                </div>
                <Select
                  isMulti
                  name="colors"
                  onChange={handleChange}
                  options={tagsdata}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>
              
            </div>
            {/* images and application row */}
            <div className="row">
              {/* images */}
              {/* <div className="col-md-4">
                <div className="addproduct-formbox">
                  <label>Upload Product Images</label>
                  <input
                    type="file"
                    multiple
                    accept="image/*"
                    // value={item['document']}
                    onChange={(t) => {
                      handleinputchange("admindocs", t.target.files);
                    }}
                    className="form-control tabinput"
                    placeholder="choose reference document"
                  />
                </div>
                {imagesarrRef.current?.length > 0 ? (
                  <div className="mt-2">
                    {imagesarrRef.current?.map((item, index) => {
                      return (
                        <p className="addproduct-imagename">{item.name}</p>
                      );
                    })}
                  </div>
                ) : null}
              </div> */}
              {/* tags */}
              
              {/* Filter tags */}
              {/* <div className="col-md-6">
                <div className="addproduct-formbox">
                  <label>Filter Tags</label>
                </div>
                <Select
                  isMulti
                  name="colors"
                  onChange={handleChange1}
                  options={filtertagsdata}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div> */}
              
            </div>


              <div className="row">
              <div className="addproduct-physicalproperties-titlediv">
                <p className="addproduct-physicalproperties-head">
                  Applications & Kevin Code
                </p>
                <div className="addproduct-applicationrightdiv">
                  <button
                    className="plusbox"
                    onClick={(e) => {
                      onplusfunction();
                    }}
                  >
                    <img src={plus} className="plusicon" />
                  </button>
                  <button
                    className="plusbox"
                    onClick={() => {
                      onminusfunction();
                    }}
                  >
                    <img src={minus} className="plusicon" />
                  </button>
                </div>
              </div>
              <table className="table table-bordered invoicetable mt-3">
                <thead>
                  <th>Sr no.</th>
                  <th>Kevin Code</th>
                  <th>Application</th>
                  <th>Images</th>
                  <th>Filter Tags</th>
                </thead>
                <tbody id="appendbody">
                    {
                        applicationarrRef?.current?.map((item, index) => {
                            // console.log('item >>',Object.keys(item.data));
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <input
                                    value={item?.kevincode}
                                    onChange={(t) => {
                                      handleinputchangeapplicationarr(
                                        index,
                                        "kevincode",
                                        t.target.value
                                      );
                                    }}
                                    type="text"
                                    className="form-control tabinput"
                                    placeholder="Enter Code"
                                  />
                                </td>
                                <td>
                                  <div className={index > 0 ? "mt-2" : null}>
                                    <input
                                      value={item?.application}
                                      onChange={(t) => {
                                        handleinputchangeapplicationarr(
                                          index,
                                          "application",
                                          t.target.value
                                        );
                                      }}
                                      type="text"
                                      className="form-control tabinput"
                                      placeholder="Enter Application"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className={index > 0 ? "mt-2" : null}>
                                    <input
                                      onChange={(t) => {
                                        handleapplicationimage(
                                          index,
                                          t.target.files
                                        );
                                      }}
                                      type="file"
                                      multiple
                                      accept="image/*"
                                      className="form-control tabinput"
                                      placeholder="Upload Image"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <Select
                                    isMulti
                                    name="colors"
                                    onChange={(t)=> {handleChange2(t, index)}}
                                    options={filtertagsdata}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                  />
                                </td>
                              </tr>
                            );
                        })
                    }
                </tbody>
              </table>
              </div>
            {/* Physical Properties */}
            <div className="row">
              <div className="addproduct-physicalproperties-titlediv">
                <p className="addproduct-physicalproperties-head">
                  Physical Properties
                </p>
                <div className="addproduct-applicationrightdiv">
                  <button
                    className="plusbox"
                    onClick={(e) => {
                      onplussectionfunction();
                    }}
                  >
                    <img src={plus} className="plusicon" />
                  </button>
                  <button
                    className="plusbox"
                    onClick={() => {
                      onminussectionfunction();
                    }}
                  >
                    <img src={minus} className="plusicon" />
                  </button>
                </div>
              </div>
              <table className="table table-bordered invoicetable mt-3">
                <thead>
                  <th>Sr no.</th>
                  <th>Template</th>
                  <th>Content</th>
                </thead>
                <tbody id="appendbody">
                  {/* {sectionsarrRef.current?.length > 0
                    ? sectionsarrRef.current?.map((item, index) => {
                        return <div className=""></div>;
                      })
                    : null} */}
                    {
                        sectionsarrRef.current?.map((item, index) => {
                            // console.log('item >>',Object.keys(item.data));
                            return (
                              <tr key={index}>
                                <td>{item.sequenceid}</td>
                                <td>
                                  <select
                                    value={item?.data?.template}
                                    onChange={(t) => {
                                      handlechangetemplate(
                                        index,
                                        t.target.value
                                      );
                                    }}
                                    className="form-control tabinput"
                                    required
                                  >
                                    <option value="text">Text</option>
                                    <option value="rating5">Rating Out of 5</option>
                                    <option value="rating8">Rating Out of 8</option>
                                    <option value="ratingpercentage">Rating Percentage</option>
                                  </select>
                                </td>
                                <td style={{ maxWidth: 150, minWidth: 150 }}>
                                  {item.data?.template == "text" ? (
                                    <div className="templateform texttemplate">
                                      <input
                                        value={item.data?.data?.subtitle}
                                        onChange={(t) => {
                                          handlechangeinputsections(index,'text','subtitle', t.target.value);
                                        }}
                                        type="text"
                                        className="form-control tabinput mb-2"
                                        placeholder="Enter Subtitle"
                                      />
                                      <input
                                        value={item.data?.data?.title}
                                        onChange={(t) => {
                                            handlechangeinputsections(index,'text','title', t.target.value);
                                        }}
                                        type="text"
                                        className="form-control tabinput mb-2"
                                        placeholder="Enter Title"
                                      />
                                      <textarea
                                      value={item.data?.data?.description}
                                      onChange={(t) => {
                                        handlechangeinputsections(index,'text','description', t.target.value);
                                    }}
                                        type="text"
                                        className="form-control tabinput mb-3"
                                        placeholder="Enter Description"
                                      ></textarea>
                                    </div>
                                  ) : (
                                    <div className="templateform ratingtemplate">
                                      <input
                                        value={item.data?.data?.title}
                                        onChange={(t) => {
                                            handlechangeinputsections(index,'rating','title', t.target.value);
                                        }}
                                        type="text"
                                        className="form-control tabinput mb-2 mt-2"
                                        placeholder="Enter Title"
                                      />
                                      <div className="ratingtemplate-div">
                                        <div className="addproduct-applicationrightdiv">
                                          <button
                                            className="plusbox"
                                            onClick={(e) => {
                                              onplusappendratingdiv(index, item?.data?.template);
                                            }}
                                          >
                                            <img
                                              src={plus}
                                              className="plusicon"
                                            />
                                          </button>
                                          <button
                                            className="plusbox"
                                            onClick={() => {
                                              onminusremoveratingdiv(index);
                                            }}
                                          >
                                            <img
                                              src={minus}
                                              className="plusicon"
                                            />
                                          </button>
                                        </div>
                                        <div className="ratingtemplate-contentdiv">
                                            {
                                                item?.data?.data?.ratingdata?.map((itm, idx) => {
                                                    return (
                                                      <div className="row">
                                                        <div className="col-md-8">
                                                          <input
                                                            value={itm.name}
                                                            onChange={(t) => {
                                                                handlechangeinputsections1(index,idx,'name', t.target.value);
                                                            }}
                                                            type="text"
                                                            className="form-control tabinput mb-2"
                                                            placeholder="Rating name"
                                                          />
                                                        </div>
                                                        <div className="col-md-4">
                                                          {
                                                            item?.data?.data?.type == 'rating5' ?
                                                            <select
                                                            value={itm?.rating}
                                                            onChange={(t) => {
                                                                handlechangeinputsections1(index,idx,'rating', t.target.value);
                                                            }}
                                                            className="form-control tabinput"
                                                            required
                                                          >
                                                            <option value="1">
                                                              1
                                                            </option>
                                                            <option value="2">
                                                              2
                                                            </option>
                                                            <option value="3">
                                                              3
                                                            </option>
                                                            <option value="4">
                                                              4
                                                            </option>
                                                            <option value="5">
                                                              5
                                                            </option>
                                                          </select> :
                                                          item?.data?.data?.type == 'rating8' ?
                                                          <select
                                                          value={itm?.rating}
                                                          onChange={(t) => {
                                                              handlechangeinputsections1(index,idx,'rating', t.target.value);
                                                          }}
                                                          className="form-control tabinput"
                                                          required
                                                        >
                                                          <option value="1">
                                                            1
                                                          </option>
                                                          <option value="2">
                                                            2
                                                          </option>
                                                          <option value="3">
                                                            3
                                                          </option>
                                                          <option value="4">
                                                            4
                                                          </option>
                                                          <option value="5">
                                                            5
                                                          </option>
                                                          <option value="6">
                                                            6
                                                          </option>
                                                          <option value="7">
                                                            7
                                                          </option>
                                                          <option value="8">
                                                            8
                                                          </option>
                                                        </select> : 
                                                        <input
                                                        value={itm?.ratingvalue}
                                                        onChange={(t) => {
                                                          handlechangeinputsections1(index,idx,'ratingvalue', t.target.value);
                                                        }}
                                                        type="text"
                                                        className="form-control tabinput mb-2"
                                                        placeholder="Value"
                                                      />
                                                          }
                                                          
                                                        </div>
                                                      </div>
                                                    );
                                                })
                                            }
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            );
                        })
                    }
                </tbody>
              </table>
            </div>
          </div>
          <div
            className="webadmin-modalbtn addproduct-btn"
            onClick={() => {
              handlesubmit();
            }}
          >
            <p>Submit</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebAdminAddProduct;
