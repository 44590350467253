import React, {useState} from 'react';
// import { Spinner } from "react-activity";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import axios from 'axios';
import { apiUrl } from '../../config/config';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'bootstrap';
import { logo2 } from '../../config/images';
import newlogotm2 from '../../assets/images/newlogo_r1.png';

import { useDispatch, useSelector } from "react-redux/es/exports";


const SuperAdminLogin = () => {
    const [username, setusername] = useState(null);
    const [password, setpassword] = useState();
    const [showloding, setshowloding] = useState(false);
    const dispatch = useDispatch(); 
    let navigate = useNavigate();
    const userdata = useSelector((store) => store.userReducer.userdata);
    console.log('SuperAdminLogin userdata >>', userdata);
    const loginpressed = async() => {
      // navigate('/customer/home');
        if(username && password) {
            setshowloding(true);
            var data = {
                'username' : username,
                'password': password
            }
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            }
            let resp = await axios.post(apiUrl + 'superadminlogin', data, headers).then((res) => {
                if(res.data.status == 'success') {
                  dispatch({ type: "userdata", payload: res.data.data })
                   navigate('/cms/superadmin/home');
                } else {
                  alert(res.data.message);
                }
            } ).catch((err) => {
                alert(err.message)
            })
            setshowloding(false);
        } else {
            alert('username and password is required');
        }
    }
    return (
      <div className="loginBoxMaindiv">
        <div className="loginBox">
        <img src={newlogotm2} style={{height: 60}} />
        <div className="mb-3 mt-3">
          <label for="email" className="form-label">
            Username:
          </label>
          <input
            type="text"
            value={username} onChange={(t) => {setusername(t.target.value)}}
            className="form-control"
            placeholder="Enter username"
          />
        </div>
        <div className="mb-3">
          <label for="pwd" className="form-label">
            Password:
          </label>
          <input
            type="password"
            value={password} onChange={(t) => {setpassword(t.target.value)}}
            className="form-control"
            id="pwd"
            placeholder="Enter password"
            name="pswd"
          />
        </div>
        <div>
            <button type="button" className="btn btn-primary custom-btn" onClick={() => {loginpressed()}}>
                {showloding ?
                <Spinner color='#fff' size={15} className='spinnerCenter' /> : 'Login'
            }
            </button>
        </div>
        
      </div>
      </div>
      
    );
}

export default SuperAdminLogin;