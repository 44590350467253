import React, { useState, useEffect } from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import InvoiceTable from "../../components/tables/InvoiceTable";
import { pdf } from "../../config/images";
import { colors } from "../../config/colors";
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from "react-redux/es/exports";
import { apiUrl } from "../../config/config";
import axios from 'axios';
import Overlay from "../../components/common/Overlay";
import { edit, deleteicon } from "../../config/images";
import {useLocation} from 'react-router-dom';
import { useParams } from 'react-router-dom';


const AdminRevisedQuoteRequest = () => {
    const [isapproved, setisapproved] = useState(false);
    const [showcomment, setshowcomment] = useState(false);
    const [isrejected, setisrejected] = useState(false);
    const [eta, seteta] = useState(null);
    const [validity1, setvalidity1] = useState(null);
    const [shipment, setshipment] = useState(null);
    const [price, setprice] = useState(null);
    const [units, setunits] = useState(null);
    const [kevincode, setkevincode] = useState(null);
    const [inco, setinco] = useState(null);
    const [dischargeport, setdischargeport] = useState(null);
    const [arrivalport, setarrivalport] = useState(null);
    const [showloding, setshowloding] = useState(false);
    const [requestsampledata, setrequestsampledata] = useState([]);
    const dispatch = useDispatch(); 
    const userdata = useSelector((store) => store.userReducer.userdata);
    let navigate = useNavigate();
    const { id } = useParams();

    const fetchrequestsampledata = async(id) => {
      setshowloding(true);
      var data = {
          'rfqid' : id,
      }
      const headers = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization' : 'Bearer ' + userdata?.token
          }
      }
      let resp = await axios.post(apiUrl + 'adminfetchrfqsingle',data, headers).then((res) => {
          // console.log('res >>>', res.data)
            if(res.data.status == 'success') {
              setrequestsampledata(res.data.data);
              setkevincode(res.data.data[0].kevincode)
              setinco(res.data.data[0].inco)
              setvalidity1(res.data.data[0].validity1)
              seteta(res.data.data[0].validity)
              setdischargeport(res.data.data[0].dischargeport)
              setarrivalport(res.data.data[0].arrivalport)
              setshipment(res.data.data[0].shipment)
              
              // dispatch({ type: "userdata", payload: res.data.data })
              //  navigate('/cms/customer/requestsample');
            } else {
              alert(res.data.message);
            }
        } ).catch((err) => {
            alert(err.message)
        })
      setshowloding(false)
  }

  const rfssamplereply = async() => {
    setshowloding(true);
    var data = {
      'rfqsid': id,
      'price': price,
      'units': units,
      'kevincode': kevincode,
      'inco': inco,
      'validity1': validity1,
      'validity' : eta,
      'shipment': shipment,
      'arrivalport': arrivalport,
      'dischargeport': dischargeport
      };
      // console.log('data .>', data)
    const headers = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + userdata?.token
        }
    }
    let resp = await axios.post(apiUrl + 'adminreplyrfq', data, headers).then((res) => {
      // console.log('res >>>', res.data)
        if(res.data.status == 'success') {
          // dispatch({ type: "userdata", payload: res.data.data })
           navigate('/cms/admin/requestquote');
        } else {
          alert(res.data.message);
        }
    } ).catch((err) => {
        alert(err.message)
    })
    setshowloding(false);
  }
    useEffect(() => {
      if(userdata && Object.keys(userdata).length > 0) {
          // console.log('id >>', id)
          fetchrequestsampledata(id);
      } else {
          navigate('/cms/admin/login');
      }
  }, []);
    return (
      <div className="HomeMain">
        <Overlay showloader={showloding} />
        <div className="sidebar-div">
          <Sidebar name={"Admin"} />
        </div>
        <div className="main-layout">
          <Navbar name={"Admin"} />
          <div className="mainDiv">
            
            
            {
              requestsampledata?.length > 0 ?
              <>
            <p className="oneplaceHead">REQUEST FOR REVISED QUOTE</p>
            <div className="uploadhere-box-big">
                    <p className="supporting-documents-head">Customer Feedback:</p>
                <div className="uploadhere-box uploadhere-box1">
                    <p className="commentstylep">{requestsampledata[0].comment}</p>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row",}} className="mt-3">
                  <p className="eta-head mt-2" style={{ width: '30%' }}>{requestsampledata[0]?.estimation} : </p>
                  <input
                    value={eta}
                    onChange={(t) => {
                      seteta(t.target.value);
                    }}
                    type="date"
                    // name={'productname'+index}
                    className="form-control tabinput"
                    style={{ width: '20%' }}
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "row",}} className="mt-3">
                  <p className="eta-head mt-2" style={{ width: '30%' }}>Validity :</p>
                  {
                    requestsampledata[0]?.status == 'accepted' ?
                    <p className="eta-head mt-2" style={{ width: '20%', fontWeight: 'normal' }}>{requestsampledata[0]?.validity1}</p> :
                    <input
                    value={validity1}
                    onChange={(t) => {
                      setvalidity1(t.target.value);
                    }}
                    type="date"
                    // name={'productname'+index}
                    className="form-control tabinput"
                    placeholder="Enter Validity"
                    style={{ width: '20%' }}
                  />
                  }
                  
                </div>
                <div style={{ display: "flex", flexDirection: "row",}} className="mt-3">
                  <p className="eta-head mt-2" style={{ width: '30%' }}>Mode of Shipment :</p>
                  <input
                    value={shipment}
                    onChange={(t) => {
                      setshipment(t.target.value);
                    }}
                    type="text"
                    // name={'productname'+index}
                    className="form-control tabinput"
                    placeholder="Enter Mode of Shipment"
                    style={{ width: '20%' }}
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "row",}} className="mt-3">
                  <p className="eta-head mt-2" style={{ width: '30%' }}>Port of Arrival :</p>
                  {
                    requestsampledata[0]?.status == 'accepted' ?
                    <p className="eta-head mt-2" style={{ width: '20%', fontWeight: 'normal' }}>{requestsampledata[0]?.arrivalport}</p> :
                    <input
                    value={arrivalport}
                    onChange={(t) => {
                      setarrivalport(t.target.value);
                    }}
                    type="text"
                    // name={'productname'+index}
                    className="form-control tabinput"
                    placeholder="Enter Port of arrival"
                    style={{ width: '20%' }}
                  />
                  }
                  
                </div>
                <div style={{ display: "flex", flexDirection: "row",}} className="mt-3">
                  <p className="eta-head mt-2" style={{ width: '30%' }}>Port of discharge :</p>
                  <input
                    value={dischargeport}
                    onChange={(t) => {
                      setdischargeport(t.target.value);
                    }}
                    type="text"
                    // name={'productname'+index}
                    className="form-control tabinput"
                    placeholder="Enter Port of discharge"
                    style={{ width: '20%' }}
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "row", marginTop: '20' }} className="mt-3 mb-5">
                  <p className="eta-head mt-2" style={{ width: '30%' }}>INCO Terms : </p>
                  <input
                    value={inco}
                    onChange={(t) => {
                      setinco(t.target.value);
                    }}
                    type="text"
                    // name={'productname'+index}
                    className="form-control tabinput"
                    placeholder="Enter inco"
                    style={{ width: '20%' }}
                  />
                </div>
            <table class="table table-bordered invoicetable">
                <thead>
                    <th>Customer Name</th>
                    <th>Product Name</th>
                    <th>Application</th>
                    <th>Ref. No.</th>
                    <th>Validity</th>
                    <th>Quantity</th>
                    <th>Kevin Code</th>
                    <th>Price in {requestsampledata[0].currency}</th>
                    <th>Units</th>
                </thead>
                <tbody>
                    {
                        requestsampledata?.map((item, index) => {
                            // console.log('item ..', item)
                            return (
                              <tr>
                                <td>{item.name}</td>
                                <td>{item.productname}</td>
                                <td>{item.application}</td>
                                <td>{item.reference}</td>
                                <td>{item.validity1}</td>
                                <td>{item.quantity}</td>
                                <td>
                            <input
                              value={kevincode}
                              onChange={(t) => {
                                setkevincode(t.target.value);
                              }}
                              type="text"
                              // name={'productname'+index}
                              className="form-control tabinput" 
                              placeholder={"Enter Kevin Code"}
                            /></td>
                                <td>
                                  <input
                                    value={price}
                                    onChange={(t) => {
                                      setprice(t.target.value);
                                    }}
                                    type="number"
                                    // name={'productname'+index}
                                    className="form-control tabinput"
                                    placeholder={"Enter Price in " + requestsampledata[0].currency}
                                  />
                                </td>
                                <td>
                                  <input
                                    value={units}
                                    onChange={(t) => {
                                      setunits(t.target.value);
                                    }}
                                    type="text"
                                    // name={'productname'+index}
                                    className="form-control tabinput"
                                    placeholder="Enter Units"
                                  />
                                </td>
                              </tr>
                            );
                        })
                    }
                </tbody>
            </table>
              </>
                
                : 
            <div className="">
                <p style={{textAlign: 'center'}}>Loading..</p>
            </div>
            }

            <button
              onClick={() => {
                rfssamplereply();
              }}
              className="submitrfs-btn submitrfs-btn2"
              style={{
                backgroundColor: colors.themeBlue,
                borderColor: colors.themeBlue,
                float: "right",
                marginTop: 10,
                marginBottom: 50,
              }}
            >
              SAVE AND SUBMIT
            </button>
          </div>
        </div>
      </div>
    );
}

export default AdminRevisedQuoteRequest