import React, { useState, useEffect, useRef } from "react";
import "./Web.css";
import ProductFinderHead from "../../components/common/ProductFinderHead";
import Webheader from "../../components/common/Webheader";
import lefticon from "../../assets/images/arrow.png";
import WebFooter from "../../components/common/WebFooter";
import box from "../../assets/images/box.png";
import more from "../../assets/images/more.png";
import calender from "../../assets/images/calendar.png";
import cart from "../../assets/images/shopping-cart.png";
import { Link } from "react-router-dom";
import SortingIcon from "../../assets/images/sorting.png";
import CategoryIcon from "../../assets/images/category.png";
import RightArrow from '../../assets/images/right-arrow.png';
import FilterIcon from '../../assets/images/filter.png';
import { Drawer, ButtonToolbar, Button, Placeholder } from 'rsuite';
import { useLocation } from "react-router-dom";
import AOS from "aos";
import 'aos/dist/aos.css';
import code from "../../assets/images/code.png";
import { apiUrl } from "../../config/config";
import axios from "axios";
import Overlay from "../../components/common/Overlay";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import favicon from '../../assets/images/favicon.png';
import plus from '../../assets/images/plus.png';

const WebProductsFinder = () => {
  const [categoriesdata, setcategoriesdata] = useState([]);
  const [popularfilterlistdata, setpopularfilterlistdata] = useState([]);
  const [popularapplicationlistdata, setpopularapplicationlistdata] = useState([]);
  const [showloding, setshowloding] = useState(false);
  const [showsortdropdown, setshowsortdropdown] = useState(false);
  const [showcategorydropdown, setshowcategorydropdown] = useState(false);
  const [productsearchtext, setproductsearchtext] = useState("");
  const [productsearchtext1, setproductsearchtext1] = useState("");
  const [productsearchresults, setproductsearchresults] = useState("");
  const [showsearchdropdown, setshowsearchdropdown] = useState(false);
  const [applicationsearchtext, setapplicationsearchtext] = useState("");
  const [applicationsearchtext1, setapplicationsearchtext1] = useState("");
  const [applicationsearchresults, setapplicationsearchresults] = useState("");
  const [showsearchdropdown1, setshowsearchdropdown1] = useState(false);
  const [kevincodesearchtext, setkevincodesearchtext] = useState("");
  const [kevincodesearchtext1, setkevincodesearchtext1] = useState("");
  const [kevincodesearchresults, setkevincodesearchresults] = useState("");
  const [showsearchdropdown2, setshowsearchdropdown2] = useState(false);
  const [productsdata, setproductsdata] = useState([]);
  const [Filteropen, setFilterOpen] = useState(false);
  const [sortbyvalue, setsortbyvalue] = useState(null);
  const [forceupdatestate, setforceupdatestate] = useState(false);
  const [showclearfilter, setshowclearfilter] = useState(false);
  const [filterarr, setfilterarr] = useState({
    sortby: "",
    filtertag: [],
    applicationtag: "",
    category: ""
  })
  const [currentpage, setcurrentpage] = useState(0);
  const currentpageRef = useRef(0);
  currentpageRef.current = currentpage;
  const productsdataRef = useRef(0);
  productsdataRef.current = productsdata;
  const location = useLocation();
  const divRef = useRef(null);
  const filterarrRef = useRef(null);
  filterarrRef.current = filterarr

  //pagination
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [itemsPerPage, setitemsPerPage] = useState(6);
  const [currentItems, setcurrentItems] = useState(0);
  // const endOffset = itemOffset + itemsPerPage;

  useEffect(() => {
    if(productsdata.length > 0) {
      const newOffset = (0 * itemsPerPage) % productsdata.length;
    var catedata = productsdata.slice(newOffset, newOffset + itemsPerPage)
    setcurrentItems(productsdata.slice(newOffset, newOffset + itemsPerPage));
    setpageCount(Math.ceil(productsdata.length / itemsPerPage));
    setItemOffset(newOffset);
    }
    
  }, [itemsPerPage])

   // Invoke when user click to request another page.
   const handlePageClick = (event) => {
    // console.log('handlePageClick event >>', event);
    // console.log('old itemOffset >>', itemOffset);
    // console.log('itemsPerPage >>', itemsPerPage);
    // console.log('productsdata?.length >>', productsdataRef.current?.length);
    setcurrentpage(event.selected);
    const newOffset = (event.selected * itemsPerPage) % productsdataRef.current?.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setcurrentItems(productsdataRef.current.slice(newOffset, newOffset + itemsPerPage));
    setpageCount(Math.ceil(productsdataRef.current.length / itemsPerPage));
    setItemOffset(newOffset);
  };

  const Items = ({ currentItems }) => {
    console.log('currentItems11 >>', currentItems.length);
    return (
      <>
      {
        currentItems?.length > 0 ?
        currentItems?.map((item, index) => {
          // console.log('item >>', item)
          if(item?.applicationimages) {
            var imagelink = JSON.parse(item?.applicationimages)[0].link;
          }
          else if(item?.imagesarr) {
            var imagelink = JSON.parse(item?.imagesarr)[0].link;
          } else {
            var imagelink = null;
          }
          var backgroundImageurl = 'url('+imagelink+')';
          return (
            <div className="productsfindermainbox" >
        <div
          onClick={() => {
            gotoproducturl1(item);
          }}
          className="productsfindermainboxleftimg"
          style={{ backgroundImage: backgroundImageurl }}
        ></div>
        <div className="productsfindermainboxlefmiddiv">
          <p onClick={() => {
            gotoproducturl1(item);
          }} className="productsfinder-producttitle">
            {
              item?.productname
            }
          </p>
          <p className="productsfinder-subtext">
            Chemical Class: {item?.chemicalclass}
          </p>
          <div className="productsfinder-left-subdiv">
            <p className="productsfinder-subtext">CAS No : {item?.cas}</p>
            <p className="productsfinder-subtext">C.I. No : {item?.ci}</p>
          </div>
          {/* <p className="productsfinder-productdescriptionhead">
            Product Description
          </p> */}
          <p className="productsfinder-subtext">Application : <b>{item?.applicationname}</b></p>
          <div className="productsfinder-tagdiv">
            {
              item?.tags && item?.tags.split(',').map((itm, idx) => {
                return (
                  <div className="productsfinder-tagbox">
                    <p>#{itm}</p>
                  </div>
                )
              })
            }
            {
              item?.applicationtags && item?.applicationtags.split(',').map((itm, idx) => {
                return (
                  <div className="productsfinder-tagbox">
                    <p>#{itm}</p>
                  </div>
                )
              })
            }
            
          </div>
        </div>
        <div className="productsfindermainboxlefrightdiv">
          <p className="productsfinder-producttitle productsfinder-producttitle1">
            {item?.kevincodes}
          </p>
          <p className="productsfinder-productdescriptionhead productsfinder-productdescriptionhead1">
            Tone: Mass and Tinit
          </p>
          <div className="productsfinder-tonediv">
            <div
              className="productsfinder-tonecircle"
              style={{ backgroundColor: '#'+item.masstone }}
            ></div>
            <div
              className="productsfinder-tonecircle"
              style={{ backgroundColor: '#'+item.tinttone }}
            ></div>
          </div>
          <div onClick={() => {
            gotoproducturl1(item);
          }} className="productsfinder-tagbox productsfinder-tagbox1" style={{cursor: 'pointer'}}>
            <p>View More</p>
          </div>
        </div>
      </div>
          )
        })
        : null
      }
      
      </>
    );
  }
  
  // console.log('endOffset >>', endOffset);

  // console.log('filterarrRef.current >>', filterarrRef.current);


  const handlechangefilter = async(value, type, index) => {
    if(type == 'application') {
      var prevfilterarr = filterarr;
      if(value == false) {
        var prevdata = popularapplicationlistdata;
        for(var i = 0; i < prevdata.length; i++) {
          if(i == index) {
            prevdata[i].ischecked = true;
          } else {
            prevdata[i].ischecked = false;
          }
        }
        prevfilterarr.applicationtag = prevdata[index].name
      } else {
        var prevdata = popularapplicationlistdata;
        prevdata[index].ischecked = !prevdata[index].ischecked;
        prevfilterarr.applicationtag = ""
      }
      setpopularapplicationlistdata(prevdata);
      fetchproductsbyid(id, prevfilterarr);
      setfilterarr(prevfilterarr);
      setforceupdatestate(!forceupdatestate);
    } else if(type == 'filtertag') {
      var prevfilterarr = filterarr;
      if(value == false) {
        var prevdata = popularfilterlistdata;
        // for(var i = 0; i < prevdata.length; i++) {
        //   if(i == index) {
        //     prevdata[i].ischecked = true;
        //   } else {
        //     prevdata[i].ischecked = false;
        //   }
        // }
        prevdata[index].ischecked = !prevdata[index].ischecked;
        var oldarr = prevfilterarr.filtertag;
        oldarr.push(prevdata[index].name);
        prevfilterarr.filtertag = oldarr;
      } else {
        var prevdata = popularfilterlistdata;
      prevdata[index].ischecked = !prevdata[index].ischecked;
        // prevfilterarr.filtertag = ""
        var oldarr = prevfilterarr.filtertag;
        if(oldarr?.length > 0) {
          var newarr = [];
          for(var i = 0; i < oldarr?.length; i++) {
            if(prevdata[index].name == oldarr[i]) {

            } else {
              newarr.push(oldarr[i]);
            }
          }
          prevfilterarr.filtertag = newarr;
        }
      }
      console.log('prevfilterarr >>>', prevfilterarr)
      setpopularfilterlistdata(prevdata);
      fetchproductsbyid(id, prevfilterarr);
      setfilterarr(prevfilterarr);
      setforceupdatestate(!forceupdatestate);
    } else if(type == 'sortby') {
      var prevfilterarr = filterarr;
      prevfilterarr.sortby = value;
      fetchproductsbyid(id, prevfilterarr);
      setfilterarr(prevfilterarr);
      setforceupdatestate(!forceupdatestate);
    } else if(type == 'category') {
      var prevfilterarr = filterarr;
      if(value == '-1') {
        prevfilterarr.category = ''
      } else {
        prevfilterarr.category = value;
      }
      fetchproductsbyid(id, prevfilterarr);
      setfilterarr(prevfilterarr);
      setforceupdatestate(!forceupdatestate);
    }
  }
  useEffect(() => {
    console.log('filterarr >>', filterarrRef.current);
    fetchproductsbyid(id, filterarrRef.current);
  }, [filterarrRef.current])

  const handleselectcategoryid = async(id) => {
    console.log('handleselectcategoryid id >>', id);
    handlechangefilter(id, 'category', 0);
  }

  const fetchproductsbyid = async(id, filterarr) => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    var data = {
      id: id,
      sortby: filterarr.sortby,
      filtertag: filterarr.filtertag,
      applicationtag: filterarr.applicationtag,
      category: filterarr.category,
    };
    let resp = await axios
      .post(apiUrl + "productfinderbyid",data, headers)
      .then((res) => {
        if (res.data.status == "success") {
          var catdata = res.data.data
          setcurrentItems(catdata.slice(itemOffset, itemsPerPage));
          setpageCount(Math.ceil(catdata.length / itemsPerPage));
          setproductsdata(res.data.data);
          setItemOffset(0);

          // setcurrentpage(0);
          // if(currentpageRef?.current == 0) {

          // } else {
          //   handlePageClick({selected: 0});
          // }
          // 
          // setkevincodesearchresults(res.data.data);
          // setcategoriesdata(res.data.data);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });

      setTimeout(() => {
        if(currentpage > 0) {
        var obj = {selected: 0}
        handlePageClick(obj);
        }
        
      }, 1000);
  }

  const handleOutsideClick = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      // Clicked outside the div, update the state
      setshowsearchdropdown(false);
      setshowsearchdropdown1(false);
      setshowsearchdropdown2(false);
    }
  };
  let navigate = useNavigate();
  const fetchdata = async () => {
    setshowloding(true);
    await fetchcategories();
    await fetchpopularfilters();
    await fetchpopularapplications();
    // await fetchproductsbyid(id);
    setshowloding(false);
  };

  const fetchcategories = async() => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
      .get(apiUrl + "usermaincategorylist", headers)
      .then((res) => {
        if (res.data.status == "success") {
          setcategoriesdata(res.data.data);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  const fetchpopularfilters = async() => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
      .get(apiUrl + "userpopularfilterlist", headers)
      .then((res) => {
        if (res.data.status == "success") {
          var applicationdata = res.data.data;
          var applicationdataarr = [];
          for(var i = 0; i < applicationdata.length; i++) {
            var obj = applicationdata[i];
            obj['ischecked'] = false;
            applicationdataarr.push(obj)
          }
          setpopularfilterlistdata(applicationdataarr);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  const fetchpopularapplications = async() => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
      .get(apiUrl + "userpopularfilterapplications", headers)
      .then((res) => {
        if (res.data.status == "success") {
          var applicationdata = res.data.data;
          var applicationdataarr = [];
          for(var i = 0; i < applicationdata.length; i++) {
            var obj = applicationdata[i];
            obj['ischecked'] = false;
            applicationdataarr.push(obj)
          }
          setpopularapplicationlistdata(applicationdataarr);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  const findproductslistbysearch = async(txt) => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    var data = {
      text: txt
    };
    let resp = await axios
      .post(apiUrl + "productslistbysearch",data, headers)
      .then((res) => {
        if (res.data.status == "success") {
          setproductsearchresults(res.data.data);
          // setcategoriesdata(res.data.data);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  const findproductslistbysearch1 = async(txt) => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    var data = {
      text: txt
    };
    let resp = await axios
      .post(apiUrl + "applicationslistbysearch",data, headers)
      .then((res) => {
        if (res.data.status == "success") {
          setapplicationsearchresults(res.data.data);
          // setcategoriesdata(res.data.data);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  const findproductslistbysearch2 = async(txt) => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    var data = {
      text: txt
    };
    let resp = await axios
      .post(apiUrl + "kevincodelistbysearch",data, headers)
      .then((res) => {
        if (res.data.status == "success") {
          setkevincodesearchresults(res.data.data);
          // setcategoriesdata(res.data.data);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  
  const orderbyproducts = async(val) => {
    setshowloding(true);
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    var data = {
      value: val
    };
    let resp = await axios
      .post(apiUrl + "productfindersortby",data, headers)
      .then((res) => {
        if (res.data.status == "success") {
          var catdata = res.data.data
          setcurrentItems(catdata.slice(itemOffset, itemsPerPage));
          setpageCount(Math.ceil(catdata.length / itemsPerPage));
          setproductsdata(res.data.data);
          setItemOffset(0);
          // setkevincodesearchresults(res.data.data);
          // setcategoriesdata(res.data.data);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
      setshowloding(false);
      setTimeout(() => {
        if(currentpage > 0) {
        var obj = {selected: 0}
        handlePageClick(obj);
        }
        
      }, 1000);
  }

  const submitsearchproduct = async() => {
    setshowloding(true);
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    var data = {
      name: productsearchtext1,
      application: applicationsearchtext1,
      kevincode: kevincodesearchtext1
    };
    let resp = await axios
      .post(apiUrl + "usersearchproduct",data, headers)
      .then((res) => {
        if (res.data.status == "success") {
          var catdata = res.data.data
          setcurrentItems(catdata.slice(itemOffset, itemsPerPage));
          setpageCount(Math.ceil(catdata.length / itemsPerPage));
          setproductsdata(res.data.data);
          setItemOffset(0);
          setshowclearfilter(true);
          // setkevincodesearchresults(res.data.data);
          // setcategoriesdata(res.data.data);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
      setshowloding(false);
      setTimeout(() => {
        if(currentpage > 0) {
        var obj = {selected: 0}
        handlePageClick(obj);
        }
        
      }, 1000);
  }

  const submitsearchproductcleartext = async() => {
    setshowloding(true);
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    var data = {
      name: '',
      application: '',
      kevincode: ''
    };
    let resp = await axios
      .post(apiUrl + "usersearchproduct",data, headers)
      .then((res) => {
        if (res.data.status == "success") {
          var catdata = res.data.data
          setcurrentItems(catdata.slice(itemOffset, itemsPerPage));
          setpageCount(Math.ceil(catdata.length / itemsPerPage));
          setproductsdata(res.data.data);
          setItemOffset(0);
          // setkevincodesearchresults(res.data.data);
          // setcategoriesdata(res.data.data);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
      setshowloding(false);
      setTimeout(() => {
        if(currentpage > 0) {
        var obj = {selected: 0}
        handlePageClick(obj);
        }
        
      }, 1000);
  }

  useEffect(() => {
    if(kevincodesearchtext.length > 0) {
      findproductslistbysearch2(kevincodesearchtext);
      setshowsearchdropdown2(true);
    } else {
      setshowsearchdropdown2(false);
    }
    
  }, [kevincodesearchtext])

  useEffect(() => {
    if(productsearchtext.length > 0) {
      findproductslistbysearch(productsearchtext);
      setshowsearchdropdown(true);
    } else {
      setshowsearchdropdown(false);
    }
    
  }, [productsearchtext])

  useEffect(() => {
    if(applicationsearchtext.length > 0) {
      findproductslistbysearch1(applicationsearchtext);
      setshowsearchdropdown1(true);
    } else {
      setshowsearchdropdown1(false);
    }
    
  }, [applicationsearchtext])

  useEffect(() => {
    if(sortbyvalue) {
      orderbyproducts(sortbyvalue);
    } else {

    }
  }, [sortbyvalue])

  const { id } = useParams();

  const handlechangepagecount = (num) => {
    setitemsPerPage(parseInt(num))
  }

  const gotoproducturl1 = (item) => {
    var url =  '/products/'+item.catslug+'/'+item.subcatslug+'/'+item.threecatslug+'/'+item.productname+'/'+item.applicationid;
    navigate(url);
  }

  const clearfilterpressed = () => {
    setshowclearfilter(false);
    setapplicationsearchtext1('')
    setkevincodesearchtext1('');
    setproductsearchtext1('');
    submitsearchproductcleartext();
  }

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('click', handleOutsideClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);
  useEffect(() => {
    document.title = "Kevin India"
    const newFavicon = document.createElement('link');
        newFavicon.rel = 'icon';
        newFavicon.href = favicon; // Replace 'new-favicon-url.png' with your new favicon URL
        document.head.appendChild(newFavicon);
        newFavicon.href = favicon;
  }, []);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
    fetchdata();
  }, []);
  return (
    <div className="webmainbg">
      <Overlay showloader={showloding} />
      <ProductFinderHead
        data={categoriesdata}
        selectcategoryid={handleselectcategoryid}
      />
      <div className="mobhide">
        <div className="productsfindermaindiv">
          <div className="productsfindermainleftdiv">
            <div className="productsfinderleftdiv">
              <div className="d-flex">
                <Link to={"/home"}>
                  <img src={lefticon} className="productsfinderlefticon" />
                </Link>
                <Link to={"/home"} style={{ textDecoration: "none" }}>
                  <p className="productsfinder-backtext">Back to home</p>
                </Link>
              </div>

              <div className="d-flex">
                <p className="productssearchtext">Product Search</p>
                {
                  showclearfilter ? 
                  productsearchtext1 || applicationsearchtext1 || kevincodesearchtext1 ?
                  <div className="productssearchclearfilterdiv" onClick={() => {clearfilterpressed()}}>
                  <img src={plus} />
                  <p className="cleartext">Clear search</p>
                </div> : null : 
                null
                }
                
              </div>

              <div className="productsfinderfilterdiv">
                <p>Search</p>
                <div className="productsfinderfilterbox">
                  <img src={box} />
                  <input
                    value={productsearchtext1}
                    onChange={(t) => {
                      setproductsearchtext(t.target.value);
                      setproductsearchtext1(t.target.value);
                    }}
                    type="text"
                    class="form-control"
                    placeholder="Enter Product Name"
                    name="productname"
                  />
                  {showsearchdropdown ? (
                    <div className="searchdiv" ref={divRef}>
                      {productsearchresults.length > 0 ? (
                        <div className="searchresults">
                          <ul>
                            {productsearchresults?.map((item, index) => {
                              return (
                                <li
                                  onClick={() => {
                                    setshowsearchdropdown(false);
                                    setproductsearchtext1(item?.productname);
                                  }}
                                >
                                  {item?.productname}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      ) : (
                        <p>No Results</p>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="productsfinderfilterdiv">
                <p>Application</p>
                <div className="productsfinderfilterbox">
                  <img src={more} />
                  <input
                    value={applicationsearchtext1}
                    onChange={(t) => {
                      setapplicationsearchtext(t.target.value);
                      setapplicationsearchtext1(t.target.value);
                    }}
                    type="text"
                    class="form-control"
                    placeholder="Enter Application"
                    name="productname"
                  />
                  {showsearchdropdown1 ? (
                    <div className="searchdiv" ref={divRef}>
                      {applicationsearchresults.length > 0 ? (
                        <div className="searchresults">
                          <ul>
                            {applicationsearchresults?.map((item, index) => {
                              return (
                                <li
                                  onClick={() => {
                                    setshowsearchdropdown1(false);
                                    setapplicationsearchtext1(item?.name);
                                  }}
                                >
                                  {item?.name}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      ) : (
                        <p>No Results</p>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="productsfinderfilterdiv">
                <p>Kevin Code</p>
                <div className="productsfinderfilterbox">
                  <img src={code} />
                  <input
                    value={kevincodesearchtext1}
                    onChange={(t) => {
                      setkevincodesearchtext(t.target.value);
                      setkevincodesearchtext1(t.target.value);
                    }}
                    type="text"
                    class="form-control"
                    placeholder="Enter Kevin Code"
                    name="productname"
                  />
                  {showsearchdropdown2 ? (
                    <div className="searchdiv" ref={divRef}>
                      {kevincodesearchresults.length > 0 ? (
                        <div className="searchresults">
                          <ul>
                            {kevincodesearchresults?.map((item, index) => {
                              return (
                                <li
                                  onClick={() => {
                                    setshowsearchdropdown2(false);
                                    setkevincodesearchtext1(item?.kevincode);
                                  }}
                                >
                                  {item?.kevincode}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      ) : (
                        <p>No Results</p>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              <button
                onClick={() => {
                  submitsearchproduct();
                }}
                className="prodcutfindersubmit-btn"
              >
                Search
              </button>
            </div>
            <div className="productsfinderleftdiv2">
              <p className="productssearchtext">Popular Filters</p>
              {popularfilterlistdata?.length > 0
                ? popularfilterlistdata?.map((item, index) => {
                    return (
                      <div className="productsfinderleftcheckboxdiv">
                        <input
                          onChange={(t) => {
                            handlechangefilter(
                              item.ischecked,
                              "filtertag",
                              index
                            );
                          }}
                          checked={item.ischecked}
                          class="form-check-input"
                          type="checkbox"
                          id="check1"
                          name="option1"
                          value="something"
                        />
                        <p>{item?.name}</p>
                      </div>
                    );
                  })
                : null}
              {/* <div className="productsfinderleftcheckboxdiv">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="check1"
                  name="option1"
                  value="something"
                />
                <p>Phthalocynine</p>
              </div>
              <div className="productsfinderleftcheckboxdiv">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="check1"
                  name="option1"
                  value="something"
                />
                <p>Colour Matching</p>
              </div>
              <div className="productsfinderleftcheckboxdiv">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="check1"
                  name="option1"
                  value="something"
                />
                <p>Food Safe</p>
              </div>
              <div className="productsfinderleftcheckboxdiv">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="check1"
                  name="option1"
                  value="something"
                />
                <p>Metal Complex Dyes</p>
              </div> */}
              <div className="mt-5">
                <p className="productssearchtext">
                  Popular Product Applications
                </p>
                {popularapplicationlistdata?.length > 0
                  ? popularapplicationlistdata?.map((item, index) => {
                      return (
                        <div className="productsfinderleftcheckboxdiv">
                          <input
                            onChange={(t) => {
                              handlechangefilter(
                                item.ischecked,
                                "application",
                                index
                              );
                            }}
                            checked={item.ischecked}
                            class="form-check-input"
                            type="checkbox"
                            id="check1"
                            name="option1"
                            value="something"
                          />
                          <p>{item.name}</p>
                        </div>
                      );
                    })
                  : null}
                {/* <div className="productsfinderleftcheckboxdiv">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="check1"
                    name="option1"
                    value="something"
                  />
                  <p>Plastic</p>
                </div>
                <div className="productsfinderleftcheckboxdiv">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="check1"
                    name="option1"
                    value="something"
                  />
                  <p>Water Based Paint</p>
                </div>
                <div className="productsfinderleftcheckboxdiv">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="check1"
                    name="option1"
                    value="something"
                  />
                  <p>Solvent Based Ink</p>
                </div>
                <div className="productsfinderleftcheckboxdiv">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="check1"
                    name="option1"
                    value="something"
                  />
                  <p>Textile</p>
                </div> */}
              </div>
            </div>
          </div>

          <div className="productsfinderrightdiv">
            <div className="productsfinderrightdiv1">
              <p className="exploretext">Explore Our Products</p>
              <div className="searchfilterdiv">
                <p className="searchfiltertext">Search Filters</p>
                <div className="sortbydiv">
                  <select
                    class="form-select"
                    value={sortbyvalue}
                    onChange={(t) => {
                      handlechangefilter(t.target.value, "sortby", 0);
                    }}
                  >
                    <option selected disabled>
                      Sort by
                    </option>
                    <option value="name">Name</option>
                    <option value="date-desc">Recently Added</option>
                  </select>
                </div>
              </div>
              <div className="tablet-displayflex">
                {productsdata?.length > 0 ? (
                  <>
                    <div className="productsection3">
                      <div className="row">
                        <Items currentItems={currentItems} />
                      </div>
                    </div>
                    <div className="paginatemaindiv">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel=">>"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="<<"
                        renderOnZeroPageCount={null}
                        className="custompaginate"
                        forcePage={currentpage}
                      />
                      <select
                        class="form-select paginatedropdown"
                        value={itemsPerPage}
                        onChange={(t) => {
                          handlechangepagecount(t.target.value);
                        }}
                      >
                        <option value="6">6</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                      </select>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* for mobile */}
      <div className="deskhide" style={{ position: "relative" }}>
        <Webheader />
        {/* <div className="mobfilterdiv">
            <img src={RightArrow} />
        </div> */}
        <div className="pagewidth">
          <div className="productsfindermaindiv">
            <div className="productsfindermainleftdiv mobhide">
              <div className="productsfinderleftdiv">
                <Link to={"/home"}>
                  <img src={lefticon} className="productsfinderlefticon" />
                </Link>

                <p className="productssearchtext">Product Search</p>
                <div className="productsfinderfilterdiv">
                  <p>Product Type11</p>
                  <div className="productsfinderfilterbox">
                    <img src={box} />
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Product Name"
                      name="productname"
                    />
                  </div>
                </div>
                <div className="productsfinderfilterdiv">
                  <p>Application</p>
                  <div className="productsfinderfilterbox">
                    <img src={more} />
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Product Name"
                      name="productname"
                    />
                  </div>
                </div>
                <div className="productsfinderfilterdiv">
                  <p>Estimated Delivery Date</p>
                  <div className="productsfinderfilterbox">
                    <img src={calender} />
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Product Name"
                      name="productname"
                    />
                  </div>
                </div>
                <div className="productsfinderfilterdiv">
                  <p>Estimated Quantity</p>
                  <div className="productsfinderfilterbox">
                    <img src={cart} />
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Product Name"
                      name="productname"
                    />
                  </div>
                </div>
                <button className="prodcutfindersubmit-btn">Search</button>
              </div>
              <div className="productsfinderleftdiv2">
                <p className="productssearchtext">Popular Filters</p>
                {popularfilterlistdata?.length > 0
                  ? popularfilterlistdata?.map((item, index) => {
                      return (
                        <div className="productsfinderleftcheckboxdiv">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="check1"
                            name="option1"
                            value="something"
                          />
                          <p>{item?.name}</p>
                        </div>
                      );
                    })
                  : null}
                {/* <div className="productsfinderleftcheckboxdiv">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="check1"
                    name="option1"
                    value="something"
                  />
                  <p>Phthalocynine</p>
                </div>
                <div className="productsfinderleftcheckboxdiv">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="check1"
                    name="option1"
                    value="something"
                  />
                  <p>Colour Matching</p>
                </div>
                <div className="productsfinderleftcheckboxdiv">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="check1"
                    name="option1"
                    value="something"
                  />
                  <p>Food Safe</p>
                </div>
                <div className="productsfinderleftcheckboxdiv">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="check1"
                    name="option1"
                    value="something"
                  />
                  <p>Metal Complex Dyes</p>
                </div> */}
                <div className="mt-5">
                  <p className="productssearchtext">
                    Popular Product Applications
                  </p>
                  {popularapplicationlistdata?.length > 0
                    ? popularapplicationlistdata?.map((item, index) => {
                        return (
                          <div className="productsfinderleftcheckboxdiv">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="check1"
                              name="option1"
                              value="something"
                            />
                            <p>{item.name}</p>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>

            <div className="productsfinderrightdiv">
              <div className="productsfinderrightdiv1">
                <p className="exploretext">Explore Our Products</p>
                <div className="searchfilterdiv">
                  <p className="searchfiltertext">Search Filters</p>
                  <div className="sortbydiv">
                    <div class="dropdown categorydropdown">
                      <div
                        onClick={() => {
                          setshowcategorydropdown(!showcategorydropdown);
                        }}
                        className="sortingicondiv"
                      >
                        <img
                          src={CategoryIcon}
                          className="sortingicon categoryicon"
                        />
                      </div>
                      <div
                        class="dropdown-content"
                        style={
                          showcategorydropdown
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <Link
                          onClick={() => {
                            handleselectcategoryid(-1);
                            setshowcategorydropdown(!showcategorydropdown);
                          }}
                        >
                          All
                        </Link>
                        {categoriesdata?.length > 0
                          ? categoriesdata?.map((item, index) => {
                              return (
                                <Link
                                  onClick={() => {
                                    handleselectcategoryid(item.id);
                                    setshowcategorydropdown(
                                      !showcategorydropdown
                                    );
                                  }}
                                >
                                  {item?.name}
                                </Link>
                              );
                            })
                          : null}
                      </div>
                    </div>
                    <div class="dropdown categorydropdown sortdropdown">
                      <div
                        onClick={() => {
                          setshowsortdropdown(!showsortdropdown);
                        }}
                        className="sortingicondiv"
                      >
                        <img src={SortingIcon} className="sortingicon" />
                      </div>
                      <div
                        class="dropdown-content"
                        style={
                          showsortdropdown
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <a
                          onClick={() => {
                            handlechangefilter("name", "sortby", 0);
                            setshowsortdropdown(!showsortdropdown);
                          }}
                        >
                          Name
                        </a>
                        <a
                          onClick={() => {
                            handlechangefilter("date-desc", "sortby", 0);
                            setshowsortdropdown(!showsortdropdown);
                          }}
                        >
                          Recently Added
                        </a>
                      </div>
                    </div>
                    <div class="dropdown sortdropdown">
                      <div
                        onClick={() => {
                          // setshowsortdropdown(!showsortdropdown);
                          setFilterOpen(true);
                        }}
                        className="sortingicondiv"
                      >
                        <img
                          src={FilterIcon}
                          className="sortingicon filtericon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tablet-displayflex">
                  {productsdata?.length > 0 ? (
                    <>
                      <div className="productsection3">
                        <div className="row">
                          <Items currentItems={currentItems} />
                        </div>
                      </div>
                      <div className="paginatemaindiv">
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel=">>"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={5}
                          pageCount={pageCount}
                          previousLabel="<<"
                          renderOnZeroPageCount={null}
                          className="custompaginate"
                          forcePage={currentpage}
                        />
                        <select
                          class="form-select paginatedropdown"
                          value={itemsPerPage}
                          onChange={(t) => {
                            handlechangepagecount(t.target.value);
                          }}
                        >
                          <option value="6">6</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                        </select>
                      </div>
                    </>
                  ) : null}
                </div>

                {/* <div className="productsection4">
                  <div className="paginationdiv">
                    <p>{"<"}</p>
                    <p>{"1"}</p>
                    <p>{"2"}</p>
                    <p>{"3"}</p>
                    <p>{"4"}</p>
                    <p>{"5"}</p>
                    <p>{"6"}</p>
                    <p>{"7"}</p>
                    <p>{"8"}</p>
                    <p>{"9"}</p>
                    <p>{">"}</p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Drawer
        className="mobproductsfinder"
        open={Filteropen}
        placement="right"
        onClick={() => setFilterOpen(false)}
        onClose={() => setFilterOpen(false)}
        backdrop={true}
        size={"xs"}
      >
        <Drawer.Header>
          {/* <p className="unreadhead">unread message</p> */}
        </Drawer.Header>
        <Drawer.Body>
          {/* <Placeholder.Paragraph rows={8} /> */}
          <div className="mobproductsfinder-bodydiv">
            <div className="productsfinderleftdiv">
            <p className="productssearchtext">Product Search</p>
              <div className="d-flex">
              
              {
                  showclearfilter ? 
                  productsearchtext1 || applicationsearchtext1 || kevincodesearchtext1 ?
                  <div className="productssearchclearfilterdiv" style={{marginTop: 0}} onClick={() => {clearfilterpressed()}}>
                  <img src={plus} />
                  <p className="cleartext">Clear search</p>
                </div> : null : 
                null
                }
              </div>
              
              <div className="productsfinderfilterdiv">
                <p>Search</p>
                <div className="productsfinderfilterbox">
                  <img src={box} />
                  <input
                    value={productsearchtext1}
                    onChange={(t) => {
                      setproductsearchtext(t.target.value);
                      setproductsearchtext1(t.target.value);
                    }}
                    type="text"
                    class="form-control"
                    placeholder="Enter Product Name"
                    name="productname"
                  />
                  {showsearchdropdown ? (
                    <div className="searchdiv" ref={divRef}>
                      {productsearchresults.length > 0 ? (
                        <div className="searchresults">
                          <ul>
                            {productsearchresults?.map((item, index) => {
                              return (
                                <li
                                  onClick={() => {
                                    setshowsearchdropdown(false);
                                    setproductsearchtext1(item?.productname);
                                  }}
                                >
                                  {item?.productname}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      ) : (
                        <p>No Results</p>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="productsfinderfilterdiv">
                <p>Application</p>
                <div className="productsfinderfilterbox">
                  <img src={more} />
                  <input
                    value={applicationsearchtext1}
                    onChange={(t) => {
                      setapplicationsearchtext(t.target.value);
                      setapplicationsearchtext1(t.target.value);
                    }}
                    type="text"
                    class="form-control"
                    placeholder="Enter Application"
                    name="productname"
                  />
                  {showsearchdropdown1 ? (
                    <div className="searchdiv" ref={divRef}>
                      {applicationsearchresults.length > 0 ? (
                        <div className="searchresults">
                          <ul>
                            {applicationsearchresults?.map((item, index) => {
                              return (
                                <li
                                  onClick={() => {
                                    setshowsearchdropdown1(false);
                                    setapplicationsearchtext1(item?.name);
                                  }}
                                >
                                  {item?.name}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      ) : (
                        <p>No Results</p>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="productsfinderfilterdiv">
                <p>Kevin Code</p>
                <div className="productsfinderfilterbox">
                  <img src={calender} />
                  <input
                    value={kevincodesearchtext1}
                    onChange={(t) => {
                      setkevincodesearchtext(t.target.value);
                      setkevincodesearchtext1(t.target.value);
                    }}
                    type="text"
                    class="form-control"
                    placeholder="Enter Kevin Code"
                    name="productname"
                  />
                  {showsearchdropdown2 ? (
                    <div className="searchdiv" ref={divRef}>
                      {kevincodesearchresults.length > 0 ? (
                        <div className="searchresults">
                          <ul>
                            {kevincodesearchresults?.map((item, index) => {
                              return (
                                <li
                                  onClick={() => {
                                    setshowsearchdropdown2(false);
                                    setkevincodesearchtext1(item?.kevincode);
                                  }}
                                >
                                  {item?.kevincode}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      ) : (
                        <p>No Results</p>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              <button
                onClick={() => {
                  submitsearchproduct();
                }}
                className="prodcutfindersubmit-btn"
              >
                Search
              </button>
            </div>
            <div className="productsfinderleftdiv2">
              <p className="productssearchtext">Popular Filters</p>
              {popularfilterlistdata?.length > 0
                ? popularfilterlistdata?.map((item, index) => {
                    return (
                      <div className="productsfinderleftcheckboxdiv">
                        <input
                          onChange={(t) => {
                            handlechangefilter(
                              item.ischecked,
                              "filtertag",
                              index
                            );
                          }}
                          checked={item.ischecked}
                          class="form-check-input"
                          type="checkbox"
                          id="check1"
                          name="option1"
                          value="something"
                        />
                        <p>{item?.name}</p>
                      </div>
                    );
                  })
                : null}
              {/* <div className="productsfinderleftcheckboxdiv">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="check1"
                    name="option1"
                    value="something"
                  />
                  <p>Phthalocynine</p>
                </div>
                <div className="productsfinderleftcheckboxdiv">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="check1"
                    name="option1"
                    value="something"
                  />
                  <p>Colour Matching</p>
                </div>
                <div className="productsfinderleftcheckboxdiv">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="check1"
                    name="option1"
                    value="something"
                  />
                  <p>Food Safe</p>
                </div>
                <div className="productsfinderleftcheckboxdiv">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="check1"
                    name="option1"
                    value="something"
                  />
                  <p>Metal Complex Dyes</p>
                </div> */}
              <div className="mt-5">
                <p className="productssearchtext">
                  Popular Product Applications
                </p>
                {popularapplicationlistdata?.length > 0
                  ? popularapplicationlistdata?.map((item, index) => {
                      return (
                        <div className="productsfinderleftcheckboxdiv">
                          <input
                            onChange={(t) => {
                              handlechangefilter(
                                item.ischecked,
                                "application",
                                index
                              );
                            }}
                            checked={item.ischecked}
                            class="form-check-input"
                            type="checkbox"
                            id="check1"
                            name="option1"
                            value="something"
                          />
                          <p>{item.name}</p>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </Drawer.Body>
      </Drawer>

      <WebFooter />
    </div>
  );
};

export default WebProductsFinder;
