import React, { useState, useEffect } from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import InvoiceTable from "../../components/tables/InvoiceTable";
import { pdf } from "../../config/images";
import { colors } from "../../config/colors";

import { useDispatch, useSelector } from "react-redux/es/exports";
import { Link, useNavigate } from 'react-router-dom';
import { apiUrl } from "../../config/config";
import axios from 'axios';
import Overlay from "../../components/common/Overlay";
import {useLocation} from 'react-router-dom';
import { useParams } from 'react-router-dom';


const CustomerReplyForQuote = () => {
    const [isapproved, setisapproved] = useState(false);
    const [showcomment, setshowcomment] = useState(false);
    const [isrejected, setisrejected] = useState(false);
    const [requestsampledata, setrequestsampledata] = useState([]);
    const [showloding, setshowloding] = useState(false);
    const [price, setprice] = useState(null);
    const [units, setunits] = useState(null);
    const [comment, setcomment] = useState(null);

    const dispatch = useDispatch(); 
    const userdata = useSelector((store) => store.userReducer.userdata);
    let navigate = useNavigate();
    const { id } = useParams();
    
    const Samplesdata = [
        {
            'Sr. No.' : '1',
            'Product' : 'Pigment Blue 15:3',
            'Kevin Code': 'KeviPound 7015B3',
            'Ref./Lot. No.': 'KDCPL/0001',
            'Price [in USD]': 'XX.XX',
            'Units': 'Per Kg.',
        }
    ]
    const submitrfs1 = () => {
        navigate('/cms/customer/proformaInvoice');  
    }
    const submitrfs2 = () => {
        navigate('/cms/customer/requestquote');  
    }

    const fetchrequestsampledata = async(id) => {
      setshowloding(true);
      var data = {
          'rfqid' : id,
      }
      const headers = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization' : 'Bearer ' + userdata?.token
          }
      }
      let resp = await axios.post(apiUrl + 'customerfetchrfqsingle',data, headers).then((res) => {
          // console.log('res >>>', res.data)
            if(res.data.status == 'success') {
              setrequestsampledata(res.data.data);
              // dispatch({ type: "userdata", payload: res.data.data })
              //  navigate('/cms/customer/requestsample');
            } else {
              alert(res.data.message);
            }
        } ).catch((err) => {
            alert(err.message)
        })
      setshowloding(false)
  }

  const rejectsamplefunction = async() => {
    if(comment) {
      setshowloding(true);
    var data = {
      'rfqid' : requestsampledata?.id,
      'comment': comment,
    }
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : 'Bearer ' + userdata?.token
      }
  }
  let resp = await axios.post(apiUrl + 'requestforrevisedquote',data, headers).then((res) => {
      // console.log('res >>>', res.data)
        if(res.data.status == 'success') {
          setisrejected(true);
          // setrequestsampledata(res.data.data);
          // dispatch({ type: "userdata", payload: res.data.data })
          //  navigate('/cms/customer/requestsample');
        } else {
          alert(res.data.message);
        }
    } ).catch((err) => {
        alert(err.message)
    })
    setshowloding(false);
    } else {
      alert('comment your feedback')
    }
    
  }

  const sampleapprovefunction = async(id) => {
    setshowloding(true);
    var data = {
      'rfqid' : id,
  }
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : 'Bearer ' + userdata?.token
      }
  }
  let resp = await axios.post(apiUrl + 'customerquoteapprove',data, headers).then((res) => {
      // console.log('res >>>', res.data)
        if(res.data.status == 'success') {
          setisapproved(true);
          // setrequestsampledata(res.data.data);
          // dispatch({ type: "userdata", payload: res.data.data })
          //  navigate('/cms/customer/requestsample');
        } else {
          alert(res.data.message);
        }
    } ).catch((err) => {
        alert(err.message)
    })
    setshowloding(false);
  }

    useEffect(() => {
      if(userdata && Object.keys(userdata).length > 0) {
        fetchrequestsampledata(id);
      } else {
          navigate('/cms/customer/login');
      }
  }, []);
    return (
      <div className="HomeMain">
        <div className="sidebar-div">
          <Sidebar />
        </div>
        <div className="main-layout">
          <Navbar />
          <div className="mainDiv">
            {isrejected ? (
              <>
                <p className="oneplaceHead">THANK YOU FOR YOUR FEEDBACK</p>
                <p className="oneplaceHead oneplaceHead1">
                  WE WILL KEEP YOU POSTED ONCE THE REVISED QUOTE IS READY!
                </p>
                <button
                  onClick={() => {
                    submitrfs2();
                  }}
                  className="submitrfs-btn submitrfs-btn1"
                  style={{
                    backgroundColor: colors.themeBlue,
                    borderColor: colors.themeBlue,
                  }}
                >
                  CLICK HERE TO CHECK RFQ STATUS
                </button>
              </>
            ) : isapproved ? (
              <>
                <p className="oneplaceHead">
                  THANK YOU FOR APPROVING THE QUOTE!
                </p>
                <p className="oneplaceHead oneplaceHead1">
                  WE WILL KEEP YOU POSTED ONCE THE PFI IS READY!
                </p>
                <button
                  onClick={() => {
                    submitrfs1();
                  }}
                  className="submitrfs-btn submitrfs-btn1"
                  style={{
                    backgroundColor: colors.themeBlue,
                    borderColor: colors.themeBlue,
                  }}
                >
                  CLICK HERE TO CHECK PFI STATUS
                </button>
              </>
            ) : (
              <>
                <p className="oneplaceHead">REQUEST FOR QUOTE</p>
            {
              Object.keys(requestsampledata).length > 0 ? 
              <>
            <table class="table table-bordered invoicetable">
                <thead>
                    <th>Product Name</th>
                    <th>Application</th>
                    <th>Ref. No.</th>
                    <th>Validity</th>
                    <th>Quantity</th>
                    <th>Kevin Code</th>
                    <th>Price in {requestsampledata.currency}</th>
                    <th>Units</th>
                </thead>
                <tbody>
                              <tr>
                                <td>{requestsampledata.productname}</td>
                                <td>{requestsampledata.application}</td>
                                <td>{requestsampledata.reference}</td>
                                <td>{requestsampledata.validity1}</td>
                                <td>{requestsampledata.quantity}</td>
                                <td>{requestsampledata.kevincode}</td>
                                <td>{requestsampledata.price}</td>
                                <td>{requestsampledata.units}</td>
                              </tr>
                </tbody>
            </table>
            {showcomment ? (
                  <div className="commentbox">
                    <textarea
                      value={comment}
                      onChange={(t) => {
                        setcomment(t.target.value);
                      }}
                      rows={4}
                      className="form-control"
                      placeholder="comment your feedback here"
                    ></textarea>
                    <div
                      className="btnrequestsamplediv"
                      style={{ marginTop: 20, justifyContent: 'flex-end' }}
                    >
                      {/* <button
                        onClick={() => {
                          // setisapproved(true)
                        }}
                        className="submitrfs-btn submitrfs-btn2 submitrfs-red"
                        style={{
                          backgroundColor: colors.themeRed,
                          borderColor: colors.themeRed,
                        }}
                      >
                        Upload feedback
                      </button> */}
                      <button
                        onClick={() => {
                          //   submitrfs();
                          // setshowcomment(true)
                          // setisrejected(true);
                          rejectsamplefunction();
                        }}
                        className="submitrfs-btn submitrfs-btn2 submitrfs-red"
                        style={{
                          backgroundColor: colors.themeRed,
                          borderColor: colors.themeRed,
                          marginLeft: 0,
                        }}
                      >
                        Request Alternative Quote
                      </button>
                    </div>
                  </div>
                ) : (
                  requestsampledata.status !== 'accepted' ?
                  <div className="btnrequestsamplediv btnrequestsamplediv1">
                    <button
                      onClick={() => {
                        // setisapproved(true);
                        sampleapprovefunction(requestsampledata.id);
                      }}
                      className="submitrfs-btn submitrfs-btn2"
                      style={{
                        backgroundColor: colors.themeBlue,
                        borderColor: colors.themeBlue,
                      }}
                    >
                      Accept Quote
                    </button>
                    <button
                      onClick={() => {
                        //   submitrfs();
                        setshowcomment(true);
                      }}
                      className="submitrfs-btn submitrfs-btn2 submitrfs-red"
                      style={{
                        backgroundColor: colors.themeRed,
                        borderColor: colors.themeRed,
                        marginLeft: 20,
                      }}
                    >
                      Reject Quote
                    </button>
                  </div> : null
                )}
              </>
                
                : 
            <div className="">
                <p style={{textAlign: 'center'}}>Loading..</p>
            </div>
            }
                
              </>
            )}
          </div>
        </div>
      </div>
    );
}

export default CustomerReplyForQuote