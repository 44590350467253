import React, {useState, useEffect} from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import InvoiceTable from "../../components/tables/InvoiceTable";
import { Link } from "react-router-dom";
import { colors } from "../../config/colors";

import { useDispatch, useSelector } from "react-redux/es/exports";
import { useNavigate } from 'react-router-dom';
import { apiUrl } from "../../config/config";
import axios from 'axios';
import Overlay from "../../components/common/Overlay";


const CustomerRequestSample = () => {
    const dispatch = useDispatch(); 
    const userdata = useSelector((store) => store.userReducer.userdata);
    let navigate = useNavigate();
    const Samplesdata = [
        {
            'Sr. No.' : '1',
            'Product Name' : 'Pigment Blue 15:3',
            'Application': 'Universal',
            'Ref. No.': 'KDCPL/0001',
            'Status': 'Dispatched',
            'RFS No.': 'RFS/CN/001',
            'status': 'ACCEPTED'
        },
        {
            'Sr. No.' : '2',
            'Product Name' : 'Pigment Blue 15:3',
            'Application': 'Universal',
            'Ref. No.': 'KDCPL/0001',
            'Status': 'Dispatched',
            'RFS No.': 'RFS/CN/001',
            'status': 'REVISED SAMPLE'
        },
        {
            'Sr. No.' : '3',
            'Product Name' : 'Pigment Blue 15:3',
            'Application': 'Universal',
            'Ref. No.': 'KDCPL/0001',
            'Status': 'Dispatched',
            'RFS No.': 'RFS/CN/001',
            'status': 'FEEDBACK PENDING'
        }
    ]
    const [showloding, setshowloding] = useState(false);
    const [requestsampledata, setrequestsampledata] = useState([]);

    const fetchrequestsampledata = async() => {
        setshowloding(true);
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + userdata?.token
            }
        }
        let resp = await axios.get(apiUrl + 'customerfetchrfs', headers).then((res) => {
            // console.log('res >>>', res.data)
              if(res.data.status == 'success') {
                setrequestsampledata(res.data.data);
                // dispatch({ type: "userdata", payload: res.data.data })
                //  navigate('/customer/requestsample');
              } else {
                alert(res.data.message);
              }
          } ).catch((err) => {
              alert(err.message)
          })
        setshowloding(false)
    }

    const clickproductname = (id) => {
        navigate('/cms/customer/reply-for-sample/'+id);
    }

    useEffect(() => {
        if(userdata && Object.keys(userdata).length > 0) {
            fetchrequestsampledata();
        } else {
            navigate('/cms/customer/login');
        }
    }, []);
    return (
      <div className="HomeMain">
        <Overlay showloader={showloding} />
        <div className="sidebar-div">
          <Sidebar />
        </div>
        <div className="main-layout">
            <Navbar />
            <div className="mainDiv">
            <p className="oneplaceHead">REQUEST FOR SAMPLE</p>
            <div className="tablebuttondiv tablebuttondivpage">
                <Link to="/cms/customer/request-new-sample" className="btndiv" style={{backgroundColor: colors.themeBlue}}>
                    <p>{'New Sample'}</p>
                </Link>
            </div>
            {
                requestsampledata?.length > 0 ?
                <table class="table table-bordered invoicetable">
                <thead>
                    <th>Sr no.</th>
                    <th>Product Name</th>
                    <th>Application</th>
                    <th>Ref. No.</th>
                    <th>Delivery Status</th>
                    <th>RFS No.</th>
                    <th>Status</th>
                </thead>
                <tbody>
                    {
                        requestsampledata?.map((item, index) => {
                            // console.log('item ..', item)
                            var samplesarr = JSON.parse(item?.samplesarr);
                            console.log('samplesarr >>', samplesarr[0]);
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>
                                    <ol type="a">
                                        {
                                            samplesarr?.map((itm, ind) => {
                                                return <li style={{textAlign: 'left', paddingLeft: 5}}>{itm?.productname}</li>
                                            })
                                        }
                                    </ol>
                                </td>
                                <td>{samplesarr[0].application}</td>
                                <td>{samplesarr[0].referencenumber ? samplesarr[0].referencenumber : '-'}</td>
                                <td>{samplesarr[0].deliverystatus ? samplesarr[0].deliverystatus : '-'}</td>
                                <td>
                                    {
                                        // item.adminsamples ?
                                        item.status == 'revised sample' ?
                                        item.revisedocument ?
                                        <div className="displayedittable" onClick={() => {clickproductname(item.id)}}>
                                            <Link >
                                                {item.rfsnumber}
                                            </Link>
                                        </div> :
                                        item.rfsnumber
                                       : <div className="displayedittable" onClick={() => {clickproductname(item.id)}}>
                                            <Link >
                                                {item.rfsnumber}
                                            </Link>
                                        </div>
                                        // : item.rfsnumber
                                    }
                                    {/* {item.rfsnumber} */}
                                </td>
                                <td>
                                {
                                        // item.adminsamples ?
                                        item.status == 'revised sample' ?
                                        item.revisedocument ?
                                        <div onClick={() => {clickproductname(item.id)}} className="statusdiv" style={item.status == 'pending' ? {backgroundColor: '#ffde59'} : item.status == 'accepted' ? {backgroundColor: '#4fb293'} : item.status == 'feedback pending' ? {backgroundColor: '#4fb293'} : item.status == 'revised sample' ? {backgroundColor: '#ff3131'} :  null}>
                                        {item.status}
                                    </div> :
                                        <div className="statusdiv" style={item.status == 'pending' ? {backgroundColor: '#ffde59', cursor: 'unset'} : item.status == 'accepted' ? {backgroundColor: '#4fb293'} : item.status == 'feedback pending' ? {backgroundColor: '#4fb293'} : item.status == 'revised sample' ? {backgroundColor: '#ff3131'} :  null}>
                                        {item.status}
                                    </div>
                                       : <div onClick={() => {clickproductname(item.id)}} className="statusdiv" style={item.status == 'pending' ? {backgroundColor: '#ffde59'} : item.status == 'accepted' ? {backgroundColor: '#4fb293'} : item.status == 'feedback pending' ? {backgroundColor: '#4fb293'} : item.status == 'revised sample' ? {backgroundColor: '#ff3131'} :  null}>
                                       {item.status}
                                   </div>
                                    //     : <div className="statusdiv" style={item.status == 'pending' ? {backgroundColor: '#ffde59', cursor: 'unset'} : item.status == 'accepted' ? {backgroundColor: '#4fb293'} : item.status == 'feedback pending' ? {backgroundColor: '#4fb293'} : item.status == 'revised sample' ? {backgroundColor: '#ff3131'} :  null}>
                                    //     {item.status}
                                    // </div>
                                    }
                                    {/* <div className="statusdiv" style={item.status == 'pending' ? {backgroundColor: '#ffde59'} : item.status == 'accepted' ? {backgroundColor: '#4fb293'} : item.status == 'feedback pending' ? {backgroundColor: '#4fb293'} : item.status == 'revised sample' ? {backgroundColor: '#ff3131'} :  null}>
                                        {item.status}
                                    </div> */}
                                </td>
                              </tr>
                            );
                        })
                    }
                </tbody>
            </table> : 
            <div className="">
                <p style={{textAlign: 'center'}}>No request yet.</p>
            </div>
            }
            </div>
        </div>
      </div>
    );
}

export default CustomerRequestSample