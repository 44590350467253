import React, {useState} from 'react';
// import { Spinner } from "react-activity";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import axios from 'axios';
import { apiUrl } from '../../config/config';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'bootstrap';
import { logo2 } from '../../config/images';
import { useDispatch, useSelector } from "react-redux/es/exports";
import { deleteicon, edit, view, hide } from "../../config/images";
import weblogo from '../../assets/images/newlogo_r.png';


const WebAdminLogin = () => {
    const [username, setusername] = useState(null);
    const [password, setpassword] = useState();
    const [showloding, setshowloding] = useState(false);
    const [token, settoken] = useState(null);
    const [showpasswordview, setshowpasswordview] = useState(false);
    const userdata = useSelector((store) => store.userReducer);
    // console.log('userdata >>', userdata);
    let navigate = useNavigate();
    const dispatch = useDispatch(); 
    const loginpressed = async() => {
      // navigate('/customer/home');
      if(username && password) {
        setshowloding(true);
        var data = {
            'username' : username,
            'password': password
        }
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        }
        let resp = await axios.post(apiUrl + 'webadminlogin', data, headers).then((res) => {
            if(res.data.status == 'success') {
              dispatch({ type: "userdata", payload: res.data.data })
              navigate('/webadmin/home');
            } else {
              alert(res.data.message);
            }
        } ).catch((err) => {
            alert(err.message)
        })
        setshowloding(false);
    } else {
        alert('username and password is required');
    }
    }   
    return (
      <div className="loginBoxMaindiv" style={{backgroundColor: '#161616'}}>
        <div className="loginBox webloginBox" style={{backgroundColor: '#2f2f2f'}}>
        <img src={weblogo} style={{height: 60}} />
        <div className="mb-3 mt-3">
          <label for="email" className="form-label">
            Username:
          </label>
          <input
            type="text"
            value={username} onChange={(t) => {setusername(t.target.value)}}
            className="form-control"
            placeholder="Enter username"
          />
        </div>
        <div className="mb-3">
          <label for="pwd" className="form-label">
            Password:
          </label>
          <input
            type="password"
            value={password} onChange={(t) => {setpassword(t.target.value)}}
            className="form-control"
            id="pwd"
            placeholder="Enter password"
            name="pswd"
          />
        </div>
        <div>
            <button type="button" className="btn btn-primary custom-btn webcustom-btn" style={{backgroundColor: '#161616', borderColor: '#161616'}} onClick={() => {loginpressed()}}>
                {showloding ?
                <Spinner color='#fff' size={15} className='spinnerCenter' /> : 'Login'
            }
            </button>
        </div>
        
        
      </div>
      </div>
      
    );
}

export default WebAdminLogin;