import React from "react";
import { logo } from "../../config/images";
import cmslogo from '../../assets/images/newlogo_r.png';
import {  Link } from 'react-router-dom';

import { useDispatch, useSelector } from "react-redux/es/exports";

const Sidebar = ({name}) => {
  const userdata = useSelector((store) => store.userReducer.userdata);
    return (
      <div className="sidebar-main">
        <img src={cmslogo} className="sidebarLogo" />
        <p className="sidebarwelcome">WELCOME,</p>
        <p className="sidebarwelcome sidebarwelcome1">{userdata?.name}</p>
        <div className="sidebarMenuDiv">
          <ul>
            <li>
              <Link to={name == 'Admin' ?
              '/cms/admin/proformaInvoice' :
              name == 'superadmin' ?
              '/cms/superadmin/proformaInvoice' :
              '/cms/customer/proformaInvoice'
            }>PROFORMA INVOICE</Link>
            </li>
            <li>
              <Link to={name == 'Admin' ?
              '/cms/admin/requestquote' :
              name == 'superadmin' ?
              '/cms/superadmin/requestquote' :
              '/cms/customer/requestquote'
            }>REQUEST FOR QUOTE</Link>
            </li>
            <li>
              <Link to={name == 'Admin' ?
              '/cms/admin/requestsample' :
              name == 'superadmin' ?
              '/cms/superadmin/requestsample' :
              '/cms/customer/requestsample'
            }>REQUEST FOR SAMPLE</Link>
            </li>
          </ul>
        </div>
      </div>
    );
}

export default Sidebar