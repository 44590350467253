import logo from './logo.svg';
import './App.css';
import { Route, Link, BrowserRouter as Router, Routes } from 'react-router-dom';
import SuperAdminLogin from './pages/Auth/SuperAdminLogin';
import bootstrap from 'bootstrap';
import Home from './pages/superadmin/Home';
import AllRoutes from './Routes';

function App() {

  const Testfunc = () => {
      <p style={{color: 'red'}}>Hello World</p>
  }

  return (
    <AllRoutes />
  );
}

export default App;
