import React, { useEffect, useState } from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import InvoiceTable from "../../components/tables/InvoiceTable";
import { Link } from "react-router-dom";
import { colors } from "../../config/colors";
import { deleteicon, edit } from "../../config/images";
import './../../components/tables/tables.css';

import { useDispatch, useSelector } from "react-redux/es/exports";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { apiUrl } from '../../config/config';
import Overlay from "../../components/common/Overlay";

const AdminCustomersList = (props) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const userdata = useSelector((store) => store.userReducer.userdata);
    const [showloading, setshowloading] = useState(false);
    const [adminlistdata, setadminlistdata] = useState([]);
    

    const fetchadminlistdata = async() => {
        setshowloading(true);
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + userdata?.token
            }
        }
        let response = await axios.get(apiUrl + 'adminfetchcustomerslist', headers).then((res) => {
            if(res.data.status == 'success') {
                setadminlistdata(res.data.data)
             } else {
               alert(res.data.message);
             }
        }).catch((err) => {
            console.log('err >>', err);
        })
        setshowloading(false);
    }

    const ondeletepressed = async(id) => {
        // console.log('ondeletepressed .>>>');
        if(window.confirm("Are you sure you want to delete this customer")) {
            setshowloading(true)
            var data = {
                'customerid' : id
            }
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + userdata?.token
                }
            }
            let resp = await axios.post(apiUrl + 'admindeletecustomer', data, headers).then((res) => {
                if(res.data.status == 'success') {
                    // setadminlistdata(res.data.data)
                    fetchadminlistdata();
                 } else {
                   alert(res.data.message);
                 }
            }).catch((err) => {
                console.log('err >>', err)
            })

            setshowloading(false)
        } else {
            return
        }
        
    }

    const oneditpressed = async(id) => {
        navigate('/cms/admin/edit-customer', {state: {customerid: id}})
    }

    useEffect(() => {
        if(userdata && Object.keys(userdata).length > 0) {
            fetchadminlistdata();
        } else {
            navigate('/cms/admin/login');
        }
    }, []);
    return (
        <div className="HomeMain">
            <Overlay showloader={showloading} />
        <div className="sidebar-div">
          <Sidebar name={'Admin'} />
        </div>
        <div className="main-layout">
            <Navbar name={'Admin'} />
            <div className="mainDiv">
                <p className="oneplaceHead">LIST OF CUSTOMERS</p>
                <div className="tableMaindiv">
            <div className="tablebuttondiv" style={{justifyContent: 'flex-end'}}>
                    <Link to="/cms/admin/add-new-customer" className="btndiv" style={{backgroundColor: colors.themeBlue}}>
                        <p>{'NEW CUSTOMER'}</p>
                    </Link>
            </div>
            {
                adminlistdata?.length > 0 ?
                <table class="table table-bordered invoicetable">
                <thead>
                    <th>Sr no.</th>
                    <th>Name</th>
                    <th>Username</th>
                    <th>Slug</th>
                    <th>Email</th>
                    <th>Settings</th>
                </thead>
                <tbody>
                    {
                        adminlistdata?.map((item, index) => {
                            // console.log('item ..', item)
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.username}</td>
                                <td>{item.slug}</td>
                                <td>{item.email}</td>
                                <td>
                                  <div className="displayedittable">
                                  <div onClick={() => {oneditpressed(item.id)}} style={{cursor: 'pointer'}}>
                                      <img
                                        src={edit}
                                        className="editicon"
                                      />
                                    </div>
                                    <div onClick={() => {ondeletepressed(item.id)}} style={{cursor: 'pointer'}}>
                                      <img
                                        src={deleteicon}
                                        className="editicon"
                                      />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                        })
                    }
                </tbody>
            </table> : 
            <div className="">
                <p style={{textAlign: 'center'}}>Customer's not created yet</p>
            </div>
            }
            
        </div>
            </div>
        </div>
      </div>
    );
}

export default AdminCustomersList