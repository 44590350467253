import React, { useEffect, useState } from "react";
import InvoiceTable from "../../components/tables/InvoiceTable";

import { Link } from "react-router-dom";
import { colors } from "../../config/colors";

import { useDispatch, useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../../config/config";
import axios from "axios";
import Overlay from "../../components/common/Overlay";
import { edit, deleteicon } from "../../config/images";
import WebNavbar from "../../components/common/WebNavbar";
import WebSidebar from "../../components/common/WebSidebar";
import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const WebAdminMainCategories = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const userdata = useSelector((store) => store.userReducer.userdata);
  const [showloding, setshowloding] = useState(false);
  const [categoriesdata, setcategoriesdata] = useState([]);
  const [showmodal, setshowmodal] = useState(false);
  const [editshowmodal, seteditshowmodal] = useState(false);
  const [categoryname, setcategoryname] = useState(null);
  const [selectedid, setselectedid] = useState(0);
  const [brandname, setbrandname] = useState(null);

  const deletecategory = async (id) => {
    if (window.confirm("Are you sure you want to delete this category")) {
      setshowloding(true);
      var data = {
        id: id,
      };
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "deletemaincategory", data, headers)
        .then((res) => {
          if (res.data.status == "success") {
            fetchdata();
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          alert(err.message);
        });
      setshowloding(false);
    } else {
      return;
    }
  };

  const submithandle = async () => {
    if (categoryname && brandname) {
      setshowloding(true);
      var data = {
        name: categoryname,
        brandname: brandname
      };
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "addnewmaincategory", data, headers)
        .then((res) => {
          // console.log('res >>>', res.data)
          if (res.data.status == "success") {
            setshowmodal(false);
            setcategoryname("");
            setbrandname("");
            fetchdata();
            setshowloding(false);
          } else {
            alert(res.data.message);
            setshowloding(false);
          }
        })
        .catch((err) => {
          setshowloding(false);
          alert(err.message);
        });
      setshowloding(false);
    } else {
      alert("Empty Field");
    }
  };

  const submithandle1 = async() => {
    if (categoryname && brandname) {
        setshowloding(true);
        var data = {
            id: selectedid,
            name: categoryname,
            brandname: brandname
        };
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userdata?.token,
          },
        };
        let resp = await axios
          .post(apiUrl + "editmaincategory", data, headers)
          .then((res) => {
            // console.log('res >>>', res.data)
            if (res.data.status == "success") {
              seteditshowmodal(false);
              setselectedid(0);
              setcategoryname("");
              setbrandname("");
              fetchdata();
              setshowloding(false);
            } else {
              alert(res.data.message);
              setshowloding(false);
            }
          })
          .catch((err) => {
            setshowloding(false);
            alert(err.message);
          });
        setshowloding(false);
      } else {
        alert("Empty Field");
      }
  }

  const editcategoryname = async(id, name, brandname) => {
    setselectedid(id);
    setcategoryname(name);
    setbrandname(brandname);
    seteditshowmodal(true);
  }

  const fetchdata = async () => {
    setshowloding(true);
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userdata?.token,
      },
    };
    let resp = await axios
      .get(apiUrl + "maincategorylist", headers)
      .then((res) => {
        // console.log('res >>>', res.data)
        if (res.data.status == "success") {
          console.log("res.data.data >>", res.data.data);
          setcategoriesdata(res.data.data);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
    setshowloding(false);
  };

  useEffect(() => {
    if (userdata && Object.keys(userdata).length > 0) {
      fetchdata();
    } else {
      navigate("/WebAdminlogin");
    }
  }, []);
  return (
    <div className="HomeMain">
      <Overlay showloader={showloding} />
      <div className="sidebar-div">
        <WebSidebar name={"Admin"} />
      </div>
      <div className="main-layout">
        <WebNavbar name={"Admin"} />
        <div className="mainDiv">
          <p className="oneplaceHead">MAIN CATEGORIES</p>
          <div className="tablebuttondiv tablebuttondivpage">
            <Link
              onClick={() => {
                setcategoryname("");
                setbrandname("");
                setshowmodal(true);
              }}
              className="btndiv webadminmaindiv"
            >
              <p>{"Add Category"}</p>
            </Link>
          </div>
          {categoriesdata?.length > 0 ? (
            <table class="table table-bordered invoicetable">
              <thead>
                <th>Sr no.</th>
                <th>Name</th>
                <th>Brand Name</th>
                <th>Settings</th>
              </thead>
              <tbody>
                {categoriesdata?.map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>{item.brandname}</td>
                      <td>
                        <div className="displayedittable">
                          <div
                            onClick={() => {
                              editcategoryname(item.id, item.name, item.brandname);
                            }}
                          >
                            <img src={edit} className="editicon" />
                          </div>
                          <div
                            onClick={() => {
                                deletecategory(item.id);
                            }}
                          >
                            <img src={deleteicon} className="editicon" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="">
              <p style={{ textAlign: "center" }}>No data yet.</p>
            </div>
          )}

          {/*Add modal */}
          <Modal
            show={showmodal}
            onHide={() => setshowmodal(false)}
            size="md"
            className="custom-width-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Main Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="webadmin-modalmainbody">
                <div className="">
                  <label>Name</label>
                  <input
                    value={categoryname}
                    onChange={(t) => {
                      setcategoryname(t.target.value);
                    }}
                    type="text"
                    className="form-control tabinput"
                    placeholder="Enter Category name"
                  />
                </div>
                <div className="mt-3">
                  <label>Brand Name</label>
                  <input
                    value={brandname}
                    onChange={(t) => {
                      setbrandname(t.target.value);
                    }}
                    type="text"
                    className="form-control tabinput"
                    placeholder="Enter Brand name"
                  />
                </div>
                <div
                  className="webadmin-modalbtn"
                  onClick={() => {
                    submithandle();
                  }}
                >
                  <p>Submit</p>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/*Edit modal */}
          <Modal
            show={editshowmodal}
            onHide={() => seteditshowmodal(false)}
            size="md"
            className="custom-width-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Main Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="webadmin-modalmainbody">
                <div className="">
                  <label>Name</label>
                  <input
                    value={categoryname}
                    onChange={(t) => {
                      setcategoryname(t.target.value);
                    }}
                    type="text"
                    className="form-control tabinput"
                    placeholder="Enter Category name"
                  />
                </div>
                <div className="mt-3">
                  <label>Brand Name</label>
                  <input
                    value={brandname}
                    onChange={(t) => {
                      setbrandname(t.target.value);
                    }}
                    type="text"
                    className="form-control tabinput"
                    placeholder="Enter Brand name"
                  />
                </div>
                <div
                  className="webadmin-modalbtn"
                  onClick={() => {
                    submithandle1();
                  }}
                >
                  <p>Update</p>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default WebAdminMainCategories;
