import React, { useState, useEffect } from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import InvoiceTable from "../../components/tables/InvoiceTable";
import { pdf } from "../../config/images";
import { colors } from "../../config/colors";

import { useDispatch, useSelector } from "react-redux/es/exports";
import { Link, useNavigate } from 'react-router-dom';
import { apiUrl } from "../../config/config";
import axios from 'axios';
import Overlay from "../../components/common/Overlay";
import {useLocation} from 'react-router-dom';
import { useParams } from 'react-router-dom';


const CustomerReplyForSample = () => {
    const [isapproved, setisapproved] = useState(false);
    const [showcomment, setshowcomment] = useState(false);
    const [isrejected, setisrejected] = useState(false);
    const [requestsampledata, setrequestsampledata] = useState([]);
    const [samplesarr, setsamplesarr] = useState([]);
    const [showloding, setshowloding] = useState(false);
    const [comment, setcomment] = useState(null);
    const [document, setdocument] = useState(null);
    const dispatch = useDispatch(); 
    const userdata = useSelector((store) => store.userReducer.userdata);
    let navigate = useNavigate();
    const { id } = useParams();
    const Samplesdata = [
        {
            'Sr. No.' : '1',
            'Product Name' : 'Pigment Blue 15:3',
            'Application': 'Universal',
            'Ref. No.': 'KDCPL/0001',
            'Status': 'Dispatched',
            'RFS No.': 'RFS/CN/001',
        }
    ]
    const submitrfs1 = () => {
        navigate('/cms/customer/requestsample');  
    }

    const fetchrequestsampledata = async(id) => {
      setshowloding(true);
      var data = {
          'rfsid' : id,
      }
      const headers = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization' : 'Bearer ' + userdata?.token
          }
      }
      let resp = await axios.post(apiUrl + 'customerfetchrfssingle',data, headers).then((res) => {
          // console.log('res >>>', res.data)
            if(res.data.status == 'success') {
              console.log('res.data.data >>', res.data.data);
              setrequestsampledata(res.data.data);
              setsamplesarr(JSON.parse(res.data.data.samplesarr));
              // dispatch({ type: "userdata", payload: res.data.data })
              //  navigate('/cms/customer/requestsample');
            } else {
              alert(res.data.message);
            }
        } ).catch((err) => {
            alert(err.message)
        })
      setshowloding(false)
  }

  const sampleapprovefunction = async(id) => {
    setshowloding(true);
    var data = {
      'rfsid' : id,
  }
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : 'Bearer ' + userdata?.token
      }
  }
  let resp = await axios.post(apiUrl + 'customersampleapprove',data, headers).then((res) => {
      // console.log('res >>>', res.data)
        if(res.data.status == 'success') {
          setisapproved(true);
          // setrequestsampledata(res.data.data);
          // dispatch({ type: "userdata", payload: res.data.data })
          //  navigate('/cms/customer/requestsample');
        } else {
          alert(res.data.message);
        }
    } ).catch((err) => {
        alert(err.message)
    })
    setshowloding(false);
  }

  const getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        // console.log("Called", reader);
        baseURL = reader.result;
        // console.log(baseURL);
        resolve(baseURL);
      };
      // console.log(fileInfo);
    });
  };

  const uploaddocsfunc = async(files) => {
    if(files[0]) {
      setdocument(null);
    var obj = {};
    await getBase64(files[0]).then(result => {
        obj['name'] = files[0].name;
        obj['image'] = result;
    });
  setdocument(obj);
    }
    
}

const rejectsamplefunction = async() => {
  if(comment) {
    setshowloding(true);
  var data = {
    'rfsid' : requestsampledata?.id,
    'comment': comment,
    'document': document
}
  const headers = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization' : 'Bearer ' + userdata?.token
    }
}
let resp = await axios.post(apiUrl + 'requestforrevisedsample',data, headers).then((res) => {
    // console.log('res >>>', res.data)
      if(res.data.status == 'success') {
        setisrejected(true);
        // setrequestsampledata(res.data.data);
        // dispatch({ type: "userdata", payload: res.data.data })
        //  navigate('/cms/customer/requestsample');
      } else {
        alert(res.data.message);
      }
  } ).catch((err) => {
      alert(err.message)
  })
  setshowloding(false);
  } else {
    alert('comment your feedback')
  }
  
}

  useEffect(() => {
      if(userdata && Object.keys(userdata).length > 0) {
        fetchrequestsampledata(id);
      } else {
          navigate('/cms/customer/login');
      }
  }, []);

    return (
      <div className="HomeMain">
        <Overlay showloader={showloding} />
        <div className="sidebar-div">
          <Sidebar />
        </div>
        <div className="main-layout">
          <Navbar />
          <div className="mainDiv">
            {isrejected ? (
              <>
                <p className="oneplaceHead">
                  THANK YOU FOR YOUR FEEDBACK ON THE SAMPLE!
                </p>
                <p className="oneplaceHead oneplaceHead1">
                  WE WILL KEEP YOU POSTED ONCE THE REVISED SAMPLE IS DISPATCHED!
                </p>
                <button
                  onClick={() => {
                    submitrfs1();
                  }}
                  className="submitrfs-btn submitrfs-btn1"
                  style={{
                    backgroundColor: colors.themeBlue,
                    borderColor: colors.themeBlue,
                  }}
                >
                  CLICK HERE TO CHECK RFS STATUS
                </button>
              </>
            ) : isapproved ? (
              <>
                {/* <p className="oneplaceHead">THANK YOU FOR SUBMITTING A RFS!</p> */}
                <p className="oneplaceHead oneplaceHead1">
                  THANK YOU FOR APPROVING THE SAMPLE!
                </p>
                <button
                  onClick={() => {
                    // submitrfs1();
                    navigate("/cms/customer/request-new-quote");
                  }}
                  className="submitrfs-btn submitrfs-btn1"
                  style={{
                    backgroundColor: colors.themeBlue,
                    borderColor: colors.themeBlue,
                  }}
                >
                  CLICK HERE TO REQUEST QUOTE
                </button>
              </>
            ) : (
              <>
                <p className="oneplaceHead">REQUEST FOR SAMPLE </p>
                {
                // Object.keys(requestsampledata).length > 0 ? 
                samplesarr?.length > 0 ?
                (

                  <>
                    <table class="table table-bordered invoicetable">
                    <thead>
                      <th>Sr no.</th>
                      <th>Product Name</th>
                      <th>Application</th>
                      <th>Ref. No.</th>
                      <th>Delivery Status</th>
                      <th>Date of Dispatch</th>
                      <th>Admin Samples</th>
                    </thead>
                    <tbody>
                      {
                        samplesarr?.map((item, index) => {
                          var adminsamples = item.admindocs;
                          return (
                            <tr>
                        <td>{index + 1}</td>
                        <td>{item.productname}</td>
                        <td>{item.application}</td>
                        <td>
                          {item.refno
                            ? item.refno
                            : "-"}
                        </td>
                        <td>
                          {item.deliverystatus
                            ? item.deliverystatus
                            : "-"}
                        </td>
                        <td>{item.dateofdispatch}</td>
                        <td>
                          {
                            adminsamples?.length > 0 ?
                            adminsamples?.map((items, indexs) => {
                              return (
                                <div className="pdfrequestdiv">
                                  <img src={pdf} style={{ width: 20, height: 20 }} />
                        <Link target="_blank" to={items.link} style={{textDecoration: 'none'}}>
                          <p
                            style={{
                              color: "#000",
                              fontSize: 17,
                              fontWeight: "bold",
                              marginLeft: 10,
                              marginTop: 0,
                            }}
                          >
                            {items.name}
                          </p>
                        </Link>
                                </div>
                              )
                            }) :
                            'No admin samples'
                          }
                        </td>
                      </tr>
                          )
                        })
                      }
                      
                    </tbody>
                  </table>
                  {
                    requestsampledata?.status == 'revised sample' ||  requestsampledata?.revisedocument ?
                    <div className="pdfrequestsamplediv mt-5" >
                    {
                      JSON.parse(requestsampledata?.revisedocument).map((item, index) => {
                        return (
                          <div className="pdfrequestdiv">
                            <img src={pdf} style={{ width: 32, height: 32 }} />
                            <Link target="_blank" to={item.link} style={{textDecoration: 'none'}}>
                              <p
                                style={{
                                  color: "#000",
                                  fontSize: 20,
                                  fontWeight: "bold",
                                  marginLeft: 10,
                                  marginTop: 3,
                                }}
                              >
                                {item.name}
                              </p>
                            </Link>
                          </div>
                        );
                      })
                    }
                </div> : 
                <div className="pdfrequestsamplediv mt-5" >
                {/* {
                  JSON.parse(requestsampledata?.adminsamples).map((item, index) => {
                    return (
                      <div className="pdfrequestdiv">
                        <img src={pdf} style={{ width: 32, height: 32 }} />
                        <Link target="_blank" to={item.link} style={{textDecoration: 'none'}}>
                          <p
                            style={{
                              color: "#000",
                              fontSize: 20,
                              fontWeight: "bold",
                              marginLeft: 10,
                              marginTop: 3,
                            }}
                          >
                            {item.name}
                          </p>
                        </Link>
                      </div>
                    );
                  })
                } */}
            </div>
                  }
                  
                { showcomment ?

                null :
                requestsampledata.status == 'accepted' || requestsampledata.status == 'pending' ?
                null :
                  <div className="btnrequestsamplediv ">
                  <button
                    onClick={() => {
                      sampleapprovefunction(requestsampledata.id);
                    }}
                    className="submitrfs-btn submitrfs-btn2"
                    style={{
                      backgroundColor: colors.themeBlue,
                      borderColor: colors.themeBlue,
                    }}
                  >
                    Approve Sample
                  </button>
                  <button
                    onClick={() => {
                      //   submitrfs();
                      setshowcomment(true);
                    }}
                    className="submitrfs-btn submitrfs-btn2 submitrfs-red"
                    style={{
                      backgroundColor: colors.themeRed,
                      borderColor: colors.themeRed,
                      marginLeft: 20,
                    }}
                  >
                    Reject Sample
                  </button>
                </div>
                }
                
                  </>
                  
                ) : (
                  <div className="">
                    <p style={{ textAlign: "center" }}>No request yet.</p>
                  </div>
                )}
                

                {showcomment ? (
                  <div className="commentbox">
                    <textarea
                      rows={4}
                      value={comment}
                      onChange={(t) => {setcomment(t.target.value)}}
                      className="form-control"
                      placeholder="comment your feedback here"
                    ></textarea>
                    <div
                      className="btnrequestsamplediv"
                      style={{ marginTop: 20 }}
                    >
                      <label
                        onClick={() => {
                          // setisapproved(true)
                        }}
                        className="submitrfs-btn submitrfs-btn2 submitrfs-red"
                        style={{
                          backgroundColor: colors.themeRed,
                          borderColor: colors.themeRed,
                        }}
                        for="upload-pdf"
                      >
                        Upload feedback
                      </label>
                      <input type="file" onChange={(t) => {uploaddocsfunc(t.target.files)}} accept="application/pdf" id="upload-pdf" />
                      <button
                        onClick={() => {
                          // setisrejected(true);
                          rejectsamplefunction();
                        }}
                        className="submitrfs-btn submitrfs-btn2 submitrfs-red"
                        style={{
                          backgroundColor: colors.themeRed,
                          borderColor: colors.themeRed,
                          marginLeft: 20,
                        }}
                      >
                        Reject Sample
                      </button>
                    </div>
                  </div>
                ) : (
                  null
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
}

export default CustomerReplyForSample