import React, {useState, useEffect} from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import InvoiceTable from "../../components/tables/InvoiceTable";

import { Link } from "react-router-dom";
import { colors } from "../../config/colors";

import { useDispatch, useSelector } from "react-redux/es/exports";
import { useNavigate } from 'react-router-dom';
import { apiUrl } from "../../config/config";
import axios from 'axios';
import Overlay from "../../components/common/Overlay";
import { edit, deleteicon } from "../../config/images";

const AdminRequestQuote = () => {
    const dispatch = useDispatch(); 
    const userdata = useSelector((store) => store.userReducer.userdata);
    let navigate = useNavigate();

    const [showloding, setshowloding] = useState(false);
    const [requestsampledata, setrequestsampledata] = useState([]);

    const fetchrequestsampledata = async() => {
        setshowloding(true);
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + userdata?.token
            }
        }
        let resp = await axios.get(apiUrl + 'adminfetchrfq', headers).then((res) => {
            console.log('res >>>', res.data)
              if(res.data.status == 'success') {
                setrequestsampledata(res.data.data);
                // dispatch({ type: "userdata", payload: res.data.data })
                //  navigate('/customer/requestsample');
              } else {
                alert(res.data.message);
              }
          } ).catch((err) => {
              alert(err.message)
          })
        setshowloding(false)
    }

    const clickproductname = (id, status) => {
        if(status == 'accepted') {
            navigate('/cms/admin/request-for-quote/'+id);
       } else  if(status == 'revised quote') {
            navigate('/cms/admin/request-for-revised-quote/'+id);
        } else {
            navigate('/cms/admin/request-for-quote/'+id);
        }
       
    }
    const clickproductname1 = (id) => {
        navigate('/cms/admin/edit-rfq/'+id);
    }
    const deleterfq = async(id) => {
        if(window.confirm("Are you sure you want to delete this quote")) {
        setshowloding(true);
        var data = {
            'rfqid' : id,
        }
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + userdata?.token
            }
        }
        let resp = await axios.post(apiUrl + 'admindeleterfq',data, headers).then((res) => {
            // console.log('res >>>', res.data)
              if(res.data.status == 'success') {
                setrequestsampledata(res.data.data);
                // dispatch({ type: "userdata", payload: res.data.data })
                //  navigate('/cms/customer/requestsample');
              } else {
                alert(res.data.message);
              }
          } ).catch((err) => {
              alert(err.message)
          })
        setshowloding(false)
    } else {
        return
    }
    }

    useEffect(() => {
        if(userdata && Object.keys(userdata).length > 0) {
            fetchrequestsampledata();
        } else {
            navigate('/cms/admin/login');
        }
    }, []);
    
    return (
      <div className="HomeMain">
        <Overlay showloader={showloding} />
        <div className="sidebar-div">
          <Sidebar name={'Admin'}/>
        </div>
        <div className="main-layout">
            <Navbar name={'Admin'} />
            <div className="mainDiv">
            <p className="oneplaceHead">REQUEST FOR QUOTE</p>
            {
                requestsampledata?.length > 0 ?
                <table class="table table-bordered invoicetable">
                <thead>
                    <th>Sr no.</th>
                    <th>Product Name</th>
                    <th>Kevin Code</th>
                    <th>Ref. No.</th>
                    <th>Application</th>
                    <th>Validity</th>
                    <th>RFQ No.</th>
                    <th>Status</th>
                    <th>Settings</th>
                </thead>
                <tbody>
                    {
                        requestsampledata?.map((item, index) => {
                            // console.log('item ..', item)
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{item.productname}</td>
                                <td>{item.kevincode}</td>
                                <td>{item.reference}</td>
                                <td>{item.application}</td>
                                <td>{item.validity1}</td>
                                <td>
                                    <div className="displayedittable" onClick={() => {clickproductname(item.id, item.status)}}>
                                            <Link >
                                                {item.rfqnumber}
                                            </Link>
                                        </div>
                                    {/* {item.rfsnumber} */}
                                </td>
                                <td>
                                    <div onClick={() => {clickproductname(item.id, item.status)}} className="statusdiv" style={item.status == 'pending' ? {backgroundColor: '#ffde59'} : item.status == 'accepted' ? {backgroundColor: '#4fb293', cursor: 'pointer'} : item.status == 'feedback pending' ? {backgroundColor: '#4fb293'} : item.status == 'revised quote' ? {backgroundColor: '#ff3131'} :  null}>
                                        {item.status}
                                    </div>
                                </td>
                                <td>
                                  <div className="displayedittable">
                                  {
                                    item.status == 'accepted' ?
                                    <div onClick={() => {deleterfq(item.id)}}>
                                      <img src={deleteicon} className="editicon" />
                                    </div> :
                                    <div onClick={() => {clickproductname1(item.id)}}>
                                        <img src={edit} className="editicon" />
                                    </div>
                                  }
                                    
                                  </div>
                                </td>
                              </tr>
                            );
                        })
                    }
                </tbody>
            </table> : 
            <div className="">
                <p style={{textAlign: 'center'}}>No quotes yet.</p>
            </div>
            }
            </div>
        </div>
      </div>
    );
}

export default AdminRequestQuote