import React, { useState, useEffect, useRef } from "react";
import Webheader from "../../components/common/Webheader";
import "./Web.css";
import WebFooter from "../../components/common/WebFooter";
import favicon from '../../assets/images/favicon.png';
import axios from "axios";
import { apiUrl } from "../../config/config";
import Overlay from "../../components/common/Overlay";

const Contact = () => {
  const [name, setname] = useState();
  const [email, setemail] = useState();
  const [message, setmessage] = useState();
  const [showloding, setshowloding] = useState(false);

  const handleformsubmit = async() => {
    if(name && email && message) {
      setshowloding(true);
    var data = {
        name: name,
        email: email,
        message: message,
    }
    const headers = {
      headers: {
        "Content-Type": "application/json"
      },
    };
    let resp = await axios
      .post(apiUrl + "webcontactformsubmit",data, headers)
      .then((res) => {
        console.log('res >>>', res.data.data)
        if (res.data.status == "success") {
            alert(res.data.message);
           
            // console.log('productsdata >>', productsdata);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
    setshowloding(false);
    } else {
      alert('Please fill all required fields');
    }
  }
  useEffect(() => {
    document.title = "Kevin India"
    const newFavicon = document.createElement('link');
        newFavicon.rel = 'icon';
        newFavicon.href = favicon; // Replace 'new-favicon-url.png' with your new favicon URL
        document.head.appendChild(newFavicon);
        newFavicon.href = favicon;
  }, []);
    return (
      <div className="webmainbg">
        <Overlay showloader={showloding} />
        <Webheader />
        <div className="contactpagemaindiv">
          <div className="pagewidth">
            <div className="row">
              <div className="col-sm-6">
                <div className="contactleftbg mobhide tabletshow"></div>
              </div>
              <div className="col-sm-6">
                <div className="contactrightdiv">
                  <p className="section4head">Contact Us</p>
                  <div className="contactleftbg deskhide tablethide"></div>
                  <p className="section3subtext contactpagedesc">
                    Explore the future with us. Feel free to get in touch.
                  </p>
                  <div className="forwdiv">
                    <div className="inputdiv">
                      <label class="form-label">
                        Name* :
                      </label>
                      <input
                        value={name}
                        onChange={(t) => setname(t.target.value)}
                        type="text"
                        class="form-control custominput"
                        placeholder="Full Name"
                      />
                    </div>
                    <div className="inputdiv">
                      <label class="form-label">
                        Email* :
                      </label>
                      <input
                      value={email}
                      onChange={(t) => setemail(t.target.value)}
                        type="text"
                        class="form-control custominput"
                        placeholder="Email Address"
                      />
                    </div>
                    <div className="inputdiv">
                      <label class="form-label">
                        Message* :
                      </label>
                      <textarea
                        type="text"
                        value={message}
                        onChange={(t) => setmessage(t.target.value)}
                        class="form-control custominput customtextarea"
                        placeholder="How can we assist you?"
                      ></textarea>
                    </div>
                    <div className="custominput contactsubmitbtn" onClick={() => {handleformsubmit()}}>
                        <p className="contactsubmitbtn-text">Send Message</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <WebFooter />
      </div>
    );
}

export default Contact;