import React, { useState, useEffect, useRef } from "react";
import weblogo from "../../assets/images/newlogo_r.png";
import { Link, useLocation } from "react-router-dom";
import Menu from "../../assets/images/more.png";
import { Drawer, ButtonToolbar, Button, Placeholder } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

const Webheader = () => {
  const [sticky, setSticky] = useState("");
  const [open, setOpen] = useState(false);

  const isSticky = () => {
    /* Method that will fix header after a specific scrollable */
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 100 ? "is-sticky" : "";
    setSticky(stickyClass);
    console.log(stickyClass);
  };

  const classes = `webheaderbg ${sticky} mobhide`;

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    console.log("hello");
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);
  return (
    <>
      <div className={classes}>
        <div className="pagewidth">
          <div className="headerdiv">
            <div className="logodiv">
              <img src={weblogo} className="weblogo" />
            </div>
            <div className="navbardiv">
              <nav class="navbar navbar-expand-sm">
                <div class="container-fluid">
                  <ul class="navbar-nav">
                    <li class="nav-item">
                      <Link
                        to={"/home"}
                        className={
                          location.pathname == "/home"
                            ? "active nav-link"
                            : "nav-link"
                        }
                      >
                        Home
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link
                        to={"/products"}
                        className={
                          location.pathname == "/products"
                            ? "active nav-link"
                            : "nav-link"
                        }
                      >
                        Products
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link
                        to={"/about-us"}
                        className={
                          location.pathname == "/about-us"
                            ? "active nav-link"
                            : "nav-link"
                        }
                      >
                        About Us
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link
                        to={"/certificates-awards"}
                        className={
                          location.pathname == "/certificates-awards"
                            ? "active nav-link"
                            : "nav-link"
                        }
                      >
                        Certificates and Awards
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link
                        to={"/contact"}
                        className={
                          location.pathname == "/contact"
                            ? "active nav-link"
                            : "nav-link"
                        }
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            <div className="logindiv">
              <Link to={"/cms/customer/login"} class="nav-link">Log in</Link>
              <Link to={"/find-product/all"} className="productheaderbtn">
                <p>Product Finder</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="deskhide mobheadermaindiv">
        <div className="pagewidth">
          <div className="mobmenubar">
            <div onClick={() => setOpen(true)} className="mobmenu">
              <img src={Menu} />
            </div>
            <div className="moblogo">
              <img src={weblogo} />
            </div>
            {/* <div class="logindiv">
              <a class="nav-link" href="/home">
                Login
              </a>
            </div> */}
          </div>
        </div>
        <Drawer
          open={open}
          placement="left"
          onClick={() => setOpen(false)}
          onClose={() => setOpen(false)}
          backdrop={true}
          size={'xs'}
        >
          <Drawer.Header >
            {/* <p className="unreadhead">unread message</p> */}
          </Drawer.Header>
          <Drawer.Body>
          {/* <Placeholder.Paragraph rows={8} /> */}
          <div className="mobmenu-slider-maindiv">
            <ul class="navbar-nav">
                    <li class="nav-item">
                      <Link
                        to={"/home"}
                        className={
                          location.pathname == "/home"
                            ? "active nav-link"
                            : "nav-link"
                        }
                      >
                        Home
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link
                        to={"/products"}
                        className={
                          location.pathname == "/products"
                            ? "active nav-link"
                            : "nav-link"
                        }
                      >
                        Products
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link
                        to={"/find-product/all"}
                        className={
                          location.pathname == "/products"
                            ? "active nav-link"
                            : "nav-link"
                        }
                      >
                        Product Finder
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link
                        to={"/about-us"}
                        className={
                          location.pathname == "/about-us"
                            ? "active nav-link"
                            : "nav-link"
                        }
                      >
                        About Us
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link
                        to={"/certificates-awards"}
                        className={
                          location.pathname == "/certificates-awards"
                            ? "active nav-link"
                            : "nav-link"
                        }
                      >
                        Certificates and Awards
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link
                        to={"/contact"}
                        className={
                          location.pathname == "/contact"
                            ? "active nav-link"
                            : "nav-link"
                        }
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
          </div>
        </Drawer.Body>
        
        </Drawer>
      </div>
    </>
  );
};

export default Webheader;
