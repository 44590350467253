import React, { useState, useEffect } from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import InvoiceTable from "../../components/tables/InvoiceTable";
import { pdf } from "../../config/images";
import { colors } from "../../config/colors";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { apiUrl } from "../../config/config";
import axios from 'axios';
import Overlay from "../../components/common/Overlay";
import { edit, deleteicon } from "../../config/images";
import {useLocation} from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';


const SuperadminnewRectificationRequest = () => {
  const dispatch = useDispatch(); 
    let navigate = useNavigate();
    const userdata = useSelector((store) => store.userReducer.userdata);
    const [isapproved, setisapproved] = useState(false);
    const [showcomment, setshowcomment] = useState(false);
    const [isrejected, setisrejected] = useState(false);
    const [comment, setcomment] = useState(null);
    const [commentdoc, setcommentdoc] = useState(null);
    const [showloding, setshowloding] = useState(false);
    const { state } = useLocation();
    const submitrectification = async(id) => {
      if(comment) {
        setshowloding(true);
        var data = {
          'invoiceid' : state.invoiceid,
          'step' : state.step,
          'comment' : comment,
          'doc' : commentdoc,
      }
        const headers = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization' : 'Bearer ' + userdata?.token
          }
      }
      let resp = await axios.post(apiUrl + 'superadminsumbitrectification',data, headers).then((res) => {
          // console.log('res >>>', res.data)
            if(res.data.status == 'success') {
              setisrejected(true)
            } else {
              alert(res.data.message);
            }
        } ).catch((err) => {
            alert(err.message)
        })
        setshowloding(false);
    } else {
        alert('Please add comment')
    }
      
    }
    const submitrfs1 = () => {
        navigate('/cms/superadmin/requestsample');  
    }
    const getBase64 = file => {
      return new Promise(resolve => {
        let fileInfo;
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();
  
        // Convert the file to base64 text
        reader.readAsDataURL(file);
  
        // on reader load somthing...
        reader.onload = () => {
          // Make a fileInfo Object
          // console.log("Called", reader);
          baseURL = reader.result;
          // console.log(baseURL);
          resolve(baseURL);
        };
        // console.log(fileInfo);
      });
    }; 
    const uploaddocsfunc = async(files) => {
      if(files[0]) {
        setcommentdoc(null);
      var obj = {};
      await getBase64(files[0]).then(result => {
          obj['name'] = files[0].name;
          obj['image'] = result;
      });
      setcommentdoc(obj);
      }
      
    }
    useEffect(() => {
      if(userdata && Object.keys(userdata).length > 0) {
          // fetchhomedata();
          console.log('invoiceid ..', state.invoiceid);
          console.log('step ..', state.step);
      } else {
        navigate('/cms/superadmin/login');
      }
  }, []);
    return (
      <div className="HomeMain">
        <Overlay showloader={showloding} />
        <div className="sidebar-div">
          <Sidebar name={'superadmin'} />
        </div>
        <div className="main-layout">
            <Navbar name={'superadmin'} />
            <div className="mainDiv">
                { 
                isrejected ?
                <>
                <p className="oneplaceHead">RESPONSE TO RECTIFICATION</p>
                <p onClick={() => {
                navigate('/cms/superadmin/new-pfi-tracking/'+state.invoiceid);
              }} className="oneplaceHead" style={{textAlign : 'left'}}>{'< BACK'}</p>
                <div className="commentbox">
                            <textarea rows={6}
                            onChange={(t) => {setcomment(t.target.value)}}
            className="form-control"
            placeholder="KEVIN RESPONSE:">{comment}</textarea>
            <div className="pdfrequestsamplediv">
            {
              commentdoc ? 
              <div className="pdfrequestdiv" >
              <img src={pdf} style={{ width: 32, height: 32 }} />
              <Link target="_blank" to={commentdoc?.link} style={{textDecoration: 'none'}}>
                <p
                  style={{
                    color: "#000",
                    fontSize: 20,
                    fontWeight: "bold",
                    marginLeft: 10,
                    marginTop: 3,
                  }}
                >
                  {commentdoc?.name}
                </p>
              </Link>
            </div> :
                null
            }
                    
                </div>
            {/* <div className="btnrequestsamplediv" style={{marginTop: 20, justifyContent: 'space-between'}}>
                <button
                        onClick={() => {
                            // setisapproved(true)
                        }}
                        className="submitrfs-btn submitrfs-btn2 submitrfs-red"
                        style={{
                          backgroundColor: colors.themeRed,
                          borderColor: colors.themeRed,
                        }}
                      >
                        Upload feedback
                    </button>
                    <button
                        onClick={() => {
                        //   submitrfs();
                        // setshowcomment(true)
                        setisrejected(true)
                        }}
                        className="submitrfs-btn submitrfs-btn2 submitrfs-red"
                        style={{
                          backgroundColor: colors.themeRed,
                          borderColor: colors.themeRed,
                          marginLeft: 20
                        }}
                      >
                        REQUEST RECTIFICATION
                    </button>
                </div> */}
                    
                </div>
                </> :
                  <>
                <p className="oneplaceHead">REQUEST FOR RECTIFICATION</p>
                <div className="commentbox">
          <textarea rows={6}
            className="form-control"
            onChange={(t) => {setcomment(t.target.value)}}
            placeholder="comment your reason here">{comment}</textarea>
            <div className="btnrequestsamplediv" style={{marginTop: 20, justifyContent: 'space-between'}}>
                    <label
                        onClick={() => {
                            // setisapproved(true)
                        }}
                        className="submitrfs-btn submitrfs-btn2 submitrfs-red"
                        style={{
                          backgroundColor: colors.themeRed,
                          borderColor: colors.themeRed,
                        }}
                        for="upload-pdf"
                      >
                        Upload feedback
                    </label>
                    <input type="file" onChange={(t) => {uploaddocsfunc(t.target.files)}} accept="application/pdf" id="upload-pdf" />
                    <button
                        onClick={() => {
                        //   submitrfs();
                        // setshowcomment(true)
                        submitrectification();
                        }}
                        className="submitrfs-btn submitrfs-btn2 submitrfs-red"
                        style={{
                          backgroundColor: colors.themeRed,
                          borderColor: colors.themeRed,
                          marginLeft: 20
                        }}
                      >
                        REQUEST RECTIFICATION
                    </button>
                </div>
                        </div>
                </>
}
            </div>
        </div>
      </div>
    );
}

export default SuperadminnewRectificationRequest