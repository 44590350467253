import React from 'react';
import { Route, Link, BrowserRouter as Router, Routes } from 'react-router-dom';
import SuperAdminLogin from '../pages/Auth/SuperAdminLogin';
import Home from '../pages/superadmin/Home';
import CustomerHome from '../pages/customer/Home';
import CustomerProformaInvoice from '../pages/customer/ProformaInvoice';
import CustomerRequestQuote from '../pages/customer/RequestQuote';
import CustomerRequestSample from '../pages/customer/RequestSample';
import CustomerNewSample from '../pages/customer/NewSample';
import CustomerReplyForSample from '../pages/customer/ReplyForSample';
import CustomerNewQuote from '../pages/customer/NewQuote';
import CustomerReplyForQuote from '../pages/customer/ReplyForQuote';
import CustomerOrderTracking from '../pages/customer/OrderTracking';
import CustomerRectificationRequest from '../pages/customer/RectificationRequest';
import CustomerUpdatePassword from '../pages/customer/UpdatePassword';

import AdminLogin from '../pages/admin/Login';
import AdminHome from '../pages/admin/Home';
import AdminRequestSample from '../pages/admin/RequestSample';
import AdminRequestQuote from '../pages/admin/RequestQuote';
import AdminProformaInvoice from '../pages/admin/ProformaInvoice';
import AdminRFSSample from '../pages/admin/RFSSample';
import AdminReverseSampleRequest from '../pages/admin/ReverseSampleRequest';
import AdminRevisedQuoteRequest from '../pages/admin/RevisedQuoteRequest';
import AdminRFQReply from '../pages/admin/RFQReply';
import AdminOrderTracking from '../pages/admin/OrderTracking';
import AdminRectificationRequest from '../pages/admin/RectificationRequest';
import AdminEditRFS from '../pages/admin/EditRFS';
import AdminEditRFQ from '../pages/admin/EditRFQ';
import AdminUpdatePassword from '../pages/admin/UpdatePassword';
import AdminCustomersList from '../pages/admin/CustomersList';
import AdminAddNewCustomer from '../pages/admin/AddNewCustomer';
import AdminEditCustomer from '../pages/admin/EditCustomer';

import SuperAdminHome from '../pages/superadmin/Home';
import AdminsList from '../pages/superadmin/AdminsList';
import AddNewAdmin from '../pages/superadmin/AddNewAdmin';
import EditAdmin from '../pages/superadmin/EditAdmin';
import AddNewCustomer from '../pages/superadmin/AddNewCustomer';
import CustomersList from '../pages/superadmin/CustomersList';
import EditCustomer from '../pages/superadmin/EditCustomer';
import CustomerLogin from '../pages/customer/Login';
import CurrencyList from '../pages/superadmin/CurrencyList';
import AddNewCurrency from '../pages/superadmin/AddNewCurrency';
import EditCurrency from '../pages/superadmin/EditCurrency';
import SuperadminRequestSample from '../pages/superadmin/RequestSample';
import SuperadminRequestQuote from '../pages/superadmin/RequestQuote';
import SuperdminProformaInvoice from '../pages/superadmin/ProformaInvoice';
import SuperAdminEditRFS from '../pages/superadmin/EditRFS';
import SuperAdminEditRFQ from '../pages/superadmin/EditRFQ';
import SuperAdminOrderTracking from '../pages/superadmin/OrderTracking';
import SuperAdminRFQReply from '../pages/superadmin/RFQReply';
import SuperAdminRFSSample from '../pages/superadmin/RFSSample';
import SuperadminNewSample from '../pages/superadmin/NewSample';
import SuperadminNewQuote from '../pages/superadmin/NewQuote';
import SuperAdminReverseSampleRequest from '../pages/superadmin/ReverseSampleRequest';
import SuperadminRevisedQuoteRequest from '../pages/superadmin/RevisedQuoteRequest';
import SuperAdminRectificationRequest from '../pages/superadmin/RectificationRequest';
import SuperadminnewRectificationRequest from '../pages/superadmin/NewRectificationRequest';

import WebHome from '../pages/website/Home';
import Contact from '../pages/website/Contact';
import WebProducts from '../pages/website/Products';
import WebProductsFinder from '../pages/website/ProductsFinder';
import WebProductDetails from '../pages/website/ProductDetails';
import Certfications from '../pages/website/Certfications';
import WebHowToGuide from '../pages/website/HowToGuide';
import WebAboutUs from '../pages/website/AboutUs';
import WebAdminLogin from '../pages/webadmin/Login';
import WebAdminHome from '../pages/webadmin/Home';
import WebAdminMainCategories from '../pages/webadmin/MainCategories';
import WebAdminSubCategories from '../pages/webadmin/SubCategories';
import WebAdminThreeTierCategories from '../pages/webadmin/ThreeTierCategories';
import WebAdminTagsList from '../pages/webadmin/TagsList';
import WebAdminAddProduct from '../pages/webadmin/AddProduct';
import WebAdminProductsList from '../pages/webadmin/ProductsList';
import WebProductDetails1 from '../pages/website/ProductDetails1';
import WebAdminEditProduct from '../pages/webadmin/EditProduct';
import WebAdminFilterTags from '../pages/webadmin/FilterTags';
import WebAdminApplicationTags from '../pages/webadmin/ApplicationTags';
import WebAdminProductImages from '../pages/webadmin/ProductImages';

const AllRoutes = () => {
    return (
      <Router>
      <Routes>
        <Route exact path="/" element={<WebHome />} />
        {/* Superadmin */}
        <Route path="/cms" element={<SuperAdminLogin />} />
        <Route path="/cms/superadmin/login" element={<SuperAdminLogin />} />
        <Route path="/cms/superadmin" element={<SuperAdminLogin />} />
        <Route path='/cms/superadmin/home' element={<SuperAdminHome />} />
        <Route path='/cms/superadmin/admins-list' element={<AdminsList />} /> 
        <Route path='/cms/superadmin/add-new-admin' element={<AddNewAdmin />} />
        <Route path='/cms/superadmin/edit-admin' element={<EditAdmin />} /> 
        <Route path='/cms/superadmin/customers-list' element={<CustomersList />} />
        <Route path='/cms/superadmin/add-new-customer' element={<AddNewCustomer />} />
        <Route path='/cms/superadmin/edit-customer' element={<EditCustomer />} />
        <Route path='/cms/superadmin/currency-list' element={<CurrencyList />} />
        <Route path='/cms/superadmin/add-new-currency' element={<AddNewCurrency />} />
        <Route path='/cms/superadmin/edit-currency' element={<EditCurrency />} />
        <Route path='/cms/superadmin/requestsample' element={<SuperadminRequestSample />} />
        <Route path='/cms/superadmin/requestquote' element={<SuperadminRequestQuote />} />
        <Route path='/cms/superadmin/proformaInvoice' element={<SuperdminProformaInvoice />} />
        <Route path='/cms/superadmin/edit-rfs/:id' element={<SuperAdminEditRFS />} /> 
        <Route path='/cms/superadmin/edit-rfq/:id' element={<SuperAdminEditRFQ />} />
        <Route path='/cms/superadmin/new-pfi-tracking/:id' element={<SuperAdminOrderTracking />} />
        <Route path='/cms/superadmin/reply-rfs/:id' element={<SuperAdminRFSSample />} />
        <Route path='/cms/superadmin/request-for-quote/:id' element={<SuperAdminRFQReply />} />
        <Route path='/cms/superadmin/request-for-revised-quote/:id' element={<SuperadminRevisedQuoteRequest />} />
        <Route path='/cms/superadmin/request-new-sample' element={<SuperadminNewSample />} />
        <Route path='/cms/superadmin/request-new-quote' element={<SuperadminNewQuote />} />
        <Route path='/cms/superadmin/request-for-revised-sample/:id' element={<SuperAdminReverseSampleRequest />} />
        <Route path='/cms/superadmin/request-rectification' element={<SuperAdminRectificationRequest />} />
        <Route path='/cms/superadmin/new-request-rectification' element={<SuperadminnewRectificationRequest />} />
        

        {/* Admin */}
        <Route path='/cms/admin' element={<AdminLogin />} />
        <Route path='/cms/adminlogin' element={<AdminLogin />} />
        <Route path='/cms/admin/login' element={<AdminLogin />} />
        <Route path='/cms/admin/home' element={<AdminHome />} />
        <Route path='/cms/admin/requestsample' element={<AdminRequestSample />} />
        <Route path='/cms/admin/requestquote' element={<AdminRequestQuote />} />
        <Route path='/cms/admin/proformaInvoice' element={<AdminProformaInvoice />} />
        <Route path='/cms/admin/reply-rfs/:id' element={<AdminRFSSample />} />
        <Route path='/cms/admin/request-for-revised-sample/:id' element={<AdminReverseSampleRequest />} />
        <Route path='/cms/admin/request-for-revised-quote/:id' element={<AdminRevisedQuoteRequest />} />
        <Route path='/cms/admin/request-for-quote/:id' element={<AdminRFQReply />} />
        <Route path='/cms/admin/new-pfi-tracking/:id' element={<AdminOrderTracking />} />
        <Route path='/cms/admin/request-rectification' element={<AdminRectificationRequest />} />
        <Route path='/cms/admin/edit-rfs/:id' element={<AdminEditRFS />} />
        <Route path='/cms/admin/edit-rfq/:id' element={<AdminEditRFQ />} />
        <Route path='/cms/admin/update-password' element={<AdminUpdatePassword />} />
        <Route path='/cms/admin/customers-list' element={<AdminCustomersList />} />
        <Route path='/cms/admin/add-new-customer' element={<AdminAddNewCustomer />} />
        <Route path='/cms/admin/edit-customer' element={<AdminEditCustomer />} />
        


        {/* customer */}
        <Route path='/cms/customer' element={<CustomerLogin />} />
        <Route path='/cms/customer/login' element={<CustomerLogin />} />
        <Route path='/cms/customer/home' element={<CustomerHome />} />
        <Route path='/cms/customer/proformaInvoice' element={<CustomerProformaInvoice />} />
        <Route path='/cms/customer/requestquote' element={<CustomerRequestQuote />} />
        <Route path='/cms/customer/requestsample' element={<CustomerRequestSample />} />
        <Route path='/cms/customer/request-new-sample' element={<CustomerNewSample />} />
        <Route path='/cms/customer/reply-for-sample/:id' element={<CustomerReplyForSample />} />
        <Route path='/cms/customer/request-new-quote' element={<CustomerNewQuote />} />
        <Route path='/cms/customer/reply-for-quote/:id' element={<CustomerReplyForQuote />} />
        <Route path='/cms/customer/ordertracking/:id' element={<CustomerOrderTracking />} />
        <Route path='/cms/customer/request-rectification' element={<CustomerRectificationRequest />} />
        <Route path='/cms/customer/update-password' element={<CustomerUpdatePassword />} />


        {/* website */}
        <Route path='/home' element={<WebHome />} />
        <Route path='/Contact' element={<Contact />} />
        <Route path='/products' element={<WebProducts />} />
        <Route path='/find-product/:id' element={<WebProductsFinder />} />
        <Route path='/product-detail' element={<WebProductDetails1 />} />
        <Route path='/certificates-awards' element={<Certfications />} />
        <Route path='/about-us' element={<WebAboutUs />} />
        <Route path='/how-to-guide' element={<WebHowToGuide />} />
        <Route path='/products/:catgory/:subcategory/:threetier/:productname/:id' element={<WebProductDetails />} />

        <Route path='/WebAdminlogin' element={<WebAdminLogin />} />
        <Route path='/Webadminlogin' element={<WebAdminLogin />} />
        <Route path='/Webadmin' element={<WebAdminLogin />} />
        <Route path='/webadmin/home' element={<WebAdminProductsList />} />
        <Route path='/webadmin/main-categories' element={<WebAdminMainCategories />} />
        <Route path='/webadmin/sub-categories' element={<WebAdminSubCategories />} />
        <Route path='/webadmin/three-tier-categories' element={<WebAdminThreeTierCategories />} />
        <Route path='/webadmin/tags-list' element={<WebAdminTagsList />} />
        <Route path='/webadmin/popular-filter-tags' element={<WebAdminFilterTags />} />
        <Route path='/webadmin/popular-applications-tags' element={<WebAdminApplicationTags />} />
        <Route path='/webadmin/add-product' element={<WebAdminAddProduct />} />
        <Route path='/webadmin/product-lists' element={<WebAdminProductsList />} />
        <Route path='/webadmin/edit-product/:id' element={<WebAdminEditProduct />} />
        <Route path='/webadmin/images' element={<WebAdminProductImages />} />
      </Routes>
  </Router>
    )
}

export default AllRoutes;