import React, {useState} from 'react';
// import { Spinner } from "react-activity";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import axios from 'axios';
import { apiUrl } from '../../config/config';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'bootstrap';
import { logo2 } from '../../config/images';
import newlogotm2 from '../../assets/images/newlogo_r1.png';
import { useDispatch, useSelector } from "react-redux/es/exports";
import { deleteicon, edit, view, hide } from "../../config/images";


const AdminLogin = () => {
    const [username, setusername] = useState(null);
    const [password, setpassword] = useState();
    const [newpassword, setnewpassword] = useState(null);
    const [newrepassword, setnewrepassword] = useState(null);
    const [showloding, setshowloding] = useState(false);
    const [token, settoken] = useState(null);
    const [shownewpassword, setshownewpassword] = useState(false);
    const [showrenewpassword, setshowrenewpassword] = useState(false);
    const [showpasswordview, setshowpasswordview] = useState(false);
    const userdata = useSelector((store) => store.userReducer);
    // console.log('userdata >>', userdata);
    let navigate = useNavigate();
    const dispatch = useDispatch(); 
    const loginpressed = async() => {
      // navigate('/customer/home');
      if(username && password) {
        setshowloding(true);
        var data = {
            'username' : username,
            'password': password
        }
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        }
        let resp = await axios.post(apiUrl + 'adminlogin', data, headers).then((res) => {
            if(res.data.status == 'success') {
              if(res.data.data.ispasswordchange == 'false') {
                setshowpasswordview(true);
                setnewpassword('');
                setnewrepassword('');
                settoken(res.data.data.token);
              } else {
                dispatch({ type: "userdata", payload: res.data.data })
                navigate('/cms/admin/home');
              }
            } else {
              alert(res.data.message);
            }
        } ).catch((err) => {
            alert(err.message)
        })
        setshowloding(false);
    } else {
        alert('username and password is required');
    }
    }
    const updatepasswordpressed = async() => {
      if(newpassword && newrepassword ) {
          if(newpassword == newrepassword) {
              setshowloding(true);
              var data = {
                  'newpassword': newpassword
              }
              const headers = {
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization' : 'Bearer ' + token
                  }
              }
              let resp = await axios.post(apiUrl + 'adminupdatepasswordwhilelogin', data, headers).then((res) => {
                  console.log('res >>>', res.data);
                  if(res.data.status == 'success') {
                      dispatch({ type: "userdata", payload: res.data.data })
                      navigate('/cms/admin/home');
                  } else {
                    alert(res.data.message);
                  }
              } ).catch((err) => {
                  console.log('err >>', err);
              })
              setshowloding(false);
          } else {
              alert('Password and re-password does not match.');
          }
      } else {
          alert('Please fill all required details');
      }
    }    
    return (
      <div className="loginBoxMaindiv">
        <div className="loginBox">
        <img src={newlogotm2} style={{height: 60}} />
        {
          !showpasswordview ?
          <>
        <div className="mb-3 mt-3">
          <label for="email" className="form-label">
            Username:
          </label>
          <input
            type="text"
            value={username} onChange={(t) => {setusername(t.target.value)}}
            className="form-control"
            placeholder="Enter username"
          />
        </div>
        <div className="mb-3">
          <label for="pwd" className="form-label">
            Password:
          </label>
          <input
            type="password"
            value={password} onChange={(t) => {setpassword(t.target.value)}}
            className="form-control"
            id="pwd"
            placeholder="Enter password"
            name="pswd"
          />
        </div>
        <div>
            <button type="button" className="btn btn-primary custom-btn" onClick={() => {loginpressed()}}>
                {showloding ?
                <Spinner color='#fff' size={15} className='spinnerCenter' /> : 'Login'
            }
            </button>
        </div>
          </> :
          <>
          <div className="mb-3 mt-3">
        <label for="email" className="form-label">
          Update new password:
        </label>
        <input
          type={!shownewpassword ? "password" : "text" }
          value={newpassword} onChange={(t) => {setnewpassword(t.target.value)}}
          className="form-control"
          placeholder="Enter new password"
        />
      </div>
      <div className="mb-3">
        <label for="pwd" className="form-label">
         Re-type new password:
        </label>
        <input
          type={!showrenewpassword ? "password" : "text" }
          value={newrepassword} onChange={(t) => {setnewrepassword(t.target.value)}}
          className="form-control"
          id="pwd"
          placeholder="re-type new password"
          name="pswd"
        />
        {/* <div className="eyediv" onClick={() => {setshowrenewpassword(!showrenewpassword)}}>
                  {
                      !showrenewpassword ?
                      <img className="hideicon" src={hide} /> :
                      <img className="hideicon" src={view} />
                  }
                  
                </div> */}
      </div>
      <div>
          <button type="button" className="btn btn-primary custom-btn" onClick={() => {updatepasswordpressed()}}>
              {showloding ?
              <Spinner color='#fff' size={15} className='spinnerCenter' /> : 'Update  Password'
          }
          </button>
      </div>
        </>
        }
        
        
      </div>
      </div>
      
    );
}

export default AdminLogin;