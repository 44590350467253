import React, { useState, useEffect } from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import InvoiceTable from "../../components/tables/InvoiceTable";
import { pdf } from "../../config/images";
import { colors } from "../../config/colors";
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from "react-redux/es/exports";
import { apiUrl } from "../../config/config";
import axios from 'axios';
import Overlay from "../../components/common/Overlay";
import { edit, deleteicon } from "../../config/images";
import {useLocation} from 'react-router-dom';
import { useParams } from 'react-router-dom';


const SuperAdminRFQReply = () => {
    const [isapproved, setisapproved] = useState(false);
    const [showcomment, setshowcomment] = useState(false);
    const [isrejected, setisrejected] = useState(false);
    const [eta, seteta] = useState(null);
    const [shipment, setshipment] = useState(null);
    const [price, setprice] = useState(null);
    const [units, setunits] = useState(null);
    const [kevincode, setkevincode] = useState(null);
    const [inco, setinco] = useState(null);
    const [validity1, setvalidity1] = useState(null);
    const [dischargeport, setdischargeport] = useState(null);
    const [showloding, setshowloding] = useState(false);
    const [requestsampledata, setrequestsampledata] = useState([]);
    const [comment, setcomment] = useState(null);
    const [arrivalport, setarrivalport] = useState(null);
    const dispatch = useDispatch(); 
    const userdata = useSelector((store) => store.userReducer.userdata);
    let navigate = useNavigate();
    const { id } = useParams();


    const fetchrequestsampledata = async(id) => {
      setshowloding(true);
      var data = {
          'rfqid' : id,
      }
      const headers = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization' : 'Bearer ' + userdata?.token
          }
      }
      let resp = await axios.post(apiUrl + 'superadminfetchrfqsingle',data, headers).then((res) => {
          // console.log('res >>>', res.data)
            if(res.data.status == 'success') {
              setrequestsampledata(res.data.data);
              // console.log('res >>>', res.data.data[0].kevincode)
              setkevincode(res.data.data[0].kevincode)
              setinco(res.data.data[0].inco)
              seteta(res.data.data[0].validity)
              setvalidity1(res.data.data[0].validity1)
              setdischargeport(res.data.data[0].dischargeport)
              setarrivalport(res.data.data[0].arrivalport)
              setshipment(res.data.data[0].shipment)
              setprice(res.data.data[0].price)
              setunits(res.data.data[0].units)
              // dispatch({ type: "userdata", payload: res.data.data })
              //  navigate('/cms/customer/requestsample');
            } else {
              alert(res.data.message);
            }
        } ).catch((err) => {
            alert(err.message)
        })
      setshowloding(false)
  }

  const rfssamplereply = async() => {
    setshowloding(true);
    var data = {
        'rfqsid': id,
        'price': price,
        'units': units,
        'kevincode': kevincode,
        'inco': inco,
        'validity' : eta,
        'validity1': validity1,
        'shipment': shipment,
        'arrivalport': arrivalport,
        'dischargeport': dischargeport
      };
      // console.log('data .>', data)
    const headers = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + userdata?.token
        }
    }
    let resp = await axios.post(apiUrl + 'superadminreplyrfq', data, headers).then((res) => {
      // console.log('res >>>', res.data)
        if(res.data.status == 'success') {
          // dispatch({ type: "userdata", payload: res.data.data })
           navigate('/cms/superadmin/requestquote');
        } else {
          alert(res.data.message);
        }
    } ).catch((err) => {
        alert(err.message)
    })
    setshowloding(false);
  }
  const rejectsamplefunction = async() => {
    if(comment) {
      setshowloding(true);
    var data = {
      'rfqid' : requestsampledata[0]?.id,
      'comment': comment,
    }
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : 'Bearer ' + userdata?.token
      }
  }
  let resp = await axios.post(apiUrl + 'superadminrequestforrevisedquote',data, headers).then((res) => {
      // console.log('res >>>', res.data)
        if(res.data.status == 'success') {
          // setisrejected(true);
          // setrequestsampledata(res.data.data);
          // dispatch({ type: "userdata", payload: res.data.data })
          //  navigate('/customer/requestsample');
          navigate('/cms/superadmin/requestquote');
        } else {
          alert(res.data.message);
        }
    } ).catch((err) => {
        alert(err.message)
    })
    setshowloding(false);
    } else {
      alert('comment your feedback')
    }
    
  }

  const sampleapprovefunction = async(id) => {
    setshowloding(true);
    var data = {
      'rfqid' : id,
  }
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : 'Bearer ' + userdata?.token
      }
  }
  let resp = await axios.post(apiUrl + 'superadminquoteapprove',data, headers).then((res) => {
      // console.log('res >>>', res.data)
        if(res.data.status == 'success') {
          // setisapproved(true);
          // setrequestsampledata(res.data.data);
          // dispatch({ type: "userdata", payload: res.data.data })
           navigate('/cms/superadmin/requestquote');
        } else {
          alert(res.data.message);
        }
    } ).catch((err) => {
        alert(err.message)
    })
    setshowloding(false);
  }

    useEffect(() => {
      if(userdata && Object.keys(userdata).length > 0) {
          // console.log('id >>', id)
          fetchrequestsampledata(id);
      } else {
          navigate('/cms/superadmin/login');
      }
  }, []);
    return (
      <div className="HomeMain">
        <Overlay showloader={showloding} />
        <div className="sidebar-div">
          <Sidebar name={"superadmin"} />
        </div>
        <div className="main-layout">
          <Navbar name={"superadmin"} />
          <div className="mainDiv">
            {requestsampledata?.length > 0 ? (
              <>
                <p className="oneplaceHead">REQUEST FOR QUOTE</p>
                {requestsampledata[0]?.note !== null ? 
                  <p style={{textAlign: 'right', marginBottom: 10}}>{requestsampledata[0]?.note}</p>  : null
                }
                <div style={{ display: "flex", flexDirection: "row",}} className="mt-3">
                  <p className="eta-head mt-2" style={{ width: '30%' }}>{requestsampledata[0]?.estimation ? requestsampledata[0]?.estimation : 'ETA'} : </p>
                  {
                    requestsampledata[0]?.status == 'accepted' ?
                    <p className="eta-head mt-2" style={{ width: '15%', fontWeight: 'normal' }}>{requestsampledata[0]?.validity}</p> :
                    <input
                    value={eta}
                    onChange={(t) => {
                      seteta(t.target.value);
                    }}
                    type="date"
                    // name={'productname'+index}
                    className="form-control tabinput"
                    style={{ width: '20%' }}
                  />
                  }
                  
                </div>
                <div style={{ display: "flex", flexDirection: "row",}} className="mt-3">
                  <p className="eta-head mt-2" style={{ width: '30%' }}>Validity :</p>
                  {
                    requestsampledata[0]?.status == 'accepted' ?
                    <p className="eta-head mt-2" style={{ width: '20%', fontWeight: 'normal' }}>{requestsampledata[0]?.validity1}</p> :
                    <input
                    value={validity1}
                    onChange={(t) => {
                      setvalidity1(t.target.value);
                    }}
                    type="date"
                    // name={'productname'+index}
                    className="form-control tabinput"
                    placeholder="Enter Validity"
                    style={{ width: '20%' }}
                  />
                  }
                  
                </div>
                <div style={{ display: "flex", flexDirection: "row",}} className="mt-3">
                  <p className="eta-head mt-2" style={{ width: '30%' }}>Mode of Shipment :</p>
                  {
                    requestsampledata[0]?.status == 'accepted' ?
                    <p className="eta-head mt-2" style={{ width: '20%', fontWeight: 'normal' }}>{requestsampledata[0]?.shipment}</p> :
                    <input
                    value={shipment}
                    onChange={(t) => {
                      setshipment(t.target.value);
                    }}
                    type="text"
                    // name={'productname'+index}
                    className="form-control tabinput"
                    placeholder="Enter Mode of Shipment"
                    style={{ width: '20%' }}
                  />
                  }
                  
                </div>
                <div style={{ display: "flex", flexDirection: "row",}} className="mt-3">
                  <p className="eta-head mt-2" style={{ width: '30%' }}>Port of discharge :</p>
                  {
                    requestsampledata[0]?.status == 'accepted' ?
                    <p className="eta-head mt-2" style={{ width: '20%', fontWeight: 'normal' }}>{requestsampledata[0]?.dischargeport}</p> :
                    <input
                    value={dischargeport}
                    onChange={(t) => {
                      setdischargeport(t.target.value);
                    }}
                    type="text"
                    // name={'productname'+index}
                    className="form-control tabinput"
                    placeholder="Enter Port of discharge"
                    style={{ width: '20%' }}
                  />
                  }
                  
                </div>
                <div style={{ display: "flex", flexDirection: "row",}} className="mt-3">
                  <p className="eta-head mt-2" style={{ width: '30%' }}>Port of Arrival :</p>
                  {
                    requestsampledata[0]?.status == 'accepted' ?
                    <p className="eta-head mt-2" style={{ width: '20%', fontWeight: 'normal' }}>{requestsampledata[0]?.arrivalport}</p> :
                    <input
                    value={arrivalport}
                    onChange={(t) => {
                      setarrivalport(t.target.value);
                    }}
                    type="text"
                    // name={'productname'+index}
                    className="form-control tabinput"
                    placeholder="Enter Port of arrival"
                    style={{ width: '20%' }}
                  />
                  }
                  
                </div>
                <div style={{ display: "flex", flexDirection: "row", marginTop: '20' }} className="mt-3 mb-5">
                  <p className="eta-head mt-2" style={{ width: '30%' }}>INCO Terms : </p>
                  {
                    requestsampledata[0]?.status == 'accepted' ?
                    <p className="eta-head mt-2" style={{ width: '10%', fontWeight: 'normal' }}>{requestsampledata[0]?.inco}</p> :
                    <input
                    value={inco}
                    onChange={(t) => {
                      setinco(t.target.value);
                    }}
                    type="text"
                    // name={'productname'+index} 
                    className="form-control tabinput"
                    placeholder="Enter inco"
                    style={{ width: '20%' }}
                  />
                  }
                  
                </div>

                {/* {requestsampledata[0].inco} */}

                <table class="table table-bordered invoicetable">
                  <thead>
                    <th>Customer Name</th>
                    <th>Product Name</th>
                    <th>Application</th>
                    <th>Ref. No.</th>
                    <th>Validity</th>
                    <th>Quantity</th>
                    <th>Kevin Code</th>
                    <th>Price in {requestsampledata[0].currency}</th>
                    <th>Units</th>
                  </thead>
                  <tbody>
                    {requestsampledata?.map((item, index) => {
                      // console.log('item ..', item)
                      return (
                        <tr>
                          <td>{item.name}</td>
                          <td>{item.productname}</td>
                          <td>{item.application}</td>
                          <td>{item.reference}</td>
                          <td>{item.validity1}</td>
                          <td>{item.quantity}</td>
                          <td>
                          {item.customerid == 'superadmin' ? 
                          item.kevincode
                          :
                          <input
                              value={kevincode}
                              onChange={(t) => {
                                setkevincode(t.target.value);
                              }}
                              type="text"
                              // name={'productname'+index}
                              className="form-control tabinput"
                              placeholder={"Enter Kevin Code"}
                            />
                        }
                            
                            {/* {item.kevincode} */}
                          </td>
                          <td>
                          {item.customerid == 'superadmin' ? 
                          item.price
                          :
                          <input
                              value={price}
                              onChange={(t) => {
                                setprice(t.target.value);
                              }}
                              type="number"
                              // name={'productname'+index}
                              className="form-control tabinput"
                              placeholder={
                                "Enter Price in " +
                                requestsampledata[0].currency
                              }
                            />
                        }
                            
                          </td>
                          <td>
                          {item.customerid == 'superadmin' ? 
                          item.units
                          :
                          <input
                              value={units}
                              onChange={(t) => {
                                setunits(t.target.value);
                              }}
                              type="text"
                              // name={'productname'+index}
                              className="form-control tabinput"
                              placeholder="Enter Units"
                            />
                        }
                            
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {requestsampledata[0].customerid == 'superadmin' ? 
                  <>
                  {showcomment ? (
                  <div className="commentbox">
                    <textarea
                      value={comment}
                      onChange={(t) => {
                        setcomment(t.target.value);
                      }}
                      rows={4}
                      className="form-control"
                      placeholder="comment your feedback here"
                    ></textarea>
                    <div
                      className="btnrequestsamplediv"
                      style={{ marginTop: 20, justifyContent: 'flex-end' }}
                    >
                      {/* <button
                        onClick={() => {
                          // setisapproved(true)
                        }}
                        className="submitrfs-btn submitrfs-btn2 submitrfs-red"
                        style={{
                          backgroundColor: colors.themeRed,
                          borderColor: colors.themeRed,
                        }}
                      >
                        Upload feedback
                      </button> */}
                      <button
                        onClick={() => {
                          //   submitrfs();
                          // setshowcomment(true)
                          // setisrejected(true);
                          rejectsamplefunction();
                        }}
                        className="submitrfs-btn submitrfs-btn2 submitrfs-red"
                        style={{
                          backgroundColor: colors.themeRed,
                          borderColor: colors.themeRed,
                          marginLeft: 0,
                        }}
                      >
                        Request Alternative Quote
                      </button>
                    </div>
                  </div>
                ) : (
                  requestsampledata[0].status !== 'accepted' ?
                  <div className="btnrequestsamplediv btnrequestsamplediv1">
                    <button
                      onClick={() => {
                        // setisapproved(true);
                        sampleapprovefunction(requestsampledata[0].id);
                      }}
                      className="submitrfs-btn submitrfs-btn2"
                      style={{
                        backgroundColor: colors.themeBlue,
                        borderColor: colors.themeBlue,
                      }}
                    >
                      Accept Quote
                    </button>
                    <button
                      onClick={() => {
                        //   submitrfs();
                        setshowcomment(true);
                      }}
                      className="submitrfs-btn submitrfs-btn2 submitrfs-red"
                      style={{
                        backgroundColor: colors.themeRed,
                        borderColor: colors.themeRed,
                        marginLeft: 20,
                      }}
                    >
                      Reject Quote
                    </button>
                  </div> : null
                )}
              </>
                  :
                  requestsampledata[0]?.status == 'accepted' ?
                  null : 
                  requestsampledata[0]?.status == 'feedback pending' ? 
                  showcomment ? (
                    <div className="commentbox">
                      <textarea
                        value={comment}
                        onChange={(t) => {
                          setcomment(t.target.value);
                        }}
                        rows={4}
                        className="form-control"
                        placeholder="comment your feedback here"
                      ></textarea>
                      <div
                        className="btnrequestsamplediv"
                        style={{ marginTop: 20, justifyContent: 'flex-end' }}
                      >
                        {/* <button
                          onClick={() => {
                            // setisapproved(true)
                          }}
                          className="submitrfs-btn submitrfs-btn2 submitrfs-red"
                          style={{
                            backgroundColor: colors.themeRed,
                            borderColor: colors.themeRed,
                          }}
                        >
                          Upload feedback
                        </button> */}
                        <button
                          onClick={() => {
                            //   submitrfs();
                            // setshowcomment(true)
                            // setisrejected(true);
                            rejectsamplefunction();
                          }}
                          className="submitrfs-btn submitrfs-btn2 submitrfs-red"
                          style={{
                            backgroundColor: colors.themeRed,
                            borderColor: colors.themeRed,
                            marginLeft: 0,
                          }}
                        >
                          Request Alternative Quote
                        </button>
                      </div>
                    </div>
                  ) : (
                    requestsampledata[0].status !== 'accepted' ?
                    <div className="btnrequestsamplediv btnrequestsamplediv1" style={{marginBottom: 50}}>
                      <button
                        onClick={() => {
                          // setisapproved(true);
                          sampleapprovefunction(requestsampledata[0].id);
                        }}
                        className="submitrfs-btn submitrfs-btn2"
                        style={{
                          backgroundColor: colors.themeBlue,
                          borderColor: colors.themeBlue,
                        }}
                      >
                        Accept Quote
                      </button>
                      <button
                        onClick={() => {
                          //   submitrfs();
                          setshowcomment(true);
                        }}
                        className="submitrfs-btn submitrfs-btn2 submitrfs-red"
                        style={{
                          backgroundColor: colors.themeRed,
                          borderColor: colors.themeRed,
                          marginLeft: 20,
                        }}
                      >
                        Reject Quote
                      </button>
                    </div> : null
                  )
                  :
            <button
              onClick={() => {
                rfssamplereply();
              }}
              className="submitrfs-btn submitrfs-btn2"
              style={{
                backgroundColor: colors.themeBlue,
                borderColor: colors.themeBlue,
                float: "right",
                marginTop: 10,
                marginBottom: 50,
              }}
            >
              SAVE AND SUBMIT
            </button>
            }
              </>
            ) : (
              <div className="">
                <p style={{ textAlign: "center" }}>Loading..</p>
              </div>
            )}
            
          </div>
        </div>
      </div>
    );
}

export default SuperAdminRFQReply