import React, { useEffect,useState } from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import InvoiceTable from "../../components/tables/InvoiceTable";
import { Link } from "react-router-dom";
import { colors } from "../../config/colors";
import { deleteicon, edit, view, hide } from "../../config/images";
import './../../components/tables/tables.css';

import { useDispatch, useSelector } from "react-redux/es/exports";
import { useNavigate } from 'react-router-dom';
import Overlay from "../../components/common/Overlay";
import axios from 'axios';
import { apiUrl } from '../../config/config';
import {useLocation} from 'react-router-dom';

const EditCurrency = (props) => {
    const dispatch = useDispatch(); 
    let navigate = useNavigate();
    const userdata = useSelector((store) => store.userReducer.userdata);
    const { state } = useLocation();
    // console.log('userdata >>', userdata);

    const [name, setname] = useState(null)
    const [symbol, setsymbol] = useState(null)
    const [showloding, setshowloding] = useState(false);

    const createadminpresses = async() => {
                setshowloding(true);
                var data = {
                    'currencyid': state.currencyid,
                    'name' : name,
                    'symbol': symbol
                }
                const headers = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization' : 'Bearer ' + userdata?.token
                    }
                }
                let resp = await axios.post(apiUrl + 'updatecurrency', data, headers).then((res) => {
                    console.log('res >>>', res.data);
                    if(res.data.status == 'success') {
                       navigate('/cms/superadmin/currency-list');
                    } else {
                      alert(res.data.message);
                    }
                } ).catch((err) => {
                    console.log('err >>', err);
                })
                setshowloding(false);
    }

    const fetchadmindetails = async(id) => {
        if(id > 0) {
            setshowloding(true);
            var data = {
                'currencyid' : id,
            }
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + userdata?.token
                }
            }
            let resp = await axios.post(apiUrl + 'singlecurrency', data, headers).then((res) => {
                // console.log('res >>>', res.data);
                if(res.data.status == 'success') {
                    setname(res.data.data.name)
                    setsymbol(res.data.data.symbol)
                } else {
                  alert(res.data.message);
                }
            } ).catch((err) => {
                console.log('err >>', err);
            })
            setshowloding(false);
        }
    }

    
    
    useEffect(() => {
        if(userdata && Object.keys(userdata).length > 0) {
            fetchadmindetails(state.currencyid);
        } else {
            navigate('/cms/superadmin');
        }
    }, []);
    return (
        <>
            <Overlay showloader={showloding} />
            <div className="HomeMain">
        <div className="sidebar-div">
          <Sidebar name={"superadmin"} />
        </div>
        <div className="main-layout">
          <Navbar name={"superadmin"} />
          <div className="mainDiv">
            <p className="oneplaceHead">EDIT ADMIN</p>
            <div class="orderscreen superadminorderscreen">
              <div class="row adminorderrow">
                <div class="col-md-3">
                  <p class="orderscreen-p orderscreen-p1">Name* :</p>
                </div>
                <div class="col-md-9">
                  <input
                    value={name} onChange={(t) => {setname(t.target.value)}}
                    type="text"
                    class="form-control admin-form-control custom-input-height"
                    placeholder="Currency Name"
                  />
                </div>
              </div>
              <div class="row adminorderrow">
                <div class="col-md-3">
                  <p class="orderscreen-p orderscreen-p1">Symbol* :</p>
                </div>
                <div class="col-md-9">
                  <input
                    value={symbol} onChange={(t) => {setsymbol(t.target.value)}}
                    type="text"
                    class="form-control admin-form-control custom-input-height"
                    placeholder="Currency Symbol"
                  />
                </div>
              </div>
              {

              }
              <button
                onClick={() => {
                //   setisapproved(true);
                createadminpresses();
                }}
                className="submitrfs-btn submitrfs-btn2 create-button"
                style={{
                  backgroundColor: colors.themeBlue,
                  borderColor: colors.themeBlue,
                  float: "right",
                  marginTop: 10,
                  marginBottom: 50,
                }}
              >
                UPDATE
              </button>
            </div>
          </div>
        </div>
      </div>
        </>
      
    );
}

export default EditCurrency