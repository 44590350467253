import React, { useState, useEffect, useRef } from "react";
import "./Web.css";
import { Link } from "react-router-dom";
import Webheader from "../../components/common/Webheader";
import WebFooter from "../../components/common/WebFooter";
import Stars from "../../assets/images/stars.png";
import BarChart from '../../assets/images/bar-chart.png';
import Leftarrow from '../../assets/images/left-arrow.png';
import Rightarrow from '../../assets/images/right-arrow.png';
import AOS from "aos";
import 'aos/dist/aos.css';

const WebProductDetails1 = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  const fastenedproperties = [
    {label: 'Water', value: '5'},
    {label: 'Ethanol', value: '5'},
    {label: 'Butanol', value: '4'},
    {label: 'Acetone', value: '4'},
    {label: 'Tolune', value: '4'},
    {label: 'M.T.O.', value: '4'},
    {label: 'Linseed Oil', value: '5'},
    {label: 'Acid', value: '5'},
    {label: 'Alkali', value: '5'},
    {label: 'Soap', value: '5'},
  ];
  const lightfastness = [
    {label: '25% Shade', value: '5'},
    {label: '10% Shade', value: '5'},
    {label: '5% Shade', value: '5'},
  ]
  return (
    <div className="webmainbg">
      <Webheader />
      <div className="productdetails-breadscumbdiv">
        <div className="pagewidth">
          <p>
            Products / Pigments / Phthalocyanine Pigments /{" "}
            <b>Pigment Blue 15:3</b>
          </p>
        </div>
      </div>
      <div className="productdetails-section2 mobhide">
        <div className="pagewidth">
          <div className="productdetails-gallerybox">
            <div className="productdetails-tumbnaildiv">
              <div className="productdetails-tumbnailbox"></div>
              <div
                className="productdetails-tumbnailbox"
                style={{ backgroundColor: "#0a83ee" }}
              ></div>
            </div>
            <div className="productdetails-imgdiv">
              <div className="productdetails-imgbg"></div>
            </div>
            <div className="productdetails-gallerybox-rightdiv">
              <div className="productdetails-gallerybox-rightdiv1">
                <p className="productdetails-producttitle">Pigment Blue 15:3</p>
                <div className="productdetails-ratingdiv">
                  <img src={Stars} className="starsimg" />
                  <p className="ratingtext">(4.8 / 5; 12 Survey)</p>
                </div>
                <p className="productdetails-universaltext">Universal</p>
                <p className="productdetails-universaltext">KeviPound 7015B3</p>
                <p className="productdetails-description">
                  A wide range of high-quality food colours, suitable for
                  various applications. Customizable options available.
                </p>
                <p className="productsfinder-productdescriptionhead productsfinder-productdescriptionhead1">
                  Tone: Mass and Tinit
                </p>
                <div className="productsfinder-tonediv productsfinder-tonediv1">
                  <div
                    className="productsfinder-tonecircle productsfinder-tonecircle1"
                    style={{ backgroundColor: "#0512a7" }}
                  ></div>
                  <div
                    className="productsfinder-tonecircle productsfinder-tonecircle1"
                    style={{ backgroundColor: "#0a83ee" }}
                  ></div>
                </div>
                <div className="productdetails-application-div">
                  {/* <div className="productdetails-application-leftdiv">
                    <p>Application:</p>
                    <div className="sortbydiv">
                      <select class="form-select">
                        <option selected disabled>
                          Paint
                        </option>
                        <option>option 1</option>
                        <option>option 2</option>
                        <option>option 3</option>
                        <option>option 4</option>
                      </select>
                    </div>
                  </div> */}
                  <div className="productdetails-application-leftdiv">
                    <p>Est. Qty:</p>
                    <input
                  type="number"
                  class="form-control"
                  placeholder="Qty"
                  name="productname"
                />
                  </div>
                </div>
                <button className="productdetails-sendenquiry">Send Enquiry</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* for mobile */}
      <div className="productdetails-section2 deskhide">
        <div className="pagewidth">
          <div className="productdetails-gallerybox">
          <div className="productdetails-imgdiv">
              <div className="productdetails-imgbg"></div>
            </div>
            <div className="productdetails-tumbnaildiv">
              <div className="productdetails-tumbnailbox"></div>
              <div
                className="productdetails-tumbnailbox"
                style={{ backgroundColor: "#0a83ee" }}
              ></div>
            </div>
            
            <div className="productdetails-gallerybox-rightdiv">
              <div className="productdetails-gallerybox-rightdiv1">
                <p className="productdetails-producttitle">Pigment Blue 15:3</p>
                <div className="productdetails-ratingdiv">
                  <img src={Stars} className="starsimg" />
                  <p className="ratingtext">(4.8 / 5; 12 Survey)</p>
                </div>
                <p className="productdetails-universaltext">Universal KeviPound 7015B3</p>
                <p className="productdetails-description">
                  A wide range of high-quality food colours, suitable for
                  various applications. Customizable options available.
                </p>
                <p className="productsfinder-productdescriptionhead productsfinder-productdescriptionhead1">
                  Tone: Mass and Tinit
                </p>
                <div className="productsfinder-tonediv productsfinder-tonediv1">
                  <div
                    className="productsfinder-tonecircle productsfinder-tonecircle1"
                    style={{ backgroundColor: "#0512a7" }}
                  ></div>
                  <div
                    className="productsfinder-tonecircle productsfinder-tonecircle1"
                    style={{ backgroundColor: "#0a83ee" }}
                  ></div>
                </div>
                <div className="productdetails-application-div">
                  <div className="productdetails-application-leftdiv">
                    <p>Application:</p>
                    <div className="sortbydiv">
                      <select class="form-select">
                        <option selected disabled>
                          Paint
                        </option>
                        <option>option 1</option>
                        <option>option 2</option>
                        <option>option 3</option>
                        <option>option 4</option>
                      </select>
                    </div>
                  </div>
                  <div className="productdetails-application-leftdiv">
                    <p>Est. Qty:</p>
                    <input
                  type="number"
                  class="form-control"
                  placeholder="Qty"
                  name="productname"
                />
                  </div>
                </div>
                <button className="productdetails-sendenquiry">Send Enquiry</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="productdetails-section3">
        <div className="productdetails-section3bg">
          <p className="productdetails-producttitle">You might also like</p>
          <div className="pagewidth">
            <div className="productdetails-section3boxdiv">
              <div className="productdetails-section3box" data-aos="fade-up" data-aos-delay="0">
                <div className="productdetails-section3imgbg"></div>
                <div className="productdetails-section3descdiv">
                  <p className="productdetails-section3descdiv-p1">Pigment Blue 15:3</p>
                  <p className="productdetails-section3descdiv-p2">Plastic </p>
                </div>
              </div>
              <div className="productdetails-section3box" data-aos="fade-up" data-aos-delay="300">
                <div className="productdetails-section3imgbg"></div>
                <div className="productdetails-section3descdiv">
                  <p className="productdetails-section3descdiv-p1">Pigment Blue 15:3</p>
                  <p className="productdetails-section3descdiv-p2">Plastic </p>
                </div>
              </div>
              <div className="productdetails-section3box" data-aos="fade-up" data-aos-delay="600">
                <div className="productdetails-section3imgbg"></div>
                <div className="productdetails-section3descdiv">
                  <p className="productdetails-section3descdiv-p1">Pigment Blue 15:3</p>
                  <p className="productdetails-section3descdiv-p2">Plastic </p>
                </div>
              </div>
              <div className="productdetails-section3box" data-aos="fade-up" data-aos-delay="900">
                <div className="productdetails-section3imgbg"></div>
                <div className="productdetails-section3descdiv">
                  <p className="productdetails-section3descdiv-p1">Pigment Blue 15:3</p>
                  <p className="productdetails-section3descdiv-p2">Plastic </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="productdetails-section4">
      <div className="pagewidth">
      <div className="productdetails-section4div">
          <div className="productdetails-section4div1">
            <div className="productdetails-surveyrating-div">
              <img src={BarChart} className="barchartimg" />
              <p className="surveyratinghead">Survey Ratings</p>
            </div>
            <div className="productdetails-ratingbox" data-aos="fade-up" data-aos-delay="0">
              <p className="productdetails-ratingnumber">4.9</p>
              <img src={Stars} className="starsimg1" />
              <p className="productdetails-ratingcount">32 customer</p>
            </div>
          </div>
          <div className="productdetails-section4div2">
          <div className="productdetails-surveyrating-div">
              <img src={BarChart} className="barchartimg" />
              <p className="surveyratinghead">Physical Properties</p>
              <div className="productdetails-surveyrating-bardiv">
                  <img src={Leftarrow} className="leftarrow" />
                  <img src={Rightarrow} className="leftarrow rightarrow" />
              </div>
            </div>
          <div className="productdetails-section4divsubdiv">
            <div className="productdetails-section4divsubleft">
              <div className="productdetails-section4divsub1" data-aos="fade-up" data-aos-delay="300">
                <p className="heatfastnesshead">Heat Fastness</p>
                <p className="heatfastnesshead1">300℃ for 15mins</p>
                <p className="heatfastnessdesc">I am extremely satisfied with the quality and performance of these products. They have exceeded my expectations and I would highly recommend them to others. The customization options are fantastic and allow me to create exactly what I need for my projects.</p>
              </div>
              <div className="productdetails-section4divsub1 mt-3" data-aos="fade-up" data-aos-delay="600">
                <p className="heatfastnesshead1 mb-3">Fastness Properties</p>
                {fastenedproperties?.map((item, index) => {
                  return (
                    <div className="fast-water-div">
                      <p className="fast-water-name">{item?.label}</p>
                      <img src={Stars} className="starsimg-water" />
                    </div>
                  );
                })}
                
              </div>
            </div>
            <div className="productdetails-section4divsubright">
            <div className="productdetails-section4divsub1" data-aos="fade-up" data-aos-delay="600">
                <p className="heatfastnesshead1 mb-3">Light Fastness</p>
                {lightfastness?.map((item, index) => {
                  return (
                    <div className="fast-water-div">
                      <p className="fast-water-name">{item?.label}</p>
                      <img src={Stars} className="starsimg-water" />
                    </div>
                  );
                })}
                
            </div>
            <div className="productdetails-section4divsub1 mt-3" data-aos="fade-up" data-aos-delay="600">
                <p className="heatfastnesshead">Documents</p>
                <p className="heatfastnesshead1">Download Here</p>
                <div className="productdetails-documents-btndiv">
                  <div className="productdetails-documentbtn">
                    <p>Technical Data Sheet</p>
                  </div>
                  <div className="productdetails-documentbtn">
                    <p>Material Safety Data Sheet</p>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          </div>
        </div>
      </div>
        
      </div>
      <WebFooter />
    </div>
    // <p>Hello World!!</p>
  );
};

export default WebProductDetails1;
