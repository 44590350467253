import React, { useEffect,useState } from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import InvoiceTable from "../../components/tables/InvoiceTable";
import { Link } from "react-router-dom";
import { colors } from "../../config/colors";
import { deleteicon, edit, view, hide } from "../../config/images";
import './../../components/tables/tables.css';

import { useDispatch, useSelector } from "react-redux/es/exports";
import { useNavigate } from 'react-router-dom';
import Overlay from "../../components/common/Overlay";
import axios from 'axios';
import { apiUrl } from '../../config/config';
import {useLocation} from 'react-router-dom';

const EditAdmin = (props) => {
    const dispatch = useDispatch(); 
    let navigate = useNavigate();
    const userdata = useSelector((store) => store.userReducer.userdata);
    const { state } = useLocation();
    // console.log('userdata >>', userdata);

    const [name, setname] = useState(null)
    const [email, setemail] = useState(null)
    const [password, setpassword] = useState(null)
    const [repassword, setrepassword] = useState(null)
    const [showpassword, setshowpassword] = useState(false)
    const [showrepassword, setshowrepassword] = useState(false)
    const [showloding, setshowloding] = useState(false);

    const createadminpresses = async() => {
        if(name && email ) {
            if(password == repassword) {
                setshowloding(true);
                var data = {
                    'adminid': state.adminid,
                    'name' : name,
                    'email': email,
                    'password': password
                }
                const headers = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization' : 'Bearer ' + userdata?.token
                    }
                }
                let resp = await axios.post(apiUrl + 'updateadmin', data, headers).then((res) => {
                    console.log('res >>>', res.data);
                    if(res.data.status == 'success') {
                       navigate('/cms/superadmin/admins-list');
                    } else {
                      alert(res.data.message);
                    }
                } ).catch((err) => {
                    console.log('err >>', err);
                })
                setshowloding(false);
            } else {
                alert('Password and re-password does not match.');
            }
        } else {
            alert('Please fill all required details');
        }
    }

    const fetchadmindetails = async(id) => {
        if(id > 0) {
            setshowloding(true);
            var data = {
                'adminid' : id,
            }
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + userdata?.token
                }
            }
            let resp = await axios.post(apiUrl + 'singleadmindetails', data, headers).then((res) => {
                // console.log('res >>>', res.data);
                if(res.data.status == 'success') {
                    setname(res.data.data.name)
                    setemail(res.data.data.email)
                } else {
                  alert(res.data.message);
                }
            } ).catch((err) => {
                console.log('err >>', err);
            })
            setshowloding(false);
        }
    }

    
    
    useEffect(() => {
        if(userdata && Object.keys(userdata).length > 0) {
            // console.log('state >>>', state.adminid)
            fetchadmindetails(state.adminid);
        } else {
            navigate('/cms/superadmin');
        }
    }, []);
    return (
        <>
            <Overlay showloader={showloding} />
            <div className="HomeMain">
        <div className="sidebar-div">
          <Sidebar name={"superadmin"} />
        </div>
        <div className="main-layout">
          <Navbar name={"superadmin"} />
          <div className="mainDiv">
            <p className="oneplaceHead">EDIT ADMIN</p>
            <div class="orderscreen superadminorderscreen">
              <div class="row adminorderrow">
                <div class="col-md-3">
                  <p class="orderscreen-p orderscreen-p1">Name* :</p>
                </div>
                <div class="col-md-9">
                  <input
                    value={name} onChange={(t) => {setname(t.target.value)}}
                    type="text"
                    class="form-control admin-form-control custom-input-height"
                    placeholder="Enter Name"
                  />
                </div>
              </div>
              <div class="row adminorderrow">
                <div class="col-md-3">
                  <p class="orderscreen-p orderscreen-p1">Email* :</p>
                </div>
                <div class="col-md-9">
                  <input
                    value={email} onChange={(t) => {setemail(t.target.value)}}
                    type="text"
                    class="form-control admin-form-control custom-input-height"
                    placeholder="Enter Email"
                  />
                </div>
              </div>
              <div class="row adminorderrow">
                <div class="col-md-3">
                  <p class="orderscreen-p orderscreen-p1">Password* :</p>
                </div>
                <div class="col-md-9">
                  <input
                  value={password} onChange={(t) => {setpassword(t.target.value)}}
                    type={!showpassword ? "password" : "text" }
                    class="form-control admin-form-control custom-input-height"
                    placeholder="Enter Password"
                  />
                  <div className="eyediv" onClick={() => {setshowpassword(!showpassword)}}>
                    {
                        !showpassword ?
                        <img className="hideicon" src={hide} /> :
                        <img className="hideicon" src={view} />
                    }
                    
                  </div>
                </div>
              </div>
              <div class="row adminorderrow">
                <div class="col-md-3">
                  <p class="orderscreen-p orderscreen-p1">Re-password* :</p>
                </div>
                <div class="col-md-9">
                  <input
                    value={repassword} onChange={(t) => {setrepassword(t.target.value)}}
                    type={!showrepassword ? "password" : "text" }
                    class="form-control admin-form-control custom-input-height"
                    placeholder="Enter Re-password"
                  />
                  <div className="eyediv" onClick={() => {setshowrepassword(!showrepassword)}}>
                    {
                        !showrepassword ?
                        <img className="hideicon" src={hide} /> :
                        <img className="hideicon" src={view} />
                    }
                    
                  </div>
                </div>
              </div>
              {

              }
              <button
                onClick={() => {
                //   setisapproved(true);
                createadminpresses();
                }}
                className="submitrfs-btn submitrfs-btn2 create-button"
                style={{
                  backgroundColor: colors.themeBlue,
                  borderColor: colors.themeBlue,
                  float: "right",
                  marginTop: 10,
                  marginBottom: 50,
                }}
              >
                UPDATE
              </button>
            </div>
          </div>
        </div>
      </div>
        </>
      
    );
}

export default EditAdmin