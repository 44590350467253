import React, { useState } from "react";
import { logo } from "../../config/images";
import { Link } from "react-router-dom";
import 'font-awesome/css/font-awesome.min.css';
import Leftarrow from '../../assets/images/left-arrow.png';

import { useDispatch, useSelector } from "react-redux/es/exports";

const WebSidebar = ({ name }) => {
  //   const userdata = useSelector((store) => store.userReducer.userdata);
  const [showcategoriesdropdown, setshowcategoriesdropdown] = useState(false);
  const [showtagsdropdown, setshowtagsdropdown] = useState(false);
  const [showproductsdropdown,setshowproductsdropdown] = useState(false);
  return (
    <div className="sidebar-main webadmin-sidebarmain">
      <img src={logo} className="sidebarLogo" />
      <div className="sidebarMenuDiv">
        <ul>
          <li>
            <div className="webadmin-sidebar-maintitlediv" onClick={() => {
            setshowcategoriesdropdown(!showcategoriesdropdown);
          }}>
            <a className="webadmin-sidebar-maintitle">Categories</a>
            {
              showcategoriesdropdown ?
              <img src={Leftarrow} className="webadmin-sidebar-upicon" /> :
              <img src={Leftarrow} className="webadmin-sidebar-downicon" />
            }
            
          </div>
            <div
              class="dropdown-container"
              style={
                showcategoriesdropdown
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <Link to="/webadmin/main-categories" className="webadmin-sidebar-subtitle">Main Categories</Link>
              <Link to="/webadmin/sub-categories" className="webadmin-sidebar-subtitle">Sub Categories</Link>
              <Link to="/webadmin/three-tier-categories" className="webadmin-sidebar-subtitle">3 tier Categories</Link>
            </div>
          </li>
          <li>
          <div className="webadmin-sidebar-maintitlediv" onClick={() => {
            setshowtagsdropdown(!showtagsdropdown);
          }}>
            <a className="webadmin-sidebar-maintitle">Tags</a>
            {
              showtagsdropdown ?
              <img src={Leftarrow} className="webadmin-sidebar-upicon" /> :
              <img src={Leftarrow} className="webadmin-sidebar-downicon" />
            }
            
          </div>
            <div
              class="dropdown-container"
              style={
                showtagsdropdown
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <Link to="/webadmin/tags-list" className="webadmin-sidebar-subtitle">List Tags</Link>
              <Link to="/webadmin/popular-filter-tags" className="webadmin-sidebar-subtitle">Popular Filter Tags</Link>
              <Link to="/webadmin/popular-applications-tags" className="webadmin-sidebar-subtitle">Popular Applications Tags</Link>
            </div>
          </li>
          <li>
          <div className="webadmin-sidebar-maintitlediv" onClick={() => {
            setshowproductsdropdown(!showproductsdropdown);
          }}>
            <a className="webadmin-sidebar-maintitle">Products</a>
            {
              showproductsdropdown ?
              <img src={Leftarrow} className="webadmin-sidebar-upicon" /> :
              <img src={Leftarrow} className="webadmin-sidebar-downicon" />
            }
            
          </div>
            <div
              class="dropdown-container"
              style={
                showproductsdropdown
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <Link to="/webadmin/add-product" className="webadmin-sidebar-subtitle">Add Product</Link>
              <Link to="/webadmin/product-lists" className="webadmin-sidebar-subtitle">Product List</Link>
              <Link to="/webadmin/images" className="webadmin-sidebar-subtitle">Images</Link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default WebSidebar;
