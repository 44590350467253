import React, { useState, useEffect, useRef } from "react";
import Webheader from "../../components/common/Webheader";
import "./Web.css";
import WebFooter from "../../components/common/WebFooter";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./slider.css";

import { EffectCoverflow, Pagination, Navigation } from "swiper";

import img1 from "../../assets/images/slider/1.png";
import img2 from "../../assets/images/slider/2.png";
import img3 from "../../assets/images/slider/3.png";
import img4 from "../../assets/images/slider/4.png";
import img5 from "../../assets/images/slider/5.png";

import Leftarrow from "../../assets/images/left-arrow-dark.png";
import Rightarrow from "../../assets/images/right-arrow-dark.png";

import AOS from "aos";
import 'aos/dist/aos.css';

import snew_about1 from '../../assets/images/snew-about1.jpg';
import snew_about2 from '../../assets/images/snew-about2.png';
import snew_about3 from '../../assets/images/snew-about3.png';
import snew_about4 from '../../assets/images/snew-about4.png';
import snew_about5 from '../../assets/images/snew-about5.png';
import snew_about6 from '../../assets/images/snew-about6.png';
import snew_about7 from '../../assets/images/snew-about7.png';
import snew_about8 from '../../assets/images/snew-about8.png';
import newstory1 from '../../assets/images/newstory1.jpg';
import newstory2 from '../../assets/images/newstory2.png';
import newstory3 from '../../assets/images/newstory3.png';
import newstory4 from '../../assets/images/newstory4.png';
import favicon from '../../assets/images/favicon.png';

const WebAboutUs = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    document.title = "Kevin India"
    const newFavicon = document.createElement('link');
        newFavicon.rel = 'icon';
        newFavicon.href = favicon; // Replace 'new-favicon-url.png' with your new favicon URL
        document.head.appendChild(newFavicon);
        newFavicon.href = favicon;
  }, []);
  return (
    <div className="webmainbg">
      <Webheader />
      <div className="certficatepagemaindiv">
        <div className="pagewidth">
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            pagination={{ el: ".swiper-pagination", clickable: true }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
              clickable: true,
            }}
            modules={[EffectCoverflow, Pagination, Navigation]}
            className="swiper_container"
          >
            <SwiperSlide>
              <img src={snew_about1} alt="slide_image" />
              <p className="swipertext">Vapi Unit 2</p>
            </SwiperSlide>
            <SwiperSlide>
              <img src={snew_about2} alt="slide_image" />
              <p className="swipertext">Morbi Unit 3</p>
            </SwiperSlide>
            <SwiperSlide>
              <img src={snew_about3} alt="slide_image" />
              <p className="swipertext">Mumbai Head Office</p>
            </SwiperSlide>
            <SwiperSlide>
              <img src={snew_about4} alt="slide_image" />
              <p className="swipertext">Mumbai Branch Office</p>
            </SwiperSlide>
            <SwiperSlide>
              <img src={snew_about5} alt="slide_image" />
              <p className="swipertext">Anklashwar Unit 1</p>
            </SwiperSlide>
            {/* <SwiperSlide>
              <img src={snew_about6} alt="slide_image" />
              <p className="swipertext">Packaging</p>
            </SwiperSlide> */}
            <SwiperSlide>
              <img src={snew_about7} alt="slide_image" />
              <p className="swipertext">Packaging</p>
            </SwiperSlide>
            <SwiperSlide>
              <img src={snew_about8} alt="slide_image" />
              <p className="swipertext">QC Laboratory</p>
            </SwiperSlide>
            <SwiperSlide>
              <img src={snew_about1} alt="slide_image" />
              <p className="swipertext">Vapi Unit 2</p>
            </SwiperSlide>
            <SwiperSlide>
              <img src={snew_about2} alt="slide_image" />
              <p className="swipertext">Morbi Unit 3</p>
            </SwiperSlide>
            <SwiperSlide>
              <img src={snew_about3} alt="slide_image" />
              <p className="swipertext">Mumbai Head Office</p>
            </SwiperSlide>
            <SwiperSlide>
              <img src={snew_about4} alt="slide_image" />
              <p className="swipertext">Mumbai Branch Office</p>
            </SwiperSlide>
            <SwiperSlide>
              <img src={snew_about5} alt="slide_image" />
              <p className="swipertext">Anklashwar Unit 1</p>
            </SwiperSlide>
            {/* <SwiperSlide>
              <img src={snew_about6} alt="slide_image" />
              <p className="swipertext">Packaging</p>
            </SwiperSlide> */}
            <SwiperSlide>
              <img src={snew_about7} alt="slide_image" />
              <p className="swipertext">Packaging</p>
            </SwiperSlide>
            <SwiperSlide>
              <img src={snew_about8} alt="slide_image" />
              <p className="swipertext">QC Laboratory</p>
            </SwiperSlide>

            <div className="slider-controler">
              <div className="swiper-button-prev slider-arrow">
                {/* <ion-icon name="arrow-back-outline"></ion-icon> */}
                <img src={Leftarrow} />
              </div>
              <div className="swiper-button-next slider-arrow">
                {/* <ion-icon name="arrow-forward-outline"></ion-icon> */}
                <img src={Rightarrow} />
              </div>
              {/* <div className="swiper-pagination"></div> */}
            </div>
          </Swiper>
        </div>
        <div className="aboutus-section-2"></div>
        <div className="section section-four">
          <div className="pagewidth">
            <div className="row">
              <div className="col-sm-6">
                <div className="section4rightdiv">
                  <p className="section4head">Corporate Philosophy </p>
                  <div className="section4img section4img-about deskhide tablethide" ></div>
                  <p className="section4description" >
                    Masters of color, blending artistry and precision. With
                    decades of cross-industry experience, we bring visions to
                    life, from historical palettes to modern aesthetics. Fueled
                    by passion, we innovate fearlessly, using the latest tech
                    and trends. Join us in experiencing impactful visual
                    narratives through the language of color.
                  </p>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="section4img section4img-about mobhide tabletshow"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-section3">
          <div className="pagewidth">
            <div className="about-3-flex row">
              <div className="col-sm-6 col-md-4" data-aos="fade-up" data-aos-delay="150">
                <div className="vision-div">
                  <p className="vision-head">Vision</p>
                  <p className="vision-text">
                    Pioneering colourant manufacturing excellence, we strive for top-quality products at competitive prices. Our commitment to cutting-edge processes integrates seamlessly with environmental consciousness, forging a sustainable and innovative path forward.
                  </p>
                  <div className="Visionimg"></div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4" data-aos="fade-up" data-aos-delay="300">
                <div className="vision-div">
                  <p className="vision-head">Mission</p>
                  <p className="vision-text">
                  At Kevin, our mission is to innovate chemical manufacturing, providing premium products at competitive prices. We prioritize cutting-edge processes and environmental responsibility to create a sustainable and customer-centric future.
                  </p>
                  <div className="mission-flexdiv">
                    <div className="Visionimg Missionimg" />
                    <div className="Visionimg Missionimg1" />
                  </div>
                </div>
              </div>
              <div className="col-md-4" data-aos="fade-up" data-aos-delay="600">
                <div className="vision-div">
                  <p className="vision-head">Environmental Commitment</p>
                  <p className="vision-text">
                  Committed to environmental stewardship, we strive for sustainable practices in every aspect of our operations. At Kevin, we are dedicated to minimizing our ecological footprint and promoting a greener, healthier planet.
                  </p>
                  <div className="mission-flexdiv">
                    <div className="Visionimg env1" />
                    <div className="Visionimg env2" />
                    <div className="Visionimg env3" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="aboutus-section-2"></div>
        <div className="about-section4">
          <div className="pagewidth">
            <div className="about-section4flexbox">
              <div className="about-section4-div1">
                <p className="kevinstoryhead">THE KEVIN STORY</p>
              </div>
              <div className="about-section4-middiv">
                <div className="row">
                    <div className="col-md-4">
                        <div className="story1img" />
                    </div>
                    <div className="col-md-8 ">
                      <p className="about-us-year">1996</p>
                      <p className="about-us-description">In the vibrant landscape of India's agricultural sector, a pioneering Kevin [India] company emerged, setting its roots in the local market. Specializing in contract manufacturing, it embarked on a journey to cultivate innovation and contribute to the nation's agrarian success.</p>
                      <p className="about-us-year about-us-year1">2003</p>
                      <p className="about-us-description">In 2003, the visionary Kevin [India] company took a bold step by establishing its inaugural plant for Dyes and Dye Intermediates in Gujarat, India. This strategic move marked the onset of a diversification journey, expanding the company's footprint and influence in the chemical industry.</p>
                    </div>
                </div>
                <div className="row storymargin">
                    <div className="col-md-8 order-2 order-md-1">
                    <p className="about-us-year">2005</p>
                      <p className="about-us-description">Continuing its trajectory of growth and innovation, Kevin [India] Co. inaugurated a new plant for Pigment Powders. This pivotal expansion not only enhanced production capabilities but also heralded the inception of KeviPound Pigments, a distinctive chapter in the company's evolution.</p>
                      <p className="about-us-year about-us-year1">2009</p>
                      <p className="about-us-description">Driven by a commitment to enhance customer value and diversify its operations, Kevin [India] Co. ventured into the production and export of Food Colours. This strategic move gave rise to the KeviDite range, a specialized offering tailored for applications in the food industry, cementing the company's position as a versatile and customer-focused enterprise.</p>
                    </div>
                    <div className="col-md-4 order-1 order-md-2">
                        <div className="story1img story2img" />
                    </div>
                </div>
                <div className="row storymargin">
                    <div className="col-md-4">
                        <div className="story1img story3img" />
                    </div>
                    <div className="col-md-8">
                    <p className="about-us-year">2010</p>
                      <p className="about-us-description">With a series of strategic expansions, investments, and a steadfast commitment to excellence, Kevin group achieved a remarkable milestone, reaching an impressive annual revenue of one million dollars. This financial success stands as a testament to the company's resilience, innovation, and strategic vision in the competitive landscape.</p>
                      <p className="about-us-year about-us-year1">2015</p>
                      <p className="about-us-description">Undeterred by financial success, Kevin [India] Co. remained committed to continuous development. In a testament to its forward-looking approach, the company inaugurated its first R&D-only focused facility, dedicated to pioneering new and innovative processes, ensuring a sustained legacy of excellence in the industry.</p>
                    </div>
                </div>
                <div className="row storymargin">
                    <div className="col-md-8 order-2 order-md-1">
                      <br /><br />
                    <p className="about-us-year">2019</p>
                      <p className="about-us-description">Bolstered by its unwavering commitment to innovation and quality, Kevin Group has achieved a significant milestone, boasting an annual revenue of ten million dollars. This remarkable success reflects the company's resilience and strategic vision in navigating the evolving landscape of the chemical industry.</p>
                    </div>
                    <div className="col-md-4 order-1 order-md-2">
                        <div className="story1img story4img" />
                    </div>
                </div>
              </div>
              <div className="about-section4-div1">
                <p className="kevinstoryhead">THE KEVIN STORY</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WebFooter />
    </div>
  );
};

export default WebAboutUs;
