import React, { useState, useEffect, useRef } from "react";
import Webheader from "../../components/common/Webheader";
import "./Web.css";
import WebFooter from "../../components/common/WebFooter";
import Productimg from "../../assets/images/product.jpg";
import { Link } from "react-router-dom";
import AOS from "aos";
import 'aos/dist/aos.css';
import { apiUrl } from "../../config/config";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Overlay from "../../components/common/Overlay";
import { useParams } from 'react-router-dom';
import PaginatedItems from "../../components/common/PaginatedItems";
import ReactPaginate from 'react-paginate';
import favicon from '../../assets/images/favicon.png';
import lefticon from '../../assets/images/arrow.png';

const count = 10;

const WebProducts = () => {
  const [categoriesdata, setcategoriesdata] = useState([]);
  const [categoryproductsdata, setcategoryproductsdata] = useState([]);
  const [showloding, setshowloding] = useState(false);
  const [categoryselect, setcategoryselect] = useState(-1);
  const [showsecondstage, setshowsecondstage] = useState(false);
  const [selectedsubcategoryid, setselectedsubcategoryid] = useState(0);

  //pagination
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [itemsPerPage, setitemsPerPage] = useState(6);
  const [currentItems, setcurrentItems] = useState(0);
  const [lastcategoryid, setlastcategoryid] = useState(-1);

  const selectedlastcategoryidRef = useRef(-1);
  selectedlastcategoryidRef.current = lastcategoryid;

  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  const endOffset = itemOffset + itemsPerPage;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  // const currentItems = categoryproductsdata.slice(itemOffset, endOffset);
  // const pageCount = Math.ceil(items.length / itemsPerPage);

  useEffect(() => {
    if(categoryproductsdata.length > 0) {
      const newOffset = (0 * itemsPerPage) % categoryproductsdata.length;
    var catedata = categoryproductsdata.slice(newOffset, newOffset + itemsPerPage)
    setcurrentItems(categoryproductsdata.slice(newOffset, newOffset + itemsPerPage));
    setpageCount(Math.ceil(categoryproductsdata.length / itemsPerPage));
    setItemOffset(newOffset);
    }
    
  }, [itemsPerPage])

  

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % categoryproductsdata.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    var catedata = categoryproductsdata.slice(newOffset, newOffset + itemsPerPage)
    setcurrentItems(categoryproductsdata.slice(newOffset, newOffset + itemsPerPage));
    setpageCount(Math.ceil(categoryproductsdata.length / itemsPerPage));
    setItemOffset(newOffset);
  };

  const Items = ({ currentItems }) => {
    // console.log('currentItems11 >>', currentItems);
    return (
      <>
        {
                currentItems?.length > 0 ?
                currentItems?.map((item, index) => {
                  console.log('catbrandname >>', item.catbrandname)
                  // var subtitle = item?.applicationarrarr ? JSON.parse(item?.applicationarrarr)[0].kevincode : null;
                  // var subtitle = 'Kevipound ' + item.catname;
                  var subtitle = item?.catbrandname ? item?.catbrandname : item?.subbrandname;
                  var title = item?.name;
                  var description = item?.description;
                  // var imagelink = item?.imagesarr ? JSON.parse(item?.imagesarr)[0].link : null;
                  if(item?.image) {
                    var imagelink = JSON.parse(item?.image).link;
                  } else {
                    var imagelink = null;
                  }
                  var backgroundImageurl = "url("+imagelink+")";
                  return (
                    <>
                      {item.name !== "All" ? (
                        index == 0 ? (
                          <>
                            <div
                            className="col-md-7 mobhide"
                            data-aos="fade-up"
                            data-aos-delay="0"
                          >
                            <div className="eachproductbg">
                              <div className="eachproductleft">
                                <p className="eachproductsubhead">{subtitle}</p>
                                <p className="eachproducthead">
                                  <span
                                    onClick={() => {
                                      gotoproducturl(item);
                                    }}
                                  >
                                    {title}
                                  </span>
                                </p>
                                <p className="eachproductdesc">{description}</p>
                              </div>
                              <div className="eachproductright">
                                {/* <img src={Productimg} className="Productimg" /> */}
                                <div
                                  onClick={() => {
                                    gotoproducturl(item);
                                  }}
                                  className="Productimg"
                                  style={{
                                    backgroundImage: backgroundImageurl,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          {/* for mobile  */}
                          <div
                            className="col-sm-6 deskhide"
                            data-aos="fade-up"
                            data-aos-delay="0"
                          >
                            <div className="eachproductbg1">
                              {/* <img src={Productimg} className="eachproduct1img" /> */}
                              <div
                                onClick={() => {
                                  gotoproducturl(item)
                                }}
                                className="eachproduct1img"
                                style={{ backgroundImage: backgroundImageurl }}
                              />
                              <div className="eachproductbottomdiv">
                                <p className="eachproductsubhead eachproductsubhead1">
                                  {subtitle}
                                </p>
                                <p className="eachproducthead eachproducthead1">
                                  <span
                                    onClick={() => {
                                      gotoproducturl(item)
                                    }}
                                  >
                                    {title}
                                  </span>
                                </p>
                                <p className="eachproductdesc eachproductdesc1">
                                  {description}
                                </p>
                              </div>
                            </div>
                          </div>
                          </>
                          
                        ) : index == 1 ? (
                          <>
                            <div
                            className="col-md-5 mobhide"
                            data-aos="fade-up"
                            data-aos-delay="500"
                          >
                            <div className="eachproductbg">
                              <div className="eachproductleft">
                                <p className="eachproductsubhead">{subtitle}</p>
                                <p className="eachproducthead">
                                  <span
                                    onClick={() => {
                                      gotoproducturl(item);
                                    }}
                                  >
                                    {title}
                                  </span>
                                </p>
                                <p className="eachproductdesc">{description}</p>
                              </div>
                              <div className="eachproductright">
                                <div
                                  onClick={() => {
                                    gotoproducturl(item);
                                  }}
                                  className="Productimg"
                                  style={{
                                    backgroundImage: backgroundImageurl,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          {/* for mobile  */}
                          <div
                          className="col-sm-6 col-md-3 deskhide"
                          data-aos="fade-up"
                          data-aos-delay="0"
                        >
                          <div className="eachproductbg1">
                            {/* <img src={Productimg} className="eachproduct1img" /> */}
                            <div
                              onClick={() => {
                                gotoproducturl(item)
                              }}
                              className="eachproduct1img"
                              style={{ backgroundImage: backgroundImageurl }}
                            />
                            <div className="eachproductbottomdiv">
                              <p className="eachproductsubhead eachproductsubhead1">
                                {subtitle}
                              </p>
                              <p className="eachproducthead eachproducthead1">
                                <span
                                  onClick={() => {
                                    gotoproducturl(item)
                                  }}
                                >
                                  {title}
                                </span>
                              </p>
                              <p className="eachproductdesc eachproductdesc1">
                                {description}
                              </p>
                            </div>
                          </div>
                        </div>
                          </>
                          
                        ) : (
                          <div
                            className="col-sm-6 col-md-3"
                            data-aos="fade-up"
                            data-aos-delay="0"
                          >
                            <div className="eachproductbg1">
                              {/* <img src={Productimg} className="eachproduct1img" /> */}
                              <div
                                onClick={() => {
                                  gotoproducturl(item)
                                }}
                                className="eachproduct1img"
                                style={{ backgroundImage: backgroundImageurl }}
                              />
                              <div className="eachproductbottomdiv">
                                <p className="eachproductsubhead eachproductsubhead1">
                                  {subtitle}
                                </p>
                                <p className="eachproducthead eachproducthead1">
                                  <span
                                    onClick={() => {
                                      gotoproducturl(item)
                                    }}
                                  >
                                    {title}
                                  </span>
                                </p>
                                <p className="eachproductdesc eachproductdesc1">
                                  {description}
                                </p>
                              </div>
                            </div>
                          </div>
                        )
                      ) : null}
                    </>
                  );
                }) : null
              }
      </>
    );
  }

  const Items1 = ({ currentItems }) => {
    // console.log('currentItems11 >>', currentItems);
    return (
      <>
        {
                currentItems?.length > 0 ?
                currentItems?.map((item, index) => {
                  console.log('Items1 item >>', item);
                  // var subtitle = item?.applicationarrarr ? JSON.parse(item?.applicationarrarr)[0].kevincode : null;
                  var subtitle = item?.catbrandname ? item?.catbrandname : item?.subbrandname;
                  var title = item?.productname;
                  // var description = item?.description;
                  var description = item?.applicationarrarr ? JSON.parse(item?.applicationarrarr)[0].kevincode : null;
                  if(item.applicationimages) {
                    var imagelink = item?.applicationimages ? JSON.parse(item?.applicationimages)[0].link : null;
                  } else {
                    var imagelink = item?.imagesarr ? JSON.parse(item?.imagesarr)[0].link : null;
                  }
                  // if(item?.image) {
                  //   var imagelink = JSON.parse(item?.image).link;
                  // } else {
                  //   var imagelink = null;
                  // }
                  var backgroundImageurl = "url("+imagelink+")";
                  return (
                    <>
                      {item.name !== "All" ? (
                        index == 0 ? (
                          <>
                            <div
                            className="col-md-7 mobhide"
                            data-aos="fade-up"
                            data-aos-delay="0"
                          >
                            <div className="eachproductbg">
                              <div className="eachproductleft">
                                <p className="eachproductsubhead">{subtitle}</p>
                                <p className="eachproducthead">
                                  <span
                                    onClick={() => {
                                      gotoproducturl1(item);
                                    }}
                                  >
                                    {title}
                                  </span>
                                </p>
                                <p className="eachproductdesc">Application : {item?.applicationname}</p>
                              </div>
                              <div className="eachproductright">
                                {/* <img src={Productimg} className="Productimg" /> */}
                                <div
                                  onClick={() => {
                                    gotoproducturl1(item);
                                  }}
                                  className="Productimg"
                                  style={{
                                    backgroundImage: backgroundImageurl,
                                  }}
                                />
                              </div>
                            </div>
                            </div>
                            {/* for mobile  */}
                            <div
                            className="col-md-3 deskhide"
                            data-aos="fade-up"
                            data-aos-delay="0"
                          >
                            <div className="eachproductbg1">
                              {/* <img src={Productimg} className="eachproduct1img" /> */}
                              <div
                                onClick={() => {
                                  gotoproducturl1(item)
                                }}
                                className="eachproduct1img"
                                style={{ backgroundImage: backgroundImageurl }}
                              />
                              <div className="eachproductbottomdiv">
                                <p className="eachproductsubhead eachproductsubhead1">
                                  {subtitle}
                                </p>
                                <p className="eachproducthead eachproducthead1">
                                  <span
                                    onClick={() => {
                                      gotoproducturl1(item)
                                    }}
                                  >
                                    {title}
                                  </span>
                                </p>
                                <p className="eachproductdesc eachproductdesc1">
                                Application : {item?.applicationname}
                                </p>
                              </div>
                            </div>
                            </div>
                          </>
                          
                        ) : index == 1 ? (
                          <>
                          <div
                            className="col-md-5 mobhide"
                            data-aos="fade-up"
                            data-aos-delay="500"
                          >
                            <div className="eachproductbg">
                              <div className="eachproductleft">
                                <p className="eachproductsubhead">{subtitle}</p>
                                <p className="eachproducthead">
                                  <span
                                    onClick={() => {
                                      gotoproducturl1(item);
                                    }}
                                  >
                                    {title}
                                  </span>
                                </p>
                                <p className="eachproductdesc">Application : {item?.applicationname}</p>
                              </div>
                              <div className="eachproductright">
                                <div
                                  onClick={() => {
                                    gotoproducturl1(item);
                                  }}
                                  className="Productimg"
                                  style={{
                                    backgroundImage: backgroundImageurl,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          {/* for mobile  */}
                          <div
                            className="col-md-3 deskhide"
                            data-aos="fade-up"
                            data-aos-delay="0"
                          >
                            <div className="eachproductbg1">
                              {/* <img src={Productimg} className="eachproduct1img" /> */}
                              <div
                                onClick={() => {
                                  gotoproducturl1(item)
                                }}
                                className="eachproduct1img"
                                style={{ backgroundImage: backgroundImageurl }}
                              />
                              <div className="eachproductbottomdiv">
                                <p className="eachproductsubhead eachproductsubhead1">
                                  {subtitle}
                                </p>
                                <p className="eachproducthead eachproducthead1">
                                  <span
                                    onClick={() => {
                                      gotoproducturl1(item)
                                    }}
                                  >
                                    {title}
                                  </span>
                                </p>
                                <p className="eachproductdesc eachproductdesc1">
                                Application : {item?.applicationname}
                                </p>
                              </div>
                            </div>
                          </div>
                          </>
                        ) : (
                          <div
                            className="col-md-3"
                            data-aos="fade-up"
                            data-aos-delay="0"
                          >
                            <div className="eachproductbg1">
                              {/* <img src={Productimg} className="eachproduct1img" /> */}
                              <div
                                onClick={() => {
                                  gotoproducturl1(item)
                                }}
                                className="eachproduct1img"
                                style={{ backgroundImage: backgroundImageurl }}
                              />
                              <div className="eachproductbottomdiv">
                                <p className="eachproductsubhead eachproductsubhead1">
                                  {subtitle}
                                </p>
                                <p className="eachproducthead eachproducthead1">
                                  <span
                                    onClick={() => {
                                      gotoproducturl1(item)
                                    }}
                                  >
                                    {title}
                                  </span>
                                </p>
                                <p className="eachproductdesc eachproductdesc1">
                                Application : {item?.applicationname}
                                </p>
                              </div>
                            </div>
                          </div>
                        )
                      ) : null}
                    </>
                  );
                }) : null
              }
      </>
    );
  }

  let navigate = useNavigate();
  const fetchdata = async () => {
    setshowloding(true);
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
      .get(apiUrl + "usermaincategorylist", headers)
      .then((res) => {
        // console.log('res >>>', res.data)
        if (res.data.status == "success") {
          console.log("res.data.data >>", res.data.data);
          setcategoriesdata(res.data.data);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
    setshowloding(false);
  };
  const productsbycategory = async(id) => {
    setshowloding(true);
      var data = {
        id: id,
      };
      const headers = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let resp = await axios
        .post(apiUrl + "usersproductsbycategory", data, headers)
        .then((res) => {
          // console.log('res >>>', res.data)
          if (res.data.status == "success") {
            setshowloding(false);
            var catdata = res.data.data;
            console.log('catdata >>', catdata);
            console.log('init itemOffset >>', itemOffset);
            console.log('init endOffset >>', endOffset);
            setcurrentItems(catdata.slice(itemOffset, endOffset));
            setpageCount(Math.ceil(catdata.length / itemsPerPage));
            setcategoryproductsdata(res.data.data);
            

          } else {
            alert(res.data.message);
            setshowloding(false);
          }
        })
        .catch((err) => {
          setshowloding(false);
          alert(err.message);
        });
      setshowloding(false);
  }
  console.log('itemOffset >>', itemOffset);
  console.log('endOffset >>', endOffset);
  const changecategoryhandle = async(num) => {
    setcategoryselect(num);
    productsbycategory(num);
    setlastcategoryid(num);
  }
  const changecategoryhandle1 = async(tabid) => {
    setcategoryselect(tabid);
    fetchsubcategories(tabid, selectedsubcategoryid)
  }
  const fetchsubcategories = async(tabid, subcatid) => {
    setshowloding(true);
      var data = {
        tabid: tabid,
        id: subcatid
      };
      const headers = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let resp = await axios
        .post(apiUrl + "userssubcategoriestab", data, headers)
        .then((res) => {
          // console.log('res >>>', res.data)
          if (res.data.status == "success") {
            setshowloding(false);
            setcategoriesdata(res.data.tabs);
            var catdata = res.data.data;
            console.log('catdata >>', catdata);
            console.log('init itemOffset >>', itemOffset);
            console.log('init endOffset >>', endOffset);
            setcurrentItems(catdata.slice(itemOffset, endOffset));
            setpageCount(Math.ceil(catdata.length / itemsPerPage));
            setcategoryproductsdata(catdata);
            
          } else {
            alert(res.data.message);
            setshowloding(false);
          }
        })
        .catch((err) => {
          setshowloding(false);
          alert(err.message);
        });
      setshowloding(false);
  }
  const gotoproducturl = (item) => {
    // var url =  '/products/'+item.catslug+'/'+item.subcatslug+'/'+item.threecatslug+'/'+item.productname+'/'+item.id;
    // navigate(url)
    console.log('item >>', item.subcatname);
    if(item.subcatname) {
      navigate('/find-product/'+item.id)
    } else {
      setshowsecondstage(true);
      setcategoryselect(-1);
      setselectedsubcategoryid(item.id);
      fetchsubcategories(-1, item.id);
    }
  }
  const gotoproducturl1 = (item) => {
    var url =  '/products/'+item.catslug+'/'+item.subcatslug+'/'+item.threecatslug+'/'+item.productname+'/'+item.applicationid;
    navigate(url);
  }
  const handlechangepagecount = (num) => {
    setitemsPerPage(num)
  }
  useEffect(() => {
    document.title = "Kevin India"
    const newFavicon = document.createElement('link');
        newFavicon.rel = 'icon';
        newFavicon.href = favicon; // Replace 'new-favicon-url.png' with your new favicon URL
        document.head.appendChild(newFavicon);
        newFavicon.href = favicon;
  }, []);
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    fetchdata();
    productsbycategory(categoryselect);
  }, []);

  return (
    <div className="webmainbg">
      <Overlay showloader={showloding} />
      <Webheader />
      <div className="products-maindiv">
        <div className="pagewidth">
          <div className="productssection1">
            <div className="row">
              <div className="col-sm-7">
                <div className="productsleftdiv">
                  <p
                    className="producthead"
                    data-aos="fade-up"
                    data-aos-delay="0"
                  >
                    Pick Your Colour!
                  </p>
                  <div className="productsleftbg deskhide tablethide"></div>
                  <p
                    className="productdesc"
                    // data-aos="fade-up"
                    // data-aos-delay="500"
                  >
                    Discover a world of vibrant possibilities with our premium
                    selection of pigments, dyes, and food colors, adding a
                    spectrum of hues to your creations. Elevate your
                    formulations with our top-grade dye intermediates, while our
                    exceptional talc and Magnesium Sulphate Heptahydrate provide
                    the perfect touch of texture and quality.
                  </p>
                </div>
              </div>
              <div className="col-sm-5">
                <div className="productsleftbg mobhide tabletshow"></div>
              </div>
            </div>
          </div>

          {/* final */}

          {!showsecondstage ? (
            <>
              <div className="productsection2">
                <div className="tagsdiv">
                  <div
                    className="tagsbg"
                    onClick={() => {
                      changecategoryhandle(-1);
                    }}
                    style={
                      categoryselect == "-1"
                        ? { backgroundColor: "#030303" }
                        : { backgroundColor: "#2f2f2f" }
                    }
                  >
                    <p>All</p>
                  </div>
                  {categoriesdata?.map((item, index) => {
                    return (
                      <div
                        className="tagsbg"
                        onClick={() => {
                          changecategoryhandle(item.id);
                        }}
                        style={
                          categoryselect == item.id
                            ? { backgroundColor: "#030303" }
                            : { backgroundColor: "#2f2f2f" }
                        }
                      >
                        <p>{item.name}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
              {categoryproductsdata?.length > 0 ? (
                <>
                  <div className="productsection3">
                    <div className="row">
                      <Items currentItems={currentItems} />
                    </div>
                  </div>
                  <div className="paginatemaindiv">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=">>"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="<<"
                      renderOnZeroPageCount={null}
                      className="custompaginate"
                    />
                  <select class="form-select paginatedropdown" value={itemsPerPage} onChange={(t) => {handlechangepagecount(t.target.value)}}>
                    <option value="6">6</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                  </select>
                  </div>
                </>
              ) : null}
            </>
          ) : (
            <>
              <div className="productsection2">
                <div className="tagsdiv">
                  <div
                    className="tagsbg tagsbackbtn"
                    onClick={() => {
                      // changecategoryhandle1(-1);
                      fetchdata()
                      changecategoryhandle(selectedlastcategoryidRef.current);
                      setshowsecondstage(false);
                      // setcategoryselect(-1);
                    }}
                    style={
                      {backgroundColor: 'unset'}
                    }
                  >
                    <img src={lefticon} className="productsfinderlefticon" />
                  </div>
                  <div
                    className="tagsbg"
                    onClick={() => {
                      changecategoryhandle1(-1);
                    }}
                    style={
                      categoryselect == "-1"
                        ? { backgroundColor: "#030303" }
                        : { backgroundColor: "#2f2f2f" }
                    }
                  >
                    <p>All</p>
                  </div>
                  {categoriesdata?.map((item, index) => {
                    return (
                      <div
                        className="tagsbg"
                        onClick={() => {
                          changecategoryhandle1(item.id);
                        }}
                        style={
                          categoryselect == item.id
                            ? { backgroundColor: "#030303" }
                            : { backgroundColor: "#2f2f2f" }
                        }
                      >
                        <p>{item.name}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
              {categoryproductsdata?.length > 0 ? (
                <>
                  <div className="productsection3">
                    <div className="row">
                      <Items1 currentItems={currentItems} />
                    </div>
                  </div>
                  <div className="paginatemaindiv">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="< previous"
                    renderOnZeroPageCount={null}
                    className="custompaginate"
                  />
                  <select class="form-select paginatedropdown" value={itemsPerPage} onChange={(t) => {handlechangepagecount(t.target.value)}}>
                    <option value="6">6</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                  </select>
                  </div>

                  
                </>
              ) : null}
            </>
          )}

          {/* <div className="productsection4">
            <div className="paginationdiv">
                <p>{'<'}</p>
                <p>{'1'}</p>
                <p>{'2'}</p>
                <p>{'3'}</p>
                <p>{'4'}</p>
                <p>{'5'}</p>
                <p>{'6'}</p>
                <p>{'7'}</p>
                <p>{'8'}</p>
                <p>{'9'}</p>
                <p>{'>'}</p>
            </div>
          </div> */}

          {/* <PaginatedItems itemsPerPage={3} data={categoryproductsdata} /> */}
        </div>
      </div>
      <WebFooter />
    </div>
  );
};

export default WebProducts;
