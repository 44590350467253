import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import InvoiceTable from "../../components/tables/InvoiceTable";
import { pdf } from "../../config/images";
import { colors } from "../../config/colors";

import { useDispatch, useSelector } from "react-redux/es/exports";
import { apiUrl } from "../../config/config";
import axios from 'axios';
import Overlay from "../../components/common/Overlay";
import { edit, deleteicon } from "../../config/images";
import {useLocation} from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const AdminReverseSampleRequest = () => {
    const [isapproved, setisapproved] = useState(false);
    const [showcomment, setshowcomment] = useState(false);
    const [isrejected, setisrejected] = useState(false);
    const [showloding, setshowloding] = useState(false);
    const [requestsampledata, setrequestsampledata] = useState([]);
    const [refnumber, setrefnumber] = useState(null);
    const [deliverystatus, setdeliverystatus] = useState(null);
    const [documentsarr, setdocumentsarr] = useState([]);
    const [documentsarr1, setdocumentsarr1] = useState([]);
    const [samplesarr, setsamplesarr] = useState([]);
    const [prevsamplesarr, setprevsamplesarr] = useState([]);
    const dispatch = useDispatch(); 
    const userdata = useSelector((store) => store.userReducer.userdata);
    let navigate = useNavigate();
    const { id } = useParams();
    const documentsarrRef = useRef();
      documentsarrRef.current = documentsarr;
      const documentsarrRef1 = useRef();
      documentsarrRef1.current = documentsarr1;
      const requestarrRef = useRef();
    requestarrRef.current = samplesarr;
    const Samplesdata = [
        {
            'Sr. No.' : '1',
            'Product Name' : 'Pigment Blue 15:3',
            'Application': 'Universal',
            'Ref. No.': 'KDCPL/0001',
            'Status': 'Dispatched',
            'RFS No.': 'RFS/WS/001',
        }
    ]

    const fetchrequestsampledata = async(id) => {
        setshowloding(true);
        var data = {
            'rfsid' : id,
        }
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + userdata?.token
            }
        }
        let resp = await axios.post(apiUrl + 'adminfetchrfssingle',data, headers).then((res) => {
            // console.log('res >>>', res.data)
              if(res.data.status == 'success') {
                setrequestsampledata(res.data.data);
                setsamplesarr(JSON.parse(res.data.data[0].samplesarr));
                setprevsamplesarr(JSON.parse(res.data.data[0].samplesarr))
                // dispatch({ type: "userdata", payload: res.data.data })
                //  navigate('/customer/requestsample');
                setrefnumber(res.data.data[0].referencenumber);
                setdeliverystatus(res.data.data[0].deliverystatus);
                if(res.data.data[0].adminsamples == null) {
                  setdocumentsarr([])
                } else {
                  setdocumentsarr(JSON.parse(res.data.data[0].adminsamples));
                }
              } else {
                alert(res.data.message);
              }
          } ).catch((err) => {
              alert(err.message)
          })
        setshowloding(false)
    }

    const getBase64 = file => {
        return new Promise(resolve => {
          let fileInfo;
          let baseURL = "";
          // Make new FileReader
          let reader = new FileReader();
    
          // Convert the file to base64 text
          reader.readAsDataURL(file);
    
          // on reader load somthing...
          reader.onload = () => {
            // Make a fileInfo Object
            // console.log("Called", reader);
            baseURL = reader.result;
            // console.log(baseURL);
            resolve(baseURL);
          };
          // console.log(fileInfo);
        });
      };
      

    const uploaddocsfunc = async(files) => {
        // setdocumentsarr([]);
        console.log('files .>', files.length);
        var documentsarr1 = [];
        for(var i = 0; i < files.length; i++) {
            var obj = {};
            await getBase64(files[i]).then(result => {
                // console.log('result ...', result);
                // base64_decode($base64encodedstring)
                obj['name'] = files[i].name;
                obj['image'] = result;
            });
            documentsarr1.push(obj);
            setdocumentsarr1(a => [...a,obj ]);
        }
        console.log('documentsarr ..', documentsarr1);
        // setdocumentsarr(documentsarr1);
    }

    const rfssamplereply1 = async(files) => {
      setshowloding(true);
      var data = {
          'refsid': id,
          'samplesarr': requestarrRef.current
          // 'refnumber': refnumber,
          // 'deliverystatus': deliverystatus,
          // 'documentsarr': documentsarr1
          
        };
        // console.log('data .>', data)
      const headers = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization' : 'Bearer ' + userdata?.token
          }
      }
      let resp = await axios.post(apiUrl + 'adminreplyrfsforrevised', data, headers).then((res) => {
        // console.log('res >>>', res.data)
          if(res.data.status == 'success') {
            // dispatch({ type: "userdata", payload: res.data.data })
             navigate('/cms/admin/requestsample');
          } else {
            alert(res.data.message);
          }
      } ).catch((err) => {
          alert(err.message)
      })
      setshowloding(false);
    }

    const handleinputchange = async(name, index, text) => {
      let data = [...requestarrRef.current]
      if(name == 'admindocs') {
        // await getBase64(text).then(result => {
        //   // console.log('result ...', result);
        //   // base64_decode($base64encodedstring)
        //   var obj = {};
        //   obj['name'] = text.name;
        //   obj['image'] = result;
        //   data[index][name] = obj;
        // });
        var documentsarr1 = [];
        for(var i = 0; i < text.length; i++) {
          var obj = {};
          await getBase64(text[i]).then(result => {
              // console.log('result ...', result);
              // base64_decode($base64encodedstring)
              obj['name'] = text[i].name;
              obj['image'] = result;
          });
          documentsarr1.push(obj);
          // setdocumentsarr(a => [...a,obj ]);
      }
      data[index][name] = documentsarr1;
      } else {
        data[index][name] = text
      }
      setsamplesarr(data)
    }

    
    useEffect(() => {
        if(userdata && Object.keys(userdata).length > 0) {
            // console.log('id >>', id)
            fetchrequestsampledata(id);
        } else {
            navigate('/cms/admin/login');
        }
    }, []);
    return (
      <div className="HomeMain">
        <Overlay showloader={showloding} />
        <div className="sidebar-div">
          <Sidebar name={'Admin'} />
        </div>
        <div className="main-layout">
            <Navbar name={'Admin'} />
            {
                requestsampledata?.length > 0 ?
            <div className="mainDiv">
                <p className="oneplaceHead">REQUEST FOR REVISED SAMPLE</p>
                <div style={{ display: "flex", flexDirection: "row",}} className="mt-3">
                  <p className="eta-head mt-2" style={{fontWeight: 'normal'}} >Customer Name :</p>
                  <p className="eta-head mt-2" style={{ marginLeft: 15 }}>{requestsampledata[0]?.name}</p>
                </div>
                <div style={{ display: "flex", flexDirection: "row",}} className="mt-0 mb-4">
                  <p className="eta-head mt-2" style={{fontWeight: 'normal'}} >RFS No :</p>
                  <p className="eta-head mt-2" style={{ marginLeft: 15 }}>{requestsampledata[0]?.rfsnumber}</p>
                </div>
                <div className="uploadhere-box-big">
                    <p className="supporting-documents-head">Customer Feedback:</p>
                <div className="uploadhere-box uploadhere-box1">
                    <p className="commentstylep">{requestsampledata[0].comment}</p>
                </div>
                <div className="pdfrequestdiv">
                        <img src={pdf} style={{width: 32, height: 32}} />
                        <p style={{color: '#000', fontSize: 20, fontWeight: 'bold', marginLeft: 10, marginTop: 3}}>Supporting document</p>
                    </div>
                </div>
                {/* <p className="supporting-documents-head">Previous samples uploaded</p> */}
                {documentsarrRef.current?.length > 0 ?
                documentsarrRef.current?.map((item, index) => {
                  return (
                    <div className="pdfrequestdiv" key={index}>
                      <img src={pdf} style={{ width: 32, height: 32 }} />
                      <Link target="_blank" to={item.link} style={{textDecoration: 'none'}}>
                        <p
                          style={{
                            color: "#000",
                            fontSize: 20,
                            fontWeight: "bold",
                            marginLeft: 10,
                            marginTop: 3,
                          }}
                        >
                          {item.name}
                        </p>
                      </Link>
                    </div>
                  );
                })
                : null
              }
              <br />
                
                <table class="table table-bordered invoicetable">
                <thead>
                    <th>Sr no.</th>
                    <th>Product Name</th>
                    <th>Application</th>
                    <th>Ref. No.</th>
                    <th>Delivery Status</th>
                    <th>Date of dispatch</th>
                    <th>Previous <br />samples</th>
                    <th>Resend documents</th>
                </thead>
                <tbody>
                    {
                        samplesarr?.map((item, index) => {
                            // console.log('item ..', item)
                            var adminsamples = item.admindocs;
                            var prevadminsamples = prevsamplesarr[index].admindocs;
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{item.productname}</td>
                                <td>{item.application}</td>
                                <td>
                                  <input
                                    value={item["refno"]}
                                    onChange={(t) => {
                                      handleinputchange(
                                        "refno",
                                        index,
                                        t.target.value
                                      );
                                    }}
                                    type="text"
                                    // name={'productname'+index}
                                    className="form-control tabinput"
                                    placeholder="Enter Ref. No."
                                  />
                                </td>
                                <td>
                                  <select
                                    class="form-select tabinput"
                                    value={item["deliverystatus"]}
                                    onChange={(t) => {
                                      handleinputchange(
                                        "deliverystatus",
                                        index,
                                        t.target.value
                                      );
                                    }}
                                  >
                                    <option disabled selected>
                                      Delivery status
                                    </option>
                                    <option value={"Not delivered"}>
                                      Not delivered
                                    </option>
                                    <option value={"Dispatched"}>
                                      Dispatched
                                    </option>
                                    <option value={"Delivered"}>
                                      Delivered
                                    </option>
                                  </select>
                                </td>
                                <td>
                                  <input
                                    value={item["dateofdispatch"]}
                                    onChange={(t) => {
                                      handleinputchange(
                                        "dateofdispatch",
                                        index,
                                        t.target.value
                                      );
                                    }}
                                    type="date"
                                    // name={'productname'+index}
                                    className="form-control tabinput"
                                    placeholder="Enter dispatch date"
                                  />
                                </td>
                                <td>
                                {
                            prevadminsamples?.length > 0 ?
                            prevadminsamples?.map((items, indexs) => {
                              return (
                                <div className="pdfrequestdiv">
                                  <img src={pdf} style={{ width: 22, height: 22 }} />
                        <Link target="_blank" to={items.link} style={{textDecoration: 'none'}}>
                          <p
                            style={{
                              color: "#000",
                              fontSize: 14,
                              fontWeight: "bold",
                              marginLeft: 10,
                              marginTop: 3,
                            }}
                          >
                            {items.name}
                          </p>
                        </Link>
                                </div>
                              )
                            }) :
                            'No admin samples'
                          }
                                </td>
                                <td>
                                  <input
                                    type="file"
                                    multiple
                                    accept="application/pdf"
                                    // value={item['document']}
                                    onChange={(t) => {
                                      handleinputchange(
                                        "admindocs",
                                        index,
                                        t.target.files
                                      );
                                    }}
                                    className="form-control tabinput"
                                    placeholder="choose reference document"
                                  />
                                </td>
                                {/* <td>{item.rfsnumber}</td>
                                <td>{item.name}</td> */}
                              </tr>
                            );
                        })
                    }
                </tbody>
            </table> 
            {/* <p className="supporting-documents-head">Supporting Documents:</p>
                <label className="uploadhere-box" for="upload-pdf">
                    <p>Upload here</p>
                </label>
                <input type="file" multiple onChange={(t) => {uploaddocsfunc(t.target.files)}} accept="application/pdf" id="upload-pdf" />
                {documentsarrRef1.current?.length > 0 ?
                documentsarrRef1.current?.map((item, index) => {
                  return (
                    <div className="pdfrequestdiv" key={index}>
                      <img src={pdf} style={{ width: 32, height: 32 }} />
                      <Link target="_blank" to={item.link} style={{textDecoration: 'none'}}>
                        <p
                          style={{
                            color: "#000",
                            fontSize: 20,
                            fontWeight: "bold",
                            marginLeft: 10,
                            marginTop: 3,
                          }}
                        >
                          {item.name}
                        </p>
                      </Link>
                    </div>
                  );
                })
                : null
              } */}
                <button
                        onClick={() => {
                            // setisapproved(true)
                            rfssamplereply1()
                        }}
                        className="submitrfs-btn submitrfs-btn2"
                        style={{
                          backgroundColor: colors.themeBlue,
                          borderColor: colors.themeBlue,
                          float: 'right',
                          marginTop: 10,
                          marginBottom: 50
                          
                        }}
                      >
                        SAVE AND SUBMIT
                    </button>
            </div>
            : 
            <div className="">
                <p style={{textAlign: 'center'}}>No request yet.</p>
            </div>
            }
        </div>
        
      </div>
    );
}

export default AdminReverseSampleRequest