import React, { useState, useEffect } from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import InvoiceTable from "../../components/tables/InvoiceTable";

import { Link } from "react-router-dom";
import { colors } from "../../config/colors";

import { useDispatch, useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../../config/config";
import axios from "axios";
import Overlay from "../../components/common/Overlay";
import { edit, deleteicon } from "../../config/images";

const CustomerProformaInvoice = () => {
  const dispatch = useDispatch();
  const userdata = useSelector((store) => store.userReducer.userdata);
  let navigate = useNavigate();
  const [showloding, setshowloding] = useState(false);
  const [requestpfidata, setrequestpfidata] = useState([]);

  const fetchrequestinvoicedata = async () => {
    setshowloding(true);
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userdata?.token,
      },
    };
    let resp = await axios
      .get(apiUrl + "customerfetchrfi", headers)
      .then((res) => {
        // console.log('res >>>', res.data)
        if (res.data.status == "success") {
          setrequestpfidata(res.data.data);
          // dispatch({ type: "userdata", payload: res.data.data })
          //  navigate('/cms/customer/requestsample');
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
    setshowloding(false);
  };

  const clickproformainvoice = async (id) => {
    navigate("/cms/customer/ordertracking/" + id);
  };

  useEffect(() => {
    if (userdata && Object.keys(userdata).length > 0) {
      fetchrequestinvoicedata();
    } else {
      navigate("/cms/customer/login");
    }
  }, []);

  return (
    <div className="HomeMain">
      <Overlay showloader={showloding} />
      <div className="sidebar-div">
        <Sidebar />
      </div>
      <div className="main-layout">
        <Navbar />
        <div className="mainDiv">
          {/* <InvoiceTable title={'Proforma Invoices'} data={invoicedata} showbtn={false} /> */}
          <p className="oneplaceHead">PROFORMA INVOICES</p>
          {requestpfidata?.length > 0 ? (
            <table class="table table-bordered invoicetable">
              <thead>
                <th>Sr no.</th>
                <th>PFI No.</th>
                <th>PFI Date</th>
                <th>PO No.</th>
                <th>Status</th>
                {/* <th>Settings</th> */}
              </thead>
              <tbody>
                {requestpfidata?.map((item, index) => {
                  console.log('item ..', item)
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>
                        <div
                          onClick={() => {
                            clickproformainvoice(item.id);
                          }}
                        >
                          <Link>{item.pfinumber}</Link>
                        </div>
                      </td>
                      <td>{item.pfidate}</td>
                      <td>{item.ponumber}</td>
                      <td>
                        {
                        item?.is_rectification ?
                        <div onClick={() => {clickproformainvoice(item.id)}} className="statusdiv" style={{backgroundColor: '#ffde59', borderColor: 'red', borderWidth: 3, borderStyle: 'solid'}}>
                          Request rectification
                        </div>
                        : item.adminstatus == -1 ? (
                          <div
                            onClick={() => {
                              clickproformainvoice(item.id);
                            }}
                            className="statusdiv"
                            // style={{ backgroundColor: "#4fb293" }}
                            style={{backgroundColor: "#4fb293"}}
                          >
                            Complete
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              clickproformainvoice(item.id);
                            }}
                            className="statusdiv"
                            style={{ backgroundColor: "#ffde59" }}
                          >
                            {item.adminstatus == 1 ? 'Proforma Invoice' : item.adminstatus == 2 ? 'Pre-shipment Sample' : item.adminstatus == 3 ? 'Draft Shipping Documents' : 'Final Shipping Documents'}
                          </div>
                        )}
                      </td>
                      {/* <td>
                                  <div className="displayedittable">
                                  {
                                    item.status == 'accepted' ?
                                    <div onClick={() => {deleterfq(item.id)}}>
                                      <img src={deleteicon} className="editicon" />
                                    </div> :
                                    <div onClick={() => {clickproductnamerfq1(item.id)}}>
                                        <img src={edit} className="editicon" />
                                    </div>
                                  }
                                    
                                  </div>
                                </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="">
              <p style={{ textAlign: "center" }}>No invoices yet.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerProformaInvoice;
