import React, { useState, useEffect, useRef } from "react";
import Webheader from "../../components/common/Webheader";
import "./Web.css";
import WebFooter from "../../components/common/WebFooter";
import { Link } from "react-router-dom";
import img1 from '../../assets/images/cms/newimg1.png';
import img2 from '../../assets/images/cms/2.png';
import img3 from '../../assets/images/cms/3.png';
import img21 from '../../assets/images/cms/newimg21.png';
import img22 from '../../assets/images/cms/22.png';
import img23 from '../../assets/images/cms/23.png';
import img24 from '../../assets/images/cms/24.png';
import img31 from '../../assets/images/cms/31.png';
import img32 from '../../assets/images/cms/32.png';
import img33 from '../../assets/images/cms/33.png';
import img41 from '../../assets/images/cms/41.png';
import img42 from '../../assets/images/cms/42.png';
import img43 from '../../assets/images/cms/43.png';
import img51 from '../../assets/images/cms/51.png';
import img61 from '../../assets/images/cms/61.png';
import img62 from '../../assets/images/cms/62.png';
import img63 from '../../assets/images/cms/63.png';
import img71 from '../../assets/images/cms/71.png';
import img72 from '../../assets/images/cms/72.png';
import img73 from '../../assets/images/cms/73.png';
import img74 from '../../assets/images/cms/74.png';
import img75 from '../../assets/images/cms/75.png';
import img76 from '../../assets/images/cms/76.png';
import img77 from '../../assets/images/cms/77.png';
import img81 from '../../assets/images/cms/81.png';
import img82 from '../../assets/images/cms/82.png';
import img83 from '../../assets/images/cms/83.png';
import img84 from '../../assets/images/cms/84.png';
import img85 from '../../assets/images/cms/85.png';
import img86 from '../../assets/images/cms/86.png';
import img87 from '../../assets/images/cms/87.png';
import newimg61 from '../../assets/images/cms/newimg61.png';
import newimg62 from '../../assets/images/cms/newimg62.png';
import newimg63 from '../../assets/images/cms/newimg63.png';
import newimg64 from '../../assets/images/cms/newimg64.png';
import favicon from '../../assets/images/favicon.png';
import SortingIcon from "../../assets/images/sorting.png";
import CategoryIcon from "../../assets/images/category.png";

const WebHowToGuide = () => {
    var featureslist = [
        {'value': 0, 'label': 'Sign In to KeviTrack'},
        {'value': 1, 'label': 'New Proforma Inovice'},
        {'value': 2, 'label': 'Per Shipment Sample Dispatch'},
        {'value': 3, 'label': 'Draft Shipping Document Feedback'},
        {'value': 4, 'label': 'Viewing Final Shipping Documents'},
        {'value': 5, 'label': 'Request for Rectification'},
        {'value': 6, 'label': 'Request for Quote'},
        {'value': 7, 'label': 'Request for Sample'},
    ]
    const [selectedindex, setselectedindex] = useState(0);
    const [showsortdropdown, setshowsortdropdown] = useState(false);

    const handlechangeindex = (index) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Optional: adds smooth scrolling animation
          });
        setselectedindex(index);
        setshowsortdropdown(false);
    }
    useEffect(() => {
      document.title = "Kevin India"
      const newFavicon = document.createElement('link');
          newFavicon.rel = 'icon';
          newFavicon.href = favicon; // Replace 'new-favicon-url.png' with your new favicon URL
          document.head.appendChild(newFavicon);
          newFavicon.href = favicon;
    }, []);

    return (
      <div className="webmainbg">
        <Webheader />
        <div className="contactpagemaindiv">
          <div className="pagewidth">
            <div className="deskhide howtoguide-mobilediv tablethide">
              <div className="sortbydiv">
                    <div class="dropdown categorydropdown sortdropdown">
                      <div
                        onClick={() => {
                          setshowsortdropdown(!showsortdropdown);
                        }}
                        className="sortingicondiv"
                      >
                        <img src={CategoryIcon} className="sortingicon categoryicon" />
                      </div>
                      <div
                        class="dropdown-content"
                        style={
                          showsortdropdown
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        {featureslist?.map((item, index) => {
                    return (
                      <div className="feature-div" key={index}>
                        <p
                          className="feature-name"
                          onClick={() => {
                            handlechangeindex(index);
                          }}
                          style={
                            selectedindex == index
                              ? { backgroundColor: "#414141" }
                              : {}
                          }
                        >
                          {item.label}
                        </p>
                      </div>
                    );
                  })}
                      </div>
                    </div>
                  </div>
            </div>
            <div className="guide-row">
              <div className="guide-leftsection">
                <div className="guide-right-box">
                  {selectedindex == 0 ? (
                    <div className="guide-left-innerdiv">
                      <p className="guide-left-welcome-head">
                        Welcome to KeviTrack
                      </p>
                      <p className="guide-left-welcome-desc">
                        Streamline your logistics with our user-friendly portal,
                        empowering you to effortlessly track and manage
                        documents for shipments, quotes, and samples in one
                        centralized hub.
                      </p>
                      <hr className="guide-left-hr" />
                      <p className="guide-left-signin-head">Sign in</p>
                      <p className="guide-left-signin-desc">
                        1. On your computer, go to{" "}
                        <Link
                          to={"https://kevinindia.in/cms/customer/login"}
                          target="_blank"
                        >
                          https://kevinindia.in/cms/customer/login
                        </Link>{" "}
                        .
                      </p>
                      <p className="guide-left-signin-desc">
                        2. Enter your username temporary password sent on your
                        email.
                      </p>
                      <div className="guide-left-imgbg">
                        <img src={img1} className="guide-left-img" />
                        {/* <div className="guide-left-green-dotted"></div> */}
                        <p className="guide-left-green-dotted-text">
                          Admin generated
                          <br /> credentials
                        </p>
                      </div>
                      <p className="guide-left-signin-desc">
                        3. Enter a password which will be permanantly associated
                        with your account.
                      </p>
                      <img src={img2} className="guide-left-img" />
                      <p className="guide-left-signin-desc">
                        4. Once updated you will be greated to your home page.
                      </p>
                      <img src={img3} className="guide-left-img" />
                    </div>
                  ) : selectedindex == 1 ? (
                    <div className="guide-left-innerdiv">
                      <p className="guide-left-signin-head">
                        Viewing New Proforma Invoice
                      </p>
                      <p className="guide-left-signin-desc">
                        1. As soon as the order is confirmed the admin team will
                        upload a Proforma Invoice.
                      </p>
                      <p className="guide-left-signin-desc">
                        2. You will receive an email for the same and you can
                        either click on the link provided in the email.
                      </p>
                      <div className="guide-left-imgbg">
                        {/* <div className="guide-left-green-dotted guide-left-green-dotted1"></div> */}
                        <img src={img21} className="guide-left-img" />
                        <p className="guide-left-green-dotted-text guide-left-green-dotted-text1">
                          Click here to reach the
                          <br /> Proforma Invoice Page
                        </p>
                      </div>
                      <p className="guide-left-signin-desc">
                        3. Now you will be asked to enter your company's login
                        credentials (i.e. username given in the earlier email
                        and the password set by you at the time of
                        regestration). For more information on this please see
                        the How to Sign In page.
                      </p>
                      <img src={img22} className="guide-left-img" />
                      <p className="guide-left-signin-desc">
                        4. After successfully loggin in you will see the list of
                        all the Proforma Invoices to see the perticulare one you
                        can click on the specific PFI number.
                      </p>
                      <img src={img23} className="guide-left-img" />
                      <p className="guide-left-signin-desc">
                        5. You are now able to view or download the Proforma
                        Invoice by clicking the PDF symbol.
                      </p>
                      <img src={img24} className="guide-left-img" />
                      <div className="d-flex guide-left-rectificationbtndiv mt-3">
                        <p className="guide-left-signin-desc">
                          6. If at any point you feel any changes are required
                          you can
                        </p>
                        <div onClick={() => {handlechangeindex(5)}} className="rectification-bg">
                          <p>Request for Rectification</p>
                        </div>
                      </div>
                    </div>
                  ) : selectedindex == 2 ? (
                    <div className="guide-left-innerdiv">
                      <p className="guide-left-signin-head">
                        Pre Shipment Dispatch
                      </p>
                      <p className="guide-left-signin-desc">
                        1. The next step for a customer is to evaluate a pre
                        shipment sample for the product ordered prior to the
                        final shipment.
                      </p>
                      <p className="guide-left-signin-desc">
                        2. The user will be able to see the progress bar go
                        ahead once the sample as been dispatched from our end,
                        where they can find the AWB as well as the date of
                        dispatch for the PSS which are dispatched.
                      </p>
                      <img src={img31} className="guide-left-img" />
                      <p className="guide-left-signin-desc">
                        3. Based on your evaluation, you are presented with an
                        option to either approve or reject the PSS.
                      </p>
                      <img src={img32} className="guide-left-img" />
                      <p className="guide-left-signin-desc">
                        4. If the PSS doesn’t meet your required standards, you
                        can put in your comments as well as upload the feedback
                        to get a revised sample based on the feedback provided.
                      </p>
                      <img src={img33} className="guide-left-img" />
                      <p className="guide-left-signin-desc">
                        5. If approved, you will sent the draft shipping
                        documents for your approval, to execute the shipment.
                        You will be notified via email when they are ready.
                      </p>
                      <div className="d-flex guide-left-rectificationbtndiv mt-3">
                        <p className="guide-left-signin-desc">
                          6. If at any point you feel any changes are required
                          you can
                        </p>
                        <div onClick={() => {handlechangeindex(5)}} className="rectification-bg">
                          <p>Request for Rectification</p>
                        </div>
                      </div>
                    </div>
                  ) : selectedindex == 3 ? (
                    <div className="guide-left-innerdiv">
                      <p className="guide-left-signin-head">
                        Draft Shipping Document Feedback
                      </p>
                      <p className="guide-left-signin-desc">
                        1. As the shipment comes closer to the sailing date, you
                        will be send the draft of your Invoice, Packing List,
                        Bill of Lading, etc.
                      </p>
                      <p className="guide-left-signin-desc">
                        2. Similer to the approval process of PSS, you will have
                        the option to view/download the shipping documents
                        against the proforma invoice.
                      </p>
                      <img src={img41} className="guide-left-img" />
                      <p className="guide-left-signin-desc">
                        3. Based on your evaluation, you are presented with an
                        option to either approve or reject the draft documents.
                      </p>
                      <img src={img42} className="guide-left-img" />
                      <p className="guide-left-signin-desc">
                        4. If the drafts have any information which is
                        incorrect/missing, you will now have a chance to review
                        it carefully before the final documents are prepared.
                        This allow us to make any changes requested by you,
                        which are premisible as per the export regulations. As
                        changes after Final Documets are genrated are very
                        difficult to make. You are given a text box to request
                        those changes or you can upload a file requesting the
                        same as well, please be as specific as you can so the
                        corrections can be made as per your requirments.
                      </p>
                      <img src={img43} className="guide-left-img" />
                      <p className="guide-left-signin-desc">
                        5. If approved, you will sent the final shipping
                        documents makring the complition of the Shipment.
                      </p>
                      <div className="d-flex guide-left-rectificationbtndiv mt-3">
                        <p className="guide-left-signin-desc">
                          6. If at any point you feel any changes are required
                          you can
                        </p>
                        <div onClick={() => {handlechangeindex(5)}} className="rectification-bg">
                          <p>Request for Rectification</p>
                        </div>
                      </div>
                    </div>
                  ) : selectedindex == 4 ? (
                    <div className="guide-left-innerdiv">
                      <p className="guide-left-signin-head">
                        Final Shipping Documents
                      </p>
                      <p className="guide-left-signin-desc">
                        1. Once the drafts are approved by you, the export team
                        will prepare and send in the final documents along with
                        any other supporting documents requested by you for
                        clearance.
                      </p>
                      <p className="guide-left-signin-desc">
                        2. Here you will be able to see the full set of final
                        documents along with courier details for the mentioned
                        set of final original documents.
                      </p>
                      <img src={img51} className="guide-left-img" />
                      <p className="guide-left-signin-desc">
                        3. This notes the final dispatch of the shipment and
                        completion of the documents from our end.
                      </p>
                      <div className="d-flex guide-left-rectificationbtndiv mt-3">
                        <p className="guide-left-signin-desc">
                          4. If at any point you feel any changes are required
                          you can
                        </p>
                        <div onClick={() => {handlechangeindex(5)}} className="rectification-bg">
                          <p>Request for Rectification</p>
                        </div>
                      </div>
                    </div>
                  ) : selectedindex == 5 ? (
                    <div className="guide-left-innerdiv">
                      <p className="guide-left-signin-head">
                        Request for Rectification
                      </p>
                      <p className="guide-left-signin-desc">
                        1. At any time in the process if you need any
                        rectification/changes to be done, the process can be
                        stopped until those are implemented.
                      </p>
                      <p className="guide-left-signin-desc">
                        2. To request the rectfication/change you can see the
                        top right corner after choosing the Proforma Invoice you
                        are tracking
                      </p>
                      {/* <img src={img61} className="guide-left-img" /> */}
                      <div className="d-md-flex justify-content-between mobhide">
                        <div className="six-leftdiv">
                          <img src={newimg61} className="guide-left-img" />
                          <img src={newimg63} className="guide-left-img" />
                        </div>
                        <div className="six-rightdiv">
                          <img src={newimg62} className="guide-left-img" />
                          <img src={newimg64} className="guide-left-img" />
                        </div>
                      </div>
                      <div className="deskhide">
                      <div className="d-md-flex justify-content-between ">
                        <div className="six-leftdiv">
                          <img src={newimg61} className="guide-left-img" />
                        </div>
                        <div className="six-rightdiv">
                          <img src={newimg62} className="guide-left-img" />
                        </div>
                      </div>
                      <div className="d-md-flex justify-content-between">
                        <div className="six-leftdiv">
                          <img src={newimg63} className="guide-left-img" />
                        </div>
                        <div className="six-rightdiv">
                          <img src={newimg64} className="guide-left-img" />
                        </div>
                      </div>
                      </div>
                      
                      
                      <p className="guide-left-signin-desc">
                        3. After that you will be directed put in the text/file
                        which discribes the rectification you need. You are
                        requested to be as descriptive as possible as that will
                        allow us to resolve the concern in the most effective
                        manner.
                      </p>
                      <img src={img62} className="guide-left-img" />
                      <p className="guide-left-signin-desc">
                        4. As soon as the requested rectifications/changes are
                        made you will be notified via email and the changed
                        information will be visible no your KeviTrack portal.
                      </p>
                      <img src={img63} className="guide-left-img" />
                    </div>
                  ) : selectedindex == 6 ? (
                    <div className="guide-left-innerdiv">
                      <p className="guide-left-signin-head">
                        Request for Quote
                      </p>
                      <p className="guide-left-signin-desc">
                        In our attempt to centrialise the processes at Kevin
                        which promotes transparency and accessibility to
                        information, we are offering you the option of
                        requesting a quote for any product of your interest.
                        This will allow your to keep a track of all the requests
                        and our responses.
                      </p>
                      <hr className="guide-left-hr" />
                      <p className="guide-left-signin-desc">
                        1. To request a quote from us, you can seclect the
                        option from your left side dashboard:
                      </p>
                      <img src={img71} className="guide-left-img" />
                      <p className="guide-left-signin-desc">
                        2. Now you will be presented with a summary of all the
                        Quotes you have requested and on the top right you can
                        find the option of requesting a new quote:
                      </p>
                      <img src={img72} className="guide-left-img" />
                      <p className="guide-left-signin-desc">
                        3. For us to understand the product which the quote is
                        being requested we compulsorily require the followig set
                        of inputs:
                      </p>
                      <p className="guide-left-signin-desc">
                        a. CI Name/Number of the product<br />
                        b. Estameted Quantity of the said product<br />
                        c. Application of the product<br />
                        d. The mode in which the shipment is preferred <br />
                        e. INCO terms needed for this shipment
                      </p>
                      <p className="guide-left-signin-desc">
                        Optionally, if you have the following information it will Help us provide you with a more accurate quote as it will allow us to undersatnd the exact quality you need
                      </p>
                      <p className="guide-left-signin-desc">
                        a. Kevin Code - If you habe received any samples from us in the past or are intrested in any specific grade of a product and are aware of the Kevin Code do mention it since this information allows us to narrow down on your requirment.<br />
                        b. Ref./Lot. No. - If you are inquiring regarding a previously supplied lot or a smaple with only its refrence no. do mention it so again to identify your requirment precisely.<br />
                        c. Select Currency - Our default for provinding quotations is USD but if you prefer receiving it any of our other options you have the option to select the same.<br />
                        d. Time of Shipment - You can choose between when do you need the shipment ETA/ETD with the option of choosing the date.<br />
                        e. Port of Arrival - If you would like us to give you a CIF quote mentioning the port of arrival will help us ascertain the freight cost and give a accurate quote.
                      </p>
                      <img src={img73} className="guide-left-img" />
                      <p className="guide-left-signin-desc">
                        4. After submitting the RFQ you can see the status for your request on the home page.
                      </p>
                      <img src={img74} className="guide-left-img" />
                      <p className="guide-left-signin-desc">
                        5. As soon as the team from Kevin will reply to your RFQ you will receive an email notification with the quote and the ability to respond:
                      </p>
                      <div className="d-md-flex">
                        <img src={img75} className="guide-left-img" />
                        <img src={img76} className="guide-left-img" style={{marginLeft: 20}} />
                      </div>
                      <p className="guide-left-signin-desc">
                        6. In the case of the offered quote not matching your expectation you havr the option of rejectig the Quote and mentioning the traget price, based on this information the team at Kevin will evaluate respond back:
                      </p>
                      <img src={img77} className="guide-left-img" />
                      <p className="guide-left-signin-desc">
                        7. When approved, the sales team will get in touch with you regarding finalising the terms of shipment or dispatching a sample based on your requirment.
                      </p>
                      
                    </div>
                  ) : selectedindex == 7 ? (
                    <div className="guide-left-innerdiv">
                      <p className="guide-left-signin-head">
                        Request for Sample
                      </p>
                      <p className="guide-left-signin-desc">
                        In our attempt to centrialise the processes at Kevin which promotes transparency and accessibility to information, we are offering you the option of requesting a sample for any product of your interest. This will allow your to keep a track of all the requests and our responses.
                      </p>
                      <hr className="guide-left-hr" />
                      <p className="guide-left-signin-desc">
                        1. To request a quote from us, you can seclect the
                        option from your left side dashboard:
                      </p>
                      <img src={img81} className="guide-left-img" />
                      <p className="guide-left-signin-desc">
                        2. Now you will be presented with a summary of all the Samples you have requested and on the top right you can find the option of requesting a new sample:
                      </p>
                      <img src={img82} className="guide-left-img" />
                      <p className="guide-left-signin-desc">
                        3. For us to understand the product which the sample is being requested we compulsorily require the followig set of inputs
                      </p>
                      <p className="guide-left-signin-desc">
                        a. CI Name/Number of the product<br />
                        b. Estameted Quantity of sample needed <br />
                        c. Application of the product<br />
                      </p>
                      <p className="guide-left-signin-desc">
                        Optionally, if you have the following information it will Help us provide you with a more accurate sample as it will allow us to undersatnd the exact quality you need:
                      </p>
                      <p className="guide-left-signin-desc">
                        1. Refrence Documents - If there is any perticular specification that you need the sample to meet or any prior COA that you might have recived from us you can attached the same with your request.
                      </p>
                      <img src={img83} className="guide-left-img" />
                      <p className="guide-left-signin-desc">
                        4. You also have an option to request multiple samples just click on the top right "+" sign and input the information.
                      </p>
                      <p className="guide-left-signin-desc">
                        5. After submitting the RFS you can see the status for your request on the home page.
                      </p>
                      <img src={img84} className="guide-left-img" />
                      <p className="guide-left-signin-desc">
                        6. As soon as the team from Kevin will reply to your RFS you will receive an email notification with the sample dispatch details and the ability to respond:
                      </p>
                      <div className="d-md-flex">
                        <img src={img85} className="guide-left-img" />
                        <img src={img86} className="guide-left-img" style={{marginLeft: 20, maxWidth: '65%'}} />
                      </div>
                      <p className="guide-left-signin-desc">
                        7. In the case of the sample sent not matching your expectation you have the option of rejectig the sample and mentioning the feedback, based on this information the team at Kevin will evaluate respond back:
                      </p>
                      <img src={img87} className="guide-left-img" />
                      <p className="guide-left-signin-desc">
                        8. When approved, the sales team will get in touch with you regarding quote for the same and check back your requriment.
                      </p>
                      
                    </div>
                  )
                  : null}
                </div>
                <div className="guide-right-box mt-4">
                  <div className="guide-left-innerdiv1">
                    <p className="guide-left-welcome-head">Need more help ?</p>
                    <p className="guide-left-welcome-desc">
                      Try these next steps :
                    </p>
                    <Link to={'mailto:kevin@kevinindia.in'} target="_blank" className="guide-left-emailbox">
                      <p>Email</p>
                    </Link>
                    
                  </div>
                </div>
              </div>
              <div className="guide-rightsection mobhide tabletshow">
                <div className="guide-right-box">
                  <p className="other-features-head">Other Features</p>
                  {featureslist?.map((item, index) => {
                    return (
                      <div className="feature-div" key={index}>
                        <p
                          className="feature-name"
                          onClick={() => {
                            handlechangeindex(index);
                          }}
                          style={
                            selectedindex == index
                              ? { backgroundColor: "#414141" }
                              : {}
                          }
                        >
                          {item.label}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <WebFooter />
      </div>
    );
}

export default WebHowToGuide;