import React, { useState, useEffect } from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import InvoiceTable from "../../components/tables/InvoiceTable";

import { Link } from "react-router-dom";
import { colors } from "../../config/colors";

import { useDispatch, useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../../config/config";
import axios from "axios";
import Overlay from "../../components/common/Overlay";
import { edit, deleteicon } from "../../config/images";
import { useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom';

const SuperAdminEditRFQ = () => {
  const dispatch = useDispatch();
  const userdata = useSelector((store) => store.userReducer.userdata);
  let navigate = useNavigate();
  const { id } = useParams();
  const [showloding, setshowloding] = useState(false);
  const [requestsampledata, setrequestsampledata] = useState([]);
  const [name, setname] = useState(null);
  const [quantity, setquantity] = useState(null);
  const [kevincode, setkevincode] = useState(null);
  const [application, setapplication] = useState(null);
  const [referencenumber, setreferencenumber] = useState(null);
  const [currencydata, setcurrencydata] = useState([]);
  const [estimatetime, setestimatetime] = useState(null);
  const [currencyselect, setcurrencyselect] = useState(null);
  const [selecteddate, setselecteddate] = useState(null);
  const [modeofshipment, setmodeofshipment] = useState(null);
  const [portofdischarge, setportofdischarge] = useState(null);

  const [customersdata, setcustomersdata] = useState([]);
    const [selectcustomer, setselectcustomer] = useState(null);

  const fetchrequestsampledata = async (id) => {
    setshowloding(true);
    var data = {
      rfqid: id,
    };
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userdata?.token,
      },
    };
    let resp = await axios
      .post(apiUrl + "superadminfetchrfqsingle", data, headers)
      .then((res) => {
        // console.log('res >>>', res.data)
        if (res.data.status == "success") {
          setrequestsampledata(res.data.data);
          setname(res.data.data[0].productname);
          setquantity(res.data.data[0].quantity);
          setapplication(res.data.data[0].application);
          setkevincode(res.data.data[0].kevincode);
          setreferencenumber(res.data.data[0].reference);
          setestimatetime(res.data.data[0].estimation);
          setselecteddate(res.data.data[0].validity);
          setmodeofshipment(res.data.data[0].shipment);
          setportofdischarge(res.data.data[0].dischargeport);
          setselectcustomer(res.data.data[0].customerid);
          // console.log('res.data.data ..', res.data.data[0].productname)
          // dispatch({ type: "userdata", payload: res.data.data })
          //  navigate('/customer/requestsample');
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
    setshowloding(false);
  };

  const rfssamplereply = async (files) => {
    if (name && quantity && application) {
      setshowloding(true);
      var data = {
        rfqid: id,
        productname: name,
        quantity: quantity,
        kevincode: kevincode,
        reference: referencenumber,
        currency: currencyselect,
        estimation: estimatetime,
        shipment: modeofshipment,
        dischargeport: portofdischarge,
        validity: selecteddate,
        application: application,
        customerid: selectcustomer,
      };
      // console.log('data .>', data)
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "superadminupdaterfq", data, headers)
        .then((res) => {
          // console.log('res >>>', res.data)
          if (res.data.status == "success") {
            // dispatch({ type: "userdata", payload: res.data.data })
            navigate("/cms/superadmin/requestquote");
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          alert(err.message);
        });
      setshowloding(false);
    } else {
      alert("Please fill all required details.");
    }
  };

  const fetchcurrencyfunc = async () => {
    setshowloding(true);
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userdata?.token,
      },
    };
    let resp = await axios
      .get(apiUrl + "currenylist", headers)
      .then((res) => {
        if (res.data.status == "success") {
          setcurrencydata(res.data.data);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
    setshowloding(false);
  };

  const fetchcustomers = async() => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userdata?.token,
      },
    };
    let resp = await axios
      .get(apiUrl + "customerslist", headers)
      .then((res) => {
        if (res.data.status == "success") {
          setcustomersdata(res.data.data);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  useEffect(() => {
    if (userdata && Object.keys(userdata).length > 0) {
      fetchrequestsampledata(id);
      fetchcurrencyfunc();
      fetchcustomers();
      
    } else {
      navigate("/cms/superadmin/login");
    }
  }, []);

  return (
    <div className="HomeMain">
      <Overlay showloader={showloding} />
      <div className="sidebar-div">
        <Sidebar name={'superadmin'} />
      </div>
      <div className="main-layout">
      <Navbar name={'superadmin'} />
        <div className="mainDiv">
          <p className="oneplaceHead">UPDATE RFQ</p>
          <div class="orderscreen superadminorderscreen">
            <div class="row adminorderrow">
              <div class="col-md-4">
                <p class="orderscreen-p orderscreen-p1">Name * :</p>
              </div>
              <div class="col-md-8">
                <input
                  value={name}
                  onChange={(t) => {
                    setname(t.target.value);
                  }}
                  type="text"
                  class="form-control admin-form-control custom-input-height"
                  placeholder="Name"
                />
              </div>
            </div>
            <div class="row adminorderrow">
              <div class="col-md-4">
                <p class="orderscreen-p orderscreen-p1">Quantity (grams) * :</p>
              </div>
              <div class="col-md-8">
                <input
                  value={quantity}
                  onChange={(t) => {
                    setquantity(t.target.value);
                  }}
                  type="number"
                  class="form-control admin-form-control custom-input-height"
                  placeholder="Quantity"
                />
              </div>
            </div>
            <div class="row adminorderrow">
              <div class="col-md-4">
                <p class="orderscreen-p orderscreen-p1">Application * :</p>
              </div>
              <div class="col-md-8">
                <input
                  value={application}
                  onChange={(t) => {
                    setapplication(t.target.value);
                  }}
                  type="text"
                  class="form-control admin-form-control custom-input-height"
                  placeholder="Application"
                />
              </div>
            </div>
            <div class="row adminorderrow">
              <div class="col-md-4">
                <p class="orderscreen-p orderscreen-p1">Kevin Code :</p>
              </div>
              <div class="col-md-8">
                <input
                  value={kevincode}
                  onChange={(t) => {
                    setkevincode(t.target.value);
                  }}
                  type="text"
                  class="form-control admin-form-control custom-input-height"
                  placeholder="Enter Code"
                />
              </div>
            </div>
            <div class="row adminorderrow">
              <div class="col-md-4">
                <p class="orderscreen-p orderscreen-p1">Ref. / Lot No. :</p>
              </div>
              <div class="col-md-8">
                <input
                  value={referencenumber}
                  onChange={(t) => {
                    setreferencenumber(t.target.value);
                  }}
                  type="text"
                  class="form-control admin-form-control custom-input-height"
                  placeholder="Enter Ref. / Lot No."
                />
              </div>
            </div>
            <div class="row adminorderrow">
              <div class="col-md-4">
                <p class="orderscreen-p orderscreen-p1">Select Currency :</p>
              </div>
              <div class="col-md-8">
                <select
                  class="form-select admin-form-control custom-input-height"
                  value={currencyselect}
                  onChange={(t) => {
                    setcurrencyselect(t.target.value);
                  }}
                >
                  <option disabled selected>
                    select currency
                  </option>
                  {currencydata?.map((item, index) => {
                    var isselect =
                      item.symbol == currencyselect ? "selected" : "";
                    return (
                      <option value={item?.symbol}>
                        {item?.name + "(" + item?.symbol + ")"}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div class="row adminorderrow">
              <div class="col-md-4">
                <p class="orderscreen-p orderscreen-p1">Estimated Time :</p>
              </div>
              <div class="col-md-8">
                <select
                  class="form-select admin-form-control custom-input-height"
                  value={estimatetime}
                  onChange={(t) => {
                    setestimatetime(t.target.value);
                  }}
                >
                  <option disabled select>
                    select
                  </option>
                  <option>ETA</option>
                  <option>ETD</option>
                </select>
              </div>
            </div>
            <div class="row adminorderrow">
              <div class="col-md-4">
                <p class="orderscreen-p orderscreen-p1">Select Date:</p>
              </div>
              <div class="col-md-8">
                <input
                  value={selecteddate}
                  onChange={(t) => {
                    setselecteddate(t.target.value);
                  }}
                  type="date"
                  class="form-control admin-form-control custom-input-height"
                  placeholder=""
                />
              </div>
            </div>
            <div class="row adminorderrow">
              <div class="col-md-4">
                <p class="orderscreen-p orderscreen-p1">Mode of Shipment * :</p>
              </div>
              <div class="col-md-8">
                <input
                  value={modeofshipment}
                  onChange={(t) => {
                    setmodeofshipment(t.target.value);
                  }}
                  type="text"
                  class="form-control admin-form-control custom-input-height"
                  placeholder="Enter mode of shipment"
                />
              </div>
            </div>
            <div class="row adminorderrow">
              <div class="col-md-4">
                <p class="orderscreen-p orderscreen-p1">Port of discharge :</p>
              </div>
              <div class="col-md-8">
                <input
                  value={portofdischarge}
                  onChange={(t) => {
                    setportofdischarge(t.target.value);
                  }}
                  type="text"
                  class="form-control admin-form-control custom-input-height"
                  placeholder="Enter port of discharge"
                />
              </div>
            </div>
            <div class="row adminorderrow">
              <div class="col-md-4">
                <p class="orderscreen-p orderscreen-p1">Select Customer :</p>
              </div>
              <div class="col-md-8">
                  <select
                      class="form-select admin-form-control custom-input-height"
                      value={selectcustomer}
                      onChange={(t) => {
                        setselectcustomer(t.target.value);
                      }}
                    >
                      <option disabled selected>
                        select customer
                      </option>
                      {customersdata?.map((item, index) => {
                        return <option value={item?.id}>{item?.name}</option>;
                      })}
                  </select>
              </div>
            </div>
            <button
              onClick={() => {
                rfssamplereply();
              }}
              className="submitrfs-btn submitrfs-btn2 create-button"
              style={{
                backgroundColor: colors.themeBlue,
                borderColor: colors.themeBlue,
                float: "right",
                marginTop: 10,
                marginBottom: 50,
              }}
            >
              UPDATE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminEditRFQ;
