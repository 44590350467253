import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import instagram from '../../assets/images/instagram.png';
import facebook from '../../assets/images/facebook.png';
import linkedin from '../../assets/images/linkedin.png';
import youtube from '../../assets/images/youtube.png';

const WebFooter = () => {
    return (
      <div className="footermaindiv">
        <div className="pagewidth">
          {/* <div className="footerrow">
                    <div className="footerleftdiv">
                        <p className="aboutfooterhead">About Kevin</p>
                        <p className="aboutfooterdesc">304-305, Gundecha Industrial Complex, Akurli Rd., Kandivali East, Mumbai 400 101, Maharashtra, India.</p>
                        <a href="mailto:kevin@kevinindia.in" className="aboutfooterdesc aboutfooterdesc1">kevin@kevinindia.in</a>
                        <p className="aboutfooterdesc aboutfooterdesc1">Your trusted color solution since 1996!</p>
                        <p className="footercopyrighttext mobhide tabletshow">Kevin [India] Co ©2024</p>
                    </div>
                    <div className="footerleftdiv1">
                        <p className="planthead">Plant 1</p>
                        <p className="aboutfooterdesc">4702, OPP New Telephone Exchange, GIDC Ankleshwar-3932002</p>
                    </div>
                    <div className="footerleftdiv1">
                        <p className="planthead">Plant 2</p>
                        <p className="aboutfooterdesc">Plot NO-808/A/1&3, GIDC, Phase-III, Vapi-396 195</p>
                    </div>
                    <div className="footerleftdiv1">
                        <p className="planthead">Plant 3</p>
                        <p className="aboutfooterdesc">Unit-2, Plot No-25/5, Nr.Pepsi Company, G.I.D.C Estate, Jhagadia</p>
                    </div>
                    <div className="footerleftdiv1">
                        <p className="planthead">Plant 4</p>
                        <p className="aboutfooterdesc">3709/5 & 3710/2/A, G.I.D.C Estate, Near New Telephone Exchange, Ankleshwar - 393002</p>
                    </div>
                    <div className="footerrightdiv">
                        <p className="assistancehead">Assistance</p>
                        <Link to={'mailto:kevin@kevinindia.in'} className="footerlink">Support</Link>
                        <Link to={'/how-to-guide'} className="footerlink">How-to Guide</Link>
                        <Link to={'/Contact'} className="footerlink">Contact</Link>
                        <div className="social-media-div">
                            <Link to={'https://www.facebook.com/KeviIndiaco/'} target="_blank" className="facebook-icon"><img src={facebook} /></Link>
                            <Link to={'https://www.linkedin.com/company/kevin-india-co/'} target="_blank" className="facebook-icon"><img src={linkedin} /></Link>
                            <Link to={'https://www.instagram.com/kevinindiaco/channel/'} target="_blank" className="facebook-icon"><img src={instagram} /></Link>
                            <Link to={'https://www.youtube.com/@kevinindiaco.6563'} target="_blank" className="facebook-icon"><img src={youtube} /></Link>
                        </div>
                    </div>
                    <p className="footercopyrighttext deskhide tablethide">Kevin [India] Co ©2024</p>
                </div> */}
          <div className="row">
            <div className="col-md-3">
              <div className="footerleftdiv">
                <p className="aboutfooterhead">About Kevin</p>
                <p className="aboutfooterdesc">
                  304-305, Gundecha Industrial Complex, Akurli Rd., Kandivali
                  East, Mumbai 400 101, Maharashtra, India.
                </p>
                <a
                  href="mailto:kevin@kevinindia.in"
                  className="aboutfooterdesc aboutfooterdesc1"
                >
                  kevin@kevinindia.in
                </a>
                <p className="aboutfooterdesc aboutfooterdesc1">
                  Your trusted color solution since 1996!
                </p>
                <p className="footercopyrighttext mobhide tabletshow">
                  Kevin [India] Co ©2024
                </p>
              </div>
            </div>
            <div className="col-md-7">
                <div className="footerrow">
                <div className="footerleftdiv1">
                        <p className="planthead">Plant 1</p>
                        <p className="aboutfooterdesc aboutfooterdesc2">4702, OPP New Telephone Exchange, GIDC Ankleshwar-3932002</p>
                    </div>
                    <div className="footerleftdiv1">
                        <p className="planthead">Plant 2</p>
                        <p className="aboutfooterdesc aboutfooterdesc2">Plot NO-808/A/1&3, GIDC, Phase-III, Vapi-396 195</p>
                    </div>
                    <div className="footerleftdiv1">
                        <p className="planthead">Plant 3</p>
                        <p className="aboutfooterdesc aboutfooterdesc2">Unit-2, Plot No-25/5, Nr.Pepsi Company, G.I.D.C Estate, Jhagadia</p>
                    </div>
                    <div className="footerleftdiv1">
                        <p className="planthead">Plant 4</p>
                        <p className="aboutfooterdesc aboutfooterdesc2">3709/5 & 3710/2/A, G.I.D.C Estate, Near New Telephone Exchange, Ankleshwar - 393002</p>
                    </div>
                </div>
            </div>
            <div className="col-md-2">
            <div className="footerrightdiv">
                        <p className="assistancehead">Assistance</p>
                        <Link to={'mailto:kevin@kevinindia.in'} className="footerlink">Support</Link>
                        <Link to={'/how-to-guide'} className="footerlink">How-to Guide</Link>
                        <Link to={'/Contact'} className="footerlink">Contact</Link>
                        <div className="social-media-div">
                            <Link to={'https://www.facebook.com/KeviIndiaco/'} target="_blank" className="facebook-icon"><img src={facebook} /></Link>
                            <Link to={'https://www.linkedin.com/company/kevin-india-co/'} target="_blank" className="facebook-icon"><img src={linkedin} /></Link>
                            <Link to={'https://www.instagram.com/kevinindiaco/channel/'} target="_blank" className="facebook-icon"><img src={instagram} /></Link>
                            <Link to={'https://www.youtube.com/@kevinindiaco.6563'} target="_blank" className="facebook-icon"><img src={youtube} /></Link>
                        </div>
                    </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default WebFooter