import React, { useEffect,useState } from "react";
import { logo, notification } from "../../config/images";
import {  Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux/es/exports";
import { Drawer, ButtonToolbar, Button, Placeholder } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { deleteicon } from "../../config/images";
import { apiUrl } from "../../config/config";
import axios from 'axios';

const Navbar = ({name}) => {
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();
  const [notificationdata, setnotificationdata] = useState([]);
  const [unreadcount, setunreadcount] = useState(0);
    const dispatch = useDispatch(); 
    const userdata = useSelector((store) => store.userReducer.userdata);
    // console.log('Navbar userdata >>', userdata);
    let navigate = useNavigate();
    const logoutpressed = () => {
      dispatch({ type: "userdata", payload: {} })
      if(userdata?.type == 'superadmin') {
        navigate('/cms/superadmin/login');
      } else if(userdata?.type == 'customer')  {
        navigate('/cms/customer/login');
      } else if(userdata?.type == 'admin') {
        navigate('/cms/adminlogin');
      }
      else {
        navigate('/');
      }
    }
    const handleOpen = key => {
      setOpen(true);
      setPlacement(key);
    };

    const samplenotificationdata = [
      {
        'message' : 'Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book', 'date': '10:30pm - 26th july 2023', 'isread': true
      },{
        'message' : 'Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book', 'date': '11:10am - 26th july 2023', 'isread': false
      }
    ]

    const getnotification = async(uri) => {
      const headers = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + userdata?.token
        }
    }
    let response = await axios.get(apiUrl + uri, headers).then((res) => {
        if(res.data.status == 'success') {
          // console.log('res.data >>', res.data);
          setunreadcount(res.data.unreadcount);
          setnotificationdata(res.data.data)
            // setadminlistdata(res.data.data)
         } else {
           alert(res.data.message);
         }
    }).catch((err) => {
        console.log('err >>', err);
    })
    }

    const markasreadapihit = async(uri, id) => {
      var data = {
        'notifyid' : id,
    }
      const headers = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + userdata?.token
        }
    }
    let response = await axios.post(apiUrl + uri,data, headers).then((res) => {
        if(res.data.status == 'success') {
          // console.log('res.data >>', res.data);
          setunreadcount(res.data.unreadcount);
          setnotificationdata(res.data.data)
            // setadminlistdata(res.data.data)
         } else {
           alert(res.data.message);
         }
    }).catch((err) => {
        console.log('err >>', err);
    })
    }
    const deleteapihit = async(uri, id) => {
      var data = {
        'notifyid' : id,
    }
      const headers = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + userdata?.token
        }
    }
    let response = await axios.post(apiUrl + uri,data, headers).then((res) => {
        if(res.data.status == 'success') {
          // console.log('res.data >>', res.data);
          setunreadcount(res.data.unreadcount);
          setnotificationdata(res.data.data)
            // setadminlistdata(res.data.data)
         } else {
           alert(res.data.message);
         }
    }).catch((err) => {
        console.log('err >>', err);
    })
    }

    const markasreadclicked = async(id) => {
      if(userdata.type == 'admin') {
        markasreadapihit('adminnotificationmarkasread', id);
      } else if(userdata.type == 'customer') {
        markasreadapihit('customernotificationmarkasread', id);
      }
    }

    const deleteclicked = async(id) => {
      if(userdata.type == 'admin') {
        deleteapihit('adminnotificationdelete', id);
      } else if(userdata.type == 'customer') {
        deleteapihit('customernotificationdelete', id);
      }
    }

    const onclickednotification = (item) => {
      console.log('item...', item);
      if(userdata.type == 'admin') {
        if(item.type == 'rfs') { 
          if(item.typestatus == '0') {
            navigate('/cms/admin/requestsample');
          } else if(item.typestatus == '1') {
            navigate('/cms/admin/reply-rfs/'+item.typeid);
          } else if(item.typestatus == '2') {
            navigate('/cms/admin/request-for-revised-sample/'+item.typeid);
          } else {
            navigate('/cms/admin/reply-rfs/'+item.typeid);
          }
        } else if(item.type == 'rfq') {
          if(item.typestatus == '0') {
            navigate('/cms/admin/requestquote');
          } else if(item.typestatus == '1') {
             navigate('/cms/admin/request-for-quote/'+item.typeid);
          } else if(item.typestatus == '2') {
            navigate('/cms/admin/request-for-revised-quote/'+item.typeid);
          } else {
          navigate('/cms/admin/reply-rfs/'+item.typeid);
          }
      } else if(item.type == 'rfi') { 
        if(item.typestatus == '0') {
          navigate('/cms/admin/proformaInvoice');
        } else if(item.typestatus == '1') {
          navigate('/cms/admin/new-pfi-tracking/'+item.typeid);
        } else {
          navigate('/cms/admin/new-pfi-tracking/'+item.typeid);
        }
      }
        
        // deleteapihit('adminnotificationdelete', id);
      } else if(userdata.type == 'customer') {
        if(item.type == 'rfs') { 
          if(item.typestatus == '0') {
            navigate('/cms/customer/requestsample');
          } else if(item.typestatus == '1') {
            navigate('/cms/customer/reply-for-sample/'+item.typeid);
          } else {
            navigate('/cms/customer/reply-for-sample/'+item.typeid);
          }
        } else if(item.type == 'rfq') { 
          if(item.typestatus == '0') {
            navigate('/cms/customer/requestquote');
          } else if(item.typestatus == '1') {
            navigate('/cms/customer/reply-for-quote/'+item.typeid);
          } else {
            navigate('/cms/customer/reply-for-quote/'+item.typeid);
          }
        } else if(item.type == 'rfi') { 
          if(item.typestatus == '0') {
            navigate('/cms/customer/proformaInvoice');
          } else if(item.typestatus == '1') {
            navigate('/cms/customer/ordertracking/'+item.typeid);
          } else {
            navigate('/cms/customer/ordertracking/'+item.typeid);
          }
        }
        // deleteapihit('customernotificationdelete', id);
      } else {
          if(item.type == 'rfs') { 
            if(item.typestatus == '0') {
              navigate('/cms/superadmin/requestsample');
            } else if(item.typestatus == '1') {
              navigate('/cms/superadmin/reply-rfs/'+item.typeid);
            } else if(item.typestatus == '2') {
              navigate('/cms/superadmin/request-for-revised-sample/'+item.typeid);
            } else {
              navigate('/cms/superadmin/reply-rfs/'+item.typeid);
            }
          } else if(item.type == 'rfq') {
            if(item.typestatus == '0') {
              navigate('/cms/superadmin/requestquote');
            } else if(item.typestatus == '1') {
               navigate('/cms/superadmin/request-for-quote/'+item.typeid);
            } else if(item.typestatus == '2') {
              navigate('/cms/superadmin/request-for-revised-quote/'+item.typeid);
            } else {
            navigate('/cms/superadmin/reply-rfs/'+item.typeid);
            }
        } else if(item.type == 'rfi') { 
          if(item.typestatus == '0') {
            navigate('/cms/superadmin/proformaInvoice');
          } else if(item.typestatus == '1') {
            navigate('/cms/superadmin/new-pfi-tracking/'+item.typeid);
          } else {
            navigate('/cms/superadmin/new-pfi-tracking/'+item.typeid);
          }
        }
      }
      // navigate(item.url)
    }

    useEffect(() => {
      if(userdata && Object.keys(userdata).length > 0) {
        if(userdata.type == 'admin') {
          getnotification('adminfetchnotifications');
        } else if(userdata.type == 'customer') {
          getnotification('customerfetchnotifications');
        } else {
          getnotification('superadminfetchnotifications');
        }
        
      } else {
        navigate('/cms/customer/login');
      }
    }, []);

    


    return (
      <>
        <div className="navbarMain">
          <div className="navbarMainMenuDiv">
            <ul>
              <li>
                <Link to={name == "Admin" ? "/cms/admin/home" : name == "superadmin" ? '/cms/superadmin/home' : "/cms/customer/home"}>
                  Home
                </Link>
              </li>
              {userdata?.type == "superadmin" ? (
                <>
                  <li>
                    <Link to={"/cms/superadmin/admins-list"}>Admins</Link>
                  </li>
                  <li>
                    <Link to={"/cms/superadmin/customers-list"}>Customers</Link>
                  </li>
                  <li>
                    <Link to={"/cms/superadmin/currency-list"}>Currency</Link>
                  </li>
                </>
              ) : null}
              {userdata?.type == "admin" ? (
                <li>
                  <Link to={"/cms/admin/customers-list"}>Customers</Link>
                </li>
              ) : null}
              {userdata?.type == "customer" ? (
                <li>
                  <Link to={"/cms/customer/update-password"}>Update Password</Link>
                </li>
              ) : userdata?.type == "admin" ? (
                <li>
                  <Link to={"/cms/admin/update-password"}>Update Password</Link>
                </li>
              ) : null}
              {/* <li>
              <Link to="/">Profile</Link>
            </li> */}
              <li
                onClick={() => {
                  logoutpressed();
                }}
              >
                {/* <Link to={name == 'Admin' ?
              '/admin/' :
              '/'
            }> */}
                <Link>
                  <span>Logout</span>
                </Link>
              </li>
              <li className="noti-li">
                <div onClick={() => setOpen(true)} style={{cursor: 'pointer'}}>
                  <img src={notification} className="navNotiicon" />
                  {
                    unreadcount !== 0 ?
                    <div className="notreddotdiv">
                    <p>{unreadcount}</p>
                  </div> : null
                  } 
                  
                </div>
              </li>
            </ul>
          </div>
        </div>
        {/* <Button onClick={() => setOpen(true)}>Open</Button> */}
        <Drawer
          open={open}
          onClick={() => setOpen(false)}
          onClose={() => setOpen(false)}
          backdrop={true}
          size={'xs'}
        >
          <Drawer.Header >
          {
                    unreadcount !== 0 ?
            <p className="unreadhead">{unreadcount} unread message</p>
            : <p className="unreadhead"></p>
                  } 
          </Drawer.Header>
          {/* <Drawer.Body>
          <Placeholder.Paragraph rows={8} />
        </Drawer.Body> */}
        <div className="notibox" >
        {
          notificationdata.length > 0 ?
          notificationdata?.map((item, index) => {
            return (
              <div className="notidiv" key={index} onClick={() => {
                onclickednotification(item);
              }}>
                <p className="notitext">{item.message}</p>
                <div className="notibottomdiv">
                  <p className="notitext1">{item.date}</p>
                  {item.isread == 'true' ? (
                    <p className="notitext1" style={{marginLeft: 'auto', marginRight: 10}}></p>
                  ) : (
                    <Link onClick={() => {markasreadclicked(item.id)}} className="notitext1" style={{marginLeft: 'auto', marginRight: 10, textDecoration: 'none'}}>{"mark as read"}</Link>
                  )}
                  <div
                    onClick={() => {
                      deleteclicked(item.id)
                    }}
                  >
                    <img src={deleteicon} style={{width: 18, height: 18, marginTop: -15}} />
                  </div>
                </div>
              </div>
            );
          }) : null
        }
        </div>
        
        </Drawer>
      </>
    );
}

export default Navbar;