import React, { useEffect,useState } from "react";
import { logo, notification } from "../../config/images";
import {  Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux/es/exports";
import { Drawer, ButtonToolbar, Button, Placeholder } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { deleteicon } from "../../config/images";
import { apiUrl } from "../../config/config";
import axios from 'axios';

const WebNavbar = ({name}) => {
    const dispatch = useDispatch(); 
    const userdata = useSelector((store) => store.userReducer.userdata);
    // console.log('Navbar userdata >>', userdata);
    let navigate = useNavigate();
    const logoutpressed = () => {
        dispatch({ type: "userdata", payload: {} })
        navigate('/webadminlogin');
    }
   

    useEffect(() => {
    //   if(userdata && Object.keys(userdata).length > 0) {
    //     if(userdata.type == 'admin') {
    //       getnotification('adminfetchnotifications');
    //     } else if(userdata.type == 'customer') {
    //       getnotification('customerfetchnotifications');
    //     } else {
    //       getnotification('superadminfetchnotifications');
    //     }
        
    //   } else {

    //   }
    }, []);

    


    return (
      <>
        <div className="navbarMain">
          <div className="navbarMainMenuDiv">
            <ul>
              <li>
                <Link to={"/webadmin/home"}>
                  Home
                </Link>
              </li>
              <li
                onClick={() => {
                  logoutpressed();
                }}
              >
                <Link>
                  <span>Logout</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
}

export default WebNavbar;