import React, { useEffect, useState, useRef } from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import './../../components/tables/tables.css';
import { plus, minus } from "../../config/images";
import { colors } from "../../config/colors";
import { useNavigate } from 'react-router-dom';

import { apiUrl } from "../../config/config";
import axios from 'axios';
import Overlay from "../../components/common/Overlay";
import { useDispatch, useSelector } from "react-redux/es/exports";

const SuperadminNewQuote = () => {
  const dispatch = useDispatch(); 
    const userdata = useSelector((store) => store.userReducer.userdata);
  let navigate = useNavigate();
    const obj = {
        "srno": 1,
        "productname": "",
        "quantity": "",
        "application": "",
        "kevincode": "",
        "reference": ""
    }
    const [requestarr, setrequestarr] = useState([obj]);
    const [issubmitted, setissubmitted] = useState(false);
    const [showloding, setshowloding] = useState(false);
    const [currencydata, setcurrencydata] = useState([]);
    const [estimatetime, setestimatetime] = useState(null);
    const [currencyselect, setcurrencyselect] = useState(null);
    const [selecteddate, setselecteddate] = useState(null);
    const [modeofshipment, setmodeofshipment] = useState(null);
    const [portofdischarge, setportofdischarge] = useState(null);
    const [inco, setinco] = useState(null);

    const [customersdata, setcustomersdata] = useState([]);
    const [selectcustomer, setselectcustomer] = useState(null);

    const requestarrRef = useRef();
    requestarrRef.current = requestarr;
    
    const onplusfunction = () => {
    
        var obj = {
            "srno": requestarr.length + 1,
            "productname": "",
            "quantity": "",
            "application": "",
            "document": "",
        }
        setrequestarr(a => [...a,obj ]);
    }
    const onminusfunction = () => {
        console.log('onminusfunction ...');
        if(requestarr.length == 1) {

        } else {
            var newarr = requestarr.slice(0, -1);
            setrequestarr(newarr);
        }
        
        
    }

    const submitrfs1 = () => {
      navigate('/cms/superadmin/requestquote');  
  }

    const submitrfq = async() => {
      // setissubmitted(true);
      setshowloding(true);
      var data = {
          'data': requestarrRef.current,
          'currencyselect': currencyselect,
          'estimatetime': estimatetime,
          'selecteddate': selecteddate,
          'modeofshipment': modeofshipment,
          'portofdischarge': null,
          'arrivalport': portofdischarge,
          'inco': inco,
          'customerid': selectcustomer,
        };
        // console.log('data .>', data)
      const headers = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization' : 'Bearer ' + userdata?.token
          }
      }
      let resp = await axios.post(apiUrl + 'superadminsubmitrfq', data, headers).then((res) => {
        // console.log('res >>>', res.data)
          if(res.data.status == 'success') {
            // dispatch({ type: "userdata", payload: res.data.data })
            //  navigate('/cms/admin/requestsample');
            setissubmitted(true);
          } else {
            alert(res.data.message);
          }
      } ).catch((err) => {
          alert(err.message)
      })
      setshowloding(false);
    }

    const getBase64 = file => {
      return new Promise(resolve => {
        let fileInfo;
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();
  
        // Convert the file to base64 text
        reader.readAsDataURL(file);
  
        // on reader load somthing...
        reader.onload = () => {
          // Make a fileInfo Object
          // console.log("Called", reader);
          baseURL = reader.result;
          // console.log(baseURL);
          resolve(baseURL);
        };
        // console.log(fileInfo);
      });
    };

    const changetext = async(name, index, text) => {
      // console.log('text11 .>',text.name);
      let data = [...requestarrRef.current]
        data[index][name] = text
      setrequestarr(data);
    }

    const fetchcurrencyfunc = async() => {
      setshowloding(true);
      const headers = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + userdata?.token
        }
    }
      let resp = await axios.get(apiUrl + 'currenylist', headers).then((res) => {
        // console.log('res >>>', res.data)
          if(res.data.status == 'success') {
            // dispatch({ type: "userdata", payload: res.data.data })
            //  navigate('/cms/customer/requestsample');
            // setissubmitted(true);
            // console.log('customerfetchcurrency ..', res.data);
            setcurrencydata(res.data.data);
          } else {
            alert(res.data.message);
          }
      } ).catch((err) => {
          alert(err.message)
      })
      setshowloding(false);
    }
    const fetchcustomers = async() => {
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .get(apiUrl + "customerslist", headers)
        .then((res) => {
          if (res.data.status == "success") {
            setcustomersdata(res.data.data);
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          alert(err.message);
        });
    }

    useEffect(() => {
      if(userdata && Object.keys(userdata).length > 0) {
          fetchcurrencyfunc();
          fetchcustomers();
      } else {
          navigate('/cms/superadmin/login');
      }
  }, []);

    // console.log('requestarrRef.current >>', requestarrRef.current);
    // console.log('selecteddate ..', selecteddate);

    return (
      <div className="HomeMain">
        <Overlay showloader={showloding} />
        <div className="sidebar-div">
          <Sidebar name={"superadmin"} />
        </div>
        <div className="main-layout">
          <Navbar name={"superadmin"} />
          <div className="mainDiv">
            {!issubmitted ? (
              <>
                <p className="oneplaceHead">REQUEST FOR QUOTE</p>
                <div className="formdiv">
                  {requestarr?.length > 0 ? (
                    <div className="formbox mt-4">
                      {/* <div className="appendingdiv">
                        <button
                          className="plusbox"
                          onClick={(e) => {
                            e.preventDefault();
                            onplusfunction();
                          }}
                        >
                          <img src={plus} className="plusicon" />
                        </button>
                        <button
                          className="plusbox"
                          onClick={() => {
                            onminusfunction();
                          }}
                        >
                          <img src={minus} className="plusicon" />
                        </button>
                      </div> */}
                      <table className="table table-bordered invoicetable formtable">
                        <thead>
                          {/* <th>Sr no.</th> */}
                          <th>Product Name *</th>
                          <th>Quantity *</th>
                          <th>Kevin Code</th>
                          <th>Ref. / Lot No.</th>
                          <th>Application*</th>
                        </thead>
                        <tbody id="appendbody">
                          {requestarr?.map((item, index) => {
                            return (
                              <tr>
                                {/* <td> {item["srno"]} </td> */}
                                <td>
                                  <input
                                    type="text"
                                    value={item["productname"]}
                                    onChange={(t) => {
                                      changetext(
                                        "productname",
                                        index,
                                        t.target.value
                                      );
                                    }}
                                    className="form-control tabinput"
                                    placeholder="Enter product name"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={item["quantity"]}
                                    onChange={(t) => {
                                      changetext(
                                        "quantity",
                                        index,
                                        t.target.value
                                      );
                                    }}
                                    className="form-control tabinput"
                                    placeholder="Enter quantity"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={item["kevincode"]}
                                    onChange={(t) => {
                                      changetext(
                                        "kevincode",
                                        index,
                                        t.target.value
                                      );
                                    }}
                                    className="form-control tabinput"
                                    placeholder="Enter code"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={item["reference"]}
                                    onChange={(t) => {
                                      changetext(
                                        "reference",
                                        index,
                                        t.target.value
                                      );
                                    }}
                                    className="form-control tabinput"
                                    placeholder="Enter Ref. / Lot No."
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={item["application"]}
                                    onChange={(t) => {
                                      changetext(
                                        "application",
                                        index,
                                        t.target.value
                                      );
                                    }}
                                    className="form-control tabinput"
                                    placeholder="Enter Application"
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div className="quoteinputdiv" style={{display : 'block'}}>
                      <div className="row">
                        {currencydata?.length > 0 ? (
                          <div className="col-md-4 quoteinputdiv1">
                            <label>Select Currency:</label>
                            <select class="form-select" value={currencyselect} onChange={(t) => {
                                setcurrencyselect(t.target.value);
                            }}>
                              <option disabled selected>
                                select currency
                              </option>
                              {currencydata?.map((item, index) => {
                                return (
                                  <option value={item?.symbol}>{item?.name +'('+item?.symbol+')'}</option>
                                )
                              })}
                            </select>
                          </div>
                        ) : null}
                        <div className="col-md-4 quoteinputdiv1">
                          <label>Estimated Time:</label>
                          <select class="form-select" value={estimatetime} onChange={(t) => {
                                      setestimatetime(t.target.value);
                                    }}>
                            <option disabled select>
                              select
                            </option>
                            <option>ETA</option>
                            <option>ETD</option>
                          </select>
                        </div>
                        <div className="col-md-4 quoteinputdiv1">
                          <label>Select Date:</label>
                          <input
                            type="date"
                            onChange={(e) => setselecteddate(e.target.value)} 
                            value={selecteddate}
                            className="form-control "
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-4 quoteinputdiv1">
                          <label>Mode of Shipment *:</label>
                          <input
                            type="text"
                            onChange={(e) => setmodeofshipment(e.target.value)} 
                            value={modeofshipment}
                            className="form-control "
                            placeholder="Enter mode of shipment"
                          />
                        </div>
                        <div className="col-md-4 quoteinputdiv1">
                          <label>Port of Arrival :</label>
                          <input
                            type="text"
                            onChange={(e) => setportofdischarge(e.target.value)} 
                            value={portofdischarge}
                            className="form-control "
                            placeholder="Enter port of discharge"
                          />
                        </div>
                        <div className="col-md-4 quoteinputdiv1">
                          <label>INCO Terms:</label>
                          <input
                            type="text"
                            onChange={(e) => setinco(e.target.value)} 
                            value={inco}
                            className="form-control "
                            placeholder="Enter inco"
                          />
                        </div>
                        
                      </div>
                      <div className="row mt-4">
                      <div className="col-md-4 quoteinputdiv1">
                      <label>Select Customer *:</label>
                    <select
                      class="form-select admin-form-control custom-input-height"
                      value={selectcustomer}
                      onChange={(t) => {
                        setselectcustomer(t.target.value);
                      }}
                    >
                      <option disabled selected>
                        select customer
                      </option>
                      {customersdata?.map((item, index) => {
                        return <option value={item?.id}>{item?.name}</option>;
                      })}
                    </select>
                  </div>
                      </div>

                        
                        
                        
                      </div>
                      <button
                        onClick={() => {
                          submitrfq();
                        }}
                        className="submitrfs-btn"
                        style={{
                          backgroundColor: colors.themeBlue,
                          borderColor: colors.themeBlue,
                        }}
                      >
                        Submit RFQ
                      </button>
                    </div>
                  ) : null}
                </div>
              </>
            ) : (
              <>
                <p className="oneplaceHead">THANK YOU FOR SUBMITTING A RFQ!</p>
                <p className="oneplaceHead oneplaceHead1">
                  WE WILL KEEP YOU POSTED ONCE THE QUOTE IS READY!
                </p>
                <button
                  onClick={() => {
                    submitrfs1();
                  }}
                  className="submitrfs-btn submitrfs-btn1"
                  style={{
                    backgroundColor: colors.themeBlue,
                    borderColor: colors.themeBlue,
                  }}
                >
                  CLICK HERE TO CHECK RFQ STATUS
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    );
}

export default SuperadminNewQuote