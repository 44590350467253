import React, { useEffect,useState, useRef } from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import InvoiceTable from "../../components/tables/InvoiceTable";
import { Link } from "react-router-dom";
import { colors } from "../../config/colors";
import { deleteicon, edit, view, hide } from "../../config/images";
import './../../components/tables/tables.css';

import { useDispatch, useSelector } from "react-redux/es/exports";
import { useNavigate } from 'react-router-dom';
import Overlay from "../../components/common/Overlay";
import axios from 'axios';
import { apiUrl } from '../../config/config';
import {useLocation} from 'react-router-dom';
import { plus, minus } from "../../config/images";

const EditCustomer = (props) => {
  const obj = {
    "srno": 1,
    "email": ""
}
    const dispatch = useDispatch(); 
    let navigate = useNavigate();
    const userdata = useSelector((store) => store.userReducer.userdata);
    const { state } = useLocation();
    // console.log('userdata >>', userdata);

    const [name, setname] = useState(null)
    const [email, setemail] = useState(null)
    const [username, setusername] = useState(null)
    const [password, setpassword] = useState('')
    const [repassword, setrepassword] = useState('')
    const [showpassword, setshowpassword] = useState(false)
    const [showrepassword, setshowrepassword] = useState(false)
    const [showloding, setshowloding] = useState(false);
    const [emaillist, setemaillist] = useState([obj]);
    const requestarrRef = useRef();
    requestarrRef.current = emaillist;

    const createadminpresses = async() => {
      var emailsarr = [];
      var data = requestarrRef.current;
      if(data.length == 1) {
        if(data[0].email) {
          emailsarr.push(data[0].email);
        } else {
          emailsarr = [];
        }
      } else {
        for(var i = 0; i < data.length; i++) {
          if(data[i].email) {
            emailsarr.push(data[i].email);
          } else {
            alert('email field cannot be empty');
            return;
          }
        }
      }
        if(name && email ) {
            if(password == repassword) {
                setshowloding(true);
                var data = {
                    'customerid': state.customerid,
                    'name' : name,
                    'username': username,
                    'email': email,
                    'password': password,
                    'emailsarr': emailsarr
                }
                const headers = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization' : 'Bearer ' + userdata?.token
                    }
                }
                let resp = await axios.post(apiUrl + 'updatecustomer', data, headers).then((res) => {
                    console.log('res >>>', res.data);
                    if(res.data.status == 'success') {
                       navigate('/cms/superadmin/customers-list');
                    } else {
                      alert(res.data.message);
                    }
                } ).catch((err) => {
                    console.log('err >>', err);
                })
                setshowloding(false);
            } else {
                alert('Password and re-password does not match.');
            }
        } else {
            alert('Please fill all required details');
        }
    }

    const fetchadmindetails = async(id) => {
        if(id > 0) {
            setshowloding(true);
            var data = {
                'customerid' : id,
            }
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + userdata?.token
                }
            }
            let resp = await axios.post(apiUrl + 'singlecustomerdetails', data, headers).then((res) => {
                // console.log('res >>>', res.data);
                if(res.data.status == 'success') {
                    setname(res.data.data.name)
                    setemail(res.data.data.email)
                    setusername(res.data.data.username)
                    if(res.data.data.emaillist)  {
                      var dataemails = res.data.data.emaillist;
                      dataemails = JSON.parse(dataemails)
                      if(dataemails.length > 0) {
                        var emailsarr = [];
                        for(var i = 0;i < dataemails.length; i++) {
                          var obj = {};
                          obj['srno'] = i + 1;
                          obj['email'] = dataemails[i];
                          emailsarr.push(obj);
                      }
                      setemaillist(emailsarr);
                    }
                      console.log('dataemails ...', dataemails)
                    }
                } else {
                  alert(res.data.message);
                }
            } ).catch((err) => {
                console.log('err >>', err);
            })
            setshowloding(false);
        }
    }

    const onplusfunction = () => {
    
      var obj = {
        "srno": requestarrRef.current.length + 1,
        "email": ""
      }
      setemaillist(a => [...a,obj ]);
  }
  const onminusfunction = () => {
      console.log('onminusfunction ...');
      if(emaillist.length == 1) {

      } else {
          var newarr = emaillist.slice(0, -1);
          setemaillist(newarr);
      }
      
      
  }

  const changetext = async( index, text) => {
    // console.log('text11 .>',text.name);
    let data = [...requestarrRef.current]
    data[index]['email'] = text
    setemaillist(data);
  }

    
    
    useEffect(() => {
        if(userdata && Object.keys(userdata).length > 0) {
            console.log('state >>>', state.customerid)
            fetchadmindetails(state.customerid);
        } else {
            navigate('/cms/superadmin');
        }
    }, []);
    return (
        <>
            <Overlay showloader={showloding} />
            <div className="HomeMain">
        <div className="sidebar-div">
          <Sidebar name={"superadmin"} />
        </div>
        <div className="main-layout">
          <Navbar name={"superadmin"} />
          <div className="mainDiv">
            <p className="oneplaceHead">EDIT CUSTOMER</p>
            <div class="orderscreen superadminorderscreen">
              <div class="row adminorderrow">
                <div class="col-md-5">
                  <p class="orderscreen-p orderscreen-p1">Name* :</p>
                </div>
                <div class="col-md-7">
                  <input
                    value={name} onChange={(t) => {setname(t.target.value)}}
                    type="text"
                    class="form-control admin-form-control custom-input-height"
                    placeholder="Enter Name"
                  />
                </div>
              </div>
              <div class="row adminorderrow">
                <div class="col-md-5">
                  <p class="orderscreen-p orderscreen-p1">Username* :</p>
                </div>
                <div class="col-md-7">
                  <input
                    value={username} onChange={(t) => {setusername(t.target.value)}}
                    type="text"
                    class="form-control admin-form-control custom-input-height"
                    placeholder="Enter username"
                  />
                </div>
              </div>
              <div class="row adminorderrow">
                <div class="col-md-5">
                  <p class="orderscreen-p orderscreen-p1">Email* :</p>
                </div>
                <div class="col-md-7">
                  <input
                    value={email} onChange={(t) => {setemail(t.target.value)}}
                    type="text"
                    class="form-control admin-form-control custom-input-height"
                    placeholder="Enter Email"
                  />
                </div>
              </div>
              <div class="row adminorderrow">
                <div class="col-md-5">
                  <p class="orderscreen-p orderscreen-p1">Additional Emails :</p>
                </div>
                <div class="col-md-7">
                  <div className="appendingdiv">
                        <button
                          className="plusbox"
                          onClick={(e) => {
                            e.preventDefault();
                            onplusfunction();
                          }}
                        >
                          <img src={plus} className="plusicon" />
                        </button>
                        <button
                          className="plusbox"
                          onClick={() => {
                            onminusfunction();
                          }}
                        >
                          <img src={minus} className="plusicon" />
                        </button>
                      </div>
                      <table className="table table-bordered invoicetable">
                        <thead>
                          <th>Sr no.</th>
                          <th>Email</th>
                        </thead>
                        <tbody id="appendbody">
                          {requestarrRef.current?.map((item, index) => {
                            // console.log('item.productname >>', item['productname']);
                            return (
                              <tr key={index}>
                                <td> {item["srno"]} </td>
                                <td>
                                  <input
                                  value={item['email']}
                                  onChange={(t) => {changetext(index, t.target.value)}}
                                    type="text"
                                    className="form-control tabinput"
                                    placeholder="Enter email"
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                </div>
              </div>
              <div class="row adminorderrow">
                <div class="col-md-5">
                  <p class="orderscreen-p orderscreen-p1">Password* :</p>
                </div>
                <div class="col-md-7">
                  <input
                  value={password} onChange={(t) => {setpassword(t.target.value)}}
                    type={!showpassword ? "password" : "text" }
                    class="form-control admin-form-control custom-input-height"
                    placeholder="Enter Password"
                  />
                  <div className="eyediv" onClick={() => {setshowpassword(!showpassword)}}>
                    {
                        !showpassword ?
                        <img className="hideicon" src={hide} /> :
                        <img className="hideicon" src={view} />
                    }
                    
                  </div>
                </div>
              </div>
              <div class="row adminorderrow">
                <div class="col-md-5">
                  <p class="orderscreen-p orderscreen-p1">Re-password* :</p>
                </div>
                <div class="col-md-7">
                  <input
                    value={repassword} onChange={(t) => {setrepassword(t.target.value)}}
                    type={!showrepassword ? "password" : "text" }
                    class="form-control admin-form-control custom-input-height"
                    placeholder="Enter Re-password"
                  />
                  <div className="eyediv" onClick={() => {setshowrepassword(!showrepassword)}}>
                    {
                        !showrepassword ?
                        <img className="hideicon" src={hide} /> :
                        <img className="hideicon" src={view} />
                    }
                    
                  </div>
                </div>
              </div>
              {

              }
              <button
                onClick={() => {
                //   setisapproved(true);
                createadminpresses();
                }}
                className="submitrfs-btn submitrfs-btn2 create-button"
                style={{
                  backgroundColor: colors.themeBlue,
                  borderColor: colors.themeBlue,
                  float: "right",
                  marginTop: 10,
                  marginBottom: 50,
                }}
              >
                UPDATE
              </button>
            </div>
          </div>
        </div>
      </div>
        </>
      
    );
}

export default EditCustomer