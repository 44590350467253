import React, { useState, useEffect, useRef } from "react";
import "./Web.css";
import { Link } from "react-router-dom";
import Webheader from "../../components/common/Webheader";
import WebFooter from "../../components/common/WebFooter";
import Stars from "../../assets/images/stars.png";
import BarChart from '../../assets/images/bar-chart.png';
import Leftarrow from '../../assets/images/left-arrow.png';
import Rightarrow from '../../assets/images/right-arrow.png';
import AOS from "aos";
import 'aos/dist/aos.css';
import { useParams } from 'react-router-dom';
import Overlay from "../../components/common/Overlay";
import axios from "axios";
import { apiUrl } from "../../config/config";
import { Rating } from 'react-simple-star-rating';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import favicon from '../../assets/images/favicon.png';
import plusicon from '../../assets/images/plus.png';

const WebProductDetails = () => {
  const [showloding, setshowloding] = useState(false);
  const [productdetails, setproductdetails] = useState({});
  const [selectedImage, setSelectedImage] = useState(0);
  const [imagesarr, setimagesarr] = useState([]);
  const [secttionsarr, setsectionsarr] = useState([]);
  const [sectionpage, setsectionpage] = useState(0);
  const [similarproductdetails, setsimilarproductdetails] = useState([]);

  //pagination
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [itemsPerPage, setitemsPerPage] = useState(4);
  const [currentItems, setcurrentItems] = useState(0);
  const [currentpage, setcurrentpage] = useState(0);

  const [name, setname] = useState(null);
  const [companyname, setcompanyname] = useState(null);
  const [email, setemail] = useState(null);
  const [quantity, setquantity] = useState('');

  useEffect(() => {
    if(secttionsarr.length > 0) {
      const newOffset = (0 * itemsPerPage) % secttionsarr.length;
    var catedata = secttionsarr?.slice(newOffset, newOffset + itemsPerPage)
    setcurrentItems(secttionsarr?.slice(newOffset, newOffset + itemsPerPage));
    setpageCount(Math.ceil(secttionsarr?.length / itemsPerPage));
    setItemOffset(newOffset);
    }
    
  }, [itemsPerPage])

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    console.log('event >>', event);
    const newOffset = (event.selected * itemsPerPage) % secttionsarr.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    var catedata = secttionsarr?.slice(newOffset, newOffset + itemsPerPage)
    setcurrentItems(secttionsarr?.slice(newOffset, newOffset + itemsPerPage));
    setpageCount(Math.ceil(secttionsarr?.length / itemsPerPage));
    setItemOffset(newOffset);
  };

  const Items = ({ currentItems }) => {
    // console.log('currentItems11 >>', currentItems);
    return (
      <>
        <div className="productdetails-section4divsubleft">
        {
            sectionpage == 0 ?
            <div className="productdetails-section4divsub1 mb-3" >
                <p className="heatfastnesshead">Documents</p>
                <p className="heatfastnesshead1">Download Here</p>
                <div className="productdetails-documents-btndiv">
                  <Link to={productdetails?.technicalsheet ? JSON.parse(productdetails?.technicalsheet).link : "/contact"} target="_blank" className="productdetails-documentbtn">
                    <p>Technical Data Sheet</p>
                  </Link>
                  <Link to={productdetails?.safetysheet ? JSON.parse(productdetails?.safetysheet).link : "/contact"} target="_blank" className="productdetails-documentbtn">
                    <p>Material Safety Data Sheet</p>
                  </Link>
                </div>
              </div> : null
          }
          {
            currentItems?.map((item, index) => {
              return (
                <>
                  {
                  index % 2 === 0 ?
                  item?.data?.template == "text" ?
                  <div
                      className="productdetails-section4divsub1 mb-3"
                      
                    >
                      <p className="heatfastnesshead">{item?.data.data.subtitle}</p>
                      <p className="heatfastnesshead1">{item?.data.data.title}</p>
                      <p className="heatfastnessdesc">
                      {item?.data.data.description}
                      </p>
                    </div> : 
                    <div
                    className="productdetails-section4divsub1 mb-3"
                    
                  >
                    <p className="heatfastnesshead1 mb-3">
                      {item.data.data.title}
                    </p>
                    {item.data.data?.ratingdata?.map((itm, index) => {
                      var count = 5;
                      if(item.data.data.type == 'rating8') {
                        count = 8;
                      } else {
                        count = 5;
                      }
                      return (
                        <div className="fast-water-div">
                          <p className="fast-water-name">{itm?.name}</p>
                          {
                            item.data.data.type == 'ratingpercentage' ?
                            <p className="fast-water-name">{itm?.ratingvalue}</p> :
                            <Rating readonly initialValue={parseInt(itm?.rating)} iconsCount={count} size={19} fillColor={'#fff'} emptyColor={'#000'} style={{borderRadius: 5}} />
                          }
                          
                        </div>
                      );
                    })}
                  </div>
                  : null }
                </>
              )
            })
          }
        </div>
        <div className="productdetails-section4divsubright">
        {
            currentItems?.map((item, index) => {
              return (
                <>
                  {
                  index % 2 === 1 ?
                  item?.data?.template == "text" ?
                  <div
                      className="productdetails-section4divsub1 mb-3"
                      data-aos="fade-up"
                      data-aos-delay="300"
                    >
                      <p className="heatfastnesshead">{item?.data.data.subtitle}</p>
                      <p className="heatfastnesshead1">{item?.data.data.title}</p>
                      <p className="heatfastnessdesc">
                      {item?.data.data.description}
                      </p>
                    </div> : 
                    <div
                    className="productdetails-section4divsub1 mb-3"
                    
                  >
                    <p className="heatfastnesshead1 mb-3">
                      {item.data.data.title}
                    </p>
                    {item.data.data?.ratingdata?.map((itm, index) => {
                       var count = 5;
                       if(item.data?.data?.type == 'rating8') {
                         count = 8;
                       } else {
                         count = 5;
                       }
                       
                      return (
                        <div className="fast-water-div">
                          <p className="fast-water-name">{itm?.name}</p>
                          {
                            item.data.data.type == 'ratingpercentage' ?
                            <p className="fast-water-name">{itm?.ratingvalue}</p> :
                            <Rating readonly initialValue={parseInt(itm?.rating)} iconsCount={count} size={19} fillColor={'#fff'} emptyColor={'#000'} style={{borderRadius: 5}} />
                          }
                        </div>
                      );
                    })}
                  </div>
                  : null }
                </>
              )
            })
          }
          
        </div>
      </>
    );
  }


  const prevpagefunc = async() => {
    if(currentpage == 0) {

    } else {
      setcurrentpage(currentpage - 1)
      handlePageClick({selected: currentpage - 1})
    }
  }
  const nextpagefunc = async() => {
    console.log('itemOffset >>', itemOffset);
    var totalcount = Math.ceil(secttionsarr.length / itemsPerPage)
    if(currentpage < totalcount - 1) {
      setcurrentpage(currentpage + 1);
      handlePageClick({selected: currentpage + 1})
    } else {
      
    }
  }

  let navigate = useNavigate();
  const handleThumbnailClick = (index) => {
    setSelectedImage(index);
  };
  const fastenedproperties = [
    {label: 'Water', value: '5'},
    {label: 'Ethanol', value: '5'},
    {label: 'Butanol', value: '4'},
    {label: 'Acetone', value: '4'},
    {label: 'Tolune', value: '4'},
    {label: 'M.T.O.', value: '4'},
    {label: 'Linseed Oil', value: '5'},
    {label: 'Acid', value: '5'},
    {label: 'Alkali', value: '5'},
    {label: 'Soap', value: '5'},
  ];
  const lightfastness = [
    {label: '25% Shade', value: '5'},
    {label: '10% Shade', value: '5'},
    {label: '5% Shade', value: '5'},
  ]
  const { id } = useParams();
  const fetchproductdetails = async(id) => {
    setshowloding(true);
    var data = {
        id: id
    }
    const headers = {
      headers: {
        "Content-Type": "application/json"
      },
    };
    let resp = await axios
      .post(apiUrl + "webproductdetails",data, headers)
      .then((res) => {
        console.log('res >>>', res.data.data)
        if (res.data.status == "success") {
            var productsdata = res.data.data;
            setproductdetails(productsdata);
            setsimilarproductdetails(res.data.similarproducts)
            // setimagesarr(productsdata.imagesarr ? JSON.parse(productsdata.imagesarr) : null)
            setimagesarr(productsdata.applicationimages ? JSON.parse(productsdata.applicationimages) : 
            productsdata.imagesarr ? JSON.parse(productsdata.imagesarr) : null);

            if(productsdata.sectionsarr) {
              var catdata = JSON.parse(productsdata.sectionsarr);
              setcurrentItems(catdata.slice(itemOffset, itemsPerPage));
              setpageCount(Math.ceil(catdata.length / itemsPerPage));
              setsectionsarr(catdata)
            } else {
              setsectionsarr(null)
            }
           
            // console.log('productsdata >>', productsdata);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
    setshowloding(false);
  }
  // console.log('similarproductdetails >>', similarproductdetails);
  const gotoproducturl1 = (item) => {
    var url =  '/products/'+item.catslug+'/'+item.subcatslug+'/'+item.threecatslug+'/'+item.productname+'/'+item.applicationid;
    navigate(url);
    fetchproductdetails(item.applicationid)
  }

  const sendenquirybtn = () => {
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight= 0
  }
  const modalclosebtn = () => {
    document.body.style.overflow = 'unset';
  }
  const handleformsubmit = async() => {
    if(name && companyname && email) {
      setshowloding(true);
      var data = {
        name: name,
        companyname: companyname,
        email: email,
        quantity: quantity,
        productname: productdetails?.productname,
        application: productdetails?.name,
        kevincode: productdetails?.kevincode
      }
      console.log('data >>', data);
      const headers = {
        headers: {
          "Content-Type": "application/json"
        },
      };
      let resp = await axios
      .post(apiUrl + "webenquiryformsubmit",data, headers)
      .then((res) => {
        console.log('res >>>', res.data.data)
        if (res.data.status == "success") {
          alert(res.data.message);
          setname('');
          setcompanyname('');
          setemail('');
          setquantity('');
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
    setshowloding(false);
    } else {
      alert('Please fill all required details');
    }
  }
  useEffect(() => {
    fetchproductdetails(id);
    AOS.init();
    AOS.refresh();
  }, []);
  useEffect(() => {
    document.title = "Kevin India"
    const newFavicon = document.createElement('link');
        newFavicon.rel = 'icon';
        newFavicon.href = favicon; // Replace 'new-favicon-url.png' with your new favicon URL
        document.head.appendChild(newFavicon);
        newFavicon.href = favicon;
  }, []);
  return (
    <>
      {Object.keys(productdetails).length > 0 ? (
        <div className="webmainbg">
          <Overlay showloader={showloding} />
          <Webheader />
          <div className="productdetails-breadscumbdiv">
            <div className="pagewidth">
              <p>
                Products / {productdetails?.catname} /{" "}
                {productdetails?.subcatname} /{" "}
                {productdetails?.threecatname !== "All"
                  ? productdetails?.threecatname + "/"
                  : null}{" "}
                <b>{productdetails?.productname}</b>
              </p>
            </div>
          </div>
          <div className="productdetails-section2 mobhide">
            <div className="pagewidth">
              <div className="productdetails-gallerybox">
                {/* tumbnail div */}
                <div className="productdetails-tumbnaildiv">
                  {imagesarr?.length > 0
                    ? imagesarr?.map((item, index) => {
                        var backgroundImageurl = "url(" + item?.link + ")";
                        return (
                          <div
                            className="productdetails-tumbnailbox"
                            style={{ backgroundImage: backgroundImageurl }}
                            onClick={() => {
                              handleThumbnailClick(index);
                            }}
                          ></div>
                        );
                      })
                    : null}
                </div>
                <div className="productdetails-imgdiv">
                  {imagesarr?.length > 0 ? (
                    <div
                      className="productdetails-imgbg"
                      style={{
                        backgroundImage:
                          "url(" + imagesarr[selectedImage].link + ")",
                      }}
                    ></div>
                  ) : (<div
                  className="productdetails-imgbg"
                ></div>)}
                </div>
                <div className="productdetails-gallerybox-rightdiv">
                  <div className="productdetails-gallerybox-rightdiv1">
                    <p className="productdetails-producttitle">
                      {productdetails?.productname}
                    </p>
                    <div className="productdetails-ratingdiv">
                      {/* <img src={Stars} className="starsimg" /> */}
                      <Rating
                        readonly
                        initialValue={productdetails?.rating}
                        size={22}
                        fillColor={"#fff"}
                        emptyColor={"#000"}
                        style={{ borderRadius: 5 }}
                      />
                      <p className="ratingtext">
                        ({productdetails?.rating} / 5;{" "}
                        {productdetails?.ratingcount} Survey)
                      </p>
                    </div>
                    {/* <p className="productdetails-universaltext">{productdetails?.applicationarrarr ? JSON.parse(productdetails?.applicationarrarr)[0].application: null}</p>
                <p className="productdetails-universaltext">{productdetails?.applicationarrarr ? JSON.parse(productdetails?.applicationarrarr)[0].kevincode: null}</p> */}
                    <p className="productdetails-universaltext">
                      {productdetails?.name}
                    </p>
                    <p className="productdetails-universaltext">
                      {productdetails?.kevincode}
                    </p>
                    <p className="productdetails-description">
                      {productdetails?.description}
                    </p>
                    <p className="productsfinder-productdescriptionhead productsfinder-productdescriptionhead1">
                      Tone: Mass and Tinit
                    </p>
                    <div className="productsfinder-tonediv productsfinder-tonediv1">
                      {productdetails?.masstone ? (
                        <div
                          className="productsfinder-tonecircle productsfinder-tonecircle1"
                          style={{
                            backgroundColor: "#" + productdetails?.masstone,
                          }}
                        ></div>
                      ) : null}
                      {productdetails?.masstone ? (
                        <div
                          className="productsfinder-tonecircle productsfinder-tonecircle1"
                          style={{
                            backgroundColor: "#" + productdetails?.tinttone,
                          }}
                        ></div>
                      ) : null}
                    </div>
                    <div className="productdetails-application-div">
                      {/* <div className="productdetails-application-leftdiv">
                    <p>Application:</p>
                    <div className="sortbydiv">
                      <select class="form-select">
                        <option selected disabled>
                          Paint
                        </option>
                        <option>option 1</option>
                        <option>option 2</option>
                        <option>option 3</option>
                        <option>option 4</option>
                      </select>
                    </div>
                  </div> */}
                      <div className="productdetails-application-leftdiv">
                        <p>Est. Qty:</p>
                        <input
                          type="text"
                          value={quantity}
                          onChange={(t) => {setquantity(t.target.value)}}
                          min={1}
                          class="form-control"
                          placeholder="Qty"
                          name="productname"
                        />
                      </div>
                    </div>
                    <button
                      className="productdetails-sendenquiry"
                      onClick={() => {
                        sendenquirybtn();
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#myModal"
                    >
                      Send Enquiry
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* modal */}
          <div class="modal sendenquirymodal" id="myModal">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title">Send Enquiry</h4>
                  {/* <button type="button" onClick={() => {modalclosebtn()}} class="btn-close" data-bs-dismiss="modal"></button> */}
                  <div className="">
                    <img
                      src={plusicon}
                      onClick={() => {
                        modalclosebtn();
                      }}
                      data-bs-dismiss="modal"
                      className="modalplusicon"
                    />
                  </div>
                </div>

                <div class="modal-body">
                  <div className="modalformdiv">
                    <div className="inputdiv">
                      <label class="form-label">Name* :</label>
                      <input
                        value={name}
                        onChange={(t) => setname(t.target.value)}
                        type="text"
                        class="form-control custominput"
                        placeholder="Full Name"
                      />
                    </div>
                    <div className="inputdiv">
                      <label class="form-label">Company Name* :</label>
                      <input
                        value={companyname}
                        onChange={(t) => setcompanyname(t.target.value)}
                        type="text"
                        class="form-control custominput"
                        placeholder="Company Name"
                      />
                    </div>
                    <div className="inputdiv">
                      <label class="form-label">Email* :</label>
                      <input
                        value={email}
                        onChange={(t) => setemail(t.target.value)}
                        type="text"
                        class="form-control custominput"
                        placeholder="Email"
                      />
                    </div>
                    <div className="custominput contactsubmitbtn" data-bs-dismiss="modal" onClick={() => {handleformsubmit()}}>
                        <p className="contactsubmitbtn-text">Enquire Now</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* for mobile */}
          <div className="productdetails-section2 deskhide tablethide">
            <div className="pagewidth">
              <div className="productdetails-gallerybox">
                <div className="productdetails-imgdiv">
                  {imagesarr?.length > 0 ? (
                    <div
                      className="productdetails-imgbg"
                      style={{
                        backgroundImage:
                          "url(" + imagesarr[selectedImage].link + ")",
                      }}
                    ></div>
                  ) : null}
                </div>

                <div className="productdetails-tumbnaildiv">
                  {imagesarr?.length > 0
                    ? imagesarr?.map((item, index) => {
                        var backgroundImageurl = "url(" + item?.link + ")";
                        return (
                          <div
                            className="productdetails-tumbnailbox"
                            style={{ backgroundImage: backgroundImageurl }}
                            onClick={() => {
                              handleThumbnailClick(index);
                            }}
                          ></div>
                        );
                      })
                    : null}
                </div>

                <div className="productdetails-gallerybox-rightdiv">
                  <div className="productdetails-gallerybox-rightdiv1">
                    <p className="productdetails-producttitle">
                      {productdetails?.productname}
                    </p>
                    <div className="productdetails-ratingdiv">
                      {/* <img src={Stars} className="starsimg" /> */}
                      <Rating
                        readonly
                        initialValue={productdetails?.rating}
                        size={22}
                        fillColor={"#fff"}
                        emptyColor={"#000"}
                        style={{ borderRadius: 5 }}
                      />
                      <p className="ratingtext">
                        ({productdetails?.rating} / 5;{" "}
                        {productdetails?.ratingcount} Survey)
                      </p>
                    </div>
                    <p className="productdetails-universaltext">
                      {productdetails?.applicationarrarr
                        ? JSON.parse(productdetails?.applicationarrarr)[0]
                            .application +
                          " " +
                          JSON.parse(productdetails?.applicationarrarr)[0]
                            .kevincode
                        : null}
                    </p>
                    <p className="productdetails-description">
                      {productdetails?.description}
                    </p>
                    <p className="productsfinder-productdescriptionhead productsfinder-productdescriptionhead1">
                      Tone: Mass and Tinit
                    </p>
                    <div className="productsfinder-tonediv productsfinder-tonediv1">
                      {productdetails?.masstone ? (
                        <div
                          className="productsfinder-tonecircle productsfinder-tonecircle1"
                          style={{
                            backgroundColor: "#" + productdetails?.masstone,
                          }}
                        ></div>
                      ) : null}
                      {productdetails?.tinttone ? (
                        <div
                          className="productsfinder-tonecircle productsfinder-tonecircle1"
                          style={{
                            backgroundColor: "#" + productdetails?.tinttone,
                          }}
                        ></div>
                      ) : null}
                    </div>
                    <div className="productdetails-application-div">
                      {/* <div className="productdetails-application-leftdiv">
                    <p>Application:</p>
                    <div className="sortbydiv">
                      <select class="form-select">
                        <option selected disabled>
                          Paint
                        </option>
                        <option>option 1</option>
                        <option>option 2</option>
                        <option>option 3</option>
                        <option>option 4</option>
                      </select>
                    </div>
                  </div> */}
                      <div className="productdetails-application-leftdiv">
                        <p>Est. Qty:</p>
                        <input
                          type="number"
                          class="form-control"
                          placeholder="Qty"
                          name="productname"
                        />
                      </div>
                    </div>
                    <button className="productdetails-sendenquiry">
                      Send Enquiry
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* for tablet */}
          <div className="productdetails-section2 deskhide mobhide tabletshow">
            <div className="pagewidth">
              <div className="productdetails-gallerybox">
                <div className="productdetails-imgdiv">
                  {imagesarr?.length > 0 ? (
                    <div
                      className="productdetails-imgbg"
                      style={{
                        backgroundImage:
                          "url(" + imagesarr[selectedImage].link + ")",
                      }}
                    ></div>
                  ) : null}

                  <div className="productdetails-tumbnaildiv">
                    {imagesarr?.length > 0
                      ? imagesarr?.map((item, index) => {
                          var backgroundImageurl = "url(" + item?.link + ")";
                          return (
                            <div
                              className="productdetails-tumbnailbox"
                              style={{ backgroundImage: backgroundImageurl }}
                              onClick={() => {
                                handleThumbnailClick(index);
                              }}
                            ></div>
                          );
                        })
                      : null}
                  </div>
                </div>

                <div className="productdetails-gallerybox-rightdiv">
                  <div className="productdetails-gallerybox-rightdiv1">
                    <p className="productdetails-producttitle">
                      {productdetails?.productname}
                    </p>
                    <div className="productdetails-ratingdiv">
                      {/* <img src={Stars} className="starsimg" /> */}
                      <Rating
                        readonly
                        initialValue={productdetails?.rating}
                        size={22}
                        fillColor={"#fff"}
                        emptyColor={"#000"}
                        style={{ borderRadius: 5 }}
                      />
                      <p className="ratingtext">
                        ({productdetails?.rating} / 5;{" "}
                        {productdetails?.ratingcount} Survey)
                      </p>
                    </div>
                    <p className="productdetails-universaltext">
                      {productdetails?.applicationarrarr
                        ? JSON.parse(productdetails?.applicationarrarr)[0]
                            .application +
                          " " +
                          JSON.parse(productdetails?.applicationarrarr)[0]
                            .kevincode
                        : null}
                    </p>
                    <p className="productdetails-description">
                      {productdetails?.description}
                    </p>
                    <p className="productsfinder-productdescriptionhead productsfinder-productdescriptionhead1">
                      Tone: Mass and Tinit
                    </p>
                    <div className="productsfinder-tonediv productsfinder-tonediv1">
                      {productdetails?.masstone ? (
                        <div
                          className="productsfinder-tonecircle productsfinder-tonecircle1"
                          style={{
                            backgroundColor: "#" + productdetails?.masstone,
                          }}
                        ></div>
                      ) : null}
                      {productdetails?.tinttone ? (
                        <div
                          className="productsfinder-tonecircle productsfinder-tonecircle1"
                          style={{
                            backgroundColor: "#" + productdetails?.tinttone,
                          }}
                        ></div>
                      ) : null}
                    </div>
                    <div className="productdetails-application-div">
                      {/* <div className="productdetails-application-leftdiv">
                    <p>Application:</p>
                    <div className="sortbydiv">
                      <select class="form-select">
                        <option selected disabled>
                          Paint
                        </option>
                        <option>option 1</option>
                        <option>option 2</option>
                        <option>option 3</option>
                        <option>option 4</option>
                      </select>
                    </div>
                  </div> */}
                      <div className="productdetails-application-leftdiv">
                        <p>Est. Qty:</p>
                        <input
                          type="number"
                          class="form-control"
                          placeholder="Qty"
                          name="productname"
                        />
                      </div>
                    </div>
                    <button className="productdetails-sendenquiry">
                      Send Enquiry
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {similarproductdetails?.length > 0  ? 
            similarproductdetails?.length == 1 ?
             similarproductdetails[0].applicationid !== productdetails.applicationid ?
            <div className="productdetails-section3">
              <div className="productdetails-section3bg">
                <p className="productdetails-producttitle">
                  You might also like
                </p>
                <div className="pagewidth">
                  <div className="productdetails-section3boxdiv">
                    {similarproductdetails?.map((item, index) => {
                      console.log('similarproductdetails item >>', item);
                      // var imagesarr = JSON.parse(item?.imagesarr);
                      if (item?.applicationimages) {
                        var imagesarr = JSON.parse(item?.applicationimages);
                      } else {
                        var imagesarr = JSON.parse(item?.imagesarr);
                      }
                      if(imagesarr?.length > 0) {
                        var backgroundImageurl = "url(" + imagesarr[0].link + ")";
                      } else {
                        var backgroundImageurl = null;
                      }
                      
                      if (item.applicationid == productdetails.applicationid) {
                      } else {
                        return (
                          <div
                            className="productdetails-section3box"
                            data-aos="fade-up"
                            data-aos-delay={index == 0 ? 0 : index * 300}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              gotoproducturl1(item);
                            }}
                          >
                            <div
                              className="productdetails-section3imgbg"
                              style={{ backgroundImage: backgroundImageurl }}
                            ></div>
                            <div className="productdetails-section3descdiv">
                              <p className="productdetails-section3descdiv-p1">
                                {item.productname}
                              </p>
                              <p className="productdetails-section3descdiv-p2">
                                {item.applicationname}
                              </p>
                            </div>
                          </div>
                        );
                      }
                    })}
                    {/* <div className="productdetails-section3box" data-aos="fade-up" data-aos-delay="0">
                <div className="productdetails-section3imgbg"></div>
                <div className="productdetails-section3descdiv">
                  <p className="productdetails-section3descdiv-p1">Pigment Blue 15:3</p>
                  <p className="productdetails-section3descdiv-p2">Plastic </p>
                </div>
              </div>
              <div className="productdetails-section3box" data-aos="fade-up" data-aos-delay="300">
                <div className="productdetails-section3imgbg"></div>
                <div className="productdetails-section3descdiv">
                  <p className="productdetails-section3descdiv-p1">Pigment Blue 15:3</p>
                  <p className="productdetails-section3descdiv-p2">Plastic </p>
                </div>
              </div>
              <div className="productdetails-section3box" data-aos="fade-up" data-aos-delay="600">
                <div className="productdetails-section3imgbg"></div>
                <div className="productdetails-section3descdiv">
                  <p className="productdetails-section3descdiv-p1">Pigment Blue 15:3</p>
                  <p className="productdetails-section3descdiv-p2">Plastic </p>
                </div>
              </div>
              <div className="productdetails-section3box" data-aos="fade-up" data-aos-delay="900">
                <div className="productdetails-section3imgbg"></div>
                <div className="productdetails-section3descdiv">
                  <p className="productdetails-section3descdiv-p1">Pigment Blue 15:3</p>
                  <p className="productdetails-section3descdiv-p2">Plastic </p>
                </div>
              </div> */}
                  </div>
                </div>
              </div>
            </div> : null : 
            <div className="productdetails-section3">
              <div className="productdetails-section3bg">
                <p className="productdetails-producttitle">
                  You might also like
                </p>
                <div className="pagewidth">
                  <div className="productdetails-section3boxdiv">
                    {similarproductdetails?.map((item, index) => {
                      console.log('similarproductdetails item >>', item);
                      // var imagesarr = JSON.parse(item?.imagesarr);
                      if (item?.applicationimages) {
                        var imagesarr = JSON.parse(item?.applicationimages);
                      } else {
                        var imagesarr = JSON.parse(item?.imagesarr);
                      }
                      if(imagesarr?.length > 0) {
                        var backgroundImageurl = "url(" + imagesarr[0].link + ")";
                      } else {
                        var backgroundImageurl = null;
                      }
                      if (item.applicationid == productdetails.applicationid) {
                      } else {
                        return (
                          <div
                            className="productdetails-section3box"
                            data-aos="fade-up"
                            data-aos-delay={index == 0 ? 0 : index * 300}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              gotoproducturl1(item);
                            }}
                          >
                            <div
                              className="productdetails-section3imgbg"
                              style={{ backgroundImage: backgroundImageurl }}
                            ></div>
                            <div className="productdetails-section3descdiv">
                              <p className="productdetails-section3descdiv-p1">
                                {item.productname}
                              </p>
                              <p className="productdetails-section3descdiv-p2">
                                {item.applicationname}
                              </p>
                            </div>
                          </div>
                        );
                      }
                    })}
                    {/* <div className="productdetails-section3box" data-aos="fade-up" data-aos-delay="0">
                <div className="productdetails-section3imgbg"></div>
                <div className="productdetails-section3descdiv">
                  <p className="productdetails-section3descdiv-p1">Pigment Blue 15:3</p>
                  <p className="productdetails-section3descdiv-p2">Plastic </p>
                </div>
              </div>
              <div className="productdetails-section3box" data-aos="fade-up" data-aos-delay="300">
                <div className="productdetails-section3imgbg"></div>
                <div className="productdetails-section3descdiv">
                  <p className="productdetails-section3descdiv-p1">Pigment Blue 15:3</p>
                  <p className="productdetails-section3descdiv-p2">Plastic </p>
                </div>
              </div>
              <div className="productdetails-section3box" data-aos="fade-up" data-aos-delay="600">
                <div className="productdetails-section3imgbg"></div>
                <div className="productdetails-section3descdiv">
                  <p className="productdetails-section3descdiv-p1">Pigment Blue 15:3</p>
                  <p className="productdetails-section3descdiv-p2">Plastic </p>
                </div>
              </div>
              <div className="productdetails-section3box" data-aos="fade-up" data-aos-delay="900">
                <div className="productdetails-section3imgbg"></div>
                <div className="productdetails-section3descdiv">
                  <p className="productdetails-section3descdiv-p1">Pigment Blue 15:3</p>
                  <p className="productdetails-section3descdiv-p2">Plastic </p>
                </div>
              </div> */}
                  </div>
                </div>
              </div>
            </div>
           : null}

          <div className="productdetails-section4">
            <div className="pagewidth">
              <div className="productdetails-section4div">
                <div className="productdetails-section4div1">
                  <div className="productdetails-surveyrating-div">
                    <img src={BarChart} className="barchartimg" />
                    <p className="surveyratinghead">Survey Ratings</p>
                  </div>
                  <div className="productdetails-ratingbox">
                    <p className="productdetails-ratingnumber">
                      {productdetails?.rating}
                    </p>
                    {/* <img src={Stars} className="starsimg1" /> */}
                    <Rating
                      readonly
                      initialValue={productdetails?.rating}
                      size={22}
                      fillColor={"#fff"}
                      emptyColor={"#000"}
                      style={{ borderRadius: 5 }}
                    />
                    <p className="productdetails-ratingcount">
                      {productdetails?.ratingcount} customer
                    </p>
                  </div>
                </div>
                <div className="productdetails-section4div2">
                  <div className="productdetails-surveyrating-div">
                    <img src={BarChart} className="barchartimg" />
                    <p className="surveyratinghead">Physical Properties</p>
                    <div className="productdetails-surveyrating-bardiv">
                      <img
                        src={Leftarrow}
                        onClick={() => {
                          prevpagefunc();
                        }}
                        className="leftarrow"
                      />
                      <img
                        src={Rightarrow}
                        onClick={() => {
                          nextpagefunc();
                        }}
                        className="leftarrow rightarrow"
                      />
                    </div>
                  </div>
                  {secttionsarr?.length > 0 ? (
                    <div className="productdetails-section4divsubdiv">
                      <Items currentItems={currentItems} />
                      {/* <ReactPaginate
                      breakLabel="..."
                      nextLabel=">>"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="<<"
                      renderOnZeroPageCount={null}
                      className="custompaginate"
                    /> */}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <WebFooter />
        </div>
      ) : (
        <Overlay showloader={true} />
      )}
    </>

    // <p>Hello World!!</p>
  );
};

export default WebProductDetails;
