import React, { useState, useEffect, useRef } from "react";
import Webheader from "../../components/common/Webheader";
import "./Web.css";
import WebFooter from "../../components/common/WebFooter";
import {Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './slider.css';

import { EffectCoverflow, Pagination, Navigation } from 'swiper';

import img1 from '../../assets/images/slider/1.png';
import img2 from '../../assets/images/slider/2.png';
import img3 from '../../assets/images/slider/3.png';
import img4 from '../../assets/images/slider/4.png';
import img5 from '../../assets/images/slider/5.png';

import new_certificate1 from '../../assets/images/new_certificate1.jpg';
import new_certificate2 from '../../assets/images/new_certificate2.jpg';
import new_certificate3 from '../../assets/images/new_certificate3.jpg';
import new_certificate4 from '../../assets/images/new_certificate4.jpg';
import new_certificate5 from '../../assets/images/new_certificate5.jpg';
import new_certificate6 from '../../assets/images/new_certificate6.jpg';
import new_certificate7 from '../../assets/images/new_certificate7.jpg';

import Leftarrow from '../../assets/images/left-arrow-dark.png';
import Rightarrow from '../../assets/images/right-arrow-dark.png';

import favicon from '../../assets/images/favicon.png';

const Certfications = () => {
  useEffect(() => {
    document.title = "Kevin India"
    const newFavicon = document.createElement('link');
        newFavicon.rel = 'icon';
        newFavicon.href = favicon; // Replace 'new-favicon-url.png' with your new favicon URL
        document.head.appendChild(newFavicon);
        newFavicon.href = favicon;
  }, []);
    return (
        <div className="webmainbg">
            <Webheader />
            <div className="certficatepagemaindiv">
                <p className="section4head certification-head">Certifications & Awards</p>
                <div className="pagewidth">
                <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: '.swiper-pagination', clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="swiper_container"
      >
        <SwiperSlide>
          <img src={new_certificate1} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={new_certificate2} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={new_certificate3} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={new_certificate4} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={new_certificate5} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={new_certificate6} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={new_certificate7} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={new_certificate1} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={new_certificate2} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={new_certificate3} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={new_certificate4} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={new_certificate5} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={new_certificate6} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={new_certificate7} alt="slide_image" />
        </SwiperSlide>

        <div className="slider-controler">
          <div className="swiper-button-prev slider-arrow">
            {/* <ion-icon name="arrow-back-outline"></ion-icon> */}
            <img src={Leftarrow} />
          </div>
          <div className="swiper-button-next slider-arrow">
            {/* <ion-icon name="arrow-forward-outline"></ion-icon> */}
            <img src={Rightarrow} />
          </div>
          {/* <div className="swiper-pagination"></div> */}
        </div>
      </Swiper>
                </div>
                
            </div>
            <WebFooter />
        </div>
    )
}

export default Certfications;