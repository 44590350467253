import React, { useEffect, useState, useRef } from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import './../../components/tables/tables.css';
import { plus, minus } from "../../config/images";
import { colors } from "../../config/colors";
import { useNavigate } from 'react-router-dom';
import { apiUrl } from "../../config/config";
import axios from 'axios';
import Overlay from "../../components/common/Overlay";

import { useDispatch, useSelector } from "react-redux/es/exports";
import { Alert } from "bootstrap";

const SuperadminNewSample = () => {
  const dispatch = useDispatch(); 
    const userdata = useSelector((store) => store.userReducer.userdata);
  let navigate = useNavigate();
    const obj = {
        "srno": 1,
        "productname": "",
        "quantity": "",
        "application": "",
        "document": "",
    }
    const [requestarr, setrequestarr] = useState([obj]);
    const [issubmitted, setissubmitted] = useState(false);
    const [showloding, setshowloding] = useState(false);
    const [customersdata, setcustomersdata] = useState([]);
    const [selectcustomer, setselectcustomer] = useState(null);

    const requestarrRef = useRef();
    requestarrRef.current = requestarr;
    
    const onplusfunction = () => {
    
        var obj = {
            "srno": requestarr.length + 1,
            "productname": "",
            "quantity": "",
            "application": "",
            "document": "",
        }
        setrequestarr(a => [...a,obj ]);
    }
    const onminusfunction = () => {
        console.log('onminusfunction ...');
        if(requestarr.length == 1) {

        } else {
            var newarr = requestarr.slice(0, -1);
            setrequestarr(newarr);
        }
        
        
    }

    const submitrfs1 = () => {
      navigate('/cms/superadmin/requestsample');  
  }

    const submitrfs = async() => {
      if(requestarrRef.current.length > 0 ) {
        var requestdata = requestarrRef.current
        for(var i = 0;i < requestdata.length; i++) {
          if(requestdata[i].productname.length == 0 || requestdata[i].quantity.length == 0 || requestdata[i].application.length == 0 ){
            alert('Please fill all mandatory fields');
            return;
          } else {
            
          }
        }
      } else {
        alert('Please fill all mandatory fields');
        return;
      }
      
      setshowloding(true);
            var data = JSON.stringify(
              {
                'data': requestarrRef.current,
                'customerid': selectcustomer,
              });
              console.log('data .>', requestarrRef.current)
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + userdata?.token
                }
            }
            let resp = await axios.post(apiUrl + 'superadminsubmitrfs', data, headers).then((res) => {
              // console.log('res >>>', res.data)
                if(res.data.status == 'success') {
                  // dispatch({ type: "userdata", payload: res.data.data })
                  //  navigate('/customer/requestsample');
                  setissubmitted(true);
                } else {
                  alert(res.data.message);
                }
            } ).catch((err) => {
                alert(err.message)
            })
            setshowloding(false);
    }

     const getBase64 = file => {
      return new Promise(resolve => {
        let fileInfo;
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();
  
        // Convert the file to base64 text
        reader.readAsDataURL(file);
  
        // on reader load somthing...
        reader.onload = () => {
          // Make a fileInfo Object
          // console.log("Called", reader);
          baseURL = reader.result;
          // console.log(baseURL);
          resolve(baseURL);
        };
        // console.log(fileInfo);
      });
    };

    const changetext = async(name, index, text) => {
      // console.log('text11 .>',text.name);
      let data = [...requestarrRef.current]
      if(name == 'document') {
        await getBase64(text).then(result => {
          // console.log('result ...', result);
          // base64_decode($base64encodedstring)
          var obj = {};
          obj['name'] = text.name;
          obj['image'] = result;
          data[index][name] = obj;
        });
      } else {
        data[index][name] = text
      }
      setrequestarr(data);
    }

    const fetchcustomers = async() => {
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .get(apiUrl + "customerslist", headers)
        .then((res) => {
          if (res.data.status == "success") {
            setcustomersdata(res.data.data);
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          alert(err.message);
        });
    }


    useEffect(() => {
      if(userdata && Object.keys(userdata).length > 0) {
        fetchcustomers();
      } else {
          navigate('/cms/superadmin/login');
      }
  }, []);



    return (
      <div className="HomeMain">
        <Overlay showloader={showloding} />
        <div className="sidebar-div">
          <Sidebar  name={'superadmin'} />
        </div>
        <div className="main-layout">
          <Navbar name={'superadmin'} />
          <div className="mainDiv">
            {!issubmitted ? (
              <>
                <p className="oneplaceHead">REQUEST FOR SAMPLE</p>
                {/* <form onSubmit={e => {submitrfs(e)}}> */}
                <div className="formdiv">
                  {requestarrRef.current?.length > 0 ? (
                    <div className="formbox mt-4">
                      <div className="appendingdiv">
                        <button
                          className="plusbox"
                          onClick={(e) => {
                            e.preventDefault();
                            onplusfunction();
                          }}
                        >
                          <img src={plus} className="plusicon" />
                        </button>
                        <button
                          className="plusbox"
                          onClick={() => {
                            onminusfunction();
                          }}
                        >
                          <img src={minus} className="plusicon" />
                        </button>
                      </div>
                      <table className="table table-bordered invoicetable">
                        <thead>
                          <th>Sr no.</th>
                          <th>Product Name *</th>
                          <th>Quantity (grams) *</th>
                          <th>Application *</th>
                          <th>Reference Documents </th>
                        </thead>
                        <tbody id="appendbody">
                          {requestarrRef.current?.map((item, index) => {
                            // console.log('item.productname >>', item['productname']);
                            return (
                              <tr key={index}>
                                <td> {item["srno"]} </td>
                                <td>
                                  <input
                                  value={item['productname']}
                                  onChange={(t) => {changetext('productname', index, t.target.value)}}
                                    type="text"
                                    // name={'productname'+index}
                                    className="form-control tabinput"
                                    placeholder="Enter product name"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    value={item['quantity']}
                                    onChange={(t) => {changetext('quantity', index, t.target.value)}}
                                    className="form-control tabinput"
                                    placeholder="Enter quantity"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={item['application']}
                                    onChange={(t) => {changetext('application', index, t.target.value)}}
                                    className="form-control tabinput"
                                    placeholder="Enter application"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="file"
                                    accept="application/pdf"
                                    // value={item['document']}
                                    onChange={(t) => {changetext('document', index, t.target.files[0])}}
                                    className="form-control tabinput"
                                    placeholder="choose reference document"
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div className="row mt-4">
                      <div className="col-md-4 quoteinputdiv1">
                      <label>Select Customer *:</label>
                    <select
                      class="form-select admin-form-control custom-input-height"
                      value={selectcustomer}
                      onChange={(t) => {
                        setselectcustomer(t.target.value);
                      }}
                    >
                      <option disabled selected>
                        select customer
                      </option>
                      {customersdata?.map((item, index) => {
                        return <option value={item?.id}>{item?.name}</option>;
                      })}
                    </select>
                  </div>
                      </div>
                      <button
                        onClick={() => {
                          submitrfs();
                        }}
                        className="submitrfs-btn"
                        style={{
                          backgroundColor: colors.themeBlue,
                          borderColor: colors.themeBlue,
                        }}
                      >
                        Submit RFS
                      </button>
                    </div>
                  ) : null}
                </div>
                {/* </form> */}
              </>
            ) : (
              <>
                <p className="oneplaceHead">THANK YOU FOR SUBMITTING A RFS!</p>
                <p className="oneplaceHead oneplaceHead1">
                  WE WILL KEEP YOU POSTED ONCE THE SAMPLE IS DISPATCHED!
                </p>
                <button
                  onClick={() => {
                    submitrfs1();
                  }}
                  className="submitrfs-btn submitrfs-btn1"
                  style={{
                    backgroundColor: colors.themeBlue,
                    borderColor: colors.themeBlue,
                  }}
                >
                  CLICK HERE TO CHECK RFS STATUS
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    );
}

export default SuperadminNewSample