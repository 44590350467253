import React, {useState, useEffect, useRef} from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import InvoiceTable from "../../components/tables/InvoiceTable";

import { Link } from "react-router-dom";
import { colors } from "../../config/colors";

import { useDispatch, useSelector } from "react-redux/es/exports";
import { useNavigate } from 'react-router-dom';
import { apiUrl } from "../../config/config";
import axios from 'axios';
import Overlay from "../../components/common/Overlay";
import { edit, deleteicon } from "../../config/images";
import {useLocation} from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { plus, minus } from "../../config/images";


const AdminEditRFS = () => {
    const dispatch = useDispatch(); 
    const userdata = useSelector((store) => store.userReducer.userdata);
    let navigate = useNavigate();
    const { id } = useParams();
    const [showloding, setshowloding] = useState(false);
    const [requestsampledata, setrequestsampledata] = useState([]);
    const [name, setname] = useState(null);
    const [quantity, setquantity] = useState(null);
    const [application, setapplication] = useState(null);

    const obj = {
      "srno": 1,
      "productname": "",
      "quantity": "",
      "application": "",
      "document": "",
      "refno": "",
      "deliverystatus": "",
      "dateofdispatch": "",
      "admindocs": ""
  }

  const [requestarr, setrequestarr] = useState([obj]);
  const requestarrRef = useRef();
    requestarrRef.current = requestarr;

    const onplusfunction = () => {
    
      var obj = {
        "srno": 1,
        "productname": "",
        "quantity": "",
        "application": "",
        "document": "",
        "refno": "",
        "deliverystatus": "",
        "dateofdispatch": "",
        "admindocs": ""
    }
      setrequestarr(a => [...a,obj ]);
  }
  const onminusfunction = () => {
      console.log('onminusfunction ...');
      if(requestarr.length == 1) {

      } else {
          var newarr = requestarr.slice(0, -1);
          setrequestarr(newarr);
      }
      
      
  }

    const fetchrequestsampledata = async(id) => {
        setshowloding(true);
        var data = {
            'rfsid' : id,
        }
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + userdata?.token
            }
        }
        let resp = await axios.post(apiUrl + 'adminfetchrfssingle',data, headers).then((res) => {
            // console.log('res >>>', res.data)
              if(res.data.status == 'success') {
                setrequestsampledata(res.data.data);
                setrequestarr(JSON.parse(res.data.data[0].samplesarr))
                setname(res.data.data[0].productname);
                setquantity(res.data.data[0].quantity);
                setapplication(res.data.data[0].application);
                // console.log('res.data.data ..', res.data.data[0].productname)
                // dispatch({ type: "userdata", payload: res.data.data })
                //  navigate('/customer/requestsample');
              } else {
                alert(res.data.message);
              }
          } ).catch((err) => {
              alert(err.message)
          })
        setshowloding(false)
    }

    const rfssamplereply = async(files) => {
        // if(name && quantity && application) {
            setshowloding(true);
        var data = {
            'refsid': id,
            'samplesarr': requestarrRef.current
            // 'productname': name,
            // 'quantity': quantity,
            // 'application': application
          };
          console.log('data .>', data)
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + userdata?.token
            }
        }
        let resp = await axios.post(apiUrl + 'adminupdaterfs', data, headers).then((res) => {
          // console.log('res >>>', res.data)
            if(res.data.status == 'success') {
              // dispatch({ type: "userdata", payload: res.data.data })
               navigate('/cms/admin/requestsample');
            } else {
              alert(res.data.message);
            }
        } ).catch((err) => {
            alert(err.message)
        })
        setshowloding(false);
        // } else {
        //     alert('Please fill all required details.')
        // }
        
      }

      const getBase64 = file => {
        return new Promise(resolve => {
          let fileInfo;
          let baseURL = "";
          // Make new FileReader
          let reader = new FileReader();
    
          // Convert the file to base64 text
          reader.readAsDataURL(file);
    
          // on reader load somthing...
          reader.onload = () => {
            // Make a fileInfo Object
            // console.log("Called", reader);
            baseURL = reader.result;
            // console.log(baseURL);
            resolve(baseURL);
          };
          // console.log(fileInfo);
        });
      };

      const changetext = async(name, index, text) => {
        // console.log('text11 .>',text.name);
        let data = [...requestarrRef.current]
        if(name == 'document') {
          await getBase64(text).then(result => {
            // console.log('result ...', result);
            // base64_decode($base64encodedstring)
            var obj = {};
            obj['name'] = text.name;
            obj['image'] = result;
            data[index][name] = obj;
          });
        } else {
          data[index][name] = text
        }
        setrequestarr(data);
      }

      const deleterfs = async(id, index) => {
        if(window.confirm("Are you sure you want to delete this sample")) {
        setshowloding(true);
        var data = {
            'rfsid' : id,
            'index' : index
        }
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + userdata?.token
            }
        }
        let resp = await axios.post(apiUrl + 'admindeleterfssingle',data, headers).then((res) => {
            // console.log('res >>>', res.data)
              if(res.data.status == 'success') {
                fetchrequestsampledata(id);
                // setrequestsampledata(res.data.data);
                // dispatch({ type: "userdata", payload: res.data.data })
                //  navigate('/cms/customer/requestsample');
              } else {
                alert(res.data.message);
              }
          } ).catch((err) => {
              alert(err.message)
          })
        setshowloding(false)
    } else {
        return
    }
    }

    useEffect(() => {
        if(userdata && Object.keys(userdata).length > 0) {
            fetchrequestsampledata(id);
        } else {
            navigate('/cms/admin/login');
        }
    }, []);

    
    return (
      <div className="HomeMain">
        <Overlay showloader={showloding} />
        <div className="sidebar-div">
          <Sidebar name={'Admin'} />
        </div>
        <div className="main-layout">
        <Navbar name={'Admin'} />
            <div className="mainDiv">
            <p className="oneplaceHead">UPDATE RFS</p>
            <div class="orderscreen ">
            {requestsampledata[0]?.note !== null ? 
            <p style={{textAlign: 'right', marginBottom: 10}}>*Edited by superadmin</p>  : null
          }

              {/* <div class="row adminorderrow mt-3">
                <div class="col-md-4">
                  <p class="orderscreen-p orderscreen-p1">Name * :</p>
                </div>
                <div class="col-md-8">
                  <input
                    value={name} onChange={(t) => {setname(t.target.value)}}
                    type="text"
                    class="form-control admin-form-control custom-input-height"
                    placeholder="Name"
                  />
                </div>
              </div>
              <div class="row adminorderrow">
                <div class="col-md-4">
                  <p class="orderscreen-p orderscreen-p1">Quantity (grams) * :</p>
                </div>
                <div class="col-md-8">
                  <input
                    value={quantity} onChange={(t) => {setquantity(t.target.value)}}
                    type="number"
                    class="form-control admin-form-control custom-input-height"
                    placeholder="Quantity"
                  />
                </div>
              </div>
              <div class="row adminorderrow">
                <div class="col-md-4">
                  <p class="orderscreen-p orderscreen-p1">Application * :</p>
                </div>
                <div class="col-md-8">
                  <input
                    value={application} onChange={(t) => {setapplication(t.target.value)}}
                    type="text"
                    class="form-control admin-form-control custom-input-height"
                    placeholder="Application"
                  />
                </div>
              </div> */}
              {console.log('requestarrRef.current >>', requestarrRef.current)}
              {requestarrRef.current?.length > 0 ? (
                    <div className="formbox mt-4">
                      <div className="appendingdiv">
                        <button
                          className="plusbox"
                          onClick={(e) => {
                            e.preventDefault();
                            onplusfunction();
                          }}
                        >
                          <img src={plus} className="plusicon" />
                        </button>
                        <button
                          className="plusbox"
                          onClick={() => {
                            onminusfunction();
                          }}
                        >
                          <img src={minus} className="plusicon" />
                        </button>
                      </div>
                      <table className="table table-bordered invoicetable">
                        <thead>
                          <th>Sr no.</th>
                          <th>Product Name *</th>
                          <th>Quantity (grams) *</th>
                          <th>Application *</th>
                          <th>Reference Documents </th>
                          <th>Delete</th>
                        </thead>
                        <tbody id="appendbody">
                          {requestarrRef.current?.map((item, index) => {
                            // console.log('item.productname >>', item['productname']);
                            return (
                              <tr key={index}>
                                <td> {item["srno"]} </td>
                                <td>
                                  <input
                                  value={item['productname']}
                                  onChange={(t) => {changetext('productname', index, t.target.value)}}
                                    type="text"
                                    // name={'productname'+index}
                                    className="form-control tabinput"
                                    placeholder="Enter product name"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    value={item['quantity']}
                                    onChange={(t) => {changetext('quantity', index, t.target.value)}}
                                    className="form-control tabinput"
                                    placeholder="Enter quantity"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={item['application']}
                                    onChange={(t) => {changetext('application', index, t.target.value)}}
                                    className="form-control tabinput"
                                    placeholder="Enter application"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="file"
                                    accept="application/pdf"
                                    // value={item['document']}
                                    onChange={(t) => {changetext('document', index, t.target.files[0])}}
                                    className="form-control tabinput"
                                    placeholder="choose reference document"
                                  />
                                </td>
                                <td>
                                  <div className="displayedittable">
                                    <div onClick={() => {deleterfs(requestsampledata[0].id, index)}}>
                                      <img src={deleteicon} className="editicon" />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  ) : null}
              <button
                onClick={() => {
                    rfssamplereply()
                }}
                className="submitrfs-btn submitrfs-btn2 create-button"
                style={{
                  backgroundColor: colors.themeBlue,
                  borderColor: colors.themeBlue,
                  float: "right",
                  marginTop: 10,
                  marginBottom: 50,
                }}
              >
                UPDATE
              </button>
            </div>
            </div>
        </div>
      </div>
    );
}

export default AdminEditRFS