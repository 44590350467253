import React, {useEffect, useState} from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import InvoiceTable from "../../components/tables/InvoiceTable";

import { useDispatch, useSelector } from "react-redux/es/exports";
import { useNavigate } from 'react-router-dom';
import { apiUrl } from "../../config/config";
import axios from 'axios';
import Overlay from "../../components/common/Overlay";
import { Link } from "react-router-dom";

const CustomerHome = () => {
    const dispatch = useDispatch(); 
    let navigate = useNavigate();
    const [showloding, setshowloding] = useState(false);
    const [requestsampledata, setrequestsampledata] = useState([]);
    const [quotedata, setquotedata] = useState([]);
    const [requestpfidata, setrequestpfidata] = useState([]);
    const userdata = useSelector((store) => store.userReducer.userdata);
    const fetchrequestsampledata = async() => {
        setshowloding(true);
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + userdata?.token
            }
        }
        let resp = await axios.get(apiUrl + 'customerfetchrfs', headers).then((res) => {
            // console.log('res >>>', res.data)
              if(res.data.status == 'success') {
                setrequestsampledata(res.data.data);
                // dispatch({ type: "userdata", payload: res.data.data })
                //  navigate('/cms/customer/requestsample');
              } else {
                alert(res.data.message);
              }
          } ).catch((err) => {
              alert(err.message)
          })
        setshowloding(false)
    }
    const fetchrequestquotedata = async() => {
        setshowloding(true);
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + userdata?.token
            }
        }
        let resp = await axios.get(apiUrl + 'customerfetchrfq', headers).then((res) => {
            // console.log('res >>>', res.data)
              if(res.data.status == 'success') {
                setquotedata(res.data.data);
                // dispatch({ type: "userdata", payload: res.data.data })
                //  navigate('/cms/customer/requestsample');
              } else {
                alert(res.data.message);
              }
          } ).catch((err) => {
              alert(err.message)
          })
        setshowloding(false)
    }
    const fetchrequestinvoicedata = async() => {
        setshowloding(true);
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + userdata?.token
            }
        }
        let resp = await axios.get(apiUrl + 'customerfetchrfi', headers).then((res) => {
            // console.log('res >>>', res.data)
              if(res.data.status == 'success') {
                setrequestpfidata(res.data.data);
                // dispatch({ type: "userdata", payload: res.data.data })
                //  navigate('/cms/customer/requestsample');
              } else {
                alert(res.data.message);
              }
          } ).catch((err) => {
              alert(err.message)
          })
        setshowloding(false)
    }
    const clickproductname = (id) => {
        navigate('/cms/customer/reply-for-sample/'+id);
    }
    const clickproductname1 = (id) => {
        navigate('/cms/customer/reply-for-quote/'+id);
    }
    const clickproformainvoice = async(id) => {
        navigate('/cms/customer/ordertracking/'+id);
    }
    const invoicedata = [
        {
            'Sr. No.' : '1',
            'PFI No.' : 'KDCPL/001/23-24',
            'PFI Date': '01/04/2023',
            'PO No.': 'XXX XXX XXX',
            'Status': 'Complete'
        }, 
        {
            'Sr. No.' : '2',
            'PFI No.' : 'KDCPL/002/23-24',
            'PFI Date': '01/04/2023',
            'PO No.': 'XXX XXX XXX',
            'Status': 'In Process'
        },
        {
            'Sr. No.' : '3',
            'PFI No.' : 'KDCPL/003/23-24',
            'PFI Date': '01/04/2023',
            'PO No.': 'XXX XXX XXX',
            'Status': 'Complete'
        }
    ];
    const fetchhomedata = async() => {
        setshowloding(true);
        await fetchrequestinvoicedata();
        await fetchrequestquotedata();
        await fetchrequestsampledata();
        setshowloding(false);
    }
    useEffect(() => {
        if(userdata && Object.keys(userdata).length > 0) {
            fetchhomedata();
        } else {
            navigate('/cms/customer/login');
        }
    }, []);
    return (
      <div className="HomeMain">
        <Overlay showloader={showloding} />
        <div className="sidebar-div">
          <Sidebar name={'Kiran Nandi'} />
        </div>
        <div className="main-layout">
            <Navbar />
            <div className="mainDiv">
                <p className="oneplaceHead">ONE PLACE FOR EVERYTHING KEVIN</p>
                {/* <InvoiceTable title={'Proforma Invoices'} data={invoicedata} showbtn={false} /> */}
                {/* <InvoiceTable title={'Request for Quotes'} data={Quotesdata} showbtn={true} />
                <InvoiceTable title={'Request for Samples'} data={Samplesdata} showbtn={true} /> */}
                <p className="proformaHead">Proforma Invoices</p>
                {
                requestpfidata?.length > 0 ?
                <table class="table table-bordered invoicetable">
                <thead>
                    <th>Sr no.</th>
                    <th>PFI No.</th>
                    <th>PFI Date</th>
                    <th>PO No.</th>
                    <th>Status</th>
                    {/* <th>Settings</th> */}
                </thead>
                <tbody>
                    {
                        requestpfidata?.map((item, index) => {
                            // console.log('item ..', item)
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>
                                  <div
                                    onClick={() => {
                                      clickproformainvoice(item.id);
                                    }}
                                  >
                                    <Link>{item.pfinumber}</Link>
                                  </div>
                                </td>
                                <td>{item.pfidate}</td>
                                <td>{item.ponumber}</td>
                                <td>
                        {
                        item?.is_rectification ?
                        <div onClick={() => {clickproformainvoice(item.id)}} className="statusdiv" style={{backgroundColor: '#ffde59', borderColor: 'red', borderWidth: 3, borderStyle: 'solid'}}>
                          Request rectification
                        </div>
                        : item.adminstatus == -1 ? (
                          <div
                            onClick={() => {
                              clickproformainvoice(item.id);
                            }}
                            className="statusdiv"
                            // style={{ backgroundColor: "#4fb293" }}
                            style={{backgroundColor: "#4fb293"}}
                          >
                            Complete
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              clickproformainvoice(item.id);
                            }}
                            className="statusdiv"
                            style={{ backgroundColor: "#ffde59" }}
                          >
                            {item.adminstatus == 1 ? 'Proforma Invoice' : item.adminstatus == 2 ? 'Pre-shipment Sample' : item.adminstatus == 3 ? 'Draft Shipping Documents' : 'Final Shipping Documents'}
                          </div>
                        )}
                      </td>
                                {/* <td>
                                  <div className="displayedittable">
                                  {
                                    item.status == 'accepted' ?
                                    <div onClick={() => {deleterfq(item.id)}}>
                                      <img src={deleteicon} className="editicon" />
                                    </div> :
                                    <div onClick={() => {clickproductnamerfq1(item.id)}}>
                                        <img src={edit} className="editicon" />
                                    </div>
                                  }
                                    
                                  </div>
                                </td> */}
                              </tr>
                            );
                        })
                    }
                </tbody>
            </table> : 
            <div className="">
                <p style={{textAlign: 'center'}}>No invoices yet.</p>
            </div>
            }
                <p className="proformaHead">Request for Quotes</p>
                            {
                quotedata?.length > 0 ?
                <table class="table table-bordered invoicetable">
                <thead>
                    <th>Sr no.</th>
                    <th>Product Name</th>
                    <th>Kevin Code</th>
                    <th>Ref. No.</th>
                    <th>Application</th>
                    <th>Validity</th>
                    <th>RFQ No.</th>
                    <th>Status</th>
                </thead>
                <tbody>
                    {
                        quotedata?.map((item, index) => {
                            // console.log('item ..', item)
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{item.productname}</td>
                                <td>{item.kevincode}</td>
                                <td>{item.reference}</td>
                                <td>{item.application}</td>
                                <td>{item.validity1}</td>
                                <td>
                                    {
                                        item.price ?
                                        <div className="displayedittable" onClick={() => {clickproductname1(item.id, item.status)}}>
                                            <Link >
                                                {item.rfqnumber}
                                            </Link>
                                        </div>
                                        : item.rfqnumber
                                    }
                                    {/* {item.rfsnumber} */}
                                </td>
                                <td>
                                {
                                        item.price ?
                                        <div onClick={() => {clickproductname1(item.id, item.status)}} className="statusdiv" style={item.status == 'pending' ? {backgroundColor: '#ffde59'} : item.status == 'accepted' ? {backgroundColor: '#4fb293'} : item.status == 'feedback pending' ? {backgroundColor: '#4fb293'} : item.status == 'revised quote' ? {backgroundColor: '#ff3131'} :  null}>
                                        {item.status}
                                    </div> : 
                                    <div className="statusdiv" style={item.status == 'pending' ? {backgroundColor: '#ffde59', cursor: 'unset'} : item.status == 'accepted' ? {backgroundColor: '#4fb293'} : item.status == 'feedback pending' ? {backgroundColor: '#4fb293'} : item.status == 'revised quote' ? {backgroundColor: '#ff3131'} :  null}>
                                    {item.status}
                                </div>
                                }
                                    
                                </td>
                              </tr>
                            );
                        })
                    }
                </tbody>
            </table> : 
            <div className="">
                {/* <p style={{textAlign: 'center'}}>No quotes yet.</p> */}
            </div>
            }
            <br />
            <p className="proformaHead">Request for Samples</p>
                {
                requestsampledata?.length > 0 ?
                <table class="table table-bordered invoicetable">
                <thead>
                    <th>Sr no.</th>
                    <th>Product Name</th>
                    <th>Application</th>
                    <th>Ref. No.</th>
                    <th>Delivery Status</th>
                    <th>RFS No.</th>
                    <th>Status</th>
                </thead>
                <tbody>
                    {
                        requestsampledata?.map((item, index) => {
                            // console.log('item ..', item)
                            var samplesarr = JSON.parse(item?.samplesarr);
                            console.log('samplesarr >>', samplesarr[0]);
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td><ol type="a">
                                        {
                                            samplesarr?.map((itm, ind) => {
                                                return <li style={{textAlign: 'left', paddingLeft: 5}}>{itm?.productname}</li>
                                            })
                                        }
                                    </ol></td>
                                <td>{samplesarr[0].application}</td>
                                <td>{samplesarr[0].referencenumber ? samplesarr[0].referencenumber : '-'}</td>
                                <td>{samplesarr[0].deliverystatus ? samplesarr[0].deliverystatus : '-'}</td>
                                <td>
                                    {
                                        // item.adminsamples ?
                                        item.status == 'revised sample' ?
                                        item.revisedocument ?
                                        <div className="displayedittable" onClick={() => {clickproductname(item.id)}}>
                                            <Link >
                                                {item.rfsnumber}
                                            </Link>
                                        </div> :
                                        item.rfsnumber
                                       : <div className="displayedittable" onClick={() => {clickproductname(item.id)}}>
                                            <Link >
                                                {item.rfsnumber}
                                            </Link>
                                        </div>
                                        // : item.rfsnumber
                                    }
                                    {/* {item.rfsnumber} */}
                                </td>
                                <td>
                                {
                                        // item.adminsamples ?
                                        item.status == 'revised sample' ?
                                        item.revisedocument ?
                                        <div onClick={() => {clickproductname(item.id)}} className="statusdiv" style={item.status == 'pending' ? {backgroundColor: '#ffde59'} : item.status == 'accepted' ? {backgroundColor: '#4fb293'} : item.status == 'feedback pending' ? {backgroundColor: '#4fb293'} : item.status == 'revised sample' ? {backgroundColor: '#ff3131'} :  null}>
                                        {item.status}
                                    </div> :
                                        <div className="statusdiv" style={item.status == 'pending' ? {backgroundColor: '#ffde59', cursor: 'unset'} : item.status == 'accepted' ? {backgroundColor: '#4fb293'} : item.status == 'feedback pending' ? {backgroundColor: '#4fb293'} : item.status == 'revised sample' ? {backgroundColor: '#ff3131'} :  null}>
                                        {item.status}
                                    </div>
                                       : <div onClick={() => {clickproductname(item.id)}} className="statusdiv" style={item.status == 'pending' ? {backgroundColor: '#ffde59'} : item.status == 'accepted' ? {backgroundColor: '#4fb293'} : item.status == 'feedback pending' ? {backgroundColor: '#4fb293'} : item.status == 'revised sample' ? {backgroundColor: '#ff3131'} :  null}>
                                       {item.status}
                                   </div>
                                    //     : <div className="statusdiv" style={item.status == 'pending' ? {backgroundColor: '#ffde59', cursor: 'unset'} : item.status == 'accepted' ? {backgroundColor: '#4fb293'} : item.status == 'feedback pending' ? {backgroundColor: '#4fb293'} : item.status == 'revised sample' ? {backgroundColor: '#ff3131'} :  null}>
                                    //     {item.status}
                                    // </div>
                                    }
                                    
                                </td>
                              </tr>
                            );
                        })
                    }
                </tbody>
            </table> : 
            <div className="">
                {/* <p style={{textAlign: 'center'}}>No request yet.</p> */}
            </div>
            }

            </div>
        </div>
      </div>
    );
}

export default CustomerHome