import React, { useEffect, useState } from "react";
import InvoiceTable from "../../components/tables/InvoiceTable";

import { Link } from "react-router-dom";
import { colors } from "../../config/colors";

import { useDispatch, useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../../config/config";
import axios from "axios";
import Overlay from "../../components/common/Overlay";
import { edit, deleteicon } from "../../config/images";
import WebNavbar from "../../components/common/WebNavbar";
import WebSidebar from "../../components/common/WebSidebar";
import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const WebAdminApplicationTags = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const userdata = useSelector((store) => store.userReducer.userdata);
  const [showloding, setshowloding] = useState(false);
  const [categoriesdata, setcategoriesdata] = useState([]);
  const [showmodal, setshowmodal] = useState(false);
  const [editshowmodal, seteditshowmodal] = useState(false);
  const [categoryname, setcategoryname] = useState(null);
  const [orderid, setorderid] = useState(null);
  const [selectedid, setselectedid] = useState(0);
  const [showsavebutton, setshowsavebutton] = useState(false);

  const deletecategory = async (id) => {
    if (window.confirm("Are you sure you want to delete this category")) {
      setshowloding(true);
      var data = {
        id: id,
      };
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "deleteapplicationtag", data, headers)
        .then((res) => {
          if (res.data.status == "success") {
            fetchdata();
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          alert(err.message);
        });
      setshowloding(false);
    } else {
      return;
    }
  };

  const submithandle = async () => {
    if (categoryname) {
      setshowloding(true);
      var data = {
        name: categoryname,
      };
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "addnewpopularapplicationtag", data, headers)
        .then((res) => {
          // console.log('res >>>', res.data)
          if (res.data.status == "success") {
            setshowmodal(false);
            setcategoryname("");
            fetchdata();
            setshowloding(false);
          } else {
            alert(res.data.message);
            setshowloding(false);
          }
        })
        .catch((err) => {
          setshowloding(false);
          alert(err.message);
        });
      setshowloding(false);
    } else {
      alert("Empty Field");
    }
  };

  const submithandle1 = async() => {
    if (categoryname) {
        setshowloding(true);
        var data = {
            id: selectedid,
            name: categoryname,
        };
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userdata?.token,
          },
        };
        let resp = await axios
          .post(apiUrl + "editapplicationtag", data, headers)
          .then((res) => {
            // console.log('res >>>', res.data)
            if (res.data.status == "success") {
              seteditshowmodal(false);
              setselectedid(0);
              setcategoryname("");
              fetchdata();
              setshowloding(false);
            } else {
              alert(res.data.message);
              setshowloding(false);
            }
          })
          .catch((err) => {
            setshowloding(false);
            alert(err.message);
          });
        setshowloding(false);
      } else {
        alert("Empty Field");
      }
  }

  const editcategoryname = async(id, name) => {
    setselectedid(id);
    setcategoryname(name);
    seteditshowmodal(true);
  }

  const fetchdata = async () => {
    setshowloding(true);
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userdata?.token,
      },
    };
    let resp = await axios
      .get(apiUrl + "getapplicationtagslist", headers)
      .then((res) => {
        // console.log('res >>>', res.data)
        if (res.data.status == "success") {
          setcategoriesdata(res.data.data);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
    setshowloding(false);
  };


  const [rows, setRows] = useState([
    { id: 1, name: 'Kiran' },
    { id: 2, name: 'Manohar' },
    { id: 3, name: 'Nandi' },
    // Add more rows as needed
  ]);

  const [draggedIndex, setDraggedIndex] = useState(null);

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (index) => {
    if (draggedIndex === null || draggedIndex === index) {
      return;
    }

    const updatedRows = [...categoriesdata];
    const draggedRow = updatedRows[draggedIndex];
    updatedRows.splice(draggedIndex, 1);
    updatedRows.splice(index, 0, draggedRow);

    console.log('updatedRows >>', updatedRows);

    setcategoriesdata(updatedRows);
    setDraggedIndex(index);
  };

  const handleDragEnd = () => {
    setshowsavebutton(true);
    setDraggedIndex(null);
  };

  const setprefernceapihit = async() => {
    setshowloding(false);
    var data = {'data' : categoriesdata};
    const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "orderapplicationtags", data, headers)
        .then((res) => {
          // console.log('res >>>', res.data)
          if (res.data.status == "success") {
            setshowsavebutton(false);
            fetchdata();
            setshowloding(false);
          } else {
            alert(res.data.message);
            setshowloding(false);
          }
        })
        .catch((err) => {
          setshowloding(false);
          alert(err.message);
        });
      setshowloding(false);
  }

  useEffect(() => {
    if (userdata && Object.keys(userdata).length > 0) {
      fetchdata();
    } else {
      navigate("/WebAdminlogin");
    }
  }, []);
  return (
    <div className="HomeMain">
      <Overlay showloader={showloding} />
      <div className="sidebar-div">
        <WebSidebar name={"Admin"} />
      </div>
      <div className="main-layout">
        <WebNavbar name={"Admin"} />
        <div className="mainDiv">
          <p className="oneplaceHead">POPULAR APPLICATION TAGS</p>
          <div className="tablebuttondiv tablebuttondivpage">
            {
                showsavebutton ?
                <Link
              onClick={() => {
                setprefernceapihit();
              }}
              className="btndiv webadminmaindiv"
              style={{marginRight: 10}}
            >
              <p>{"Save"}</p>
            </Link> :
            null
            }
          
            <Link
              onClick={() => {
                setcategoryname("");
                setshowmodal(true);
              }}
              className="btndiv webadminmaindiv"
            >
              <p>{"Add Application Tag"}</p>
            </Link>
          </div>
          {categoriesdata?.length > 0 ? (
            <table class="table table-bordered invoicetable">
              <thead>
                <th>Sr no.</th>
                <th>Name</th>
                <th>Preference Id</th>
                <th>Settings</th>
              </thead>
              <tbody>
                {categoriesdata?.map((item, index) => {
                  return (
                    <tr key={item.id}
                    onDragStart={() => handleDragStart(index)}
                    onDragOver={() => handleDragOver(index)}
                    onDragEnd={handleDragEnd}
                    draggable>
                      <td>{index + 1}</td>
                      <td>{'#'+item.name}</td>
                      <td>{item.preferenceid}</td>
                      <td>
                        <div className="displayedittable">
                          <div
                            onClick={() => {
                              editcategoryname(item.id, item.name);
                            }}
                          >
                            <img src={edit} className="editicon" />
                          </div>
                          <div
                            onClick={() => {
                                deletecategory(item.id);
                            }}
                          >
                            <img src={deleteicon} className="editicon" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="">
              <p style={{ textAlign: "center" }}>No data yet.</p>
            </div>
          )}

          {/*Add modal */}
          <Modal
            show={showmodal}
            onHide={() => setshowmodal(false)}
            size="md"
            className="custom-width-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Hash Tag</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="webadmin-modalmainbody">
                <div className="">
                  <label>Tag</label>
                  <input
                    value={categoryname}
                    onChange={(t) => {
                      setcategoryname(t.target.value);
                    }}
                    type="text"
                    className="form-control tabinput"
                    placeholder="Enter Tag Without Hash"
                  />
                </div>
                <div
                  className="webadmin-modalbtn"
                  onClick={() => {
                    submithandle();
                  }}
                >
                  <p>Submit</p>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/*Edit modal */}
          <Modal
            show={editshowmodal}
            onHide={() => seteditshowmodal(false)}
            size="md"
            className="custom-width-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Main Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="webadmin-modalmainbody">
                <div className="">
                  <label>Name</label>
                  <input
                    value={categoryname}
                    onChange={(t) => {
                      setcategoryname(t.target.value);
                    }}
                    type="text"
                    className="form-control tabinput"
                    placeholder="Enter Category name"
                  />
                </div>
                <div
                  className="webadmin-modalbtn"
                  onClick={() => {
                    submithandle1();
                  }}
                >
                  <p>Update</p>
                </div>
              </div>
            </Modal.Body>
          </Modal>


          {/* Draggable */}
          {/* <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr
            key={row.id}
            onDragStart={() => handleDragStart(index)}
            onDragOver={() => handleDragOver(index)}
            onDragEnd={handleDragEnd}
            draggable
          >
            <td>{row.id}</td>
            <td>{row.name}</td>
          </tr>
        ))}
      </tbody>
    </table> */}
        </div>
      </div>
    </div>
  );
};

export default WebAdminApplicationTags;
