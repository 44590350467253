import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import InvoiceTable from "../../components/tables/InvoiceTable";
import { pdf } from "../../config/images";
import { colors } from "../../config/colors";

import { useDispatch, useSelector } from "react-redux/es/exports";
import { apiUrl } from "../../config/config";
import axios from 'axios';
import Overlay from "../../components/common/Overlay";
import { edit, deleteicon } from "../../config/images";
import {useLocation} from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';


const SuperAdminRFSSample = () => {
    const [isapproved, setisapproved] = useState(false);
    const [showcomment, setshowcomment] = useState(false);
    const [isrejected, setisrejected] = useState(false);
    const [showloding, setshowloding] = useState(false);
    const [requestsampledata, setrequestsampledata] = useState([]);
    const [samplesarr, setsamplesarr] = useState([]);
    const [refnumber, setrefnumber] = useState(null);
    const [deliverystatus, setdeliverystatus] = useState(null);
    const [dispatchdate, setdispatchdate] = useState(null);
    const [documentsarr, setdocumentsarr] = useState([]);
    const [comment, setcomment] = useState(null);
    const [document, setdocument] = useState(null);
    const dispatch = useDispatch(); 
    const userdata = useSelector((store) => store.userReducer.userdata);
    let navigate = useNavigate();
    const { id } = useParams();
    const documentsarrRef = useRef();
    documentsarrRef.current = documentsarr;
    const requestarrRef = useRef();
    requestarrRef.current = samplesarr;
    const Samplesdata = [
        {
            'Sr. No.' : '1',
            'Product Name' : 'Pigment Blue 15:3',
            'Application': 'Universal',
            'Ref. No.': 'KDCPL/0001',
            'Status': 'Dispatched',
            'RFS No.': 'RFS/WS/001',
        }
    ]

    const fetchrequestsampledata = async(id) => {
        setshowloding(true);
        var data = {
            'rfsid' : id,
        }
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + userdata?.token
            }
        }
        let resp = await axios.post(apiUrl + 'superadminfetchrfssingle',data, headers).then((res) => {
              if(res.data.status == 'success') {
                setrequestsampledata(res.data.data);
                // var prevdata = JSON.parse(res.data.data);
                // console.log('prevdata keys >>>', Object.keys( res.data.data[0]))
                console.log('prevdata >>>', res.data.data)
                setsamplesarr(JSON.parse(res.data.data[0].samplesarr));
                setrefnumber(res.data.data[0].referencenumber);
                setdeliverystatus(res.data.data[0].deliverystatus);
                if(res.data.data[0].adminsamples == null) {
                  setdocumentsarr([])
                } else {
                  setdocumentsarr(JSON.parse(res.data.data[0].adminsamples));
                }
                // dispatch({ type: "userdata", payload: res.data.data })
                //  navigate('/cms/customer/requestsample');
              } else {
                alert(res.data.message);
              }
          } ).catch((err) => {
              alert(err.message)
          })
        setshowloding(false)
    }

    const getBase64 = file => {
        return new Promise(resolve => {
          let fileInfo;
          let baseURL = "";
          // Make new FileReader
          let reader = new FileReader();
    
          // Convert the file to base64 text
          reader.readAsDataURL(file);
    
          // on reader load somthing...
          reader.onload = () => {
            // Make a fileInfo Object
            // console.log("Called", reader);
            baseURL = reader.result;
            // console.log(baseURL);
            resolve(baseURL);
          };
          // console.log(fileInfo);
        });
      };

    const uploaddocsfunc = async(files) => {
        // setdocumentsarr([]);
        console.log('files .>', files.length);
        var documentsarr1 = [];
        for(var i = 0; i < files.length; i++) {
            var obj = {};
            await getBase64(files[i]).then(result => {
                // console.log('result ...', result);
                // base64_decode($base64encodedstring)
                obj['name'] = files[i].name;
                obj['image'] = result;
            });
            documentsarr1.push(obj);
            setdocumentsarr(a => [...a,obj ]);
        }
        console.log('documentsarr ..', documentsarr1);
        
    }
    const uploaddocsfunc1 = async(files) => {
      // setdocumentsarr([]);
      if(files[0]) {
        setdocument(null);
      var obj = {};
      await getBase64(files[0]).then(result => {
          obj['name'] = files[0].name;
          obj['image'] = result;
      });
    setdocument(obj);
      }
      
  }

    const rfssamplereply = async(files) => {
      setshowloding(true);
      // var data = {
      //     'refsid': id,
      //     'refnumber': refnumber,
      //     'deliverystatus': deliverystatus,
      //     'documentsarr': documentsarr,
      //     'dispatchdate': dispatchdate,
      //   };
      var data = {
        'refsid': id,
        'samplesarr': requestarrRef.current
      };
        // console.log('data .>', data)
      const headers = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization' : 'Bearer ' + userdata?.token
          }
      }
      let resp = await axios.post(apiUrl + 'superadminreplyrfs', data, headers).then((res) => {
        // console.log('res >>>', res.data)
          if(res.data.status == 'success') {
            // dispatch({ type: "userdata", payload: res.data.data })
             navigate('/cms/superadmin/requestsample');
          } else {
            alert(res.data.message);
          }
      } ).catch((err) => {
          alert(err.message)
      })
      setshowloding(false);
    }

    const rfssamplereply1 = async(files) => {
      setshowloding(true);
      var data = {
          'refsid': id,
          'refnumber': refnumber,
          'deliverystatus': deliverystatus,
          'dispatchdate': dispatchdate,
          'documentsarr': documentsarr
        };
        // console.log('data .>', data)
      const headers = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization' : 'Bearer ' + userdata?.token
          }
      }
      let resp = await axios.post(apiUrl + 'superadminreplyrfsforrevised', data, headers).then((res) => {
        // console.log('res >>>', res.data)
          if(res.data.status == 'success') {
            // dispatch({ type: "userdata", payload: res.data.data })
             navigate('/cms/superadmin/requestsample');
          } else {
            alert(res.data.message);
          }
      } ).catch((err) => {
          alert(err.message)
      })
      setshowloding(false);
    }

    const sampleapprovefunction = async(id) => {
      setshowloding(true);
      var data = {
        'rfsid' : id,
    }
      const headers = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + userdata?.token
        }
    }
    let resp = await axios.post(apiUrl + 'superadminsampleapprove',data, headers).then((res) => {
        // console.log('res >>>', res.data)
          if(res.data.status == 'success') {
            navigate('/cms/superadmin/requestsample');
          } else {
            alert(res.data.message);
          }
      } ).catch((err) => {
          alert(err.message)
      })
      setshowloding(false);
    }

    const rejectsamplefunction = async() => {
      if(comment) {
        setshowloding(true);
      var data = {
        'rfsid' : requestsampledata[0]?.id,
        'comment': comment,
        'document': document
    }
    console.log('data ...', data);
      const headers = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + userdata?.token
        }
    }
    let resp = await axios.post(apiUrl + 'superadminrequestforrevisedsample',data, headers).then((res) => {
        // console.log('res >>>', res.data)
          if(res.data.status == 'success') {
             navigate('/cms/superadmin/requestsample');
          } else {
            alert(res.data.message);
          }
      } ).catch((err) => {
          alert(err.message)
      })
      setshowloding(false);
      } else {
        alert('comment your feedback')
      }
      
    }

    const handleinputchange = async(name, index, text) => {
      let data = [...requestarrRef.current]
      if(name == 'admindocs') {
        // await getBase64(text).then(result => {
        //   // console.log('result ...', result);
        //   // base64_decode($base64encodedstring)
        //   var obj = {};
        //   obj['name'] = text.name;
        //   obj['image'] = result;
        //   data[index][name] = obj;
        // });
        var documentsarr1 = [];
        for(var i = 0; i < text.length; i++) {
          var obj = {};
          await getBase64(text[i]).then(result => {
              // console.log('result ...', result);
              // base64_decode($base64encodedstring)
              obj['name'] = text[i].name;
              obj['image'] = result;
          });
          documentsarr1.push(obj);
          // setdocumentsarr(a => [...a,obj ]);
      }
      data[index][name] = documentsarr1;
      } else {
        data[index][name] = text
      }
      setsamplesarr(data)
    }

    const deleterfs = async(id, index) => {
      if(window.confirm("Are you sure you want to delete this sample")) {
      setshowloding(true);
      var data = {
          'rfsid' : id,
          'index' : index
      }
      const headers = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization' : 'Bearer ' + userdata?.token
          }
      }
      let resp = await axios.post(apiUrl + 'superadmindeleterfssingle',data, headers).then((res) => {
          // console.log('res >>>', res.data)
            if(res.data.status == 'success') {
              fetchrequestsampledata(id);
              // setrequestsampledata(res.data.data);
              // dispatch({ type: "userdata", payload: res.data.data })
              //  navigate('/customer/requestsample');
            } else {
              alert(res.data.message);
            }
        } ).catch((err) => {
            alert(err.message)
        })
      setshowloding(false)
  } else {
      return
  }
  }

    // console.log('documentsarrRef.current ..', documentsarrRef.current );

    
    useEffect(() => {
        if(userdata && Object.keys(userdata).length > 0) {
            // console.log('id >>', id)
            fetchrequestsampledata(id);
        } else {
            navigate('/cms/superadmin/login');
        }
    }, []);
    return (
      <div className="HomeMain">
        <Overlay showloader={showloding} />
        <div className="sidebar-div">
          <Sidebar name={"superadmin"} />
        </div>
        <div className="main-layout">
          <Navbar name={"superadmin"} />
          <div className="mainDiv">
            <p className="oneplaceHead">REQUEST FOR SAMPLE</p>
            <div style={{ display: "flex", flexDirection: "row",}} className="mt-3">
                  <p className="eta-head mt-2" style={{fontWeight: 'normal'}} >Customer Name :</p>
                  <p className="eta-head mt-2" style={{ marginLeft: 15 }}>{requestsampledata[0]?.name}</p>
                </div>
                <div style={{ display: "flex", flexDirection: "row",}} className="mt-0 mb-4">
                  <p className="eta-head mt-2" style={{fontWeight: 'normal'}} >RFS No :</p>
                  <p className="eta-head mt-2" style={{ marginLeft: 15 }}>{requestsampledata[0]?.rfsnumber}</p>
                </div>
            {samplesarr?.length > 0 ? (
              <table class="table table-bordered invoicetable">
                <thead>
                  <th>Sr no.</th>
                  <th>Product Name</th>
                  <th>Application</th>
                  <th>Ref. No.</th>
                  <th>Delivery Status</th>
                  <th>Date of dispatch</th>
                  <th>Reference <br />document</th>
                  <th>Supporting documents</th>
                  <th>Delete</th>
                </thead>
                <tbody>
                  {
                  samplesarr?.map((item, index) => {
                    // console.log('item ..', item)
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.productname}</td>
                        <td>{item.application}</td>
                        <td>
                                  <input
                                    value={item["refno"]}
                                    onChange={(t) => {
                                      handleinputchange(
                                        "refno",
                                        index,
                                        t.target.value
                                      );
                                    }}
                                    type="text"
                                    // name={'productname'+index}
                                    className="form-control tabinput"
                                    placeholder="Enter Ref. No."
                                  />
                                </td>
                                <td>
                                  <select
                                    class="form-select tabinput"
                                    value={deliverystatus}
                                    onChange={(t) => {
                                      handleinputchange(
                                        "deliverystatus",
                                        index,
                                        t.target.value
                                      );
                                    }}
                                  >
                                    <option disabled selected>
                                      Delivery status
                                    </option>
                                    <option value={"Not delivered"}>
                                      Not delivered
                                    </option>
                                    <option value={"Dispatched"}>
                                      Dispatched
                                    </option>
                                    <option value={"Delivered"}>
                                      Delivered
                                    </option>
                                  </select>
                                </td>
                                <td>
                                  <input
                                    value={item["dateofdispatch"]}
                                    onChange={(t) => {
                                      handleinputchange(
                                        "dateofdispatch",
                                        index,
                                        t.target.value
                                      );
                                    }}
                                    type="date"
                                    // name={'productname'+index}
                                    className="form-control tabinput"
                                    placeholder="Enter dispatch date"
                                  />
                                </td>
                                <td>
                                  {
                                    item?.document?.link ?
                                    <Link
                                    target="_blank"
                                    to={item?.document?.link}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <img
                                      src={pdf}
                                      style={{ width: 32, height: 32 }}
                                    />
                                  </Link> : '-'
                                  }
                                  
                                </td>
                                <td>
                                  <input
                                    type="file"
                                    multiple
                                    accept="application/pdf"
                                    // value={item['document']}
                                    onChange={(t) => {
                                      handleinputchange(
                                        "admindocs",
                                        index,
                                        t.target.files
                                      );
                                    }}
                                    className="form-control tabinput"
                                    placeholder="choose reference document"
                                  />
                                </td>
                                <td>
                                  <div className="displayedittable">
                                    <div onClick={() => {deleterfs(requestsampledata[0].id, index)}}>
                                      <img src={deleteicon} className="editicon" />
                                    </div>
                                  </div>
                                </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className="">
                <p style={{ textAlign: "center" }}>No request yet.</p>
              </div>
            )}
            {/* <div className="pdfrequestsamplediv">
                    <div className="pdfrequestdiv">
                        <img src={pdf} style={{width: 32, height: 32}} />
                        <p style={{color: '#000', fontSize: 20, fontWeight: 'bold', marginLeft: 10, marginTop: 3}}>To view/download COA Click here</p>
                    </div>
                    <div className="pdfrequestdiv">
                        <img src={pdf} style={{width: 32, height: 32}} />
                        <p style={{color: '#000', fontSize: 20, fontWeight: 'bold', marginLeft: 10, marginTop: 3}}>To view/download TDS Click Here</p>
                    </div>
                    <div className="pdfrequestdiv">
                        <img src={pdf} style={{width: 32, height: 32}} />
                        <p style={{color: '#000', fontSize: 20, fontWeight: 'bold', marginLeft: 10, marginTop: 3}}>To view/download MSDS Click Here</p>
                    </div>
                    <div className="pdfrequestdiv">
                        <img src={pdf} style={{width: 32, height: 32}} />
                        <p style={{color: '#000', fontSize: 20, fontWeight: 'bold', marginLeft: 10, marginTop: 3}}>To view/download the AWB Click Here</p>
                    </div>
                </div> */}
            {requestsampledata[0]?.customerid == "superadmin" ? (
              <div className="">
                {requestsampledata[0]?.status == "revised sample" ||
                requestsampledata[0]?.revisedocument ? (
                  <div className="pdfrequestsamplediv mt-5">
                    {JSON.parse(requestsampledata[0]?.revisedocument).map(
                      (item, index) => {
                        return (
                          <div className="pdfrequestdiv">
                            <img src={pdf} style={{ width: 32, height: 32 }} />
                            <Link
                              target="_blank"
                              to={item.link}
                              style={{ textDecoration: "none" }}
                            >
                              <p
                                style={{
                                  color: "#000",
                                  fontSize: 20,
                                  fontWeight: "bold",
                                  marginLeft: 10,
                                  marginTop: 3,
                                }}
                              >
                                {item.name}
                              </p>
                            </Link>
                          </div>
                        );
                      }
                    )}
                  </div>
                ) : (
                  <div className="pdfrequestsamplediv mt-5">
                    {
                      requestsampledata[0]?.adminsamples ?
                    JSON.parse(requestsampledata[0]?.adminsamples).map(
                      (item, index) => {
                        return (
                          <div className="pdfrequestdiv">
                            <img src={pdf} style={{ width: 32, height: 32 }} />
                            <Link
                              target="_blank"
                              to={item.link}
                              style={{ textDecoration: "none" }}
                            >
                              <p
                                style={{
                                  color: "#000",
                                  fontSize: 20,
                                  fontWeight: "bold",
                                  marginLeft: 10,
                                  marginTop: 3,
                                }}
                              >
                                {item.name}
                              </p>
                            </Link>
                          </div>
                        );
                      }
                    ) : null}
                  </div>
                )}
                {showcomment ? null : requestsampledata[0].status ==
                  "accepted" ? null : (
                  <div className="btnrequestsamplediv ">
                    <button
                      onClick={() => {
                        sampleapprovefunction(requestsampledata[0].id);
                      }}
                      className="submitrfs-btn submitrfs-btn2"
                      style={{
                        backgroundColor: colors.themeBlue,
                        borderColor: colors.themeBlue,
                      }}
                    >
                      Approve Sample
                    </button>
                    <button
                      onClick={() => {
                        //   submitrfs();
                        setshowcomment(true);
                      }}
                      className="submitrfs-btn submitrfs-btn2 submitrfs-red"
                      style={{
                        backgroundColor: colors.themeRed,
                        borderColor: colors.themeRed,
                        marginLeft: 20,
                      }}
                    >
                      Reject Sample
                    </button>
                  </div>
                )}

                {showcomment ? (
                  <div className="commentbox">
                    <textarea
                      rows={4}
                      value={comment}
                      onChange={(t) => {
                        setcomment(t.target.value);
                      }}
                      className="form-control"
                      placeholder="comment your feedback here"
                    ></textarea>
                    <div
                      className="btnrequestsamplediv"
                      style={{ marginTop: 20 }}
                    >
                      <label
                        onClick={() => {
                          // setisapproved(true)
                        }}
                        className="submitrfs-btn submitrfs-btn2 submitrfs-red"
                        style={{
                          backgroundColor: colors.themeRed,
                          borderColor: colors.themeRed,
                        }}
                        for="upload-pdf"
                      >
                        Upload feedback
                      </label>
                      <input
                        type="file"
                        onChange={(t) => {
                          uploaddocsfunc1(t.target.files);
                        }}
                        accept="application/pdf"
                        id="upload-pdf"
                      />
                      <button
                        onClick={() => {
                          // setisrejected(true);
                          rejectsamplefunction();
                        }}
                        className="submitrfs-btn submitrfs-btn2 submitrfs-red"
                        style={{
                          backgroundColor: colors.themeRed,
                          borderColor: colors.themeRed,
                          marginLeft: 20,
                        }}
                      >
                        Reject Sample
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : (
              <div className="">
                {/* <p className="supporting-documents-head">
                  Supporting Documents:
                </p>
                <label className="uploadhere-box" for="upload-pdf">
                  <p>Upload here</p>
                </label>
                <input
                  type="file"
                  multiple
                  onChange={(t) => {
                    uploaddocsfunc(t.target.files);
                  }}
                  accept="application/pdf"
                  id="upload-pdf"
                />
                {documentsarrRef.current?.length > 0
                  ? documentsarrRef.current?.map((item, index) => {
                      return (
                        <div className="pdfrequestdiv" key={index}>
                          <img src={pdf} style={{ width: 32, height: 32 }} />
                          <Link
                            target="_blank"
                            to={item.link}
                            style={{ textDecoration: "none" }}
                          >
                            <p
                              style={{
                                color: "#000",
                                fontSize: 20,
                                fontWeight: "bold",
                                marginLeft: 10,
                                marginTop: 3,
                              }}
                            >
                              {item.name}
                            </p>
                          </Link>
                        </div>
                      );
                    })
                  : null} */}
                  {showcomment ? null : requestsampledata[0]?.status !==
                  "feedback pending" ? null : (
                  <div className="btnrequestsamplediv ">
                    <button
                      onClick={() => {
                        sampleapprovefunction(requestsampledata[0].id);
                      }}
                      className="submitrfs-btn submitrfs-btn2"
                      style={{
                        backgroundColor: colors.themeBlue,
                        borderColor: colors.themeBlue,
                      }}
                    >
                      Approve Sample
                    </button>
                    <button
                      onClick={() => {
                        //   submitrfs();
                        setshowcomment(true);
                      }}
                      className="submitrfs-btn submitrfs-btn2 submitrfs-red"
                      style={{
                        backgroundColor: colors.themeRed,
                        borderColor: colors.themeRed,
                        marginLeft: 20,
                      }}
                    >
                      Reject Sample
                    </button>
                  </div>
                )}

                {showcomment ? (
                  <div className="commentbox">
                    <textarea
                      rows={4}
                      value={comment}
                      onChange={(t) => {
                        setcomment(t.target.value);
                      }}
                      className="form-control"
                      placeholder="comment your feedback here"
                    ></textarea>
                    <div
                      className="btnrequestsamplediv"
                      style={{ marginTop: 20 }}
                    >
                      <label
                        onClick={() => {
                          // setisapproved(true)
                        }}
                        className="submitrfs-btn submitrfs-btn2 submitrfs-red"
                        style={{
                          backgroundColor: colors.themeRed,
                          borderColor: colors.themeRed,
                        }}
                        for="upload-pdf"
                      >
                        Upload feedback
                      </label>
                      <input
                        type="file"
                        onChange={(t) => {
                          uploaddocsfunc1(t.target.files);
                        }}
                        accept="application/pdf"
                        id="upload-pdf"
                      />
                      <button
                        onClick={() => {
                          // setisrejected(true);
                          rejectsamplefunction();
                        }}
                        className="submitrfs-btn submitrfs-btn2 submitrfs-red"
                        style={{
                          backgroundColor: colors.themeRed,
                          borderColor: colors.themeRed,
                          marginLeft: 20,
                        }}
                      >
                        Reject Sample
                      </button>
                    </div>
                  </div>
                ) : null}



                {requestsampledata[0]?.status == "revised sample" ? (
                  <button
                    onClick={() => {
                      // setisapproved(true)
                      rfssamplereply1();
                    }}
                    className="submitrfs-btn submitrfs-btn2"
                    style={{
                      backgroundColor: colors.themeBlue,
                      borderColor: colors.themeBlue,
                      float: "right",
                      marginTop: 10,
                    }}
                  >
                    SAVE AND SUBMIT
                  </button>
                ) : 
                requestsampledata[0]?.status == "feedback pending" ? null :
                (
                  <button
                    onClick={() => {
                      // setisapproved(true)

                      rfssamplereply();
                    }}
                    className="submitrfs-btn submitrfs-btn2"
                    style={{
                      backgroundColor: colors.themeBlue,
                      borderColor: colors.themeBlue,
                      float: "right",
                      marginTop: 10,
                    }}
                  >
                    SAVE AND SUBMIT
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
}

export default SuperAdminRFSSample