import React, { useState, useEffect } from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import InvoiceTable from "../../components/tables/InvoiceTable";
import { pdf } from "../../config/images";
import { colors } from "../../config/colors";
import { Link, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from "react-redux/es/exports";
import { apiUrl } from "../../config/config";
import axios from 'axios';
import Overlay from "../../components/common/Overlay";
import { edit, deleteicon } from "../../config/images";
import {useLocation} from 'react-router-dom';


const AdminRectificationRequest = () => {
    const [isapproved, setisapproved] = useState(false);
    const [showcomment, setshowcomment] = useState(false);
    const [isrejected, setisrejected] = useState(false);
    const [rectificationdata, setrectificationdata] = useState({});
    const dispatch = useDispatch(); 
    const userdata = useSelector((store) => store.userReducer.userdata);
    let navigate = useNavigate();
    const { state } = useLocation();
    const submitrfs1 = () => {
        navigate('/cms/customer/requestsample');  
    }
    useEffect(() => {
      if(userdata && Object.keys(userdata).length > 0) {
          // console.log('state.rfsid >>', state.rfsid)
          // fetchreqtificationdatasampledata(state.rfsid);
          // console.log('rectification data ...', state.rectificationdata);
          setrectificationdata(state.rectificationdata[0])
      } else {
          navigate('/cms/admin/login');
      }
  }, []);
    return (
      <div className="HomeMain">
        <div className="sidebar-div">
          <Sidebar name={'Admin'} />
        </div>
        <div className="main-layout">
            <Navbar name={'Admin'} />
            <div className="mainDiv">
               <p className="oneplaceHead">REQUEST FOR RECTIFICATION</p>
               <p onClick={() => {
                // setstep3feedback(!step3feedback)
                navigate('/cms/admin/new-pfi-tracking/'+rectificationdata?.invoiceid);
              }} className="oneplaceHead" style={{textAlign : 'left'}}>{'< BACK'}</p>
                  <div className="uploadhere-box-big">
                    <p className="supporting-documents-head">Customer Feedback:</p>
                <div className="uploadhere-box uploadhere-box1">
                {
                  Object.keys(rectificationdata).length > 0 ?
                  <p className="commentstylep">{rectificationdata?.comment}</p> :
                  <p className="commentstylep">{'\n'}</p>
                }
                    
                </div>
                {
                  Object.keys(rectificationdata).length > 0 ?
                  <div className="pdfrequestdiv" >
                      <img src={pdf} style={{ width: 32, height: 32 }} />
                      <Link target="_blank" to={JSON.parse(rectificationdata?.doc)?.link} style={{textDecoration: 'none'}}>
                        <p
                          style={{
                            color: "#000",
                            fontSize: 20,
                            fontWeight: "bold",
                            marginLeft: 10,
                            marginTop: 3,
                          }}
                        >
                          {JSON.parse(rectificationdata?.doc)?.name}
                        </p>
                      </Link>
                    </div> :
                  <p className="commentstylep">{'\n'}</p>
                }
                </div>
                <br />
                {/* <div className="uploadhere-box-big mt-3">
                    <p className="supporting-documents-head">Kevin Response:</p>
                <div className="uploadhere-box">
                    <p>{'\n'}</p>
                </div>
                <div className="row adminorderrow mb-0 mt-4">
                  <div className="col-md-5">
                    <p className="orderscreen-p orderscreen-p1">SUPPORTING DOCUMENTS:</p>
                  </div>
                  <div className="col-md-7">
                    <input
                      type="file"
                      className="form-control admin-form-control"
                      placeholder=""
                    />
                  </div>
                </div>
                </div> */}
                {/* <button
              onClick={() => {
                // setstep3feedback(!step3feedback)
              }}
              className="submitrfs-btn submitrfs-btn2"
              style={{
                backgroundColor: colors.themeBlue,
                borderColor: colors.themeBlue,
                float: "right",
                marginTop: 10,
                marginBottom: 50,
              }}
            >
              SAVE AND SUBMIT
            </button> */}
            </div>
        </div>
      </div>
    );
}

export default AdminRectificationRequest