import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const ProductFinderHead = ({data, selectcategoryid}) => {
  const [selectedid, setselectedid] = useState(-1);
  return (
    <div className="webheaderbg mobhide">
      <div className="pagewidth">
        <div className="headerdiv">
          <p className="productfinderhead">Product Finder</p>
          <div className="productfindercatdiv">
            <nav class="navbar navbar-expand-sm products-finder-nav">
              <ul class="navbar-nav">
              <li class="nav-item">
                        <Link to={"/home"}
                          class="nav-link nav-link1"
                        >
                          Home
                        </Link>
                      </li>
              <li class="nav-item">
                        <div
                          onClick={() => {
                            selectcategoryid(-1);
                            setselectedid(-1);
                          }}
                          class="nav-link nav-link1"
                          style={selectedid == '-1' ? {backgroundColor: '#131313', borderRadius: 50} : null}
                        >
                          All
                        </div>
                      </li>
                {
                  data?.length > 0 ?
                  data?.map((item, index) => {
                    return (
                      <li class="nav-item" key={index} style={selectedid == item.id ? {backgroundColor: '#131313', borderRadius: 50} : null}>
                        <div
                          onClick={() => {
                            selectcategoryid(item.id);
                            setselectedid(item.id);
                          }}
                          class="nav-link nav-link1"
                        >
                          {item?.name}
                        </div>
                      </li>
                    );
                  }) : null
                }
              </ul>
            </nav>
          </div>
          <div class="logindiv">
            <Link to={'/Contact'} class="productheaderbtn">
              <p>Contact Us</p>
            </Link>
            <Link to={"/cms/customer/login"} class="productheaderbtn">
              <p>Log In</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductFinderHead;
