import React, {useEffect, useState} from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import InvoiceTable from "../../components/tables/InvoiceTable";

import { Link } from "react-router-dom";
import { colors } from "../../config/colors";

import { useDispatch, useSelector } from "react-redux/es/exports";
import { useNavigate } from 'react-router-dom';
import { apiUrl } from "../../config/config";
import axios from 'axios';
import Overlay from "../../components/common/Overlay";
import { edit, deleteicon } from "../../config/images";

const SuperdminProformaInvoice = () => {
    const dispatch = useDispatch(); 
    let navigate = useNavigate();
    const userdata = useSelector((store) => store.userReducer.userdata);
    const [requestpfidata, setrequestpfidata] = useState([]);
    const [showloding, setshowloding] = useState(false)
    const invoicedata = [
        {
            'Sr. No.' : '1',
            'PFI No.' : 'KDCPL/001/23-24',
            'PFI Date': '01/04/2023',
            'PO No.': 'XXX XXX XXX',
            'Status': 'Complete',
            'Edit' : ''
        }, 
        {
            'Sr. No.' : '2',
            'PFI No.' : 'KDCPL/002/23-24',
            'PFI Date': '01/04/2023',
            'PO No.': 'XXX XXX XXX',
            'Status': 'In Process',
            'Edit' : ''
        },
        {
            'Sr. No.' : '3',
            'PFI No.' : 'KDCPL/003/23-24',
            'PFI Date': '01/04/2023',
            'PO No.': 'XXX XXX XXX',
            'Status': 'Complete',
            'Edit' : ''
        }
    ];
    const fetchpfidata = async() => {
        setshowloding(true);
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + userdata?.token
            }
        }
        let resp = await axios.get(apiUrl + 'superadminfetchpfi', headers).then((res) => {
            // console.log('res >>>', res.data)
              if(res.data.status == 'success') {
                setrequestpfidata(res.data.data);
              } else {
                alert(res.data.message);
              }
          } ).catch((err) => {
              alert(err.message)
          })
          setshowloding(false);
    }
    const deleterfi = async(id) => {
        if(window.confirm("Are you sure you want to delete this quote")) {
        setshowloding(true);
        var data = {
            'invid' : id,
        }
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + userdata?.token
            }
        }
        let resp = await axios.post(apiUrl + 'superadmindeleterfi',data, headers).then((res) => {
            // console.log('res >>>', res.data)
              if(res.data.status == 'success') {
                setrequestpfidata(res.data.data);
                // setrequestsampledata(res.data.data);
                // dispatch({ type: "userdata", payload: res.data.data })
                 navigate('/cms/superadmin/proformaInvoice');
              } else {
                alert(res.data.message);
              }
          } ).catch((err) => {
              alert(err.message)
          })
        setshowloding(false)
    } else {
        return
    }
    }
    const clickproductnamerfi = (id) => {
        navigate('/cms/superadmin/new-pfi-tracking/'+id);
    }

    const clicknewpfi = () => {
        navigate('/cms/superadmin/new-pfi-tracking/'+'-2');
    }

    useEffect(() => {
        if(userdata && Object.keys(userdata).length > 0) {
            fetchpfidata();
        } else {
            navigate('/cms/superadmin/login');
        }
    }, []);

    return (
      <div className="HomeMain">
        <Overlay showloader={showloding} />
        <div className="sidebar-div">
          <Sidebar name={'superadmin'} />
        </div>
        <div className="main-layout">
            <Navbar name={'superadmin'} />
            <div className="mainDiv">
                {/* <InvoiceTable title={'Proforma Invoices'} data={invoicedata} showbtn={true} /> */}
                <p className="oneplaceHead">PROFORMA INVOICES</p>
                <div className="tablebuttondiv tablebuttondivpage" onClick={() => {
                    clicknewpfi()
                }}>
                <Link className="btndiv" style={{backgroundColor: colors.themeBlue}}>
                    <p>{'New PFI'}</p>
                </Link>
            </div>
                {
                requestpfidata?.length > 0 ?
                <table class="table table-bordered invoicetable">
                <thead>
                    <th>Sr no.</th>
                    <th>Customer Name</th>
                    <th>PFI No.</th>
                    <th>PFI Date</th>
                    <th>PO No.</th>
                    <th>Status</th>
                    <th>Settings</th>
                </thead>
                <tbody>
                    {
                        requestpfidata?.map((item, index) => {
                            console.log('item ..', item)
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>
                                    <div onClick={() => {clickproductnamerfi(item.id)}}>
                                        <Link>{item.pfinumber}</Link>
                                    </div>
                                </td>
                                <td>{item.pfidate}</td>
                                <td>{item.ponumber}</td>
                                <td>{
                                item.is_rectification == true ?
                                <div onClick={() => {clickproductnamerfi(item.id)}} className="statusdiv" style={{backgroundColor: '#ffde59', borderColor: 'red', borderWidth: 3, borderStyle: 'solid'}}>
                                    Request rectification
                                </div>
                                
                               : item.adminstatus == -1 ?
                                <div onClick={() => {clickproductnamerfi(item.id)}} className="statusdiv" style={{backgroundColor: '#4fb293'}}>
                                    Complete
                                </div>
                                 :
                                 <div onClick={() => {clickproductnamerfi(item.id)}} className="statusdiv" style={{backgroundColor: '#ffde59'}}>
                                    {
                                    item.adminstatus == 1 ? 'Proforma Invoice' : item.adminstatus == 2 ? 'Pre-shipment Sample' : item.adminstatus == 3 ? 'Draft Shipping Documents' : 'Final Shipping Documents'}
                                </div>
                                
                                }</td>
                                <td>
                                  <div className="displayedittable">
                                    <div onClick={() => {deleterfi(item.id)}}>
                                      <img src={deleteicon} className="editicon" />
                                    </div>
                                    <div onClick={() => {clickproductnamerfi(item.id)}}>
                                        <img src={edit} className="editicon" />
                                    </div>
                                    
                                  </div>
                                </td>
                              </tr>
                            );
                        })
                    }
                </tbody>
            </table> : 
            <div className="">
                <p style={{textAlign: 'center'}}>No invoices yet.</p>
            </div>
            }
            </div>
        </div>
      </div>
    );
}

export default SuperdminProformaInvoice