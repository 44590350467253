import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import InvoiceTable from "../../components/tables/InvoiceTable";
import { pdf } from "../../config/images";
import { colors } from "../../config/colors";
import { useNavigate } from 'react-router-dom';
import { plus, minus } from "../../config/images";

import { useDispatch, useSelector } from "react-redux/es/exports";
import { apiUrl } from "../../config/config";
import axios from 'axios';
import Overlay from "../../components/common/Overlay";
import { edit, deleteicon } from "../../config/images";
import { Link } from "react-router-dom";
import {useLocation} from 'react-router-dom';
import { useParams } from 'react-router-dom';


const AdminOrderTracking = () => {
  const obj = {
    "srno": 1,
    "productname": "",
    "quantity": "",
    "productcode": "",
    "lotnumber": "",
  }
  var dsdobj = {
    "srno": 1,
    'image': '',
}
var fsdobj = {
  "srno": 1,
  'image': '',
}
    const [ponumber, setponumber] = useState(null);
    const [pfidate, setpfidate] = useState(null);
    const [pfinumber, setpfinumber] = useState(null);
    const [pfidoc, setpfidoc] = useState(null);
    const [pfidoc1, setpfidoc1] = useState(null);

    const [isapproved, setisapproved] = useState(false);
    const [showcomment, setshowcomment] = useState(false);
    const [showcomment2, setshowcomment2] = useState(false);
    const [showcomment3, setshowcomment3] = useState(false);
    const [isrejected, setisrejected] = useState(false);
    const [orderstep, setorderstep] = useState(1);
    const [orderstep1, setorderstep1] = useState(1);
    const [requestarr, setrequestarr] = useState([obj]);
    const [dsdimagearr, setdsdimagearr] = useState([dsdobj]);
    const [fsdimagearr, setfsdimagearr] = useState([fsdobj]);
    const [issubmitted, setissubmitted] = useState(false);
    const [step2feedback, setstep2feedback] = useState(false);
    const [step3feedback, setstep3feedback] = useState(false);
    const [showloding, setshowloding] = useState(false);
    const [customersdata, setcustomersdata] = useState([]);
    const [selectcustomer, setselectcustomer] = useState(null);
    const [invoicedata, setinvoicedata] = useState(null);
    const [pssawbdoc, setpssawbdoc] = useState(null);
    const [pssawbdoc1, setpssawbdoc1] = useState(null);
    const [passdate, setpassdate] = useState(null);
    const [showpsswaiting, setshowpsswaiting] = useState(false);
    const [documentsdssarr, setdocumentsdssarr] = useState(false);
    const [documentsdssarr1, setdocumentsdssarr1] = useState(null);
    const [documentsfsdarr, setdocumentsfsdarr] = useState(null);
    const [documentsfsdarr1, setdocumentsfsdarr1] = useState(null);
    const [fsddate, setfsddate] = useState(null);
    const [fsdcourier, setfsdcourier] = useState(null);
    const [fsdawbnumber, setfsdawbnumber] = useState(null);
    const [fsdawbfile, setfsdawbfile] = useState(null);
    const [fsdawbfile1, setfsdawbfile1] = useState(null);
    const [fsdamendment, setfsdamendment] = useState(null);
    const [rectificationstep, setrectificationstep] = useState(0);
    const [rectificationdata, setrectificationdata] = useState([]);

    const [showdsswaiting, setshowdsswaiting] = useState(false);
    const dispatch = useDispatch(); 
    const userdata = useSelector((store) => store.userReducer.userdata);
    let navigate = useNavigate();
    const { id } = useParams();
    const requestarrRef = useRef();
    requestarrRef.current = requestarr;
    const submitrfs1 = () => {
        navigate('/cms/customer/proformaInvoice');  
    }
    const submitrfs2 = () => {
        navigate('/cms/customer/requestquote');  
    }

    const submitpfi = async() => {
      setshowloding(true);
      if(pfidate && pfinumber && pfidoc && selectcustomer) {
        var data = JSON.stringify(
          {
            'ponumber': ponumber,
            'pfidate': pfidate,
            'pfinumber': pfinumber,
            'pfidoc': pfidoc,
            'customerid': selectcustomer
          });
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + userdata?.token
            }
        }
        let resp = await axios.post(apiUrl + 'adminpfisubmit', data, headers).then((res) => {
          // console.log('res >>>', res.data)
            if(res.data.status == 'success') {
              navigate('/cms/admin/proformaInvoice');
              setorderstep(2);
              setorderstep1(2);
              // setissubmitted(true);
            } else {
              alert(res.data.message);
            }
        } ).catch((err) => {
            alert(err.message)
        })
    } else {
            alert('Please fill all required details');
        }
      

      setshowloding(false);
    }

    const submitpfiv2 = async() => {
      setshowloding(true);
      var data = JSON.stringify(
        {
          'ponumber': ponumber,
          'pfidate': pfidate,
          'pfinumber': pfinumber,
          'pfidoc': pfidoc,
          'customerid': selectcustomer,
          'invoiceid': id,
          'rectificationid': rectificationdata[0].id
        });
      const headers = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization' : 'Bearer ' + userdata?.token
          }
      }
      let resp = await axios.post(apiUrl + 'adminpfiupdate', data, headers).then((res) => {
        // console.log('res >>>', res.data)
          if(res.data.status == 'success') { 
            setrectificationstep(0);
            setrectificationdata([]);
            setorderstep1(orderstep);
            // setissubmitted(true);
          } else {
            alert(res.data.message);
          }
      } ).catch((err) => {
          alert(err.message)
      })

      setshowloding(false);
    }
    
    const onplusfunction = () => {
        setrequestarr(a => [...a,obj ]);
    }
    const onminusfunction = () => {
        console.log('onminusfunction ...');
        if(requestarr.length == 1) {

        } else {
            var newarr = requestarr.slice(0, -1);
            setrequestarr(newarr);
        }
        
        
    }

    const getBase64 = file => {
      return new Promise(resolve => {
        let fileInfo;
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();
  
        // Convert the file to base64 text
        reader.readAsDataURL(file);
  
        // on reader load somthing...
        reader.onload = () => {
          // Make a fileInfo Object
          // console.log("Called", reader);
          baseURL = reader.result;
          // console.log(baseURL);
          resolve(baseURL);
        };
        // console.log(fileInfo);
      });
    };

    const changetext = async(text) => {
        await getBase64(text).then(result => {
          // console.log('result ...', result);
          // base64_decode($base64encodedstring)
          var obj = {};
          obj['name'] = text.name;
          obj['image'] = result;
          setpfidoc(obj);
        }); 
    } 
    const changetext1 = async(name, index, text) => {
      // console.log('text11 .>',text.name);
      let data = [...requestarrRef.current]
      if(name == 'document') {
        await getBase64(text).then(result => {
          // console.log('result ...', result);
          // base64_decode($base64encodedstring)
          var obj = {};
          obj['name'] = text.name;
          obj['image'] = result;
          data[index][name] = obj;
        });
      } else {
        data[index][name] = text
      }
      setrequestarr(data);
    }
    const pssuploadawb = async(text) => {
      await getBase64(text).then(result => {
        // console.log('result ...', result);
        // base64_decode($base64encodedstring)
        var obj = {};
        obj['name'] = text.name;
        obj['image'] = result;
        setpssawbdoc(obj);
      }); 
  } 
    const fetchcustomers = async() => {
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .get(apiUrl + "adminfetchcustomers", headers)
        .then((res) => {
          if (res.data.status == "success") {
            setcustomersdata(res.data.data);
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          alert(err.message);
        });
    }
    const fetchsingleinvoiceadmin = async(id) => {
      setshowloding(true);
      var data = {
          'invid' : id,
      }
      const headers = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization' : 'Bearer ' + userdata?.token
          }
      }
      let resp = await axios.post(apiUrl + 'adminfetchinvoicesingle',data, headers).then((res) => {
          // console.log('customerfetchinvoicesingle res >>>', res.data)
            if(res.data.status == 'success') {
              setinvoicedata(res.data.data);
              setponumber(res.data.data.ponumber);
              setpfidate(res.data.data.pfidate);
              setpfinumber(res.data.data.pfinumber);
              setpfidoc1(res.data.data.pfidoc);
              setselectcustomer(res.data.data.customerid)

              setrequestarr(res.data.data.pssdata ? JSON.parse(res.data.data.pssdata) : [obj]);
              setpassdate(res.data.data.pssdate);
              setpssawbdoc1(res.data.data.pssawbill);

              setdocumentsdssarr1(res.data.data.dsdadmindocs);

              setdocumentsfsdarr1(res.data.data.fsddocs);
              setfsdawbfile1(res.data.data.fsdawbdoc);
              setfsddate(res.data.data.fsddate);
              setfsdcourier(res.data.data.fsdcourier);
              setfsdawbnumber(res.data.data.fsdawbnumber);
              setfsdamendment(res.data.data.fsdamendment);
 

              // setponumber(res.data.data.ponumber);
              // setponumber(res.data.data.ponumber);
              // setponumber(res.data.data.ponumber);
              // setponumber(res.data.data.ponumber);
              // setponumber(res.data.data.ponumber);
              // setponumber(res.data.data.ponumber);
              // setponumber(res.data.data.ponumber);
              if(res.data.data.adminstatus == 2) {
                setorderstep(res.data.data.adminstatus);
                if(res.data.data.is_rectification == true) {
                  setorderstep1(res.data.data.step);
                } else {
                  setorderstep1(res.data.data.adminstatus);
                }
                
                if(res.data.data.pssdata) {
                  if(res.data.data.psscustomercomment) {
                    setstep2feedback(true);
                  } else {
                    setshowpsswaiting(true);
                  }
                } else  {

                }
                
              } 
              else if(res.data.data.adminstatus == 3) {
                setorderstep(res.data.data.adminstatus);
                if(res.data.data.is_rectification == true) {
                  setorderstep1(res.data.data.step);
                } else {
                  setorderstep1(res.data.data.adminstatus);
                }
                if(res.data.data.dsdadmindocs) {
                  if(res.data.data.dsdcomment) {
                    setstep3feedback(true);
                  } else {
                    setshowdsswaiting(true);
                  }
                } else  {

                }
              }
              else if(res.data.data.adminstatus == 4 || res.data.data.adminstatus == -1) {
                setorderstep(4);
                if(res.data.data.is_rectification == true) {
                  setorderstep1(res.data.data.step);
                } else {
                  setorderstep1(4);
                }
              }
              else {
              }

              // setrequestsampledata(res.data.data);
              // dispatch({ type: "userdata", payload: res.data.data })
              //  navigate('/cms/customer/requestsample');
            } else {
              alert(res.data.message);
            }
        } ).catch((err) => {
            alert(err.message)
        })
      setshowloding(false)
    }
    const checkforrectification = async(id) => {
      setshowloding(true);
      var data = {
          'invid' : id,
      }
      const headers = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization' : 'Bearer ' + userdata?.token
          }
      }
      let resp = await axios.post(apiUrl + 'admincheckrectification',data, headers).then((res) => {
          // console.log('customerfetchinvoicesingle res >>>', res.data)
            if(res.data.status == 'success') {
              if(res.data.data.length > 0) {
                var rectidata = res.data.data;
                setrectificationdata(rectidata);
                setrectificationstep(rectidata[0].step);
              }
              // setrectificationstep(0)
            } else {
              alert(res.data.message);
            }
        } ).catch((err) => {
            alert(err.message)
        })
      setshowloding(false)
    }
    const fetchinvoicedata = async(num) => {
      if(num == -2) {
        fetchcustomers();
      } else {
        fetchsingleinvoiceadmin(num);
        checkforrectification(num);
        fetchcustomers();
      }
    }
    const submitpss = async() => {
      var tabledata = requestarrRef.current;
      if(tabledata.length > 0) {
        for(var i = 0; i < tabledata.length; i++) {
          if(tabledata[i].productname && tabledata[i].quantity && tabledata[i].productcode && tabledata[i].lotnumber) {
            
          } else {
            alert('Please fill all required details.')
            return;
          }
        }
      }  
      setshowloding(true);
            var data = JSON.stringify(
              {
                'invid': id,
                'pssdata': requestarrRef.current,
                'pssdate': passdate,
                'pssawbill': pssawbdoc
              });
              // console.log('data .>', requestarrRef.current)
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + userdata?.token
                }
            }
            let resp = await axios.post(apiUrl + 'adminsubmitpss', data, headers).then((res) => {
                if(res.data.status == 'success') {
                  setshowpsswaiting(true);
                  setstep2feedback(false);
                  // setorderstep(3);
                  //  navigate('/cms/customer/requestsample');
                  // setissubmitted(true);
                } else {
                  alert(res.data.message);
                }
            } ).catch((err) => {
                alert(err.message)
            })
            setshowloding(false);
    }
    const submitpssv2 = async() => {
      var tabledata = requestarrRef.current;
      if(tabledata.length > 0) {
        for(var i = 0; i < tabledata.length; i++) {
          if(tabledata[i].productname && tabledata[i].quantity && tabledata[i].productcode && tabledata[i].lotnumber) {
            
          } else {
            alert('Please fill all required details.')
            return;
          }
        }
      } 
      setshowloding(true);
            var data = JSON.stringify(
              {
                'invid': id,
                'pssdata': requestarrRef.current,
                'pssdate': passdate,
                'pssawbill': pssawbdoc,
                'rectificationid': rectificationdata[0].id
              });
              // console.log('data .>', requestarrRef.current)
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + userdata?.token
                }
            }
            let resp = await axios.post(apiUrl + 'adminupdatepss', data, headers).then((res) => {
                if(res.data.status == 'success') {
                  setrectificationstep(0);
                  setrectificationdata([]);
                  setorderstep1(orderstep);
                  // setshowpsswaiting(true);
                  // setstep2feedback(false);
                  
                } else {
                  alert(res.data.message);
                }
            } ).catch((err) => {
                alert(err.message)
            })
            setshowloding(false);
    }
    const submitskipppss = async() => {
      setshowloding(true);
      var data = JSON.stringify(
        {
          'invid': id,
        });
        // console.log('data .>', requestarrRef.current)
      const headers = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization' : 'Bearer ' + userdata?.token
          }
      }
      let resp = await axios.post(apiUrl + 'adminskippss', data, headers).then((res) => {
          if(res.data.status == 'success') {
            setorderstep(3);
            setorderstep1(3);
            // setshowpsswaiting(true);
            // setstep2feedback(false);
          } else {
            alert(res.data.message);
          }
      } ).catch((err) => {
          alert(err.message)
      })
      setshowloding(false);
    }
    const submitdssfunction = async() => {
      var tabledata = dsdimagearr;
      if(tabledata.length > 0) {
        for(var i = 0; i < tabledata.length; i++) {
          if(tabledata[i].image) {
            
          } else {
            alert('Image cannot be empty.')
            return;
          }
        }
      } 
      setshowloding(true);
      var data = JSON.stringify(
        {
          'invid': id,
          'documentsdssarr': dsdimagearr
        });
        const headers = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization' : 'Bearer ' + userdata?.token
          }
      }
      let resp = await axios.post(apiUrl + 'adminsubmitdss', data, headers).then((res) => {
        if(res.data.status == 'success') {
          setshowdsswaiting(true);
          setstep3feedback(false);
          // setorderstep(3);
          //  navigate('/cms/customer/requestsample');
          // setissubmitted(true);
        } else {
          alert(res.data.message);
        }
    } ).catch((err) => {
        alert(err.message)
    })
      setshowloding(false);
    }
    const submitdssfunctionv2 = async() => {
      var tabledata = dsdimagearr;
      if(tabledata.length > 0) {
        for(var i = 0; i < tabledata.length; i++) {
          if(tabledata[i].image) {
            
          } else {
            alert('Image cannot be empty.')
            return;
          }
        }
      } 
      setshowloding(true);
      var data = JSON.stringify(
        {
          'invid': id,
          'documentsdssarr': dsdimagearr,
          'rectificationid': rectificationdata[0].id
        });
        const headers = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization' : 'Bearer ' + userdata?.token
          }
      }
      let resp = await axios.post(apiUrl + 'adminupdatedss', data, headers).then((res) => {
        if(res.data.status == 'success') {
          setrectificationstep(0);
                  setrectificationdata([]);
                  setorderstep1(orderstep);
          // setshowdsswaiting(true);
          // setstep3feedback(false);
          // setorderstep(3);
          //  navigate('/cms/customer/requestsample');
          // setissubmitted(true);
        } else {
          alert(res.data.message);
        }
    } ).catch((err) => {
        alert(err.message)
    })
      setshowloding(false);
    }

    const uploaddssdocsfunc = async(files) => {
      setdocumentsdssarr([]);
      console.log('files .>', files.length);
      var documentsarr1 = [];
      for(var i = 0; i < files.length; i++) {
          var obj = {};
          await getBase64(files[i]).then(result => {
              obj['name'] = files[i].name;
              obj['image'] = result;
          });
          documentsarr1.push(obj);
      }
      console.log('documentsarr ..', documentsarr1);
      setdocumentsdssarr(documentsarr1);
      
  }
  const uploaddssdocsfunc1 = async(index, text) => {
    
    var data = [...dsdimagearr]
    await getBase64(text).then(result => {
      var obj = {};
      obj['name'] = text.name;
      obj['image'] = result;
      data[index]['image'] = obj;
    });
    console.log('uploaddssdocsfunc1 data ...', data);
    setdsdimagearr(data)
}
const onplusfunctiondsd = () => {
  var dsdobj = {
    "srno": dsdimagearr.length + 1,
    'image': '',
}
  setdsdimagearr(a => [...a,dsdobj ]);
}
const onminusfunctiondsd = () => {
  if(dsdimagearr.length == 1) {

  } else {
      var newarr = dsdimagearr.slice(0, -1);
      setdsdimagearr(newarr);
  }
  
  
}

  const uploadfsdawbdocsfunc = async(files) => {
    await getBase64(files).then(result => {
      // console.log('result ...', result);
      // base64_decode($base64encodedstring)
      var obj = {};
      obj['name'] = files.name;
      obj['image'] = result;
      setfsdawbfile(obj);
    });
  }

  const uploadfsddocsfunc = async(files) => {
    setdocumentsfsdarr([]);
      console.log('files .>', files.length);
      var documentsarr1 = [];
      for(var i = 0; i < files.length; i++) {
          var obj = {};
          await getBase64(files[i]).then(result => {
              // console.log('result ...', result);
              // base64_decode($base64encodedstring)
              obj['name'] = files[i].name;
              obj['image'] = result;
          });
          documentsarr1.push(obj);
      }
      console.log('documentsarr ..', documentsarr1);
      setdocumentsfsdarr(documentsarr1);
  }
  const uploadfsddocsfunc1 = async(index, text) => {
    
    var data = [...fsdimagearr]
    await getBase64(text).then(result => {
      var obj = {};
      obj['name'] = text.name;
      obj['image'] = result;
      data[index]['image'] = obj;
    });
    console.log('uploaddssdocsfunc1 data ...', data);
    setfsdimagearr(data);
}
const onplusfunctionfsd = () => {
  var dsdobj = {
    "srno": fsdimagearr.length + 1,
    'image': '',
}
  setfsdimagearr(a => [...a,dsdobj ]);
}
const onminusfunctionfsd = () => {
  if(fsdimagearr.length == 1) {

  } else {
      var newarr = fsdimagearr.slice(0, -1);
      setfsdimagearr(newarr);
  }
  
  
}

  const submitfsdfunc = async() => {
    var tabledata = fsdimagearr;
      if(tabledata.length > 0) {
        for(var i = 0; i < tabledata.length; i++) {
          if(tabledata[i].image ) {
            
          } else {
            alert('Image cannot be empty.')
            return;
          }
        }
      }
      // if(fsdawbfile) {

      // } else {
      //   alert('Please fill all required details.')
      //   return;
      // }
    setshowloding(true);
      var data = JSON.stringify(
        {
          'invid': id,
          'fsddocs': fsdimagearr,
          'fsddate': fsddate,
          'fsdcourier': fsdcourier,
          'fsdawbnumber': fsdawbnumber,
          'fsdawbdoc': fsdawbfile,
          'fsdamendment': fsdamendment,
        });
        const headers = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization' : 'Bearer ' + userdata?.token
          }
      }
      let resp = await axios.post(apiUrl + 'adminsubmitfsd', data, headers).then((res) => {
        if(res.data.status == 'success') {
          // setshowdsswaiting(true);
          // setstep3feedback(false);
          // setorderstep(3);
          //  navigate('/cms/customer/requestsample');
          // setissubmitted(true);
          navigate('/cms/admin/proformaInvoice');
        } else {
          alert(res.data.message);
        }
    } ).catch((err) => {
        alert(err.message)
    })
      setshowloding(false);
  }

  const submitfsdfuncv2 = async() => {
    var fsdimagearr1 = fsdimagearr;
    if(fsdimagearr1.length == 1) {
      if(fsdimagearr1[0].image) {
        // console.log('not empty ..');
      } else {
        // console.log('empty ..');
        fsdimagearr1 = [];
      }
    } else {
      // console.log('more then one ..');
    }
    setshowloding(true);
      var data = JSON.stringify(
        {
          'invid': id,
          'fsddocs': fsdimagearr1,
          'fsddate': fsddate,
          'fsdcourier': fsdcourier,
          'fsdawbnumber': fsdawbnumber,
          'fsdawbdoc': fsdawbfile,
          'fsdamendment': fsdamendment,
          'rectificationid': rectificationdata[0].id
        });
        const headers = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization' : 'Bearer ' + userdata?.token
          }
      }
      let resp = await axios.post(apiUrl + 'adminupdatefsd', data, headers).then((res) => {
        if(res.data.status == 'success') {
          navigate('/cms/admin/proformaInvoice');
        } else {
          alert(res.data.message);
        }
    } ).catch((err) => {
        alert(err.message)
    })
      setshowloding(false);
  }

  const submitfsdfuncv3 = async() => {
    var fsdimagearr1 = fsdimagearr;
    if(fsdimagearr1.length == 1) {
      if(fsdimagearr1[0].image) {
        // console.log('not empty ..');
      } else {
        // console.log('empty ..');
        fsdimagearr1 = [];
      }
    } else {
      // console.log('more then one ..');
    }
    setshowloding(true);
      var data = JSON.stringify(
        {
          'invid': id,
          'fsddocs': fsdimagearr1,
          'fsddate': fsddate,
          'fsdcourier': fsdcourier,
          'fsdawbnumber': fsdawbnumber,
          'fsdawbdoc': fsdawbfile,
          'fsdamendment': fsdamendment
        });
        const headers = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization' : 'Bearer ' + userdata?.token
          }
      }
      let resp = await axios.post(apiUrl + 'adminupdatefsdv3', data, headers).then((res) => {
        if(res.data.status == 'success') {
          navigate('/cms/admin/proformaInvoice');
        } else {
          alert(res.data.message);
        }
    } ).catch((err) => {
        alert(err.message)
    })
      setshowloding(false);
  }

  // console.log('rectificationstep ..', rectificationstep);

  console.log('rectificationstep step >>>', rectificationstep);
  

    useEffect(() => {
      if (userdata && Object.keys(userdata).length > 0) {
        // fetchcustomers();
        fetchinvoicedata(id);
        // console.log('invid >>>', id);
      } else {
        navigate("/cms/admin/login");
      }
    }, []);
 

    return (
      <div className="HomeMain">
        <Overlay showloader={showloding} />
        <div className="sidebar-div">
          <Sidebar name={"Admin"} />
        </div>
        <div className="main-layout">
          <Navbar name={"Admin"} />
          <div className="mainDiv">
            <p className="oneplaceHead">ORDER TRACKING</p>
            <div
              style={{ width: "100%", marginTop: -25, position: "relative" }}
            >
              {rectificationdata.length > 0 ? 
              <>
                <button
                onClick={() => {
                  //   submitrfs();
                  // setshowcomment(true)
                  // setshowcomment3(false)
                  
                  navigate("/cms/admin/request-rectification", {state: {rectificationdata: rectificationdata}}) 
                }}
                className="submitrfs-btn submitrfs-btn2 submitrfs-red request-rectification-btn"
                style={{
                  backgroundColor: colors.themeRed,
                  borderColor: colors.themeRed,
                  marginLeft: 20,
                }}
              >
                REQUEST RECTIFICATION
              </button> 
              <div className="rectification-circle">1</div>
              </>
              
              : null }
              
            </div>

            <div className="bardiv">
              <div className="dotstring">
                <div className="dots dots1"></div>
                <div
                  className="dots"
                  style={
                    orderstep > 1
                      ? {
                          backgroundColor: colors.themeBlue,
                          borderColor: rectificationstep == "1" ? 'red' : "white",
                        }
                      : null
                  }
                ></div>
                <div
                  className="dots"
                  style={
                    orderstep > 2
                      ? {
                          backgroundColor: colors.themeBlue,
                          borderColor: rectificationstep == "2" ? 'red' : "white",
                        }
                      : orderstep == 2
                      ? {
                          backgroundColor: colors.themeyellow,
                          borderColor: colors.themeyellow,
                        }
                      : null
                  }
                ></div>
                <div
                  className="dots"
                  style={
                    orderstep > 3
                      ? {
                          backgroundColor: colors.themeBlue,
                          borderColor: rectificationstep == "3" ? 'red' : "white",
                        }
                      : orderstep == 3
                      ? {
                          backgroundColor: colors.themeyellow,
                          borderColor: colors.themeyellow,
                        }
                      : null
                  }
                ></div>
                <div
                  className="dots"
                  style={
                    orderstep == 4
                      ? {
                          backgroundColor: colors.themeBlue,
                          borderColor: rectificationstep == "4" ? 'red' : "white",
                        }
                      : null
                  }
                ></div>
              </div>
              <div class="progress">
                <div
                  class="progress-bar progressbarbagcolor"
                  role="progressbar"
                  aria-valuenow="70"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={
                    orderstep == 2
                      ? { width: "50%" }
                      : orderstep == 3
                      ? { width: "75%" }
                      : orderstep == 4
                      ? { width: "100%" }
                      : { width: "25%" }
                  }
                ></div>
              </div>
              <div className="headstring">
                <div
                  onClick={() => {
                    // setorderstep(1);
                    setorderstep1(1);
                  }}
                  className="headdiv"
                >
                  <p className="headtext headtext1">
                    PROFORMA
                    <br />
                    INVOICE
                  </p>
                </div>
                <div
                  onClick={() => {
                    // setorderstep(2);
                    if(orderstep > 1) {
                      setorderstep1(2);
                    } else {

                    }
                    
                  }}
                  className="headdiv"
                >
                  <p className="headtext headtext2">
                    PRE SHIPMENT
                    <br />
                    SAMPLE
                  </p>
                </div>
                <div
                  onClick={() => {
                    // setorderstep(3);
                    
                    if(orderstep > 2) {
                      setorderstep1(3);
                    } else {
                      
                    }
                  }}
                  className="headdiv"
                >
                  <p className="headtext headtext3">
                    DRAFT SHIPPING
                    <br />
                    DOCUMENTS
                  </p>
                </div>
                <div
                  onClick={() => {
                    // setorderstep(4);
                    if(orderstep > 3) {
                      setorderstep1(4);
                    } else {
                      
                    }
                  }}
                  className="headdiv"
                >
                  <p className="headtext headtext4">
                    FINAL SHIPPING
                    <br />
                    DOCUMENTS
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="mt-3">
             <InvoiceTable title={''} data={Samplesdata} showbtn={false} />
            </div> */}
            {orderstep1 == 1 ? (
              <div className="orderscreen">
                <div className="row adminorderrow">
                  <div className="col-md-5">
                    <p className="orderscreen-p orderscreen-p1">PO Number :</p>
                  </div>
                  <div className="col-md-7">
                    <input
                      value={ponumber}
                      onChange={(t) => {
                        setponumber(t.target.value);
                      }}
                      type="text"
                      className="form-control admin-form-control"
                      placeholder="Enter PO Number"
                    />
                  </div>
                </div>
                <div className="row adminorderrow">
                  <div className="col-md-5">
                    <p className="orderscreen-p orderscreen-p1">
                      Proforma Invoice Date * :
                    </p>
                  </div>
                  <div className="col-md-7">
                    <input
                      value={pfidate}
                      onChange={(t) => {
                        setpfidate(t.target.value);
                      }}
                      type="date"
                      className="form-control admin-form-control"
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="row adminorderrow">
                  <div className="col-md-5">
                    <p className="orderscreen-p orderscreen-p1">
                      Proforma Invoice Number * :
                    </p>
                  </div>
                  <div className="col-md-7">
                    <input
                      value={pfinumber}
                      onChange={(t) => {
                        setpfinumber(t.target.value);
                      }}
                      type="text"
                      className="form-control admin-form-control"
                      placeholder="Enter Proforma Invoice Number"
                    />
                  </div>
                </div>
                <div className="row adminorderrow">
                  <div className="col-md-5">
                    <p className="orderscreen-p orderscreen-p1">
                      Upload PFI here * :
                    </p>
                  </div>
                  <div className="col-md-7">
                    <input
                      accept="application/pdf"
                      type="file"
                      onChange={(t) => {
                        changetext(t.target.files[0]);
                      }}
                      className="form-control admin-form-control"
                      placeholder=""
                    />
                    {
                      pfidoc1 ?
                        <div className="pdfrequestdiv">
                                <img
                                  src={pdf}
                                  style={{ width: 32, height: 32 }}
                                />
                                <Link
                                  target="_blank"
                                  to={JSON.parse(pfidoc1).link}
                                  style={{ textDecoration: "none" }}
                                >
                                  <p
                                    style={{
                                      color: "#000",
                                      fontSize: 20,
                                      fontWeight: "bold",
                                      marginLeft: 10,
                                      marginTop: 3,
                                    }}
                                  >
                                    {JSON.parse(pfidoc1).name}
                                  </p>
                                </Link>
                              </div> : null
                    }
                  </div>
                </div>
                <div className="row adminorderrow">
                  <div className="col-md-5">
                    <p className="orderscreen-p orderscreen-p1">
                      Select customer * :
                    </p>
                  </div>
                  <div className="col-md-7">
                    <select
                      class="form-select admin-form-control custom-input-height"
                      value={selectcustomer}
                      onChange={(t) => {
                        setselectcustomer(t.target.value);
                      }}
                    >
                      <option disabled selected>
                        select customer
                      </option>
                      {customersdata?.map((item, index) => {
                        return <option value={item?.id}>{item?.name}</option>;
                      })}
                    </select>
                  </div>
                </div>

                {/* <div className="row adminorderrow">
                  <div className="col-md-4">
                    <p className="orderscreen-p orderscreen-p1">Preview :</p>
                  </div>
                  <div className="col-md-8">
                    <img src={pdf} style={{ width: 45, height: 45 }} />
                  </div>
                </div> */}
                {
                  orderstep > 1 ?
                  rectificationstep == 1 ?
                  <button
                  onClick={() => {
                    // setisapproved(true);
                    submitpfiv2();
                  }}
                  className="submitrfs-btn submitrfs-btn2"
                  style={{
                    backgroundColor: colors.themeBlue,
                    borderColor: colors.themeBlue,
                    float: "right",
                    marginTop: 10,
                    marginBottom: 50,
                  }}
                >
                  SAVE AND SUBMIT
                </button> : null
                
                : 
                <button
                  onClick={() => {
                    // setisapproved(true);
                    submitpfi();
                  }}
                  className="submitrfs-btn submitrfs-btn2"
                  style={{
                    backgroundColor: colors.themeBlue,
                    borderColor: colors.themeBlue,
                    float: "right",
                    marginTop: 10,
                    marginBottom: 50,
                  }}
                >
                  SAVE AND SUBMIT
                </button>
                }
                
              </div>
            ) : orderstep1 == 2 ? (
              showpsswaiting && rectificationstep !== '2' ? (
                <div className="orderscreen">
                  <p className="oneplaceHead">
                    WAITING FOR CUSTOMER'S FEEDBACK ON PRE SHIPMENT SAMPLE
                  </p>
                </div>
              ) : (
                <div className="orderscreen">
                  {!step2feedback ? (
                    <></>
                  ) : (
                    <>
                      <p className="oneplaceHead">REVISED SAMPLE</p>
                      <div className="uploadhere-box-big">
                        <p
                          className="supporting-documents-head"
                          style={{ marginTop: 0 }}
                        >
                          Customer Feedback:
                        </p>
                        <div className="uploadhere-box uploadhere-box1">
                          <p className="commentstylep">
                            {invoicedata?.psscustomercomment}
                          </p>
                        </div>
                        {/* <div className="pdfrequestdiv">
                        <img src={pdf} style={{width: 32, height: 32}} />
                        <p style={{color: '#000', fontSize: 20, fontWeight: 'bold', marginLeft: 10, marginTop: 3}}>Supporting document</p>
                    </div> */}
                        {invoicedata?.psscustomerdocs ? (
                          <div className="pdfrequestdiv">
                            <img src={pdf} style={{ width: 32, height: 32 }} />
                            <Link
                              target="_blank"
                              to={JSON.parse(invoicedata?.psscustomerdocs).link}
                              style={{ textDecoration: "none" }}
                            >
                              <p
                                style={{
                                  color: "#000",
                                  fontSize: 20,
                                  fontWeight: "bold",
                                  marginLeft: 10,
                                  marginTop: 3,
                                }}
                              >
                                {JSON.parse(invoicedata?.psscustomerdocs).name}
                              </p>
                            </Link>
                          </div>
                        ) : null}
                      </div>
                    </>
                  )}

                  {/* common */}
                  <div className="formdiv mb-5">
                  {invoicedata?.pssisskipped == true ?
                        <p className="oneplaceHead" style={{textAlign : 'left'}}>PSS IS SKIPPED</p> : null
                      }
                        
                    {requestarr?.length > 0 ? (
                      <div className="formbox mt-4">
                        
                        <div className="appendingdiv">
                          <button
                            className="plusbox"
                            onClick={(e) => {
                              e.preventDefault();
                              onplusfunction();
                            }}
                          >
                            <img src={plus} className="plusicon" />
                          </button>
                          <button
                            className="plusbox"
                            onClick={() => {
                              onminusfunction();
                            }}
                          >
                            <img src={minus} className="plusicon" />
                          </button>
                        </div>
                        <table className="table table-bordered invoicetable">
                          <thead>
                            <th>Sr no.</th>
                            <th>Product Name *</th>
                            <th>Quantity *</th>
                            <th>Product Code *</th>
                            <th>Lot No. *</th>
                          </thead>
                          <tbody id="appendbody">
                            {requestarrRef.current?.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    <input
                                      type="text"
                                      value={item["productname"]}
                                      onChange={(t) => {
                                        changetext1(
                                          "productname",
                                          index,
                                          t.target.value
                                        );
                                      }}
                                      className="form-control tabinput"
                                      placeholder="Enter product name"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      value={item["quantity"]}
                                      onChange={(t) => {
                                        changetext1(
                                          "quantity",
                                          index,
                                          t.target.value
                                        );
                                      }}
                                      className="form-control tabinput"
                                      placeholder="Enter quantity"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      value={item["productcode"]}
                                      onChange={(t) => {
                                        changetext1(
                                          "productcode",
                                          index,
                                          t.target.value
                                        );
                                      }}
                                      className="form-control tabinput"
                                      placeholder="Enter product code"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      value={item["lotnumber"]}
                                      onChange={(t) => {
                                        changetext1(
                                          "lotnumber",
                                          index,
                                          t.target.value
                                        );
                                      }}
                                      className="form-control tabinput"
                                      placeholder="Enter lot no."
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    ) : null}
                  </div>
                  <div className="row adminorderrow">
                    <div className="col-md-4">
                      <p className="orderscreen-p orderscreen-p1">Date :</p>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="date"
                        value={passdate}
                        onChange={(t) => {
                          setpassdate(t.target.value);
                        }}
                        className="form-control admin-form-control"
                        placeholder="Enter Lot No."
                      />
                    </div>
                  </div>
                  <div className="row adminorderrow">
                    <div className="col-md-4">
                      <p className="orderscreen-p orderscreen-p1">Upload AWB bill :</p>
                    </div>
                    <div className="col-md-8">
                      <input
                        accept="application/pdf"
                        onChange={(t) => {
                          pssuploadawb(t.target.files[0]);
                        }}
                        type="file"
                        className="form-control admin-form-control"
                        placeholder=""
                      />
                      {
                      pssawbdoc1 ?
                        <div className="pdfrequestdiv">
                                <img
                                  src={pdf}
                                  style={{ width: 32, height: 32 }}
                                />
                                <Link
                                  target="_blank"
                                  to={JSON.parse(pssawbdoc1).link}
                                  style={{ textDecoration: "none" }}
                                >
                                  <p
                                    style={{
                                      color: "#000",
                                      fontSize: 20,
                                      fontWeight: "bold",
                                      marginLeft: 10,
                                      marginTop: 3,
                                    }}
                                  >
                                    {JSON.parse(pssawbdoc1).name}
                                  </p>
                                </Link>
                              </div> : null
                    }
                    </div>
                  </div>
                  {/* <div className="row adminorderrow">
                  <div className="col-md-4">
                    <p className="orderscreen-p orderscreen-p1">Preview :</p>
                  </div>
                  <div className="col-md-8">
                    <img src={pdf} style={{ width: 45, height: 45 }} />
                  </div>
                </div> */}
                 {
                  orderstep > 2 ?
                  rectificationstep == 2 ?
                  <button
                  onClick={() => {
                    // setisapproved(true);
                    submitpssv2();
                  }}
                  className="submitrfs-btn submitrfs-btn2"
                  style={{
                    backgroundColor: colors.themeBlue,
                    borderColor: colors.themeBlue,
                    float: "right",
                    marginTop: 10,
                    marginBottom: 50,
                  }}
                >
                  SAVE AND SUBMIT
                </button> : 
                invoicedata?.pssisskipped == true ?
                <button
                onClick={() => {
                  // setisapproved(true);
                  submitpss();
                }}
                className="submitrfs-btn submitrfs-btn2"
                style={{
                  backgroundColor: colors.themeBlue,
                  borderColor: colors.themeBlue,
                  float: "right",
                  marginTop: 10,
                  marginBottom: 50,
                }}
              >
                SAVE AND SUBMIT
              </button>
               : null
                
                
                : 
                rectificationstep == 2 ? 
                <button
                  onClick={() => {
                    // setisapproved(true);
                    submitpssv2();
                  }}
                  className="submitrfs-btn submitrfs-btn2"
                  style={{
                    backgroundColor: colors.themeBlue,
                    borderColor: colors.themeBlue,
                    float: "right",
                    marginTop: 10,
                    marginBottom: 50,
                  }}
                >
                  UPDATE
                </button>

                : <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                  <button
                    onClick={() => {
                      submitskipppss();
                    }}
                    className="submitrfs-btn submitrfs-btn2"
                    style={{
                      backgroundColor: colors.themeBlue,
                      borderColor: colors.themeBlue,
                      float: "right",
                      marginTop: 10,
                      marginBottom: 50,
                    }}
                  >
                    SKIP PSS
                  </button>
                    <button
                    onClick={() => {
                      // setstep2feedback(!step2feedback)
                      submitpss();
                      // setshowpsswaiting(true)
                    }}
                    className="submitrfs-btn submitrfs-btn2"
                    style={{
                      backgroundColor: colors.themeBlue,
                      borderColor: colors.themeBlue,
                      float: "right",
                      marginTop: 10,
                      marginBottom: 50,
                    }}
                  >
                    SAVE AND SUBMIT
                  </button>
                </div>
                  
                  }
                </div>
              )
            ) : orderstep1 == 3 ? (
              <div className="orderscreen">
                {!step3feedback ? (
                  <></>
                ) : 
                  <div style={{marginBottom: 25}}>
                    <div className="uploadhere-box-big">
                      <p className="supporting-documents-head" style={{marginTop: 0}}>
                        Customer Feedback:
                      </p>
                      <div className="uploadhere-box uploadhere-box1">
                          <p className="commentstylep">
                            {invoicedata?.dsdcomment}
                          </p>
                        </div>
                        {invoicedata?.dsdcustomerdoc ? (
                          <div className="pdfrequestdiv">
                            <img src={pdf} style={{ width: 32, height: 32 }} />
                            <Link
                              target="_blank"
                              to={JSON.parse(invoicedata?.dsdcustomerdoc).link}
                              style={{ textDecoration: "none" }}
                            >
                              <p
                                style={{
                                  color: "#000",
                                  fontSize: 20,
                                  fontWeight: "bold",
                                  marginLeft: 10,
                                  marginTop: 3,
                                }}
                              >
                                {JSON.parse(invoicedata?.dsdcustomerdoc).name}
                              </p>
                            </Link>
                          </div>
                        ) : null}
                    </div>
                    {invoicedata?.dsdadmindocs
                      ? JSON.parse(invoicedata?.dsdadmindocs)?.map(
                          (item, index) => {
                            return (
                              <div className="pdfrequestdiv">
                                <img
                                  src={pdf}
                                  style={{ width: 32, height: 32 }}
                                />
                                <Link
                                  target="_blank"
                                  to={item.link}
                                  style={{ textDecoration: "none" }}
                                >
                                  <p
                                    style={{
                                      color: "#000",
                                      fontSize: 20,
                                      fontWeight: "bold",
                                      marginLeft: 10,
                                      marginTop: 3,
                                    }}
                                  >
                                    {item.name}
                                  </p>
                                </Link>
                              </div>
                            );
                          }
                        )
                      : null}
                  </div>
                }
                { showdsswaiting && rectificationstep !== '3' ?
                  <div className="orderscreen">
                  <p className="oneplaceHead">
                    WAITING FOR CUSTOMER'S FEEDBACK ON DRAFT SHIPPING DOCUMENTS
                  </p>
                </div> :
                <>
                  <div>
                  <div className="row adminorderrow">
                    <div className="col-md-12">
                      <p className="orderscreen-p orderscreen-p1">
                        Upload Draft Shipping Documents here :
                      </p>
                      {
                  orderstep > 3 || rectificationstep == '3' ?
                  invoicedata?.dsdadmindocs
                      ? JSON.parse(invoicedata?.dsdadmindocs)?.map(
                          (item, index) => {
                            return (
                              <div className="pdfrequestdiv">
                                <img
                                  src={pdf}
                                  style={{ width: 32, height: 32 }}
                                />
                                <Link
                                  target="_blank"
                                  to={item.link}
                                  style={{ textDecoration: "none" }}
                                >
                                  <p
                                    style={{
                                      color: "#000",
                                      fontSize: 20,
                                      fontWeight: "bold",
                                      marginLeft: 10,
                                      marginTop: 3,
                                    }}
                                  >
                                    {item.name}
                                  </p>
                                </Link>
                              </div>
                            );
                          }
                        )
                      : null : null
                }
                    </div>
                    <div className="col-md-12">
                    <div className="appendingdiv">
                        <button
                          className="plusbox"
                          onClick={(e) => {
                            e.preventDefault();
                            onplusfunctiondsd();
                          }}
                        >
                          <img src={plus} className="plusicon" />
                        </button>
                        <button
                          className="plusbox"
                          onClick={() => {
                            onminusfunctiondsd();
                          }}
                        >
                          <img src={minus} className="plusicon" />
                        </button>
                      </div>
                      <table className="table table-bordered invoicetable">
                        <thead>
                          <th>Sr no.</th>
                          <th>File</th>
                        </thead>
                        <tbody id="appendbody">
                          {dsdimagearr?.map((item, index) => {
                            // console.log('item.productname >>', item['productname']);
                            return (
                              <tr key={index}>
                                <td> {item["srno"]} </td>
                                <td>
                                  <input
                                    type="file" 
                                    onChange={(t) => {
                                      uploaddssdocsfunc1(index, t.target.files[0]);
                                    }}
                                    accept="application/pdf"
                                    className="form-control admin-form-control"
                                    placeholder=""
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {/* <input
                        type="file"
                        onChange={(t) => {uploaddssdocsfunc(t.target.files)}}
                        accept="application/pdf"
                        multiple
                        className="form-control admin-form-control"
                        placeholder=""
                      /> */}
                    </div>
                  </div>
                  {/* {documentsdssarr1
                      ? JSON.parse(documentsdssarr1)?.map(
                          (item, index) => {
                            return (
                              <div className="pdfrequestdiv">
                                <img
                                  src={pdf}
                                  style={{ width: 32, height: 32 }}
                                />
                                <Link
                                  target="_blank"
                                  to={item.link}
                                  style={{ textDecoration: "none" }}
                                >
                                  <p
                                    style={{
                                      color: "#000",
                                      fontSize: 20,
                                      fontWeight: "bold",
                                      marginLeft: 10,
                                      marginTop: 3,
                                    }}
                                  >
                                    {item.name}
                                  </p>
                                </Link>
                              </div>
                            );
                          }
                        )
                      : null} */}
                </div>
                {
                  orderstep > 3 ?
                  rectificationstep == 3 ?
                <button
                  onClick={() => {
                    // setstep3feedback(!step3feedback);
                    submitdssfunctionv2();
                  }}
                  className="submitrfs-btn submitrfs-btn2"
                  style={{
                    backgroundColor: colors.themeBlue,
                    borderColor: colors.themeBlue,
                    float: "right",
                    marginTop: 10,
                    marginBottom: 50,
                  }}
                >
                  SAVE AND SUBMIT
                </button> : null 
                :
                rectificationstep == 3 ? 
                <button
                  onClick={() => {
                    // setstep3feedback(!step3feedback);
                    submitdssfunctionv2();
                  }}
                  className="submitrfs-btn submitrfs-btn2"
                  style={{
                    backgroundColor: colors.themeBlue,
                    borderColor: colors.themeBlue,
                    float: "right",
                    marginTop: 10,
                    marginBottom: 50,
                  }}
                >
                  SAVE AND SUBMIT
                </button>

                :
                <button
                onClick={() => {
                  // setstep3feedback(!step3feedback);
                  submitdssfunction();
                }}
                className="submitrfs-btn submitrfs-btn2"
                style={{
                  backgroundColor: colors.themeBlue,
                  borderColor: colors.themeBlue,
                  float: "right",
                  marginTop: 10,
                  marginBottom: 50,
                }}
              >
                SAVE AND SUBMIT
              </button>
              }
                </>
                
                }
              </div>
            ) : orderstep1 == 4 ? (
              <div className="orderscreen">
                <div>
                  <div className="row adminorderrow">
                    <div className="col-md-6">
                      <p className="orderscreen-p orderscreen-p1">
                        Upload Final Shipping Documents here :
                      </p>
                      {invoicedata?.fsddocs
                      ? JSON.parse(invoicedata?.fsddocs)?.map(
                          (item, index) => {
                            return (
                              <div className="pdfrequestdiv">
                                <img
                                  src={pdf}
                                  style={{ width: 32, height: 32 }}
                                />
                                <Link
                                  target="_blank"
                                  to={item.link}
                                  style={{ textDecoration: "none" }}
                                >
                                  <p
                                    style={{
                                      color: "#000",
                                      fontSize: 20,
                                      fontWeight: "bold",
                                      marginLeft: 10,
                                      marginTop: 3,
                                    }}
                                  >
                                    {item.name}
                                  </p>
                                </Link>
                              </div>
                            );
                          }
                        )
                      : null}
                    </div>
                    {/* <div className="col-md-6">
                      <input
                        type="file"
                        onChange={(t) => {uploadfsddocsfunc(t.target.files)}}
                        accept="application/pdf"
                        multiple
                        className="form-control admin-form-control"
                        placeholder=""
                      />
                      <div className="mb-4" style={{marginTop: 0}}>
                  {documentsfsdarr1
                      ? JSON.parse(documentsfsdarr1)?.map(
                          (item, index) => {
                            return (
                              <div className="pdfrequestdiv">
                                <img
                                  src={pdf}
                                  style={{ width: 32, height: 32 }}
                                />
                                <Link
                                  target="_blank"
                                  to={item.link}
                                  style={{ textDecoration: "none" }}
                                >
                                  <p
                                    style={{
                                      color: "#000",
                                      fontSize: 20,
                                      fontWeight: "bold",
                                      marginLeft: 10,
                                      marginTop: 3,
                                    }}
                                  >
                                    {item.name}
                                  </p>
                                </Link>
                              </div>
                            );
                          }
                        )
                      : null}
                  </div>
                    </div> */}
                    <div className="col-md-6">
                    <div className="appendingdiv">
                        <button
                          className="plusbox"
                          onClick={(e) => {
                            e.preventDefault();
                            onplusfunctionfsd();
                          }}
                        >
                          <img src={plus} className="plusicon" />
                        </button>
                        <button
                          className="plusbox"
                          onClick={() => {
                            onminusfunctionfsd();
                          }}
                        >
                          <img src={minus} className="plusicon" />
                        </button>
                      </div>
                      <table className="table table-bordered invoicetable">
                        <thead>
                          <th>Sr no.</th>
                          <th>File</th>
                        </thead>
                        <tbody id="appendbody">
                          {fsdimagearr?.map((item, index) => {
                            // console.log('item.productname >>', item['productname']);
                            return (
                              <tr key={index}>
                                <td> {item["srno"]} </td>
                                <td>
                                  <input
                                    type="file" 
                                    onChange={(t) => {
                                      uploadfsddocsfunc1(index, t.target.files[0]);
                                    }}
                                    accept="application/pdf"
                                    className="form-control admin-form-control"
                                    placeholder=""
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {/* <input
                        type="file"
                        onChange={(t) => {uploaddssdocsfunc(t.target.files)}}
                        accept="application/pdf"
                        multiple
                        className="form-control admin-form-control"
                        placeholder=""
                      /> */}
                    </div>
                  </div>
                  
                   

                  <div className="row adminorderrow">
                    <div className="col-md-6">
                      <p className="orderscreen-p orderscreen-p1">Date :</p>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="date"
                        value={fsddate}
                        onChange={(t) => {
                          setfsddate(t.target.value);
                        }}
                        className="form-control admin-form-control"
                        placeholder="Enter Lot No."
                      />
                    </div>
                  </div>
                  <div className="row adminorderrow">
                    <div className="col-md-6">
                      <p className="orderscreen-p orderscreen-p1">Courier :</p>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        value={fsdcourier}
                        onChange={(t) => {
                          setfsdcourier(t.target.value);
                        }}
                        className="form-control admin-form-control"
                        placeholder="Enter Courier No."
                      />
                    </div>
                  </div>
                  <div className="row adminorderrow">
                    <div className="col-md-6">
                      <p className="orderscreen-p orderscreen-p1">AWB bill number :</p>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        value={fsdawbnumber}
                        onChange={(t) => {
                          setfsdawbnumber(t.target.value);
                        }}
                        className="form-control admin-form-control"
                        placeholder="Enter AWB Bill No."
                      />
                    </div>
                  </div>
                  {/* <div className="row adminorderrow">
                    <div className="col-md-6">
                      <p className="orderscreen-p orderscreen-p1">FSD Amendment :</p>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        value={fsdamendment}
                        onChange={(t) => {
                          setfsdamendment(t.target.value);
                        }}
                        className="form-control admin-form-control"
                        placeholder="Enter FSD Amendment"
                      />
                    </div>
                  </div> */}
                  <div className="row adminorderrow">
                    <div className="col-md-6">
                      <p className="orderscreen-p orderscreen-p1">Upload AWB file  :</p>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="file"
                        onChange={(t) => {uploadfsdawbdocsfunc(t.target.files[0])}}
                        accept="application/pdf"
                        className="form-control admin-form-control"
                        placeholder="Enter Lot No."
                      />
                      <div className="mb-2" style={{marginTop: 0}}>
                  {fsdawbfile1
                      ?
                        <div className="pdfrequestdiv">
                                <img
                                  src={pdf}
                                  style={{ width: 32, height: 32 }}
                                />
                                <Link
                                  target="_blank"
                                  to={JSON.parse(fsdawbfile1).link}
                                  style={{ textDecoration: "none" }}
                                >
                                  <p
                                    style={{
                                      color: "#000",
                                      fontSize: 20,
                                      fontWeight: "bold",
                                      marginLeft: 10,
                                      marginTop: 3,
                                    }}
                                  >
                                    {JSON.parse(fsdawbfile1).name}
                                  </p>
                                </Link>
                              </div>
                      : null}
                  </div>
                    </div>
                  </div>
                  
                  
                </div>

                {
                  invoicedata?.adminstatus == '-1' ?
                  rectificationstep == 4 ?
                  <button
                  onClick={() => {
                    // setstep3feedback(!step3feedback)
                    submitfsdfuncv2();
                  }}
                  className="submitrfs-btn submitrfs-btn2"
                  style={{
                    backgroundColor: colors.themeBlue,
                    borderColor: colors.themeBlue,
                    float: "right",
                    marginTop: 10,
                    marginBottom: 50,
                  }}
                >
                  SAVE AND COMPLETE
                </button> : 
                 <button
                 onClick={() => {
                   // setstep3feedback(!step3feedback)
                   submitfsdfuncv3();
                 }}
                 className="submitrfs-btn submitrfs-btn2"
                 style={{
                   backgroundColor: colors.themeBlue,
                   borderColor: colors.themeBlue,
                   float: "right",
                   marginTop: 10,
                   marginBottom: 50,
                 }}
               >
                 UPDATE
               </button>
                  :
                <button
                  onClick={() => {
                    // setstep3feedback(!step3feedback)
                    submitfsdfunc();
                  }}
                  className="submitrfs-btn submitrfs-btn2"
                  style={{
                    backgroundColor: colors.themeBlue,
                    borderColor: colors.themeBlue,
                    float: "right",
                    marginTop: 10,
                    marginBottom: 50,
                  }}
                >
                  SAVE AND COMPLETE
                </button>
                }
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
}

export default AdminOrderTracking